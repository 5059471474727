import {
  Flex,
  Avatar,
  Text,
  Box,
  useBreakpointValue,
} from '@hausgold/designsystem';
import SidebarHeader from 'app-components/page/Sidebar/SidebarHeader';
import { useTranslation } from 'react-i18next';
import { formatFullName } from 'app-services/models/gql/preferencesSet';
import { useUserQuery } from 'app-graphql';
import { SIDEBAR_WIDTH } from 'app-utils/constants/ui';
import usePWADisplayMode from 'app-utils/hooks/usePWADisplayMode';
import SidebarMenu from './SidebarMenu';

const User = () => {
  const { t } = useTranslation();

  const [{ data }] = useUserQuery();

  const name = formatFullName(data?.broker?.preferences);
  const src = data?.broker?.avatar?.fileUrl;

  return (
    <Flex w="100%" alignItems="center" direction="column">
      {/* If a `name` is given, `Avatar` uses it in the first render despite a `src` being available, causing the flicker. */}
      <Avatar size="lg" boxSize="84px" name={!src ? name : null} src={src} />

      <Text color="whiteAlpha.800" size="sm" textAlign="center" mt={3}>
        {name
          ? `${t('sidebar.welcome')}, ${name}.`
          : `${t('sidebar.welcome')}!`}
      </Text>
    </Flex>
  );
};

/**
 * Sidebar
 *
 * Displays the menu sidebar for the whole portal.
 *
 * @param {import('@hausgold/designsystem').FlexProps} props
 */
const Sidebar = (props) => {
  const { isWindowControlsOverlay } = usePWADisplayMode();
  const isMobile = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const showSidebarHeader =
    (!isWindowControlsOverlay && !isMobile) ||
    (isWindowControlsOverlay && isMobile) ||
    (!isWindowControlsOverlay && isMobile);

  return (
    <Flex
      // Note: This is a default and is overwritten at the moment from props normally.
      bg="darkBlue.300"
      width={SIDEBAR_WIDTH}
      direction="column"
      minH="100vh"
      flexShrink={0}
      {...props}
      data-testid="sidebar"
      color="white"
    >
      {showSidebarHeader && <SidebarHeader />}

      {/* Inner sidebar */}
      {/* CAUTION: See caution comment inside SidebarMenu before changing `px`. */}
      <Box py={6} px={4}>
        <User />
        <SidebarMenu />
      </Box>
    </Flex>
  );
};

export default Sidebar;
