import { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { DesignsystemProvider } from '@hausgold/designsystem';
import theme from 'app-utils/theme';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import appsignal, { CATEGORIES } from 'app-lib/appsignal';
import queryClient from 'app-lib/reactQuery';
import { config, isLocal } from 'app-config';
import { HeaderHeightContext } from 'app-utils/contexts/headerHeight';
import { ClientProvider, createClient } from 'app-lib/urql';
import Script from 'next/script';
import ErrorBoundary from 'app-components/ErrorBoundary';
import Router from 'next/router';
import Head from 'next/head';
import { useTranslation } from 'react-i18next';
import { useAccessToken, useUserId } from 'app-services/queries/bundleQueries';
import JabberProvider, {
  JabberContext,
} from 'app-components/jabber/JabberProvider';
import PWAPrompt from 'app-components/PWAPrompt';
import useTracking from 'app-utils/hooks/useTracking';
import StatusPrompt from 'app-components/StatusPrompt/StatusPrompt';
import { anonymizeUrl } from 'app-utils/compute/anonymizeUrl';
import useFirebase from 'app-utils/hooks/useFirebase';
import BundleCheckProvider from 'app-components/misc/BundleCheckProvider';
import FiveHundred from './500.page';

Router.events.on('routeChangeStart', (url) => {
  appsignal.addBreadcrumb({
    action: `Route change to: ${url}`,
    category: CATEGORIES.Navigation,
  });
});

/**
 * Central Wrapper around all components.
 * Manage the
 * - initial auth state handling on entering MP pages.
 * - serviceWorker including firebase auth and token handling as well as push notification redirects.
 * @param Component
 * @param pageProps
 */
export const AppInner = ({ Component, pageProps }) => {
  const userId = useUserId();
  const accessToken = useAccessToken();

  useFirebase();

  /**
   * Segment Page Tracking
   */
  const { segmentPage, segmentTrack } = useTracking();

  const handleSegmentUpdate = (url) => {
    segmentPage({
      url: anonymizeUrl(url),
    });
  };

  /*
   * Track client-side page views with Segment
   * It is only executed once when AppInner is mounted.
   * We use segmentTrack() to track changes to the app's display mode, which is more of a side case from a tracking perspective.
   */
  useEffect(() => {
    Router.events.on('routeChangeComplete', handleSegmentUpdate);
    /*
     * If the component is unmounted, unsubscribe from the event with the `off` method:
     */
    return () => {
      Router.events.off('routeChangeComplete', handleSegmentUpdate);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segmentPage]);

  useEffect(() => {
    /*
     * Track the display mode changes once
     * This is needed because the switch between display modes fires no router event: routeChangeStart or routeChangeComplete.
     * Changing of the display mode to "standalone" also indicates a switch into the PWA. After the user changes the route, the "usePWA" boolean should be set.
     */
    segmentTrack('Display Mode changed');
  }, [segmentTrack]);

  useEffect(() => {
    /*
     * Track initial pageView
     */
    segmentPage({ url: window.location.pathname });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Define user information in jabber.
  const { setClientConfig: setJabberConfig } = useContext(JabberContext);
  useEffect(() => {
    setJabberConfig({ userId, password: accessToken });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, accessToken]);

  return (
    <>
      <PWAPrompt isActive={!!userId} />
      <StatusPrompt />
      <Component {...pageProps} />
      {/* When we have urgent news, we show the InfoModal here.  */}
      {/* {userId && <InfoModal />} */}
    </>
  );
};

AppInner.propTypes = {
  Component: PropTypes.func.isRequired,
  pageProps: PropTypes.object.isRequired,
};

const App = (props) => {
  const { t } = useTranslation();
  const [headerHeight, setHeaderHeight] = useState(null);

  const headerHeightContextValue = useMemo(
    () => ({
      headerHeight,
      setHeaderHeight,
    }),
    [headerHeight, setHeaderHeight]
  );

  const APP_NAME = `${t('glossary:hausgold')} ${t('glossary:maklerportal')}`;
  const { renderSegmentSnippet } = useTracking();

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="application-name" content={APP_NAME} />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="apple-mobile-web-app-title" content={APP_NAME} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="msapplication-config" content="/browserconfig.xml" />
        <meta name="msapplication-TileColor" content="#1098F3" />
        <meta name="msapplication-tap-highlight" content="no" />

        <link rel="apple-touch-icon" sizes="128x128" href="/icon-128.png" />
        <link rel="apple-touch-icon" sizes="144x144" href="/icon-144.png" />
        <link rel="apple-touch-icon" sizes="192x192" href="/icon-192.png" />
        <link rel="apple-touch-icon" sizes="256x256" href="/icon-256.png" />
        <link rel="apple-touch-icon" sizes="512x512" href="/icon-512.png" />
        <link rel="apple-touch-icon" sizes="any" href="/icon-vector.svg" />

        <link rel="manifest" href="/site.webmanifest" />

        <link
          rel="mask-icon"
          href="/icon-maskable-vector.svg"
          color="#F0F1F7"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
      </Head>
      <DesignsystemProvider theme={theme}>
        <ErrorBoundary instance={appsignal} fallback={FiveHundred}>
          <BundleCheckProvider>
            <QueryClientProvider client={queryClient}>
              <ClientProvider createClient={createClient}>
                <JabberProvider config={config.jabber}>
                  <HeaderHeightContext.Provider
                    value={headerHeightContextValue}
                  >
                    <AppInner {...props} />
                  </HeaderHeightContext.Provider>
                </JabberProvider>
              </ClientProvider>
              {isLocal && <ReactQueryDevtools />}
            </QueryClientProvider>
          </BundleCheckProvider>
        </ErrorBoundary>
      </DesignsystemProvider>
      <Script
        dangerouslySetInnerHTML={{ __html: renderSegmentSnippet() }}
        id="segment"
      />
    </>
  );
};

export default App;
