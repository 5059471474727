import commonDE from './de/common.json';
import resourceDE from './de/resource.json';
import formDE from './de/form.json';
import glossaryDE from './de/glossary.json';
import propertyDE from './de/property.json';
import leadDE from './de/lead.json';
import assetDE from './de/asset.json';
import tasksDE from './de/tasks.json';
import stanzaDE from './de/stanza.json';
import paymentDE from './de/payment.json';

export default {
  de: {
    common: commonDE,
    resource: resourceDE,
    form: formDE,
    glossary: glossaryDE,
    lead: leadDE,
    property: propertyDE,
    asset: assetDE,
    tasks: tasksDE,
    stanza: stanzaDE,
    payment: paymentDE,
  },
};
