import {
  Box,
  Button,
  Flex,
  HStack,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@hausgold/designsystem';
import { BurgerMenu } from '@hausgold/designsystem/icons';
import { useHeaderHeight } from 'app-utils/contexts/headerHeight';
import PropTypes from 'prop-types';
import useResizeObserver from 'use-resize-observer';
import usePWADisplayMode from 'app-utils/hooks/usePWADisplayMode';
import SidebarHeader from 'app-components/page/Sidebar/SidebarHeader';
import { SIDEBAR_WIDTH } from 'app-utils/constants/ui';
import { isSafari } from 'app-utils/isEnv';
import Search from './Search';

const Header = ({
  pageTitle,
  showSearchBar,
  drawerIsOpen,
  drawerOnOpen,
  ...rest
}) => {
  const [, setHeaderHeight] = useHeaderHeight();
  const {
    isOpen: searchIsOpen,
    onOpen: searchOnOpen,
    onClose: searchOnClose,
    onToggle: searchOnToggle,
  } = useDisclosure();

  const { ref } = useResizeObserver({
    onResize: ({ height }) => {
      setHeaderHeight(`${height}px`);
    },
  });

  const { isPWA, isWindowControlsOverlay } = usePWADisplayMode();

  const isMobile = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  /*
   * Show a different background style dependent of a shown title bar.
   * The title bar theme-color can't be a gradient and to match the color be show a solid bg color in case it is
   * shown together.
   */
  const bgStyle = {
    ...(isPWA || (isSafari() && isMobile)
      ? { bgColor: 'darkBlue.500' }
      : { bgGradient: 'linear(to-r, darkBlue.500, darkBlue.400)' }),
  };

  return (
    <Flex
      position={{
        base: 'sticky',
        md: 'static',
      }}
      left={0}
      right={0}
      top={0}
      zIndex="sticky"
      height={12}
      color="white"
      alignItems="center"
      ref={ref}
      px={4}
      data-testid="header"
      sx={{
        '@media print': {
          position: 'static',
        },
      }}
      {...bgStyle}
      {...rest}
    >
      {drawerOnOpen && !drawerIsOpen && (
        <Button
          display={{
            base: 'block',
            md: 'none',
          }}
          ml={-4}
          h="100%"
          pl={4}
          pr={4}
          mr={isWindowControlsOverlay ? 0 : 'auto'}
          color="inherit"
          _hover={{
            color: 'inherit',
          }}
          variant="unstyled"
          onClick={drawerOnOpen}
          lineHeight={0}
          sx={{
            '@media print': {
              visibility: 'hidden',
            },
            appRegion: 'no-drag',
          }}
        >
          <BurgerMenu boxSize={isWindowControlsOverlay ? 4 : 6} />
        </Button>
      )}

      <Text
        mb={0}
        color="inherit"
        position={{
          base: 'relative',
          md: 'static',
        }}
        pr={4}
        textTransform="uppercase"
        size="lg"
        noOfLines={1}
      >
        {pageTitle}
      </Text>

      <HStack
        alignItems="center"
        spacing={{
          base: 4,
          sm: 5,
          md: 10,
        }}
        mr={{
          base: 0,
          md: 5,
        }}
        ml={isWindowControlsOverlay && isMobile ? '0' : 'auto'}
        justifyContent="flex-end"
        sx={{
          '@media print': {
            visibility: 'hidden',
          },
        }}
      >
        {showSearchBar && (
          <Search
            isOpen={searchIsOpen}
            onOpen={searchOnOpen}
            onClose={searchOnClose}
            onToggle={searchOnToggle}
          />
        )}
      </HStack>
    </Flex>
  );
};

Header.propTypes = {
  pageTitle: PropTypes.string,
  showSearchBar: PropTypes.bool,
  drawerIsOpen: PropTypes.bool,
  drawerOnOpen: PropTypes.func,
};

Header.defaultProps = {
  pageTitle: null,
  showSearchBar: false,
  drawerIsOpen: false,
  drawerOnOpen: null,
};

export const WithWcoHeader = ({ headerProps }) => {
  const { isWindowControlsOverlay } = usePWADisplayMode();

  const isMobile = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  return (
    <Box
      bgColor="darkBlue.500"
      sx={{
        appRegion: 'drag',
        position: 'sticky',
        top: '0',
        left: '0',
        zIndex: 'sticky',
        height: 'env(titlebar-area-height, 48px)',
      }}
    >
      <Box
        sx={{
          '@media print': {
            position: 'static',
          },
          position: 'absolute',
          top: 'env(titlebar-area-y, 0)',
          left: 'env(titlebar-area-x, 0)',
          height: 'env(titlebar-area-height, 48px)',
          width:
            isWindowControlsOverlay && !isMobile
              ? `env(titlebar-area-width , 100%)`
              : '100%',
          transition: 'width .5s',
        }}
      >
        <HStack spacing={0}>
          {!isMobile && (
            <Box>
              <SidebarHeader
                width={SIDEBAR_WIDTH}
                height="env(titlebar-area-height, 48px)"
              />
            </Box>
          )}

          <Header
            {...headerProps}
            width="100%"
            height="env(titlebar-area-height, 48px)"
          />
        </HStack>
      </Box>
    </Box>
  );
};

export default Header;
