import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { isLocal } from 'app-config';
import {
  formatDate,
  formatNumber,
  formatPrice,
  formatTime,
} from 'app-services/utils/formatters';
import languages from '../../../i18n';
import falsyContext from './falsyContext';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(falsyContext)
  .init({
    fallbackLng: 'de',
    debug: isLocal,
    ns: ['common', 'resource', 'glossary', 'form', 'property', 'lead'],
    defaultNS: 'resource',
    whitelist: ['de'],
    resources: languages,
    react: {
      useSuspense: true,
      bindI18n: 'languageChanged',
    },
    postProcess: ['falsyContext'],
    lng: 'de',
    interpolation: {
      /*
       * Defines custom formatter functions
       * https://www.i18next.com/translation-function/formatting
       */
      format(value, format, lng) {
        if (typeof value === 'string') {
          /*
           * Formats a string to uppercase
           * Example: {{value, uppercase}}
           */
          if (format === 'uppercase') return value.toUpperCase();

          /*
           * Formats a string to lowercase
           * Example: {{value, lowercase}}
           */
          if (format === 'lowercase') return value.toLowerCase();
        }

        if (value instanceof Date) {
          /*
           * Formats date to dd.MM.yyy
           * Example: {{value, time}}
           */
          if (format === 'date') return formatDate(value);

          /*
           * Formats time to HH:mm
           * Example: {{value, time}}
           */
          if (format === 'time') return formatTime(value);

          /*
           * Date formatting with format given via `format`.
           * Example: {{value, dd.MM.yy}} or {{value, dd.MM.yyyy HH:mm
           */
          return formatDate(value, format);
        }

        if (typeof value === 'number') {
          /*
           * Formats currency
           * Example: {{value, currency}}
           */
          if (format === 'currency') {
            return formatPrice(parseFloat(value), null, lng);
          }

          /*
           * Formats a number
           * Example: {{value, number}}
           */
          if (format === 'number') {
            return formatNumber(parseFloat(value), null, lng);
          }
        }

        return value;
      },

      // Not needed, as React escapes by default.
      escapeValue: false,
    },
  });

export default i18n;
