import { PropertyPropertyType } from 'app-graphql';
import {
  RealtyTypeApartment,
  RealtyTypeCommercial,
  RealtyTypeHouse,
  RealtyTypeOther,
  RealtyTypeSite,
} from '@hausgold/designsystem/illustrations';

/** Results per page (for paginations, lists) */
export const RESULTS_PER_PAGE = 20;

/**
 * Returns a property icon (as a react element) for a given property type#
 *
 * @param {string} propertyType
 * @return {React.ReactElement}
 */
export const PROPERTY_TYPE_ICON = (propertyType) => {
  switch (propertyType) {
    case PropertyPropertyType.Room:
    case PropertyPropertyType.Apartment:
      return RealtyTypeApartment;
    case PropertyPropertyType.House:
      return RealtyTypeHouse;
    case PropertyPropertyType.Site:
    case PropertyPropertyType.Agriculture:
      return RealtyTypeSite;
    case PropertyPropertyType.Office:
    case PropertyPropertyType.Retail:
    case PropertyPropertyType.HospitalityIndustry:
    case PropertyPropertyType.Warehouse:
    case PropertyPropertyType.Parking:
    case PropertyPropertyType.LeisureCommercial:
    case PropertyPropertyType.InterestHouse:
      return RealtyTypeCommercial;
    default:
      return RealtyTypeOther;
  }
};

/**
 * Decides when the layout on the lead detail page is switched.
 */
export const BREAKPOINT = 'lg';

/**
 * The Sidebar width
 *
 * This is used for HAUSGOLD sidebar, that is different in size than chakra's responsive values. Closest is `2xs`, which is 256px. We use 17 * 16, which is 272px.
 * Original Maklerportal uses 275px.
 *
 * CAUTION: This constant is used in different places to keep the Sidebar, PageSlide and DrawerContent in sync.
 */

export const SIDEBAR_WIDTH = '272px';

/**
 * The SimpleGrid props used by the LeadList and other grid components to make them flush with each other.
 * @type {{sx: {justifyContent: string}, gap: number, templateColumns: {lg: string, base: string}}}
 */

export const CARD_GRID_PROPS = {
  templateColumns: {
    base: '1fr',
    lg: 'repeat(2, minmax(10px, 500px))',
  },
  sx: {
    justifyContent: 'center',
  },
  gap: 6,
};

/**
 * This email ending indicates that the provided email is fake and only used as placeholder for backend.
 * No user can be reached with.
 */
export const PLACEHOLDER_MAIL = '@no-email.hausgold.de';
