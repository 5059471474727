module.exports = {
  "environment": "production",
  "cookieHost": ".hausgold.de",
  "trustedDomain": ".hausgold.de",
  "url": "https://maklerportal-next.hausgold.de",
  "googleMaps": {
    "apiKey": "AIzaSyALZoaJZXYu5BnVNBi9uQMZ65vAV_vMqYA"
  },
  "segment": {
    "apiKey": "OUTs1JhdqFYBy719gmpD2n7hwT8WOYfP"
  },
  "deployment": {
    "commit": "982ee8e4fdcb8e8a98772d29c2360ccd267c9726",
    "branch": "refs/tags/v2.90.0",
    "deployedAt": "2024-08-19T08:43:26Z",
    "s3Bucket": "maklerportal-next-frontend-production"
  },
  "appsignal": {
    "appName": "Maklerportal Next Frontend",
    "key": "d2749f59-643e-4406-83e8-ad89a28af141",
    "pushKey": "393e9a6e-3c5a-4eb8-ac98-e3fe82b499cf",
    "revision": "982ee8e4fdcb8e8a98772d29c2360ccd267c9726",
    "branch": "refs/tags/v2.90.0",
    "active": true
  },
  "firebase": {
    "apiKey": "AIzaSyA3gWCyoXElWt-csvLZZWF4wIMk1SCFIPE",
    "authDomain": "hausgold-connect.firebaseapp.com",
    "databaseURL": "https://hausgold-connect.firebaseio.com",
    "projectId": "hausgold-connect",
    "storageBucket": "hausgold-connect.appspot.com",
    "messagingSenderId": "462307856009",
    "appId": "1:462307856009:web:7118273ad9277036a24058",
    "measurementId": "G-9SVXE32TPS"
  },
  "features": {
  },
  "zap": {
    "support": "https://hooks.zapier.com/hooks/catch/2548295/3qh9s9f/",
    "leadBid": "https://hooks.zapier.com/hooks/catch/2548295/2opli5o/"
  },
  "contentful": {
    "host": "cdn.contentful.com",
    "space": "5qea3umoe8gl",
    "accessToken": "DdzT4koJdPWFme6fktB4wxnZXfvjgiBj_OquI3O51j0"
  }
};
