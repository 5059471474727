// https://github.com/hausgold/knowledge/tree/master/concepts/jabber/1-jabber#lead-was-imoprted-lead-added-legacy-imported
const leadAddedLegacy = (client, stanzas) => {
  const types = stanzas.utils;
  const NS = 'xmpp:hausgold:lead:added_legacy';

  stanzas.define({
    name: 'lead_added_legacy',
    element: 'lead_added_legacy',
    namespace: NS,
    fields: {
      id: types.attribute('id'),
      property_id: types.attribute('property_id'),
      supervisor: types.textSub('supervisor'),
      created_at: types.textSub('created_at'),
    },
  });
};

export default leadAddedLegacy;
