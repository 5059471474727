/* prettier-ignore */
/* eslint-disable */
/** ID of an entity (UUID). */
export type ID = `${string}-${string}-${string}-${string}-${string}`;

 /** An ID that is globally identifiable throughout the entire HAUSGOLD ecosystem. The structure is `gid://name-api/model/model-id\`. */
export type GlobalId<Api extends string = string, Model extends string = string> = `gid://${Api}/${Model}/${ID}`;

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: ID;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * An ID of a `AccountBalancePackage` entity, or a valid `AccountBalancePackage` GlobalID.
   * GlobalIDs of other entity types are rejected. If provided a
   * GlobalID, it must have the structure
   * `gid://payment-api/AccountBalancePackage/model-id`.
   */
  AccountBalancePackageGlobalIdOrUuid: GlobalId<'payment-api', 'AccountBalancePackage'> | ID;
  /**
   * An ID of a `AccountBalanceTransaction` entity, or a valid `AccountBalanceTransaction` GlobalID.
   * GlobalIDs of other entity types are rejected. If provided a
   * GlobalID, it must have the structure
   * `gid://payment-api/AccountBalanceTransaction/model-id`.
   */
  AccountBalanceTransactionGlobalIdOrUuid: GlobalId<'payment-api', 'AccountBalanceTransaction'> | ID;
  /**
   * An ID of a `Activity` entity, or a valid `Activity` GlobalID.
   * GlobalIDs of other entity types are rejected. If provided a
   * GlobalID, it must have the structure
   * `gid://preferences/Activity/model-id`.
   */
  ActivityGlobalIdOrUuid: GlobalId<'preferences', 'Activity'> | ID;
  /**
   * An ID of a `Appointment` entity, or a valid `Appointment` GlobalID.
   * GlobalIDs of other entity types are rejected. If provided a
   * GlobalID, it must have the structure
   * `gid://calendar-api/Appointment/model-id`.
   */
  AppointmentGlobalIdOrUuid: GlobalId<'calendar-api', 'Appointment'> | ID;
  /**
   * An ID of a `Asset` entity, or a valid `Asset` GlobalID.
   * GlobalIDs of other entity types are rejected. If provided a
   * GlobalID, it must have the structure
   * `gid://asset-api/Asset/model-id`.
   */
  AssetGlobalIdOrUuid: GlobalId<'asset-api', 'Asset'> | ID;
  /**
   * An ID of a `Broker` entity, or a valid `Broker` GlobalID.
   * GlobalIDs of other entity types are rejected. If provided a
   * GlobalID, it must have the structure
   * `gid://maklerportal-api/User/model-id`.
   */
  BrokerGlobalIdOrUuid: GlobalId<'maklerportal-api', 'User'> | ID;
  /**
   * An ID of a `Customer` entity, or a valid `Customer` GlobalID.
   * GlobalIDs of other entity types are rejected. If provided a
   * GlobalID, it must have the structure
   * `gid://verkaeuferportal-api/User/model-id`.
   */
  CustomerGlobalIdOrUuid: GlobalId<'verkaeuferportal-api', 'User'> | ID;
  /**
   * An ID of a `DataPoint` entity, or a valid `DataPoint` GlobalID.
   * GlobalIDs of other entity types are rejected. If provided a
   * GlobalID, it must have the structure
   * `gid://analytic-api/DataPoint/model-id`.
   */
  DataPointGlobalIdOrUuid: GlobalId<'analytic-api', 'DataPoint'> | ID;
  /** An ISO 8601 compatible date time scalar. */
  DateTime: string;
  /** Either an uploaded file or the URL of the file to be uploaded. If a file is to be uploaded, we make use of the GraphQL Multipart Request Specification v2. */
  File: File | Blob;
  /**
   * An ID of a `FinancingLead` entity, or a valid `FinancingLead` GlobalID.
   * GlobalIDs of other entity types are rejected. If provided a
   * GlobalID, it must have the structure
   * `gid://leads-api/FinancingLead/model-id`.
   */
  FinancingLeadGlobalIdOrUuid: GlobalId<'leads-api', 'FinancingLead'> | ID;
  /**
   * An ID that is globally identifiable throughout the entire HAUSGOLD
   * ecosystem and can be automatically converted to its corresponding type.
   *
   * The structure of a GID is always as follows:
   * `gid://app-name/ModelName/model-id`
   */
  GlobalID: GlobalId;
  /**
   * JSON doesn't support IEEE 754 to include all possible float values.
   * This means that values such as `NaN` and `Infinity` can not be properly
   * passed in a JSON object.
   *
   * Therefore this custom scalar adds support for those. Regular float
   * values can be passed as normal JSON floats, whereas the special values
   * can be passed in a stringified form.
   *
   * The supported special values are `"Infinity"`, `"-Infinity"`
   * and `"NaN"`.
   */
  IEEE754Float: number | "Infinity" | "-Infinity" | "NaN";
  /**
   * An ID of a `InsightsQuery` entity, or a valid `InsightsQuery` GlobalID.
   * GlobalIDs of other entity types are rejected. If provided a
   * GlobalID, it must have the structure
   * `gid://intelligence/Query/model-id`.
   */
  InsightsQueryGlobalIdOrUuid: GlobalId<'intelligence', 'Query'> | ID;
  /**
   * An ID of a `InsuranceLead` entity, or a valid `InsuranceLead` GlobalID.
   * GlobalIDs of other entity types are rejected. If provided a
   * GlobalID, it must have the structure
   * `gid://leads-api/InsuranceLead/model-id`.
   */
  InsuranceLeadGlobalIdOrUuid: GlobalId<'leads-api', 'InsuranceLead'> | ID;
  /**
   * An ID of a `InvoiceAddress` entity, or a valid `InvoiceAddress` GlobalID.
   * GlobalIDs of other entity types are rejected. If provided a
   * GlobalID, it must have the structure
   * `gid://payment-api/InvoiceAddress/model-id`.
   */
  InvoiceAddressGlobalIdOrUuid: GlobalId<'payment-api', 'InvoiceAddress'> | ID;
  /**
   * An unspecific JSON Blob, which is used if a field's type can't be
   * further specified. It works as a scalar type for GraphQL, so you can't
   * further select deep values. Instead the full object blob is returned.
   *
   * This type is used for schema-free client data blobs, or as fallback.
   */
  JsonBlob: Record<string, unknown>;
  /**
   * An ID of a `Lead` entity, or a valid `Lead` GlobalID.
   * GlobalIDs of other entity types are rejected. If provided a
   * GlobalID, it must have the structure
   * `gid://maklerportal-api/Lead/model-id`.
   */
  LeadGlobalIdOrUuid: GlobalId<'maklerportal-api', 'Lead'> | ID;
  /**
   * An ID of a `LeadManager` entity, or a valid `LeadManager` GlobalID.
   * GlobalIDs of other entity types are rejected. If provided a
   * GlobalID, it must have the structure
   * `gid://maklerportal-api/LeadManager/model-id`.
   */
  LeadManagerGlobalIdOrUuid: GlobalId<'maklerportal-api', 'LeadManager'> | ID;
  /**
   * An ID of a `Login` entity, or a valid `Login` GlobalID.
   * GlobalIDs of other entity types are rejected. If provided a
   * GlobalID, it must have the structure
   * `gid://identity-api/Login/model-id`.
   */
  LoginGlobalIdOrUuid: GlobalId<'identity-api', 'Login'> | ID;
  /**
   * An ID of a `NotificationToken` entity, or a valid `NotificationToken` GlobalID.
   * GlobalIDs of other entity types are rejected. If provided a
   * GlobalID, it must have the structure
   * `gid://identity-api/NotificationToken/model-id`.
   */
  NotificationTokenGlobalIdOrUuid: GlobalId<'identity-api', 'NotificationToken'> | ID;
  /**
   * An ID of a `Office` entity, or a valid `Office` GlobalID.
   * GlobalIDs of other entity types are rejected. If provided a
   * GlobalID, it must have the structure
   * `gid://maklerportal-api/Office/model-id`.
   */
  OfficeGlobalIdOrUuid: GlobalId<'maklerportal-api', 'Office'> | ID;
  /**
   * An ID of a `Organization` entity, or a valid `Organization` GlobalID.
   * GlobalIDs of other entity types are rejected. If provided a
   * GlobalID, it must have the structure
   * `gid://maklerportal-api/Organization/model-id`.
   */
  OrganizationGlobalIdOrUuid: GlobalId<'maklerportal-api', 'Organization'> | ID;
  /**
   * An ID of a `Payment` entity, or a valid `Payment` GlobalID.
   * GlobalIDs of other entity types are rejected. If provided a
   * GlobalID, it must have the structure
   * `gid://payment-api/Payment/model-id`.
   */
  PaymentGlobalIdOrUuid: GlobalId<'payment-api', 'Payment'> | ID;
  /**
   * An ID of a `PaymentMandate` entity, or a valid `PaymentMandate` GlobalID.
   * GlobalIDs of other entity types are rejected. If provided a
   * GlobalID, it must have the structure
   * `gid://payment-api/PaymentMandate/model-id`.
   */
  PaymentMandateGlobalIdOrUuid: GlobalId<'maklerportal-api', 'PaymentMandate'> | ID;
  /**
   * An ID of a `Pdf` entity, or a valid `Pdf` GlobalID.
   * GlobalIDs of other entity types are rejected. If provided a
   * GlobalID, it must have the structure
   * `gid://pdf-api/Pdf/model-id`.
   */
  PdfGlobalIdOrUuid: GlobalId<'pdf-api', 'Pdf'> | ID;
  /**
   * An ID of a `Policy` entity, or a valid `Policy` GlobalID.
   * GlobalIDs of other entity types are rejected. If provided a
   * GlobalID, it must have the structure
   * `gid://identity-api/Policy/model-id`.
   */
  PolicyGlobalIdOrUuid: GlobalId<'identity-api', 'Policy'> | ID;
  /**
   * An ID of a `PreferencesSet` entity, or a valid `PreferencesSet` GlobalID.
   * GlobalIDs of other entity types are rejected. If provided a
   * GlobalID, it must have the structure
   * `gid://preferences/PreferencesSet/model-id`.
   */
  PreferencesSetGlobalIdOrUuid: GlobalId<'preferences', 'PreferencesSet'> | ID;
  /**
   * An ID of a `Property` entity, or a valid `Property` GlobalID.
   * GlobalIDs of other entity types are rejected. If provided a
   * GlobalID, it must have the structure
   * `gid://property-api/Property/model-id`.
   */
  PropertyGlobalIdOrUuid: GlobalId<'property-api', 'Property'> | ID;
  /**
   * An ID of a `Review` entity, or a valid `Review` GlobalID.
   * GlobalIDs of other entity types are rejected. If provided a
   * GlobalID, it must have the structure
   * `gid://analytic-api/Review/model-id`.
   */
  ReviewGlobalIdOrUuid: GlobalId<'analytic-api', 'Review'> | ID;
  /**
   * An ID of a `Role` entity, or a valid `Role` GlobalID.
   * GlobalIDs of other entity types are rejected. If provided a
   * GlobalID, it must have the structure
   * `gid://identity-api/Role/model-id`.
   */
  RoleGlobalIdOrUuid: GlobalId<'identity-api', 'Role'> | ID;
  /**
   * An ID of a `SearchProfile` entity, or a valid `SearchProfile` GlobalID.
   * GlobalIDs of other entity types are rejected. If provided a
   * GlobalID, it must have the structure
   * `gid://property-api/SearchProfile/model-id`.
   */
  SearchProfileGlobalIdOrUuid: GlobalId<'property-api', 'SearchProfile'> | ID;
  /**
   * An ID of a `Task` entity, or a valid `Task` GlobalID.
   * GlobalIDs of other entity types are rejected. If provided a
   * GlobalID, it must have the structure
   * `gid://calendar-api/Task/model-id`.
   */
  TaskGlobalIdOrUuid: GlobalId<'calendar-api', 'Task'> | ID;
  /**
   * An ID of a `Timeframe` entity, or a valid `Timeframe` GlobalID.
   * GlobalIDs of other entity types are rejected. If provided a
   * GlobalID, it must have the structure
   * `gid://calendar-api/Timeframe/model-id`.
   */
  TimeframeGlobalIdOrUuid: GlobalId<'calendar-api', 'Timeframe'> | ID;
  /**
   * An ID of a `User` entity, or a valid `User` GlobalID.
   * GlobalIDs of other entity types are rejected. If provided a
   * GlobalID, it must have the structure
   * `gid://identity-api/User/model-id`.
   */
  UserGlobalIdOrUuid: GlobalId<'identity-api', 'User'> | ID;
};

/** The HAUSGOLD ecosystem allows to manage an account balance for invoice addresses. This account balance can be increased by purchasing pre-specified packages. */
export type AccountBalancePackage = CanHaveDataPoints & GlobalIdentifiable & {
  __typename?: 'AccountBalancePackage';
  /** The bonus of the package in cents */
  bonus?: Maybe<Scalars['Int']>;
  /** When the package was created (ISO 8601) */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Searches for referenced `data_points` */
  dataPoints?: Maybe<DataPointsResult>;
  /** Global ID of the package (UUID) */
  gid: Scalars['GlobalID'];
  /** ID of the package (UUID) */
  id: Scalars['ID'];
  /** The price of the package in cents */
  price?: Maybe<Scalars['Int']>;
  /** The total amount that this package gains the user in cents */
  totalAmount?: Maybe<Scalars['Int']>;
  /** When the package was last updated (ISO 8601) */
  updatedAt?: Maybe<Scalars['DateTime']>;
};


/** The HAUSGOLD ecosystem allows to manage an account balance for invoice addresses. This account balance can be increased by purchasing pre-specified packages. */
export type AccountBalancePackageDataPointsArgs = {
  aggregation?: InputMaybe<Aggregation>;
  context: Scalars['String'];
  endAt?: InputMaybe<Scalars['DateTime']>;
  interval?: InputMaybe<Interval>;
  metrics: Array<Scalars['String']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
};

/** Autogenerated return type of AccountBalancePackageAddToPayment */
export type AccountBalancePackageAddToPaymentPayload = {
  __typename?: 'AccountBalancePackageAddToPaymentPayload';
  accountBalancePackage?: Maybe<AccountBalancePackage>;
  error?: Maybe<MutationError>;
};

export type AccountBalancePackageEdge = {
  __typename?: 'AccountBalancePackageEdge';
  /** The `AccountBalancePackage` entity that this edge points to. */
  node: AccountBalancePackage;
};

export type AccountBalancePackageNamespace = {
  __typename?: 'AccountBalancePackageNamespace';
  /** Adds this entity as an item to the given payment. The payment can be passed as an actual instance of `Hausgold::Payment` via the `payment` argument or its UUID identifier as `payment_id`. One of these variants must be given. */
  addToPayment: AccountBalancePackageAddToPaymentPayload;
  /** Removes this entity (as payment item) from the given payment. The payment can be passed as an actual instance of `Hausgold::Payment` via the `payment` argument or its UUID identifier as `payment_id`. One of these variants must be given. */
  removeFromPayment: AccountBalancePackageRemoveFromPaymentPayload;
};


export type AccountBalancePackageNamespaceAddToPaymentArgs = {
  id: Scalars['AccountBalancePackageGlobalIdOrUuid'];
  paymentId: Scalars['ID'];
};


export type AccountBalancePackageNamespaceRemoveFromPaymentArgs = {
  id: Scalars['AccountBalancePackageGlobalIdOrUuid'];
  paymentId: Scalars['ID'];
};

export type AccountBalancePackagePage = {
  __typename?: 'AccountBalancePackagePage';
  /** The total count of results for this search. If this is more than `limit` you may repeat the request with a higher `offset` to get the remaining nodes. */
  count: Scalars['Int'];
  /** The connections to the `AccountBalancePackage` entities that are included in this page. */
  edges: Array<AccountBalancePackageEdge>;
  /** The limit that was used to generate this page. The limit is the upper bound of how many nodes are returned. */
  limit: Scalars['Int'];
  /** The `AccountBalancePackage` entities that are included in this page. */
  nodes: Array<AccountBalancePackage>;
  /** The offset that was used to generate this page. */
  offset: Scalars['Int'];
};

/** Autogenerated return type of AccountBalancePackageRemoveFromPayment */
export type AccountBalancePackageRemoveFromPaymentPayload = {
  __typename?: 'AccountBalancePackageRemoveFromPaymentPayload';
  accountBalancePackage?: Maybe<AccountBalancePackage>;
  error?: Maybe<MutationError>;
};

export type AccountBalancePackageSearchParameters = {
  ids?: InputMaybe<Array<Scalars['AccountBalancePackageGlobalIdOrUuid']>>;
};

/** The HAUSGOLD ecosystem allows to manage an account balance for invoice addresses. */
export type AccountBalanceTransaction = CanHaveDataPoints & GlobalIdentifiable & {
  __typename?: 'AccountBalanceTransaction';
  /** The actor that has caused this transaction to occur (UUID) */
  actor?: Maybe<GlobalIdentifiable>;
  /** The amount that has changed in cents */
  amount?: Maybe<Scalars['Int']>;
  /** When the transaction was created (ISO 8601) */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The currency of the transaction */
  currency?: Maybe<AccountBalanceTransactionCurrency>;
  /** Searches for referenced `data_points` */
  dataPoints?: Maybe<DataPointsResult>;
  /** Global ID of the transaction (UUID) */
  gid: Scalars['GlobalID'];
  /** ID of the transaction (UUID) */
  id: Scalars['ID'];
  /** ID of the related invoice address of which the balance has changed (UUID) */
  invoiceAddressId?: Maybe<Scalars['ID']>;
  /** The subtype in case this is a manual transaction */
  manualType?: Maybe<AccountBalanceTransactionManualType>;
  /** A manually added note */
  note?: Maybe<Scalars['String']>;
  /** A referenced entity that is related to this transaction (UUID) */
  reference?: Maybe<GlobalIdentifiable>;
  /** The type of the transaction */
  transactionType?: Maybe<AccountBalanceTransactionTransactionType>;
  /** When the transaction was last updated (ISO 8601) */
  updatedAt?: Maybe<Scalars['DateTime']>;
};


/** The HAUSGOLD ecosystem allows to manage an account balance for invoice addresses. */
export type AccountBalanceTransactionDataPointsArgs = {
  aggregation?: InputMaybe<Aggregation>;
  context: Scalars['String'];
  endAt?: InputMaybe<Scalars['DateTime']>;
  interval?: InputMaybe<Interval>;
  metrics: Array<Scalars['String']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
};

/** The currency of the transaction */
export enum AccountBalanceTransactionCurrency {
  Aed = 'AED',
  Afa = 'AFA',
  All = 'ALL',
  Amd = 'AMD',
  Ang = 'ANG',
  Aon = 'AON',
  Arp = 'ARP',
  Ats = 'ATS',
  Aud = 'AUD',
  Awf = 'AWF',
  Azm = 'AZM',
  Bak = 'BAK',
  Bbd = 'BBD',
  Bdt = 'BDT',
  Bef = 'BEF',
  Bes = 'BES',
  Bgl = 'BGL',
  Bhd = 'BHD',
  Bif = 'BIF',
  Bmd = 'BMD',
  Bnd = 'BND',
  Bob = 'BOB',
  Brl = 'BRL',
  Bsd = 'BSD',
  Btr = 'BTR',
  Bwp = 'BWP',
  Byr = 'BYR',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Chf = 'CHF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Crc = 'CRC',
  Cup = 'CUP',
  Cuw = 'CUW',
  Cve = 'CVE',
  Cyp = 'CYP',
  Czk = 'CZK',
  Dem = 'DEM',
  Djf = 'DJF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Ecs = 'ECS',
  Eek = 'EEK',
  Egp = 'EGP',
  Ern = 'ERN',
  Esp = 'ESP',
  Etb = 'ETB',
  Eur = 'EUR',
  Fim = 'FIM',
  Fjd = 'FJD',
  Fkp = 'FKP',
  Frf = 'FRF',
  Gbp = 'GBP',
  Gel = 'GEL',
  Ghc = 'GHC',
  Gip = 'GIP',
  Gmd = 'GMD',
  Gnf = 'GNF',
  Grd = 'GRD',
  Gtq = 'GTQ',
  Gyd = 'GYD',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Hrk = 'HRK',
  Htg = 'HTG',
  Huf = 'HUF',
  Idr = 'IDR',
  Iep = 'IEP',
  Ils = 'ILS',
  Inr = 'INR',
  Iqd = 'IQD',
  Irr = 'IRR',
  Isk = 'ISK',
  Itl = 'ITL',
  Jmd = 'JMD',
  Jod = 'JOD',
  Jpy = 'JPY',
  Kes = 'KES',
  Kgs = 'KGS',
  Khr = 'KHR',
  Kmf = 'KMF',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kyd = 'KYD',
  Kzt = 'KZT',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Lrd = 'LRD',
  Lsl = 'LSL',
  Ltl = 'LTL',
  Luf = 'LUF',
  Lvl = 'LVL',
  Lyd = 'LYD',
  Mad = 'MAD',
  Mdl = 'MDL',
  Mgf = 'MGF',
  Mmk = 'MMK',
  Mnt = 'MNT',
  Mop = 'MOP',
  Mro = 'MRO',
  Mtl = 'MTL',
  Mur = 'MUR',
  Mvr = 'MVR',
  Mwk = 'MWK',
  Mxp = 'MXP',
  Mzm = 'MZM',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nio = 'NIO',
  Nlg = 'NLG',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pab = 'PAB',
  Pen = 'PEN',
  Pgk = 'PGK',
  Php = 'PHP',
  Pkr = 'PKR',
  Plz = 'PLZ',
  Pte = 'PTE',
  Pyg = 'PYG',
  Qar = 'QAR',
  Rol = 'ROL',
  Rur = 'RUR',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sbd = 'SBD',
  Sbl = 'SBL',
  Scr = 'SCR',
  Sdd = 'SDD',
  Sek = 'SEK',
  Sgd = 'SGD',
  Shp = 'SHP',
  Sit = 'SIT',
  Skk = 'SKK',
  Sll = 'SLL',
  Sos = 'SOS',
  Srg = 'SRG',
  Std = 'STD',
  Svc = 'SVC',
  Syp = 'SYP',
  Szl = 'SZL',
  Thb = 'THB',
  Tjr = 'TJR',
  Tmm = 'TMM',
  Tnd = 'TND',
  Top = 'TOP',
  Trl = 'TRL',
  Ttd = 'TTD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Ugx = 'UGX',
  Usd = 'USD',
  Uyu = 'UYU',
  Uzs = 'UZS',
  Veb = 'VEB',
  Vnd = 'VND',
  Vuv = 'VUV',
  Wst = 'WST',
  Xaf = 'XAF',
  Xag = 'XAG',
  Xau = 'XAU',
  Xcd = 'XCD',
  Xco = 'XCO',
  Xdr = 'XDR',
  Xpd = 'XPD',
  Xpf = 'XPF',
  Xpt = 'XPT',
  Yer = 'YER',
  Yun = 'YUN',
  Zar = 'ZAR',
  Zmk = 'ZMK',
  Zwd = 'ZWD'
}

export type AccountBalanceTransactionEdge = {
  __typename?: 'AccountBalanceTransactionEdge';
  /** The `AccountBalanceTransaction` entity that this edge points to. */
  node: AccountBalanceTransaction;
};

/** The subtype in case this is a manual transaction */
export enum AccountBalanceTransactionManualType {
  Bonus = 'bonus',
  Deposit = 'deposit',
  Misc = 'misc',
  RescindedPurchase = 'rescinded_purchase'
}

export type AccountBalanceTransactionPage = {
  __typename?: 'AccountBalanceTransactionPage';
  /** The total count of results for this search. If this is more than `limit` you may repeat the request with a higher `offset` to get the remaining nodes. */
  count: Scalars['Int'];
  /** The connections to the `AccountBalanceTransaction` entities that are included in this page. */
  edges: Array<AccountBalanceTransactionEdge>;
  /** The limit that was used to generate this page. The limit is the upper bound of how many nodes are returned. */
  limit: Scalars['Int'];
  /** The `AccountBalanceTransaction` entities that are included in this page. */
  nodes: Array<AccountBalanceTransaction>;
  /** The offset that was used to generate this page. */
  offset: Scalars['Int'];
};

export type AccountBalanceTransactionSearchParameters = {
  actorGid?: InputMaybe<Scalars['GlobalID']>;
  ids?: InputMaybe<Array<Scalars['AccountBalanceTransactionGlobalIdOrUuid']>>;
  invoiceAddressId?: InputMaybe<Scalars['ID']>;
  manualType?: InputMaybe<Array<Scalars['String']>>;
  text?: InputMaybe<Scalars['String']>;
  transactionType?: InputMaybe<Array<Scalars['String']>>;
};

/** The type of the transaction */
export enum AccountBalanceTransactionTransactionType {
  Initial = 'initial',
  Manual = 'manual',
  Recharged = 'recharged',
  Reverted = 'reverted',
  Used = 'used'
}

/** The Activity model stores the latest activity of a user on a per-app basis, which can help us determine when sending notifications is superfluous or not. */
export type Activity = CanHaveDataPoints & GlobalIdentifiable & {
  __typename?: 'Activity';
  /** The application of the activity */
  app?: Maybe<Scalars['String']>;
  /** When the activity was created (ISO 8601) */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Searches for referenced `data_points` */
  dataPoints?: Maybe<DataPointsResult>;
  /** Global Id of the activity (UUID) */
  gid: Scalars['GlobalID'];
  /** Id of the activity (UUID) */
  id: Scalars['ID'];
  /** The route or deep link of the activity */
  route?: Maybe<Scalars['String']>;
  /** The application specific activity type */
  type?: Maybe<Scalars['String']>;
  /** The application specific activity type identifier, additional to the regular type */
  typeId?: Maybe<Scalars['ID']>;
  /** When the activity was updated last time (ISO 8601) */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** Global Id of the owner (GID) */
  user?: Maybe<User>;
};


/** The Activity model stores the latest activity of a user on a per-app basis, which can help us determine when sending notifications is superfluous or not. */
export type ActivityDataPointsArgs = {
  aggregation?: InputMaybe<Aggregation>;
  context: Scalars['String'];
  endAt?: InputMaybe<Scalars['DateTime']>;
  interval?: InputMaybe<Interval>;
  metrics: Array<Scalars['String']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
};

export enum ActivityApp {
  Crm = 'crm',
  KundenportalWeb = 'kundenportal_web',
  MaklerportalIos = 'maklerportal_ios',
  MaklerportalWeb = 'maklerportal_web'
}

/** Autogenerated return type of ActivityCreate */
export type ActivityCreatePayload = {
  __typename?: 'ActivityCreatePayload';
  activity?: Maybe<Activity>;
  error?: Maybe<MutationError>;
};

/** Autogenerated return type of ActivityDelete */
export type ActivityDeletePayload = {
  __typename?: 'ActivityDeletePayload';
  activity?: Maybe<Activity>;
  error?: Maybe<MutationError>;
};

export type ActivityEdge = {
  __typename?: 'ActivityEdge';
  /** The `Activity` entity that this edge points to. */
  node: Activity;
};

export type ActivityNamespace = {
  __typename?: 'ActivityNamespace';
  /** Creates an object (or multiple objects) and saves it at the remote application, if validations pass. The resulting object is returned whether the object was saved successfully or not. The attributes parameter can be either a Hash or an Array of Hashes. These Hashes describe the attributes on the objects that are to be created. */
  create: ActivityCreatePayload;
  /** Deletes the instance at the remote application and freezes this instance to reflect that no changes should be made (since they can't be persisted). To enforce the object's before_destroy and after_destroy callbacks use #destroy. */
  delete: ActivityDeletePayload;
  /** Updates the attributes of the entity from the passed-in hash and saves the entity. If the object is invalid, the saving will fail and false will be returned. Also aliased as: update_attributes. */
  update: ActivityUpdatePayload;
};


export type ActivityNamespaceCreateArgs = {
  app: ActivityApp;
  route: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['ID']>;
  userGid: Scalars['GlobalID'];
};


export type ActivityNamespaceDeleteArgs = {
  id: Scalars['ActivityGlobalIdOrUuid'];
};


export type ActivityNamespaceUpdateArgs = {
  app?: InputMaybe<ActivityApp>;
  id: Scalars['ActivityGlobalIdOrUuid'];
  route?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['ID']>;
  userGid?: InputMaybe<Scalars['GlobalID']>;
};

export type ActivityPage = {
  __typename?: 'ActivityPage';
  /** The total count of results for this search. If this is more than `limit` you may repeat the request with a higher `offset` to get the remaining nodes. */
  count: Scalars['Int'];
  /** The connections to the `Activity` entities that are included in this page. */
  edges: Array<ActivityEdge>;
  /** The limit that was used to generate this page. The limit is the upper bound of how many nodes are returned. */
  limit: Scalars['Int'];
  /** The `Activity` entities that are included in this page. */
  nodes: Array<Activity>;
  /** The offset that was used to generate this page. */
  offset: Scalars['Int'];
};

export type ActivitySearchParameters = {
  app?: InputMaybe<ActivityApp>;
  ids?: InputMaybe<Array<Scalars['ActivityGlobalIdOrUuid']>>;
  text?: InputMaybe<Scalars['String']>;
  userGid?: InputMaybe<Scalars['GlobalID']>;
};

/** Autogenerated return type of ActivityUpdate */
export type ActivityUpdatePayload = {
  __typename?: 'ActivityUpdatePayload';
  activity?: Maybe<Activity>;
  error?: Maybe<MutationError>;
};

export enum Aggregation {
  /** Use the average of all values in the interval */
  Avg = 'avg',
  /** Use the last value per interval */
  Last = 'last',
  /** Use the maximum value per interval */
  Max = 'max',
  /** Use the minimum value per interval */
  Min = 'min',
  /** Sum all values of the interval */
  Sum = 'sum'
}

/** The HAUSGOLD ecosystem includes abstract appointments for all kind of entities. Users can have appointments, or even properties if you like to. */
export type Appointment = CanHaveDataPoints & GlobalIdentifiable & {
  __typename?: 'Appointment';
  /** All the defined alarms. */
  alarms?: Maybe<Array<AppointmentAlarms>>;
  /** External defined attendees gids */
  attendees?: Maybe<Array<GlobalIdentifiable>>;
  /** When the appointment was created (ISO 8601) */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Searches for referenced `data_points` */
  dataPoints?: Maybe<DataPointsResult>;
  /** Full description of the appointment */
  description?: Maybe<Scalars['String']>;
  /** Whenever the data can be edited */
  editable?: Maybe<Scalars['Boolean']>;
  /** When the appointment ends (ISO 8601) */
  endAt?: Maybe<Scalars['DateTime']>;
  /** Global Id of the appointment (UUID) */
  gid: Scalars['GlobalID'];
  /** Id of the appointment (UUID) */
  id: Scalars['ID'];
  /** A free typed location/address */
  location?: Maybe<Scalars['String']>;
  /** External defined additional data */
  metadata?: Maybe<Scalars['JsonBlob']>;
  /** External defined owner gid */
  owner?: Maybe<GlobalIdentifiable>;
  /** External defined references gids */
  references?: Maybe<Array<GlobalIdentifiable>>;
  /** When the appointment starts (ISO 8601) */
  startAt?: Maybe<Scalars['DateTime']>;
  /** [open accepted rejected] */
  status?: Maybe<Scalars['String']>;
  /** Descriptive title of the appointment */
  title?: Maybe<Scalars['String']>;
  /** When the appointment was updated last time (ISO 8601) */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** External defined user id (deprecated) */
  userId?: Maybe<Scalars['ID']>;
};


/** The HAUSGOLD ecosystem includes abstract appointments for all kind of entities. Users can have appointments, or even properties if you like to. */
export type AppointmentDataPointsArgs = {
  aggregation?: InputMaybe<Aggregation>;
  context: Scalars['String'];
  endAt?: InputMaybe<Scalars['DateTime']>;
  interval?: InputMaybe<Interval>;
  metrics: Array<Scalars['String']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
};

export type AppointmentAlarms = {
  __typename?: 'AppointmentAlarms';
  /** Relative minutes to the time, when the alarm should occur (eg. 15 minutes before the due date) */
  beforeMinutes?: Maybe<Scalars['Int']>;
  /** The user defined alarm channel (eg. email) */
  channel?: Maybe<Scalars['String']>;
};

export enum AppointmentChannel {
  Email = 'email',
  PushNotification = 'push_notification',
  WebNotification = 'web_notification'
}

export type AppointmentCreateAlarms = {
  beforeMinutes: Scalars['Int'];
  channel: AppointmentChannel;
};

/** Autogenerated return type of AppointmentCreate */
export type AppointmentCreatePayload = {
  __typename?: 'AppointmentCreatePayload';
  appointment?: Maybe<Appointment>;
  error?: Maybe<MutationError>;
};

/** Autogenerated return type of AppointmentDelete */
export type AppointmentDeletePayload = {
  __typename?: 'AppointmentDeletePayload';
  appointment?: Maybe<Appointment>;
  error?: Maybe<MutationError>;
};

export type AppointmentEdge = {
  __typename?: 'AppointmentEdge';
  /** The `Appointment` entity that this edge points to. */
  node: Appointment;
};

export type AppointmentNamespace = {
  __typename?: 'AppointmentNamespace';
  /** Creates an object (or multiple objects) and saves it at the remote application, if validations pass. The resulting object is returned whether the object was saved successfully or not. The attributes parameter can be either a Hash or an Array of Hashes. These Hashes describe the attributes on the objects that are to be created. */
  create: AppointmentCreatePayload;
  /** Deletes the instance at the remote application and freezes this instance to reflect that no changes should be made (since they can't be persisted). To enforce the object's before_destroy and after_destroy callbacks use #destroy. */
  delete: AppointmentDeletePayload;
  /** Updates the attributes of the entity from the passed-in hash and saves the entity. If the object is invalid, the saving will fail and false will be returned. Also aliased as: update_attributes. */
  update: AppointmentUpdatePayload;
};


export type AppointmentNamespaceCreateArgs = {
  alarms?: InputMaybe<Array<AppointmentCreateAlarms>>;
  attendeeGids?: InputMaybe<Array<Scalars['GlobalID']>>;
  attendeeIds?: InputMaybe<Array<Scalars['String']>>;
  description?: InputMaybe<Scalars['String']>;
  editable?: InputMaybe<Scalars['Boolean']>;
  endAt: Scalars['DateTime'];
  location?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['JsonBlob']>;
  ownerGid?: InputMaybe<Scalars['GlobalID']>;
  referenceGids?: InputMaybe<Array<Scalars['GlobalID']>>;
  referenceIds?: InputMaybe<Array<Scalars['String']>>;
  startAt: Scalars['DateTime'];
  status?: InputMaybe<AppointmentStatus>;
  title: Scalars['String'];
  userId?: InputMaybe<Scalars['ID']>;
};


export type AppointmentNamespaceDeleteArgs = {
  id: Scalars['AppointmentGlobalIdOrUuid'];
};


export type AppointmentNamespaceUpdateArgs = {
  alarms?: InputMaybe<Array<AppointmentUpdateAlarms>>;
  attendeeGids?: InputMaybe<Array<Scalars['GlobalID']>>;
  attendeeIds?: InputMaybe<Array<Scalars['String']>>;
  description?: InputMaybe<Scalars['String']>;
  endAt?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['AppointmentGlobalIdOrUuid'];
  location?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['JsonBlob']>;
  referenceGids?: InputMaybe<Array<Scalars['GlobalID']>>;
  referenceIds?: InputMaybe<Array<Scalars['String']>>;
  startAt?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<AppointmentStatus>;
  title?: InputMaybe<Scalars['String']>;
};

export type AppointmentPage = {
  __typename?: 'AppointmentPage';
  /** The total count of results for this search. If this is more than `limit` you may repeat the request with a higher `offset` to get the remaining nodes. */
  count: Scalars['Int'];
  /** The connections to the `Appointment` entities that are included in this page. */
  edges: Array<AppointmentEdge>;
  /** The limit that was used to generate this page. The limit is the upper bound of how many nodes are returned. */
  limit: Scalars['Int'];
  /** The `Appointment` entities that are included in this page. */
  nodes: Array<Appointment>;
  /** The offset that was used to generate this page. */
  offset: Scalars['Int'];
};

export type AppointmentSearchParameters = {
  attendeeIds?: InputMaybe<Array<Scalars['String']>>;
  from?: InputMaybe<Scalars['DateTime']>;
  ids?: InputMaybe<Array<Scalars['AppointmentGlobalIdOrUuid']>>;
  ownerGid?: InputMaybe<Array<Scalars['String']>>;
  referenceIds?: InputMaybe<Array<Scalars['String']>>;
  text?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Array<Scalars['String']>>;
};

export enum AppointmentStatus {
  Accepted = 'accepted',
  Open = 'open',
  Rejected = 'rejected'
}

export type AppointmentUpdateAlarms = {
  beforeMinutes: Scalars['Int'];
  channel: AppointmentChannel;
};

/** Autogenerated return type of AppointmentUpdate */
export type AppointmentUpdatePayload = {
  __typename?: 'AppointmentUpdatePayload';
  appointment?: Maybe<Appointment>;
  error?: Maybe<MutationError>;
};

/** The HAUSGOLD ecosystem includes abstract assets for all kind of entities. An actual asset is a file (eg. images, documents, etc) which can be upload/downloaded. Beside the raw file data an asset bundles also metadata.  Users can have appointments, or even properties if you like to. */
export type Asset = CanHaveDataPoints & GlobalIdentifiable & {
  __typename?: 'Asset';
  /** The entity the asset is attached to. */
  attachable?: Maybe<GlobalIdentifiable>;
  /** The byte size of the asset file */
  byteSize?: Maybe<Scalars['Int']>;
  /** Category of the asset */
  category?: Maybe<Scalars['String']>;
  /** The content type of the asset file */
  contentType?: Maybe<Scalars['String']>;
  /** When the asset was created (ISO 8601) */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Searches for referenced `data_points` */
  dataPoints?: Maybe<DataPointsResult>;
  /** Description of the asset */
  description?: Maybe<Scalars['String']>;
  /** The download link for asset file. */
  fileUrl?: Maybe<Scalars['String']>;
  /** The filename of the asset file */
  filename?: Maybe<Scalars['String']>;
  /** GID of the asset (UUID) */
  gid: Scalars['GlobalID'];
  /** Id of the asset (UUID) */
  id: Scalars['ID'];
  /** Allows storing custom key value pairs */
  metadata?: Maybe<Scalars['JsonBlob']>;
  /** Visibility of the asset */
  public?: Maybe<Scalars['Boolean']>;
  /** Descriptive title of the asset */
  title?: Maybe<Scalars['String']>;
  /** When the asset was updated last time (ISO 8601) */
  updatedAt?: Maybe<Scalars['DateTime']>;
};


/** The HAUSGOLD ecosystem includes abstract assets for all kind of entities. An actual asset is a file (eg. images, documents, etc) which can be upload/downloaded. Beside the raw file data an asset bundles also metadata.  Users can have appointments, or even properties if you like to. */
export type AssetDataPointsArgs = {
  aggregation?: InputMaybe<Aggregation>;
  context: Scalars['String'];
  endAt?: InputMaybe<Scalars['DateTime']>;
  interval?: InputMaybe<Interval>;
  metrics: Array<Scalars['String']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
};

/** Autogenerated return type of AssetCreate */
export type AssetCreatePayload = {
  __typename?: 'AssetCreatePayload';
  asset?: Maybe<Asset>;
  error?: Maybe<MutationError>;
};

/** Autogenerated return type of AssetDelete */
export type AssetDeletePayload = {
  __typename?: 'AssetDeletePayload';
  asset?: Maybe<Asset>;
  error?: Maybe<MutationError>;
};

export type AssetEdge = {
  __typename?: 'AssetEdge';
  /** The `Asset` entity that this edge points to. */
  node: Asset;
};

export type AssetNamespace = {
  __typename?: 'AssetNamespace';
  /** Creates an object (or multiple objects) and saves it at the remote application, if validations pass. The resulting object is returned whether the object was saved successfully or not. The attributes parameter can be either a Hash or an Array of Hashes. These Hashes describe the attributes on the objects that are to be created. */
  create: AssetCreatePayload;
  /** Deletes the instance at the remote application and freezes this instance to reflect that no changes should be made (since they can't be persisted). To enforce the object's before_destroy and after_destroy callbacks use #destroy. */
  delete: AssetDeletePayload;
  /** Updates the attributes of the entity from the passed-in hash and saves the entity. If the object is invalid, the saving will fail and false will be returned. Also aliased as: update_attributes. */
  update: AssetUpdatePayload;
};


export type AssetNamespaceCreateArgs = {
  attachable: Scalars['GlobalID'];
  category?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  file: Scalars['File'];
  filename?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['JsonBlob']>;
  public?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
};


export type AssetNamespaceDeleteArgs = {
  id: Scalars['AssetGlobalIdOrUuid'];
};


export type AssetNamespaceUpdateArgs = {
  category?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['AssetGlobalIdOrUuid'];
  metadata?: InputMaybe<Scalars['JsonBlob']>;
  public?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
};

export type AssetPage = {
  __typename?: 'AssetPage';
  /** The total count of results for this search. If this is more than `limit` you may repeat the request with a higher `offset` to get the remaining nodes. */
  count: Scalars['Int'];
  /** The connections to the `Asset` entities that are included in this page. */
  edges: Array<AssetEdge>;
  /** The limit that was used to generate this page. The limit is the upper bound of how many nodes are returned. */
  limit: Scalars['Int'];
  /** The `Asset` entities that are included in this page. */
  nodes: Array<Asset>;
  /** The offset that was used to generate this page. */
  offset: Scalars['Int'];
};

export type AssetSearchParameters = {
  attachable?: InputMaybe<Scalars['GlobalID']>;
  category?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['AssetGlobalIdOrUuid']>>;
  text?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of AssetUpdate */
export type AssetUpdatePayload = {
  __typename?: 'AssetUpdatePayload';
  asset?: Maybe<Asset>;
  error?: Maybe<MutationError>;
};

/** A HAUSGOLD ecosystem broker account is a specialization of its identity (`User`). A broker is located at the Maklerportal API and contains personal data like first- and lastnames. All the user objects (`identity-api/User`, `maklerportal-api/User`, `verkaeuferportal-api/User`, etc) share the same id (UUID) to signalize they belong together, but differ on the gid (Global Id). Furthermore, the `maklerportal-api/User` is aliased here to be a `Hausgold::Broker` to distinguish the entities on client sides easily. */
export type Broker = CanHaveDataPoints & GlobalIdentifiable & {
  __typename?: 'Broker';
  /** Searches for referenced `assets` */
  assets?: Maybe<AssetPage>;
  /** The user avatar */
  avatar?: Maybe<Asset>;
  /** The contact_id of the user */
  contactId?: Maybe<Scalars['ID']>;
  /** The user's contacts, representing all brokers that have been invited to this user's team, as well as all brokers that have invited this user to their team. This field is only returned for the user that is making the request. */
  contacts?: Maybe<Array<BrokerContact>>;
  /** When the user was created (ISO 8601) */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Id of the user used for invoices */
  customerId?: Maybe<Scalars['ID']>;
  /** Searches for referenced `data_points` */
  dataPoints?: Maybe<DataPointsResult>;
  /** Global Id of the user (UUID) */
  gid: Scalars['GlobalID'];
  /** Id of the user (UUID) */
  id: Scalars['ID'];
  /** Searches for referenced `logins` */
  logins?: Maybe<LoginPage>;
  /** The offices GIDs connected to the user */
  offices?: Maybe<Array<Office>>;
  /** The organizations GIDs connected to the user */
  organizations?: Maybe<Array<Organization>>;
  /** The user settings, including personal data */
  preferences: PreferencesSet;
  /** Searches for referenced `role_assignments` */
  roleAssignments?: Maybe<RoleAssignmentPage>;
  /** Searches for referenced `tasks` */
  tasks?: Maybe<TaskPage>;
  /** When the user was updated the last time (ISO 8601) */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The HAUSGOLD ecosystem user account */
  user: User;
};


/** A HAUSGOLD ecosystem broker account is a specialization of its identity (`User`). A broker is located at the Maklerportal API and contains personal data like first- and lastnames. All the user objects (`identity-api/User`, `maklerportal-api/User`, `verkaeuferportal-api/User`, etc) share the same id (UUID) to signalize they belong together, but differ on the gid (Global Id). Furthermore, the `maklerportal-api/User` is aliased here to be a `Hausgold::Broker` to distinguish the entities on client sides easily. */
export type BrokerAssetsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<BrokerAssetSearchParameters>;
};


/** A HAUSGOLD ecosystem broker account is a specialization of its identity (`User`). A broker is located at the Maklerportal API and contains personal data like first- and lastnames. All the user objects (`identity-api/User`, `maklerportal-api/User`, `verkaeuferportal-api/User`, etc) share the same id (UUID) to signalize they belong together, but differ on the gid (Global Id). Furthermore, the `maklerportal-api/User` is aliased here to be a `Hausgold::Broker` to distinguish the entities on client sides easily. */
export type BrokerDataPointsArgs = {
  aggregation?: InputMaybe<Aggregation>;
  context: Scalars['String'];
  endAt?: InputMaybe<Scalars['DateTime']>;
  interval?: InputMaybe<Interval>;
  metrics: Array<Scalars['String']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
};


/** A HAUSGOLD ecosystem broker account is a specialization of its identity (`User`). A broker is located at the Maklerportal API and contains personal data like first- and lastnames. All the user objects (`identity-api/User`, `maklerportal-api/User`, `verkaeuferportal-api/User`, etc) share the same id (UUID) to signalize they belong together, but differ on the gid (Global Id). Furthermore, the `maklerportal-api/User` is aliased here to be a `Hausgold::Broker` to distinguish the entities on client sides easily. */
export type BrokerLoginsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<BrokerLoginSearchParameters>;
};


/** A HAUSGOLD ecosystem broker account is a specialization of its identity (`User`). A broker is located at the Maklerportal API and contains personal data like first- and lastnames. All the user objects (`identity-api/User`, `maklerportal-api/User`, `verkaeuferportal-api/User`, etc) share the same id (UUID) to signalize they belong together, but differ on the gid (Global Id). Furthermore, the `maklerportal-api/User` is aliased here to be a `Hausgold::Broker` to distinguish the entities on client sides easily. */
export type BrokerRoleAssignmentsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<BrokerRoleAssignmentSearchParameters>;
};


/** A HAUSGOLD ecosystem broker account is a specialization of its identity (`User`). A broker is located at the Maklerportal API and contains personal data like first- and lastnames. All the user objects (`identity-api/User`, `maklerportal-api/User`, `verkaeuferportal-api/User`, etc) share the same id (UUID) to signalize they belong together, but differ on the gid (Global Id). Furthermore, the `maklerportal-api/User` is aliased here to be a `Hausgold::Broker` to distinguish the entities on client sides easily. */
export type BrokerTasksArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<BrokerTaskSearchParameters>;
};

export type BrokerAssetSearchParameters = {
  category?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['AssetGlobalIdOrUuid']>>;
  text?: InputMaybe<Scalars['String']>;
};

export type BrokerContact = {
  __typename?: 'BrokerContact';
  /** The resolved broker for this contact */
  contact?: Maybe<Broker>;
  /** The current state of the connection to this contact. A contact with a state of `requested` has invited the current user. In this case, the current user is able to accept or decline the contact invitation. A contact with a state of `pending` was invited by the current user. */
  state?: Maybe<BrokerContactState>;
};

/** The current state of the connection to this contact. A contact with a state of `requested` has invited the current user. In this case, the current user is able to accept or decline the contact invitation. A contact with a state of `pending` was invited by the current user. */
export enum BrokerContactState {
  Accepted = 'accepted',
  Declined = 'declined',
  Pending = 'pending',
  Requested = 'requested'
}

/** Autogenerated return type of BrokerCreate */
export type BrokerCreatePayload = {
  __typename?: 'BrokerCreatePayload';
  broker?: Maybe<Broker>;
  error?: Maybe<MutationError>;
};

export type BrokerEdge = {
  __typename?: 'BrokerEdge';
  /** The `Broker` entity that this edge points to. */
  node: Broker;
};

export enum BrokerGender {
  Female = 'female',
  Male = 'male',
  Other = 'other'
}

export enum BrokerLocale {
  DeAt = 'de_AT',
  DeCh = 'de_CH',
  DeDe = 'de_DE',
  EsEs = 'es_ES',
  FrCh = 'fr_CH',
  FrFr = 'fr_FR'
}

export type BrokerLoginSearchParameters = {
  expired?: InputMaybe<Scalars['Boolean']>;
  failed?: InputMaybe<Scalars['Boolean']>;
  fingerprint?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

export type BrokerNamespace = {
  __typename?: 'BrokerNamespace';
  /** Creates an object (or multiple objects) and saves it at the remote application, if validations pass. The resulting object is returned whether the object was saved successfully or not. The attributes parameter can be either a Hash or an Array of Hashes. These Hashes describe the attributes on the objects that are to be created. */
  create: BrokerCreatePayload;
  /** Updates the attributes of the entity from the passed-in hash and saves the entity. If the object is invalid, the saving will fail and false will be returned. Also aliased as: update_attributes. */
  update: BrokerUpdatePayload;
};


export type BrokerNamespaceCreateArgs = {
  contactEmail?: InputMaybe<Scalars['String']>;
  contactId?: InputMaybe<Scalars['ID']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  gender?: InputMaybe<BrokerGender>;
  lastName: Scalars['String'];
  locale: BrokerLocale;
  password?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<BrokerStatus>;
};


export type BrokerNamespaceUpdateArgs = {
  contactEmail?: InputMaybe<Scalars['String']>;
  contactId?: InputMaybe<Scalars['ID']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<BrokerGender>;
  id: Scalars['BrokerGlobalIdOrUuid'];
  lastName?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<BrokerLocale>;
};

export type BrokerPage = {
  __typename?: 'BrokerPage';
  /** The total count of results for this search. If this is more than `limit` you may repeat the request with a higher `offset` to get the remaining nodes. */
  count: Scalars['Int'];
  /** The connections to the `Broker` entities that are included in this page. */
  edges: Array<BrokerEdge>;
  /** The limit that was used to generate this page. The limit is the upper bound of how many nodes are returned. */
  limit: Scalars['Int'];
  /** The `Broker` entities that are included in this page. */
  nodes: Array<Broker>;
  /** The offset that was used to generate this page. */
  offset: Scalars['Int'];
};

export type BrokerRoleAssignmentSearchParameters = {
  object?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type BrokerSearchParameters = {
  ids?: InputMaybe<Array<Scalars['BrokerGlobalIdOrUuid']>>;
  text?: InputMaybe<Scalars['String']>;
};

export enum BrokerStatus {
  Active = 'active',
  Inactive = 'inactive'
}

export type BrokerTaskSearchParameters = {
  from?: InputMaybe<Scalars['DateTime']>;
  ids?: InputMaybe<Array<Scalars['TaskGlobalIdOrUuid']>>;
  ownerGid?: InputMaybe<Array<Scalars['String']>>;
  referenceIds?: InputMaybe<Array<Scalars['String']>>;
  status?: InputMaybe<Array<Scalars['String']>>;
  text?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

/** Autogenerated return type of BrokerUpdate */
export type BrokerUpdatePayload = {
  __typename?: 'BrokerUpdatePayload';
  broker?: Maybe<Broker>;
  error?: Maybe<MutationError>;
};

/** An entity that can have associated data points. */
export type CanHaveDataPoints = {
  /** Searches for referenced `data_points` */
  dataPoints?: Maybe<DataPointsResult>;
};


/** An entity that can have associated data points. */
export type CanHaveDataPointsDataPointsArgs = {
  aggregation?: InputMaybe<Aggregation>;
  context: Scalars['String'];
  endAt?: InputMaybe<Scalars['DateTime']>;
  interval?: InputMaybe<Interval>;
  metrics: Array<Scalars['String']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
};

/** A HAUSGOLD ecosystem customer account is a specialization of its identity (`User`). A customer is located at the Kundenportal API and contains personal data like first- and lastnames. All the user objects (`identity-api/User`, `maklerportal-api/User`, `verkaeuferportal-api/User`, etc) share the same id (UUID) to signalize they belong together, but differ on the gid (Global Id). Furthermore, the `verkaeuferportal-api/User` is aliased here to be a `Hausgold::Customer` to distinguish the entities on client sides easily. */
export type Customer = CanHaveDataPoints & GlobalIdentifiable & {
  __typename?: 'Customer';
  /** Searches for referenced `assets` */
  assets?: Maybe<AssetPage>;
  /** The user avatar */
  avatar?: Maybe<Asset>;
  /** When the user was closed (ISO 8601) */
  closedAt?: Maybe<Scalars['DateTime']>;
  /** When the user was created (ISO 8601) */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Searches for referenced `data_points` */
  dataPoints?: Maybe<DataPointsResult>;
  /** Global Id of the user (UUID) */
  gid: Scalars['GlobalID'];
  /** Id of the user (UUID) */
  id: Scalars['ID'];
  /** The lead flag of the user */
  leadFlag?: Maybe<Scalars['String']>;
  /** The lead source of the user */
  leadSource?: Maybe<Scalars['String']>;
  /** Searches for referenced `logins` */
  logins?: Maybe<LoginPage>;
  /** The user settings, including personal data */
  preferences: PreferencesSet;
  /** The user who recruited this customer */
  recruiter?: Maybe<GlobalIdentifiable>;
  /** Searches for referenced `role_assignments` */
  roleAssignments?: Maybe<RoleAssignmentPage>;
  /** Searches for referenced `tasks` */
  tasks?: Maybe<TaskPage>;
  /** When the user was updated last time (ISO 8601) */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The HAUSGOLD ecosystem user account */
  user: User;
};


/** A HAUSGOLD ecosystem customer account is a specialization of its identity (`User`). A customer is located at the Kundenportal API and contains personal data like first- and lastnames. All the user objects (`identity-api/User`, `maklerportal-api/User`, `verkaeuferportal-api/User`, etc) share the same id (UUID) to signalize they belong together, but differ on the gid (Global Id). Furthermore, the `verkaeuferportal-api/User` is aliased here to be a `Hausgold::Customer` to distinguish the entities on client sides easily. */
export type CustomerAssetsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<CustomerAssetSearchParameters>;
};


/** A HAUSGOLD ecosystem customer account is a specialization of its identity (`User`). A customer is located at the Kundenportal API and contains personal data like first- and lastnames. All the user objects (`identity-api/User`, `maklerportal-api/User`, `verkaeuferportal-api/User`, etc) share the same id (UUID) to signalize they belong together, but differ on the gid (Global Id). Furthermore, the `verkaeuferportal-api/User` is aliased here to be a `Hausgold::Customer` to distinguish the entities on client sides easily. */
export type CustomerDataPointsArgs = {
  aggregation?: InputMaybe<Aggregation>;
  context: Scalars['String'];
  endAt?: InputMaybe<Scalars['DateTime']>;
  interval?: InputMaybe<Interval>;
  metrics: Array<Scalars['String']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
};


/** A HAUSGOLD ecosystem customer account is a specialization of its identity (`User`). A customer is located at the Kundenportal API and contains personal data like first- and lastnames. All the user objects (`identity-api/User`, `maklerportal-api/User`, `verkaeuferportal-api/User`, etc) share the same id (UUID) to signalize they belong together, but differ on the gid (Global Id). Furthermore, the `verkaeuferportal-api/User` is aliased here to be a `Hausgold::Customer` to distinguish the entities on client sides easily. */
export type CustomerLoginsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<CustomerLoginSearchParameters>;
};


/** A HAUSGOLD ecosystem customer account is a specialization of its identity (`User`). A customer is located at the Kundenportal API and contains personal data like first- and lastnames. All the user objects (`identity-api/User`, `maklerportal-api/User`, `verkaeuferportal-api/User`, etc) share the same id (UUID) to signalize they belong together, but differ on the gid (Global Id). Furthermore, the `verkaeuferportal-api/User` is aliased here to be a `Hausgold::Customer` to distinguish the entities on client sides easily. */
export type CustomerRoleAssignmentsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<CustomerRoleAssignmentSearchParameters>;
};


/** A HAUSGOLD ecosystem customer account is a specialization of its identity (`User`). A customer is located at the Kundenportal API and contains personal data like first- and lastnames. All the user objects (`identity-api/User`, `maklerportal-api/User`, `verkaeuferportal-api/User`, etc) share the same id (UUID) to signalize they belong together, but differ on the gid (Global Id). Furthermore, the `verkaeuferportal-api/User` is aliased here to be a `Hausgold::Customer` to distinguish the entities on client sides easily. */
export type CustomerTasksArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<CustomerTaskSearchParameters>;
};

export type CustomerAssetSearchParameters = {
  category?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['AssetGlobalIdOrUuid']>>;
  text?: InputMaybe<Scalars['String']>;
};

export type CustomerCreateAddress = {
  city?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  streetAddition?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CustomerCreate */
export type CustomerCreatePayload = {
  __typename?: 'CustomerCreatePayload';
  customer?: Maybe<Customer>;
  error?: Maybe<MutationError>;
};

/** Autogenerated return type of CustomerDelete */
export type CustomerDeletePayload = {
  __typename?: 'CustomerDeletePayload';
  customer?: Maybe<Customer>;
  error?: Maybe<MutationError>;
};

export type CustomerEdge = {
  __typename?: 'CustomerEdge';
  /** The `Customer` entity that this edge points to. */
  node: Customer;
};

export enum CustomerGender {
  Female = 'female',
  Male = 'male'
}

export type CustomerLoginSearchParameters = {
  expired?: InputMaybe<Scalars['Boolean']>;
  failed?: InputMaybe<Scalars['Boolean']>;
  fingerprint?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

export type CustomerNamespace = {
  __typename?: 'CustomerNamespace';
  /** Creates an object (or multiple objects) and saves it at the remote application, if validations pass. The resulting object is returned whether the object was saved successfully or not. The attributes parameter can be either a Hash or an Array of Hashes. These Hashes describe the attributes on the objects that are to be created. */
  create: CustomerCreatePayload;
  /** Deletes the instance at the remote application and freezes this instance to reflect that no changes should be made (since they can't be persisted). To enforce the object's before_destroy and after_destroy callbacks use #destroy. */
  delete: CustomerDeletePayload;
  /** Updates the attributes of the entity from the passed-in hash and saves the entity. If the object is invalid, the saving will fail and false will be returned. Also aliased as: update_attributes. */
  update: CustomerUpdatePayload;
};


export type CustomerNamespaceCreateArgs = {
  address?: InputMaybe<CustomerCreateAddress>;
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<CustomerGender>;
  lastName?: InputMaybe<Scalars['String']>;
  leadFlag?: InputMaybe<Scalars['String']>;
  leadSource?: InputMaybe<Scalars['String']>;
  mobile?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  recruiterGid?: InputMaybe<Scalars['GlobalID']>;
  status?: InputMaybe<CustomerStatus>;
};


export type CustomerNamespaceDeleteArgs = {
  id: Scalars['CustomerGlobalIdOrUuid'];
};


export type CustomerNamespaceUpdateArgs = {
  address?: InputMaybe<CustomerUpdateAddress>;
  closedAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<CustomerGender>;
  id: Scalars['CustomerGlobalIdOrUuid'];
  lastName?: InputMaybe<Scalars['String']>;
  leadFlag?: InputMaybe<Scalars['String']>;
  leadSource?: InputMaybe<Scalars['String']>;
  mobile?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  recruiterGid?: InputMaybe<Scalars['GlobalID']>;
};

export type CustomerPage = {
  __typename?: 'CustomerPage';
  /** The total count of results for this search. If this is more than `limit` you may repeat the request with a higher `offset` to get the remaining nodes. */
  count: Scalars['Int'];
  /** The connections to the `Customer` entities that are included in this page. */
  edges: Array<CustomerEdge>;
  /** The limit that was used to generate this page. The limit is the upper bound of how many nodes are returned. */
  limit: Scalars['Int'];
  /** The `Customer` entities that are included in this page. */
  nodes: Array<Customer>;
  /** The offset that was used to generate this page. */
  offset: Scalars['Int'];
};

export type CustomerRoleAssignmentSearchParameters = {
  object?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type CustomerSearchParameters = {
  closed?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['CustomerGlobalIdOrUuid']>>;
  leadSource?: InputMaybe<Scalars['String']>;
  recruiterGid?: InputMaybe<Scalars['GlobalID']>;
  recruiterGids?: InputMaybe<Array<Scalars['GlobalID']>>;
  text?: InputMaybe<Scalars['String']>;
};

export enum CustomerStatus {
  Active = 'active',
  Inactive = 'inactive'
}

export type CustomerTaskSearchParameters = {
  from?: InputMaybe<Scalars['DateTime']>;
  ids?: InputMaybe<Array<Scalars['TaskGlobalIdOrUuid']>>;
  ownerGid?: InputMaybe<Array<Scalars['String']>>;
  referenceIds?: InputMaybe<Array<Scalars['String']>>;
  status?: InputMaybe<Array<Scalars['String']>>;
  text?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

export type CustomerUpdateAddress = {
  city?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  streetAddition?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CustomerUpdate */
export type CustomerUpdatePayload = {
  __typename?: 'CustomerUpdatePayload';
  customer?: Maybe<Customer>;
  error?: Maybe<MutationError>;
};

/** The HAUSGOLD ecosystem allows the tracking of metrics in an abstract way. The underlying Analytic API acts as a time series database with rich query functionalities. A single data point represents a point in time (in an entity_gid/context/metric hierarchy). */
export type DataPoint = GlobalIdentifiable & {
  __typename?: 'DataPoint';
  /** The captured_at date */
  capturedAt?: Maybe<Scalars['DateTime']>;
  /** The context */
  context?: Maybe<Scalars['String']>;
  /** The created_at date */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The GID of the entity */
  entity?: Maybe<GlobalIdentifiable>;
  /** Global Id of the data point (UUID) */
  gid: Scalars['GlobalID'];
  /** Id of the data point (UUID) */
  id: Scalars['ID'];
  /** The metric */
  metric?: Maybe<Scalars['String']>;
  /** The updated_at date */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The value */
  value?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DataPointCreate */
export type DataPointCreatePayload = {
  __typename?: 'DataPointCreatePayload';
  dataPoint?: Maybe<DataPoint>;
  error?: Maybe<MutationError>;
};

export type DataPointDatum = {
  __typename?: 'DataPointDatum';
  count?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  metric?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['IEEE754Float']>;
};

export type DataPointNamespace = {
  __typename?: 'DataPointNamespace';
  /** Creates an object (or multiple objects) and saves it at the remote application, if validations pass. The resulting object is returned whether the object was saved successfully or not. The attributes parameter can be either a Hash or an Array of Hashes. These Hashes describe the attributes on the objects that are to be created. */
  create: DataPointCreatePayload;
};


export type DataPointNamespaceCreateArgs = {
  capturedAt: Scalars['DateTime'];
  context: Scalars['String'];
  entityGid: Scalars['GlobalID'];
  metric: Scalars['String'];
  value: Scalars['IEEE754Float'];
};

/** The HAUSGOLD ecosystem allows rich data point queries with aggregations, time gap fillups and adjustable data resolution. This entity is just a data model for the result set of a successful query. */
export type DataPointsResult = {
  __typename?: 'DataPointsResult';
  aggregation?: Maybe<Aggregation>;
  context?: Maybe<Scalars['String']>;
  data?: Maybe<Array<DataPointDatum>>;
  endAt?: Maybe<Scalars['DateTime']>;
  entity: GlobalIdentifiable;
  interval?: Maybe<Interval>;
  metrics: Array<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  totalCount?: Maybe<Scalars['Int']>;
  totalValue?: Maybe<Scalars['IEEE754Float']>;
};

/** A HAUSGOLD ecosystem financing lead is located at the Leads API and contains contact information. They can be bought by insurance brokers using the Payment API. */
export type FinancingLead = CanHaveDataPoints & GlobalIdentifiable & {
  __typename?: 'FinancingLead';
  /** The address of the financing lead */
  address?: Maybe<FinancingLeadAddress>;
  /** When the financing lead was created (ISO 8601) */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Searches for referenced `data_points` */
  dataPoints?: Maybe<DataPointsResult>;
  /** The email of this lead's contact */
  email?: Maybe<Scalars['String']>;
  /** The first name of this lead's contact */
  firstName?: Maybe<Scalars['String']>;
  /** The gender of this lead's contact */
  gender?: Maybe<Scalars['String']>;
  /** Global Id of the financing lead (UUID) */
  gid: Scalars['GlobalID'];
  /** Id of the financing lead (UUID) */
  id: Scalars['ID'];
  /** Identifier of the financing lead */
  identifier?: Maybe<Scalars['String']>;
  /** The last name of this lead's contact */
  lastName?: Maybe<Scalars['String']>;
  /** When the financing lead was owned/bought (ISO 8601) */
  ownedAt?: Maybe<Scalars['DateTime']>;
  /** The current owner of this lead */
  owner?: Maybe<GlobalIdentifiable>;
  /** The phone number of this lead's contact */
  phoneNumber?: Maybe<Scalars['String']>;
  /** The price of this lead in cents */
  price?: Maybe<Scalars['Int']>;
  /** The status of this lead */
  status?: Maybe<Scalars['String']>;
  /** When the financing lead was updated last time (ISO 8601) */
  updatedAt?: Maybe<Scalars['DateTime']>;
};


/** A HAUSGOLD ecosystem financing lead is located at the Leads API and contains contact information. They can be bought by insurance brokers using the Payment API. */
export type FinancingLeadDataPointsArgs = {
  aggregation?: InputMaybe<Aggregation>;
  context: Scalars['String'];
  endAt?: InputMaybe<Scalars['DateTime']>;
  interval?: InputMaybe<Interval>;
  metrics: Array<Scalars['String']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
};

/** Autogenerated return type of FinancingLeadAddToPayment */
export type FinancingLeadAddToPaymentPayload = {
  __typename?: 'FinancingLeadAddToPaymentPayload';
  error?: Maybe<MutationError>;
  financingLead?: Maybe<FinancingLead>;
};

export type FinancingLeadAddress = {
  __typename?: 'FinancingLeadAddress';
  /** The city of the financing lead */
  city?: Maybe<Scalars['String']>;
  /** The financing lead's country code */
  countryCode?: Maybe<Scalars['String']>;
  /** The street address of the financing lead */
  street?: Maybe<Scalars['String']>;
  /** Additions to the financing lead street address */
  streetAddition?: Maybe<Scalars['String']>;
  /** The postal code of the financing lead */
  zipcode?: Maybe<Scalars['String']>;
};

export type FinancingLeadCreateAddress = {
  city?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  streetAddition?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of FinancingLeadCreate */
export type FinancingLeadCreatePayload = {
  __typename?: 'FinancingLeadCreatePayload';
  error?: Maybe<MutationError>;
  financingLead?: Maybe<FinancingLead>;
};

/** Autogenerated return type of FinancingLeadDelete */
export type FinancingLeadDeletePayload = {
  __typename?: 'FinancingLeadDeletePayload';
  error?: Maybe<MutationError>;
  financingLead?: Maybe<FinancingLead>;
};

export type FinancingLeadEdge = {
  __typename?: 'FinancingLeadEdge';
  /** The `FinancingLead` entity that this edge points to. */
  node: FinancingLead;
};

export enum FinancingLeadGender {
  Female = 'female',
  Male = 'male'
}

export type FinancingLeadNamespace = {
  __typename?: 'FinancingLeadNamespace';
  /** Adds this entity as an item to the given payment. The payment can be passed as an actual instance of `Hausgold::Payment` via the `payment` argument or its UUID identifier as `payment_id`. One of these variants must be given. */
  addToPayment: FinancingLeadAddToPaymentPayload;
  /** Creates an object (or multiple objects) and saves it at the remote application, if validations pass. The resulting object is returned whether the object was saved successfully or not. The attributes parameter can be either a Hash or an Array of Hashes. These Hashes describe the attributes on the objects that are to be created. */
  create: FinancingLeadCreatePayload;
  /** Deletes the instance at the remote application and freezes this instance to reflect that no changes should be made (since they can't be persisted). To enforce the object's before_destroy and after_destroy callbacks use #destroy. */
  delete: FinancingLeadDeletePayload;
  /** Removes this entity (as payment item) from the given payment. The payment can be passed as an actual instance of `Hausgold::Payment` via the `payment` argument or its UUID identifier as `payment_id`. One of these variants must be given. */
  removeFromPayment: FinancingLeadRemoveFromPaymentPayload;
  /** Updates the attributes of the entity from the passed-in hash and saves the entity. If the object is invalid, the saving will fail and false will be returned. Also aliased as: update_attributes. */
  update: FinancingLeadUpdatePayload;
  /** Verify the current financing lead instance. */
  verify: FinancingLeadVerifyPayload;
};


export type FinancingLeadNamespaceAddToPaymentArgs = {
  id: Scalars['FinancingLeadGlobalIdOrUuid'];
  paymentId: Scalars['ID'];
};


export type FinancingLeadNamespaceCreateArgs = {
  address?: InputMaybe<FinancingLeadCreateAddress>;
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  gender?: InputMaybe<FinancingLeadGender>;
  lastName: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  price: Scalars['Int'];
};


export type FinancingLeadNamespaceDeleteArgs = {
  id: Scalars['FinancingLeadGlobalIdOrUuid'];
};


export type FinancingLeadNamespaceRemoveFromPaymentArgs = {
  id: Scalars['FinancingLeadGlobalIdOrUuid'];
  paymentId: Scalars['ID'];
};


export type FinancingLeadNamespaceUpdateArgs = {
  address?: InputMaybe<FinancingLeadUpdateAddress>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<FinancingLeadGender>;
  id: Scalars['FinancingLeadGlobalIdOrUuid'];
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
};


export type FinancingLeadNamespaceVerifyArgs = {
  id: Scalars['FinancingLeadGlobalIdOrUuid'];
};

export type FinancingLeadPage = {
  __typename?: 'FinancingLeadPage';
  /** The total count of results for this search. If this is more than `limit` you may repeat the request with a higher `offset` to get the remaining nodes. */
  count: Scalars['Int'];
  /** The connections to the `FinancingLead` entities that are included in this page. */
  edges: Array<FinancingLeadEdge>;
  /** The limit that was used to generate this page. The limit is the upper bound of how many nodes are returned. */
  limit: Scalars['Int'];
  /** The `FinancingLead` entities that are included in this page. */
  nodes: Array<FinancingLead>;
  /** The offset that was used to generate this page. */
  offset: Scalars['Int'];
};

/** Autogenerated return type of FinancingLeadRemoveFromPayment */
export type FinancingLeadRemoveFromPaymentPayload = {
  __typename?: 'FinancingLeadRemoveFromPaymentPayload';
  error?: Maybe<MutationError>;
  financingLead?: Maybe<FinancingLead>;
};

export type FinancingLeadSearchParameters = {
  ids?: InputMaybe<Array<Scalars['FinancingLeadGlobalIdOrUuid']>>;
  includeDetails?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<FinancingLeadStatus>;
  text?: InputMaybe<Scalars['String']>;
};

export enum FinancingLeadStatus {
  Owned = 'owned',
  Reserved = 'reserved',
  Unverified = 'unverified',
  Verified = 'verified'
}

export type FinancingLeadUpdateAddress = {
  city?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  streetAddition?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of FinancingLeadUpdate */
export type FinancingLeadUpdatePayload = {
  __typename?: 'FinancingLeadUpdatePayload';
  error?: Maybe<MutationError>;
  financingLead?: Maybe<FinancingLead>;
};

/** Autogenerated return type of FinancingLeadVerify */
export type FinancingLeadVerifyPayload = {
  __typename?: 'FinancingLeadVerifyPayload';
  error?: Maybe<MutationError>;
  financingLead?: Maybe<FinancingLead>;
};

/** An entity that can be identified and retrieved via a GlobalID. */
export type GlobalIdentifiable = {
  gid: Scalars['GlobalID'];
  id: Scalars['ID'];
};

/** The HAUSGOLD ecosystem allows users to manage and run business intelligence queries. These queries are useful for augemented data presentation or analytical processing. The queries are executed by the intelligence application on read-replicated database instances. */
export type InsightsQuery = CanHaveDataPoints & GlobalIdentifiable & {
  __typename?: 'InsightsQuery';
  /** When the query was created (ISO 8601) */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Searches for referenced `data_points` */
  dataPoints?: Maybe<DataPointsResult>;
  /** The data source which is used by the query */
  dataSource?: Maybe<Scalars['String']>;
  /** Description of the query */
  description?: Maybe<Scalars['String']>;
  /** Global Id of the query (UUID) */
  gid: Scalars['GlobalID'];
  /** Id of the query (UUID) */
  id: Scalars['ID'];
  /** Descriptive name of the query */
  name?: Maybe<Scalars['String']>;
  /** The SQL statement of the query */
  statement?: Maybe<Scalars['String']>;
  /** When the query was updated last time (ISO 8601) */
  updatedAt?: Maybe<Scalars['DateTime']>;
};


/** The HAUSGOLD ecosystem allows users to manage and run business intelligence queries. These queries are useful for augemented data presentation or analytical processing. The queries are executed by the intelligence application on read-replicated database instances. */
export type InsightsQueryDataPointsArgs = {
  aggregation?: InputMaybe<Aggregation>;
  context: Scalars['String'];
  endAt?: InputMaybe<Scalars['DateTime']>;
  interval?: InputMaybe<Interval>;
  metrics: Array<Scalars['String']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
};

/** Autogenerated return type of InsightsQueryCreate */
export type InsightsQueryCreatePayload = {
  __typename?: 'InsightsQueryCreatePayload';
  error?: Maybe<MutationError>;
  insightsQuery?: Maybe<InsightsQuery>;
};

export enum InsightsQueryDataSource {
  AnalyticApi = 'analytic_api',
  AssetApi = 'asset_api',
  CalendarApi = 'calendar_api',
  Crm = 'crm',
  Dwh = 'dwh',
  IdentityApi = 'identity_api',
  Immodb = 'immodb',
  Intelligence = 'intelligence',
  Jabber = 'jabber',
  Maklerportal = 'maklerportal',
  PaymentApi = 'payment_api',
  Preferences = 'preferences',
  PropertyApi = 'property_api',
  Segment = 'segment',
  Varkaeuferportal = 'varkaeuferportal',
  Wizard = 'wizard',
  Zammad = 'zammad'
}

/** Autogenerated return type of InsightsQueryDelete */
export type InsightsQueryDeletePayload = {
  __typename?: 'InsightsQueryDeletePayload';
  error?: Maybe<MutationError>;
  insightsQuery?: Maybe<InsightsQuery>;
};

export type InsightsQueryEdge = {
  __typename?: 'InsightsQueryEdge';
  /** The `InsightsQuery` entity that this edge points to. */
  node: InsightsQuery;
};

export type InsightsQueryNamespace = {
  __typename?: 'InsightsQueryNamespace';
  /** Creates an object (or multiple objects) and saves it at the remote application, if validations pass. The resulting object is returned whether the object was saved successfully or not. The attributes parameter can be either a Hash or an Array of Hashes. These Hashes describe the attributes on the objects that are to be created. */
  create: InsightsQueryCreatePayload;
  /** Deletes the instance at the remote application and freezes this instance to reflect that no changes should be made (since they can't be persisted). To enforce the object's before_destroy and after_destroy callbacks use #destroy. */
  delete: InsightsQueryDeletePayload;
  /** Updates the attributes of the entity from the passed-in hash and saves the entity. If the object is invalid, the saving will fail and false will be returned. Also aliased as: update_attributes. */
  update: InsightsQueryUpdatePayload;
};


export type InsightsQueryNamespaceCreateArgs = {
  dataSource: InsightsQueryDataSource;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  statement: Scalars['String'];
};


export type InsightsQueryNamespaceDeleteArgs = {
  id: Scalars['InsightsQueryGlobalIdOrUuid'];
};


export type InsightsQueryNamespaceUpdateArgs = {
  dataSource?: InputMaybe<InsightsQueryDataSource>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['InsightsQueryGlobalIdOrUuid'];
  name?: InputMaybe<Scalars['String']>;
  statement?: InputMaybe<Scalars['String']>;
};

export type InsightsQueryPage = {
  __typename?: 'InsightsQueryPage';
  /** The total count of results for this search. If this is more than `limit` you may repeat the request with a higher `offset` to get the remaining nodes. */
  count: Scalars['Int'];
  /** The connections to the `InsightsQuery` entities that are included in this page. */
  edges: Array<InsightsQueryEdge>;
  /** The limit that was used to generate this page. The limit is the upper bound of how many nodes are returned. */
  limit: Scalars['Int'];
  /** The `InsightsQuery` entities that are included in this page. */
  nodes: Array<InsightsQuery>;
  /** The offset that was used to generate this page. */
  offset: Scalars['Int'];
};

export type InsightsQuerySearchParameters = {
  dataSource?: InputMaybe<InsightsQueryDataSource>;
  name?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of InsightsQueryUpdate */
export type InsightsQueryUpdatePayload = {
  __typename?: 'InsightsQueryUpdatePayload';
  error?: Maybe<MutationError>;
  insightsQuery?: Maybe<InsightsQuery>;
};

/** A HAUSGOLD ecosystem insurance lead is located at the Leads API and contains contact information. They can be bought by insurance brokers using the Payment API. */
export type InsuranceLead = CanHaveDataPoints & GlobalIdentifiable & {
  __typename?: 'InsuranceLead';
  /** The address of the insurance lead */
  address?: Maybe<InsuranceLeadAddress>;
  /** When the insurance lead was created (ISO 8601) */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Searches for referenced `data_points` */
  dataPoints?: Maybe<DataPointsResult>;
  /** The details of the insurance lead */
  details?: Maybe<InsuranceLeadDetails>;
  /** The email of this lead's contact */
  email?: Maybe<Scalars['String']>;
  /** The first name of this lead's contact */
  firstName?: Maybe<Scalars['String']>;
  /** The gender of this lead's contact */
  gender?: Maybe<Scalars['String']>;
  /** Global Id of the insurance lead (UUID) */
  gid: Scalars['GlobalID'];
  /** Id of the insurance lead (UUID) */
  id: Scalars['ID'];
  /** Identifier of the insurance lead */
  identifier?: Maybe<Scalars['String']>;
  /** The last name of this lead's contact */
  lastName?: Maybe<Scalars['String']>;
  /** When the insurance lead was owned/bought (ISO 8601) */
  ownedAt?: Maybe<Scalars['DateTime']>;
  /** The current owner of this lead */
  owner?: Maybe<GlobalIdentifiable>;
  /** The phone number of this lead's contact */
  phoneNumber?: Maybe<Scalars['String']>;
  /** The price of this lead in cents */
  price?: Maybe<Scalars['Int']>;
  /** The status of this lead */
  status?: Maybe<Scalars['String']>;
  /** When the insurance lead was updated last time (ISO 8601) */
  updatedAt?: Maybe<Scalars['DateTime']>;
};


/** A HAUSGOLD ecosystem insurance lead is located at the Leads API and contains contact information. They can be bought by insurance brokers using the Payment API. */
export type InsuranceLeadDataPointsArgs = {
  aggregation?: InputMaybe<Aggregation>;
  context: Scalars['String'];
  endAt?: InputMaybe<Scalars['DateTime']>;
  interval?: InputMaybe<Interval>;
  metrics: Array<Scalars['String']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
};

/** Autogenerated return type of InsuranceLeadAddToPayment */
export type InsuranceLeadAddToPaymentPayload = {
  __typename?: 'InsuranceLeadAddToPaymentPayload';
  error?: Maybe<MutationError>;
  insuranceLead?: Maybe<InsuranceLead>;
};

export type InsuranceLeadAddress = {
  __typename?: 'InsuranceLeadAddress';
  /** The city of the financing lead */
  city?: Maybe<Scalars['String']>;
  /** The financing lead's country code */
  countryCode?: Maybe<Scalars['String']>;
  /** The street address of the financing lead */
  street?: Maybe<Scalars['String']>;
  /** Additions to the financing lead street address */
  streetAddition?: Maybe<Scalars['String']>;
  /** The postal code of the financing lead */
  zipcode?: Maybe<Scalars['String']>;
};

export type InsuranceLeadCreateAddress = {
  city?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  streetAddition?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type InsuranceLeadCreateDetails = {
  insuranceType: InsuranceLeadInsuranceType;
};

/** Autogenerated return type of InsuranceLeadCreate */
export type InsuranceLeadCreatePayload = {
  __typename?: 'InsuranceLeadCreatePayload';
  error?: Maybe<MutationError>;
  insuranceLead?: Maybe<InsuranceLead>;
};

/** Autogenerated return type of InsuranceLeadDelete */
export type InsuranceLeadDeletePayload = {
  __typename?: 'InsuranceLeadDeletePayload';
  error?: Maybe<MutationError>;
  insuranceLead?: Maybe<InsuranceLead>;
};

export type InsuranceLeadDetails = {
  __typename?: 'InsuranceLeadDetails';
  /** The insurance type of theinsurance lead */
  insuranceType?: Maybe<Scalars['String']>;
};

export type InsuranceLeadEdge = {
  __typename?: 'InsuranceLeadEdge';
  /** The `InsuranceLead` entity that this edge points to. */
  node: InsuranceLead;
};

export enum InsuranceLeadGender {
  Female = 'female',
  Male = 'male'
}

export enum InsuranceLeadInsuranceType {
  BuildingInsurance = 'building_insurance'
}

export type InsuranceLeadNamespace = {
  __typename?: 'InsuranceLeadNamespace';
  /** Adds this entity as an item to the given payment. The payment can be passed as an actual instance of `Hausgold::Payment` via the `payment` argument or its UUID identifier as `payment_id`. One of these variants must be given. */
  addToPayment: InsuranceLeadAddToPaymentPayload;
  /** Creates an object (or multiple objects) and saves it at the remote application, if validations pass. The resulting object is returned whether the object was saved successfully or not. The attributes parameter can be either a Hash or an Array of Hashes. These Hashes describe the attributes on the objects that are to be created. */
  create: InsuranceLeadCreatePayload;
  /** Deletes the instance at the remote application and freezes this instance to reflect that no changes should be made (since they can't be persisted). To enforce the object's before_destroy and after_destroy callbacks use #destroy. */
  delete: InsuranceLeadDeletePayload;
  /** Removes this entity (as payment item) from the given payment. The payment can be passed as an actual instance of `Hausgold::Payment` via the `payment` argument or its UUID identifier as `payment_id`. One of these variants must be given. */
  removeFromPayment: InsuranceLeadRemoveFromPaymentPayload;
  /** Updates the attributes of the entity from the passed-in hash and saves the entity. If the object is invalid, the saving will fail and false will be returned. Also aliased as: update_attributes. */
  update: InsuranceLeadUpdatePayload;
  /** Verify the current insurance lead instance. */
  verify: InsuranceLeadVerifyPayload;
};


export type InsuranceLeadNamespaceAddToPaymentArgs = {
  id: Scalars['InsuranceLeadGlobalIdOrUuid'];
  paymentId: Scalars['ID'];
};


export type InsuranceLeadNamespaceCreateArgs = {
  address?: InputMaybe<InsuranceLeadCreateAddress>;
  details?: InputMaybe<InsuranceLeadCreateDetails>;
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  gender?: InputMaybe<InsuranceLeadGender>;
  lastName: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  price: Scalars['Int'];
};


export type InsuranceLeadNamespaceDeleteArgs = {
  id: Scalars['InsuranceLeadGlobalIdOrUuid'];
};


export type InsuranceLeadNamespaceRemoveFromPaymentArgs = {
  id: Scalars['InsuranceLeadGlobalIdOrUuid'];
  paymentId: Scalars['ID'];
};


export type InsuranceLeadNamespaceUpdateArgs = {
  address?: InputMaybe<InsuranceLeadUpdateAddress>;
  details?: InputMaybe<InsuranceLeadUpdateDetails>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<InsuranceLeadGender>;
  id: Scalars['InsuranceLeadGlobalIdOrUuid'];
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
};


export type InsuranceLeadNamespaceVerifyArgs = {
  id: Scalars['InsuranceLeadGlobalIdOrUuid'];
};

export type InsuranceLeadPage = {
  __typename?: 'InsuranceLeadPage';
  /** The total count of results for this search. If this is more than `limit` you may repeat the request with a higher `offset` to get the remaining nodes. */
  count: Scalars['Int'];
  /** The connections to the `InsuranceLead` entities that are included in this page. */
  edges: Array<InsuranceLeadEdge>;
  /** The limit that was used to generate this page. The limit is the upper bound of how many nodes are returned. */
  limit: Scalars['Int'];
  /** The `InsuranceLead` entities that are included in this page. */
  nodes: Array<InsuranceLead>;
  /** The offset that was used to generate this page. */
  offset: Scalars['Int'];
};

/** Autogenerated return type of InsuranceLeadRemoveFromPayment */
export type InsuranceLeadRemoveFromPaymentPayload = {
  __typename?: 'InsuranceLeadRemoveFromPaymentPayload';
  error?: Maybe<MutationError>;
  insuranceLead?: Maybe<InsuranceLead>;
};

export type InsuranceLeadSearchParameters = {
  ids?: InputMaybe<Array<Scalars['InsuranceLeadGlobalIdOrUuid']>>;
  includeDetails?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<InsuranceLeadStatus>;
  text?: InputMaybe<Scalars['String']>;
};

export enum InsuranceLeadStatus {
  Owned = 'owned',
  Reserved = 'reserved',
  Unverified = 'unverified',
  Verified = 'verified'
}

export type InsuranceLeadUpdateAddress = {
  city?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  streetAddition?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type InsuranceLeadUpdateDetails = {
  insuranceType?: InputMaybe<InsuranceLeadInsuranceType>;
};

/** Autogenerated return type of InsuranceLeadUpdate */
export type InsuranceLeadUpdatePayload = {
  __typename?: 'InsuranceLeadUpdatePayload';
  error?: Maybe<MutationError>;
  insuranceLead?: Maybe<InsuranceLead>;
};

/** Autogenerated return type of InsuranceLeadVerify */
export type InsuranceLeadVerifyPayload = {
  __typename?: 'InsuranceLeadVerifyPayload';
  error?: Maybe<MutationError>;
  insuranceLead?: Maybe<InsuranceLead>;
};

export enum Interval {
  Day = 'day',
  Month = 'month',
  Year = 'year'
}

/** The HAUSGOLD ecosystem allows to manage special addresses for invoicing. */
export type InvoiceAddress = CanHaveDataPoints & GlobalIdentifiable & {
  __typename?: 'InvoiceAddress';
  /** The current account balance in cents */
  accountBalance?: Maybe<Scalars['Int']>;
  /** The currency of the account balance */
  accountBalanceCurrency?: Maybe<InvoiceAddressAccountBalanceCurrency>;
  /** The ID of the related billomat client */
  billomatClientId?: Maybe<Scalars['ID']>;
  /** When the invoice address was created (ISO 8601) */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Searches for referenced `data_points` */
  dataPoints?: Maybe<DataPointsResult>;
  /** Global ID of the invoice address (UUID) */
  gid: Scalars['GlobalID'];
  /** ID of the invoice address (UUID) */
  id: Scalars['ID'];
  /** Global ID of the owner/creator of the invoice address (UUID) */
  owner?: Maybe<GlobalIdentifiable>;
  /** When the invoice address was last updated (ISO 8601) */
  updatedAt?: Maybe<Scalars['DateTime']>;
};


/** The HAUSGOLD ecosystem allows to manage special addresses for invoicing. */
export type InvoiceAddressDataPointsArgs = {
  aggregation?: InputMaybe<Aggregation>;
  context: Scalars['String'];
  endAt?: InputMaybe<Scalars['DateTime']>;
  interval?: InputMaybe<Interval>;
  metrics: Array<Scalars['String']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
};

/** The currency of the account balance */
export enum InvoiceAddressAccountBalanceCurrency {
  Aed = 'AED',
  Afa = 'AFA',
  All = 'ALL',
  Amd = 'AMD',
  Ang = 'ANG',
  Aon = 'AON',
  Arp = 'ARP',
  Ats = 'ATS',
  Aud = 'AUD',
  Awf = 'AWF',
  Azm = 'AZM',
  Bak = 'BAK',
  Bbd = 'BBD',
  Bdt = 'BDT',
  Bef = 'BEF',
  Bes = 'BES',
  Bgl = 'BGL',
  Bhd = 'BHD',
  Bif = 'BIF',
  Bmd = 'BMD',
  Bnd = 'BND',
  Bob = 'BOB',
  Brl = 'BRL',
  Bsd = 'BSD',
  Btr = 'BTR',
  Bwp = 'BWP',
  Byr = 'BYR',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Chf = 'CHF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Crc = 'CRC',
  Cup = 'CUP',
  Cuw = 'CUW',
  Cve = 'CVE',
  Cyp = 'CYP',
  Czk = 'CZK',
  Dem = 'DEM',
  Djf = 'DJF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Ecs = 'ECS',
  Eek = 'EEK',
  Egp = 'EGP',
  Ern = 'ERN',
  Esp = 'ESP',
  Etb = 'ETB',
  Eur = 'EUR',
  Fim = 'FIM',
  Fjd = 'FJD',
  Fkp = 'FKP',
  Frf = 'FRF',
  Gbp = 'GBP',
  Gel = 'GEL',
  Ghc = 'GHC',
  Gip = 'GIP',
  Gmd = 'GMD',
  Gnf = 'GNF',
  Grd = 'GRD',
  Gtq = 'GTQ',
  Gyd = 'GYD',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Hrk = 'HRK',
  Htg = 'HTG',
  Huf = 'HUF',
  Idr = 'IDR',
  Iep = 'IEP',
  Ils = 'ILS',
  Inr = 'INR',
  Iqd = 'IQD',
  Irr = 'IRR',
  Isk = 'ISK',
  Itl = 'ITL',
  Jmd = 'JMD',
  Jod = 'JOD',
  Jpy = 'JPY',
  Kes = 'KES',
  Kgs = 'KGS',
  Khr = 'KHR',
  Kmf = 'KMF',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kyd = 'KYD',
  Kzt = 'KZT',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Lrd = 'LRD',
  Lsl = 'LSL',
  Ltl = 'LTL',
  Luf = 'LUF',
  Lvl = 'LVL',
  Lyd = 'LYD',
  Mad = 'MAD',
  Mdl = 'MDL',
  Mgf = 'MGF',
  Mmk = 'MMK',
  Mnt = 'MNT',
  Mop = 'MOP',
  Mro = 'MRO',
  Mtl = 'MTL',
  Mur = 'MUR',
  Mvr = 'MVR',
  Mwk = 'MWK',
  Mxp = 'MXP',
  Mzm = 'MZM',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nio = 'NIO',
  Nlg = 'NLG',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pab = 'PAB',
  Pen = 'PEN',
  Pgk = 'PGK',
  Php = 'PHP',
  Pkr = 'PKR',
  Plz = 'PLZ',
  Pte = 'PTE',
  Pyg = 'PYG',
  Qar = 'QAR',
  Rol = 'ROL',
  Rur = 'RUR',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sbd = 'SBD',
  Sbl = 'SBL',
  Scr = 'SCR',
  Sdd = 'SDD',
  Sek = 'SEK',
  Sgd = 'SGD',
  Shp = 'SHP',
  Sit = 'SIT',
  Skk = 'SKK',
  Sll = 'SLL',
  Sos = 'SOS',
  Srg = 'SRG',
  Std = 'STD',
  Svc = 'SVC',
  Syp = 'SYP',
  Szl = 'SZL',
  Thb = 'THB',
  Tjr = 'TJR',
  Tmm = 'TMM',
  Tnd = 'TND',
  Top = 'TOP',
  Trl = 'TRL',
  Ttd = 'TTD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Ugx = 'UGX',
  Usd = 'USD',
  Uyu = 'UYU',
  Uzs = 'UZS',
  Veb = 'VEB',
  Vnd = 'VND',
  Vuv = 'VUV',
  Wst = 'WST',
  Xaf = 'XAF',
  Xag = 'XAG',
  Xau = 'XAU',
  Xcd = 'XCD',
  Xco = 'XCO',
  Xdr = 'XDR',
  Xpd = 'XPD',
  Xpf = 'XPF',
  Xpt = 'XPT',
  Yer = 'YER',
  Yun = 'YUN',
  Zar = 'ZAR',
  Zmk = 'ZMK',
  Zwd = 'ZWD'
}

/** Autogenerated return type of InvoiceAddressCreate */
export type InvoiceAddressCreatePayload = {
  __typename?: 'InvoiceAddressCreatePayload';
  error?: Maybe<MutationError>;
  invoiceAddress?: Maybe<InvoiceAddress>;
};

/** Autogenerated return type of InvoiceAddressDelete */
export type InvoiceAddressDeletePayload = {
  __typename?: 'InvoiceAddressDeletePayload';
  error?: Maybe<MutationError>;
  invoiceAddress?: Maybe<InvoiceAddress>;
};

export type InvoiceAddressEdge = {
  __typename?: 'InvoiceAddressEdge';
  /** The `InvoiceAddress` entity that this edge points to. */
  node: InvoiceAddress;
};

export type InvoiceAddressNamespace = {
  __typename?: 'InvoiceAddressNamespace';
  /** Creates an object (or multiple objects) and saves it at the remote application, if validations pass. The resulting object is returned whether the object was saved successfully or not. The attributes parameter can be either a Hash or an Array of Hashes. These Hashes describe the attributes on the objects that are to be created. */
  create: InvoiceAddressCreatePayload;
  /** Deletes the instance at the remote application and freezes this instance to reflect that no changes should be made (since they can't be persisted). To enforce the object's before_destroy and after_destroy callbacks use #destroy. */
  delete: InvoiceAddressDeletePayload;
};


export type InvoiceAddressNamespaceCreateArgs = {
  billomatClientId?: InputMaybe<Scalars['ID']>;
  city?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  ownerGid: Scalars['GlobalID'];
  phone?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  streetAddition?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};


export type InvoiceAddressNamespaceDeleteArgs = {
  id: Scalars['InvoiceAddressGlobalIdOrUuid'];
};

export type InvoiceAddressPage = {
  __typename?: 'InvoiceAddressPage';
  /** The total count of results for this search. If this is more than `limit` you may repeat the request with a higher `offset` to get the remaining nodes. */
  count: Scalars['Int'];
  /** The connections to the `InvoiceAddress` entities that are included in this page. */
  edges: Array<InvoiceAddressEdge>;
  /** The limit that was used to generate this page. The limit is the upper bound of how many nodes are returned. */
  limit: Scalars['Int'];
  /** The `InvoiceAddress` entities that are included in this page. */
  nodes: Array<InvoiceAddress>;
  /** The offset that was used to generate this page. */
  offset: Scalars['Int'];
};

export type InvoiceAddressSearchParameters = {
  billomatClientId?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<Scalars['InvoiceAddressGlobalIdOrUuid']>>;
  ownerGid?: InputMaybe<Scalars['GlobalID']>;
};

/** A regular (J)son (W)eb (T)oken which acts as an authentication and authorization key to the HAUSGOLD ecosystem. It is passed as header to each request you do on an HAUSGOLD service. */
export type Jwt = {
  __typename?: 'Jwt';
  /** The JWT access token for resource servers */
  accessToken?: Maybe<Scalars['String']>;
  /** A JWT access token containing only the bare minimum information. Should be used in size limited contexts (e.g. cookies) */
  bareAccessToken?: Maybe<Scalars['String']>;
  /** The context for which this JWT was created */
  context: Scalars['String'];
  /** Whether this JWT is already expired or not */
  expired: Scalars['Boolean'];
  /** The date time at which this JWT expires */
  expiresAt: Scalars['DateTime'];
  /** Access token expires in n seconds */
  expiresIn?: Maybe<Scalars['Int']>;
  /** The decoded JWT payload as a JSON blob */
  payload: Scalars['JsonBlob'];
  /** A JWT refresh token to retrieve new access tokens from this service */
  refreshToken?: Maybe<Scalars['String']>;
  /** By default bearer */
  tokenType?: Maybe<Scalars['String']>;
  /** UsersApi_ApiV1_Entities_User model */
  user?: Maybe<User>;
};

/** A HAUSGOLD ecosystem lead belongs to a Broker (`User`). An lead is located at the Maklerportal API and contains general data like address and contact information.  rubocop:disable Metrics/ClassLength because of the associations and helpers :reek:TooManyMethods because of the number of workflows */
export type Lead = CanHaveDataPoints & GlobalIdentifiable & {
  __typename?: 'Lead';
  /** When HG provided access to the customer contact data time (ISO 8601) */
  accessPermittedAt?: Maybe<Scalars['DateTime']>;
  /** When the lead account was activated time (ISO 8601) */
  activatedAt?: Maybe<Scalars['DateTime']>;
  /** Searches for referenced `assets` */
  assets?: Maybe<AssetPage>;
  /** The GIDs of all brokers connected to the lead */
  brokers?: Maybe<Array<Broker>>;
  /** When the lead was closed time (ISO 8601) */
  closedAt?: Maybe<Scalars['DateTime']>;
  /** Comment when closing the lead */
  closedComment?: Maybe<Scalars['String']>;
  /** Reason when closing the lead */
  closedReason?: Maybe<Scalars['String']>;
  /** Global Id of the user who closed the lead (UUID) */
  closer?: Maybe<GlobalIdentifiable>;
  /** The contacts of the lead */
  contacts?: Maybe<Array<LeadContact>>;
  /** The contract properties of the lead */
  contractProperties?: Maybe<Scalars['JsonBlob']>;
  /** When the lead was created (ISO 8601) */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The name of the current mandatory task */
  currentMandatoryTaskName?: Maybe<Scalars['String']>;
  /** The customers GIDs connected to the lead */
  customers?: Maybe<Array<Customer>>;
  /** Searches for referenced `data_points` */
  dataPoints?: Maybe<DataPointsResult>;
  /** Time by which the lead has to be processed (accepted/rejected) time (ISO 8601) */
  dueAt?: Maybe<Scalars['DateTime']>;
  /** When HG sent a reminder to process the lead time (ISO 8601) */
  dueDateWarningSentAt?: Maybe<Scalars['DateTime']>;
  /** Global Id of the lead (UUID) */
  gid: Scalars['GlobalID'];
  /** Id of the lead (UUID) */
  id: Scalars['ID'];
  /** ZVID of the lead */
  identifier?: Maybe<Scalars['String']>;
  /** Labels that quickly provide basic information about the lead */
  labels?: Maybe<Array<Scalars['String']>>;
  /** Global Id of the connected lead manager (UUID) */
  leadManager?: Maybe<LeadManager>;
  /** The version of the lead manager when supplied */
  leadManagerVersion?: Maybe<Scalars['Int']>;
  /** When the lead account was locked time (ISO 8601) */
  lockedAt?: Maybe<Scalars['DateTime']>;
  /** The current mandatory task of the lead */
  mandatoryTask?: Maybe<MandatoryLeadTask>;
  /** The object properties of the lead */
  objectProperties?: Maybe<LeadObjectProperties>;
  /** Global Id of the connected office (UUID) */
  office?: Maybe<Office>;
  /** The OpenImmo Feedback XML representation of this lead */
  openImmoFeedbackXml?: Maybe<Scalars['String']>;
  /** The OpenImmo XML representation of this lead */
  openImmoXml?: Maybe<Scalars['String']>;
  /** Global Id of the connected organization (UUID) */
  organization?: Maybe<Organization>;
  /** When the lead was accepted/rejected time (ISO 8601) */
  processedAt?: Maybe<Scalars['DateTime']>;
  /** Comment when accepting/rejecting the lead */
  processedComment?: Maybe<Scalars['String']>;
  /** Shows if the lead was accepted or rejected */
  processedWith?: Maybe<Scalars['String']>;
  /** Global Id of the user who accepted/rejected the lead (UUID) */
  processor?: Maybe<Broker>;
  /** Global ID of the related property (UUID) */
  property?: Maybe<Property>;
  /** Global Id of the connected user who received the lead initially (UUID) */
  receiver?: Maybe<Broker>;
  /** Global Id of the connected user who marked the lead as sold (UUID) */
  seller?: Maybe<Broker>;
  /** When the lead was marked as sold time (ISO 8601) */
  soldAt?: Maybe<Scalars['DateTime']>;
  /** The state properties of the lead */
  stateProperties?: Maybe<LeadStateProperties>;
  /** When HG requested state updates last time (ISO 8601) */
  statePropertiesUpdateRequestedAt?: Maybe<Scalars['DateTime']>;
  /** Comment when skipping the lead update */
  statePropertiesUpdateSkippedComment?: Maybe<Scalars['String']>;
  /** Reason when skipping the lead update */
  statePropertiesUpdateSkippedReason?: Maybe<Scalars['String']>;
  /** Date until the lead update is skipped time (ISO 8601) */
  statePropertiesUpdateSkippedUntil?: Maybe<Scalars['DateTime']>;
  /** Global Id of the user who last skipped the lead update (UUID) */
  statePropertiesUpdateSkipper?: Maybe<GlobalIdentifiable>;
  /** When the leads state properties were updated the last time (ISO 8601) */
  statePropertiesUpdatedAt?: Maybe<Scalars['DateTime']>;
  /** Global Id of the user who is currently responsible for managing the lead (UUID) */
  supervisor?: Maybe<Broker>;
  /** Searches for referenced `tasks` */
  tasks?: Maybe<TaskPage>;
  /** When the lead was updated the last time (ISO 8601) */
  updatedAt?: Maybe<Scalars['DateTime']>;
};


/** A HAUSGOLD ecosystem lead belongs to a Broker (`User`). An lead is located at the Maklerportal API and contains general data like address and contact information.  rubocop:disable Metrics/ClassLength because of the associations and helpers :reek:TooManyMethods because of the number of workflows */
export type LeadAssetsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<LeadAssetSearchParameters>;
};


/** A HAUSGOLD ecosystem lead belongs to a Broker (`User`). An lead is located at the Maklerportal API and contains general data like address and contact information.  rubocop:disable Metrics/ClassLength because of the associations and helpers :reek:TooManyMethods because of the number of workflows */
export type LeadDataPointsArgs = {
  aggregation?: InputMaybe<Aggregation>;
  context: Scalars['String'];
  endAt?: InputMaybe<Scalars['DateTime']>;
  interval?: InputMaybe<Interval>;
  metrics: Array<Scalars['String']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
};


/** A HAUSGOLD ecosystem lead belongs to a Broker (`User`). An lead is located at the Maklerportal API and contains general data like address and contact information.  rubocop:disable Metrics/ClassLength because of the associations and helpers :reek:TooManyMethods because of the number of workflows */
export type LeadTasksArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<LeadTaskSearchParameters>;
};

/** Autogenerated return type of LeadAccept */
export type LeadAcceptPayload = {
  __typename?: 'LeadAcceptPayload';
  error?: Maybe<MutationError>;
  lead?: Maybe<Lead>;
};

export type LeadAcceptPaymentMethod = {
  allowPaymentStorage?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  details?: InputMaybe<LeadAcceptPaymentMethodDetails>;
  nonce?: InputMaybe<Scalars['String']>;
  type: LeadType;
  vaulted?: InputMaybe<Scalars['Boolean']>;
};

export type LeadAcceptPaymentMethodDetails = {
  cardType?: InputMaybe<Scalars['String']>;
  correlationId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  lastTwo?: InputMaybe<Scalars['String']>;
  mandateId?: InputMaybe<Scalars['String']>;
  payerInfo?: InputMaybe<LeadAcceptPaymentMethodDetailsPayerinfo>;
};

export type LeadAcceptPaymentMethodDetailsPayerinfo = {
  billingAgreementId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of LeadAddToPayment */
export type LeadAddToPaymentPayload = {
  __typename?: 'LeadAddToPaymentPayload';
  error?: Maybe<MutationError>;
  lead?: Maybe<Lead>;
};

/** Autogenerated return type of LeadAddUser */
export type LeadAddUserPayload = {
  __typename?: 'LeadAddUserPayload';
  error?: Maybe<MutationError>;
  lead?: Maybe<Lead>;
};

export type LeadAssetSearchParameters = {
  category?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['AssetGlobalIdOrUuid']>>;
  text?: InputMaybe<Scalars['String']>;
};

export enum LeadBrokerContractBuyerCommissionUnit {
  Currency = 'currency',
  Percent = 'percent'
}

export enum LeadBrokerContractCommissionUnit {
  Currency = 'currency',
  Percent = 'percent'
}

export enum LeadBrokerContractType {
  ExclusiveOrder = 'exclusive_order',
  QualifiedExclusiveOrder = 'qualified_exclusive_order',
  SimpleOrder = 'simple_order',
  VerbalAgreement = 'verbal_agreement'
}

/** Autogenerated return type of LeadChangeSupervisor */
export type LeadChangeSupervisorPayload = {
  __typename?: 'LeadChangeSupervisorPayload';
  error?: Maybe<MutationError>;
  lead?: Maybe<Lead>;
};

/** Autogenerated return type of LeadClose */
export type LeadClosePayload = {
  __typename?: 'LeadClosePayload';
  error?: Maybe<MutationError>;
  lead?: Maybe<Lead>;
};

export type LeadContact = {
  __typename?: 'LeadContact';
  /** The customer this contact is related to */
  customer?: Maybe<Customer>;
  /** The emails of this person */
  emails?: Maybe<Array<Scalars['String']>>;
  /** The first name of this contact person */
  firstName?: Maybe<Scalars['String']>;
  /** This person's gender */
  gender?: Maybe<LeadGender>;
  /** The last name of this contact person */
  lastName?: Maybe<Scalars['String']>;
  /** The phone numbers of this person */
  phoneNumbers?: Maybe<Array<Scalars['String']>>;
  /** A note about the reachability of this person */
  reachability?: Maybe<Scalars['String']>;
};

export type LeadCreateContractProperties = {
  contactName: Scalars['String'];
  fee: Scalars['IEEE754Float'];
  freeReason?: InputMaybe<Scalars['String']>;
  leadCategory?: InputMaybe<Scalars['String']>;
  leadChannel?: InputMaybe<Scalars['String']>;
  partner?: InputMaybe<LeadCreateContractPropertiesPartner>;
  priceSurcharge?: InputMaybe<Scalars['IEEE754Float']>;
  strikeFee?: InputMaybe<Scalars['String']>;
  url: Scalars['String'];
  validatedWithVoiceRecord?: InputMaybe<Scalars['Boolean']>;
  version: Scalars['String'];
};

export type LeadCreateContractPropertiesPartner = {
  address?: InputMaybe<LeadCreateContractPropertiesPartnerAddress>;
  legalForm: Scalars['String'];
  name: Scalars['String'];
};

export type LeadCreateContractPropertiesPartnerAddress = {
  city?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  streetAddition?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type LeadCreateCustomerProperties = {
  contacts?: InputMaybe<Array<LeadCreateCustomerPropertiesContacts>>;
};

export type LeadCreateCustomerPropertiesContacts = {
  customerId?: InputMaybe<Scalars['String']>;
  emails?: InputMaybe<Array<Scalars['String']>>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  numbers?: InputMaybe<Array<Scalars['String']>>;
  reachability?: InputMaybe<Scalars['String']>;
};

export type LeadCreateObjectProperties = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  additionalInfoText?: InputMaybe<Scalars['String']>;
  askingPrice?: InputMaybe<Scalars['String']>;
  descriptionText?: InputMaybe<Scalars['String']>;
  equipmentText?: InputMaybe<Scalars['String']>;
  evaluationReason?: InputMaybe<Scalars['String']>;
  isForSale?: InputMaybe<Scalars['Boolean']>;
  locationText?: InputMaybe<Scalars['String']>;
  providerContacted?: InputMaybe<Scalars['Boolean']>;
  realEstateType: Scalars['String'];
  saleByProvider?: InputMaybe<Scalars['Boolean']>;
  type: Scalars['String'];
  viewingAppointments: Scalars['String'];
};

/** Autogenerated return type of LeadCreate */
export type LeadCreatePayload = {
  __typename?: 'LeadCreatePayload';
  error?: Maybe<MutationError>;
  lead?: Maybe<Lead>;
};

export enum LeadCurrency {
  Chf = 'CHF',
  Eur = 'EUR'
}

export enum LeadCurrentMandatoryTasks {
  HaveValuationMeeting = 'have_valuation_meeting',
  PlanNotaryDate = 'plan_notary_date',
  PlanValuationMeeting = 'plan_valuation_meeting',
  SendContractCopyToUs = 'send_contract_copy_to_us',
  SendValuationReport = 'send_valuation_report',
  SignContractAtNotary = 'sign_contract_at_notary',
  SignMarketingContract = 'sign_marketing_contract'
}

/** Autogenerated return type of LeadDelay */
export type LeadDelayPayload = {
  __typename?: 'LeadDelayPayload';
  error?: Maybe<MutationError>;
  lead?: Maybe<Lead>;
};

export type LeadEdge = {
  __typename?: 'LeadEdge';
  /** The `Lead` entity that this edge points to. */
  node: Lead;
};

/** Autogenerated return type of LeadExtendDueDate */
export type LeadExtendDueDatePayload = {
  __typename?: 'LeadExtendDueDatePayload';
  error?: Maybe<MutationError>;
  lead?: Maybe<Lead>;
};

/** This person's gender */
export enum LeadGender {
  Female = 'female',
  Male = 'male',
  Other = 'other'
}

/** A HAUSGOLD ecosystem lead_manager groups as set of leads (`Lead`). A lead_manager is located at the Maklerportal API and contains supply relevant data like acceptable leads count. */
export type LeadManager = CanHaveDataPoints & GlobalIdentifiable & {
  __typename?: 'LeadManager';
  /** The limit of acceptable leads */
  acceptableLimit?: Maybe<Scalars['Int']>;
  /** How many leads can still be added to the lead manager in its current version */
  acceptableLimitRemaining?: Maybe<Scalars['Int']>;
  /** When the lead_manager was created (ISO 8601) */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Id of related realty in crm context */
  crmObjectId?: Maybe<Scalars['ID']>;
  /** Searches for referenced `data_points` */
  dataPoints?: Maybe<DataPointsResult>;
  /** Global Id of the lead_manager (UUID) */
  gid: Scalars['GlobalID'];
  /** Id of the lead_manager (UUID) */
  id: Scalars['ID'];
  /** The leads GIDs connected to the lead manager */
  leads?: Maybe<Array<Lead>>;
  /** When the lead_manager was updated the last time (ISO 8601) */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The current version of the manager */
  version?: Maybe<Scalars['Int']>;
};


/** A HAUSGOLD ecosystem lead_manager groups as set of leads (`Lead`). A lead_manager is located at the Maklerportal API and contains supply relevant data like acceptable leads count. */
export type LeadManagerDataPointsArgs = {
  aggregation?: InputMaybe<Aggregation>;
  context: Scalars['String'];
  endAt?: InputMaybe<Scalars['DateTime']>;
  interval?: InputMaybe<Interval>;
  metrics: Array<Scalars['String']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
};

/** Autogenerated return type of LeadManagerActivate */
export type LeadManagerActivatePayload = {
  __typename?: 'LeadManagerActivatePayload';
  error?: Maybe<MutationError>;
  leadManager?: Maybe<LeadManager>;
};

/** Autogenerated return type of LeadManagerCreate */
export type LeadManagerCreatePayload = {
  __typename?: 'LeadManagerCreatePayload';
  error?: Maybe<MutationError>;
  leadManager?: Maybe<LeadManager>;
};

export type LeadManagerEdge = {
  __typename?: 'LeadManagerEdge';
  /** The `LeadManager` entity that this edge points to. */
  node: LeadManager;
};

/** Autogenerated return type of LeadManagerFinish */
export type LeadManagerFinishPayload = {
  __typename?: 'LeadManagerFinishPayload';
  error?: Maybe<MutationError>;
  leadManager?: Maybe<LeadManager>;
};

export type LeadManagerNamespace = {
  __typename?: 'LeadManagerNamespace';
  /** Activates a lead manager, who is responsible for activating the connected leads. */
  activate: LeadManagerActivatePayload;
  /** Creates an object (or multiple objects) and saves it at the remote application, if validations pass. The resulting object is returned whether the object was saved successfully or not. The attributes parameter can be either a Hash or an Array of Hashes. These Hashes describe the attributes on the objects that are to be created. */
  create: LeadManagerCreatePayload;
  /** Finish a lead manager which results in closing all open leads connected to the lead manager. */
  finish: LeadManagerFinishPayload;
  /** Reset a lead manager which results in its version increasing and a new acceptable limit being set. */
  reset: LeadManagerResetPayload;
};


export type LeadManagerNamespaceActivateArgs = {
  id: Scalars['LeadManagerGlobalIdOrUuid'];
};


export type LeadManagerNamespaceCreateArgs = {
  acceptableLimit: Scalars['Int'];
  crmObjectId: Scalars['Int'];
};


export type LeadManagerNamespaceFinishArgs = {
  id: Scalars['LeadManagerGlobalIdOrUuid'];
};


export type LeadManagerNamespaceResetArgs = {
  acceptableLimit: Scalars['Int'];
  id: Scalars['LeadManagerGlobalIdOrUuid'];
};

export type LeadManagerPage = {
  __typename?: 'LeadManagerPage';
  /** The total count of results for this search. If this is more than `limit` you may repeat the request with a higher `offset` to get the remaining nodes. */
  count: Scalars['Int'];
  /** The connections to the `LeadManager` entities that are included in this page. */
  edges: Array<LeadManagerEdge>;
  /** The limit that was used to generate this page. The limit is the upper bound of how many nodes are returned. */
  limit: Scalars['Int'];
  /** The `LeadManager` entities that are included in this page. */
  nodes: Array<LeadManager>;
  /** The offset that was used to generate this page. */
  offset: Scalars['Int'];
};

/** Autogenerated return type of LeadManagerReset */
export type LeadManagerResetPayload = {
  __typename?: 'LeadManagerResetPayload';
  error?: Maybe<MutationError>;
  leadManager?: Maybe<LeadManager>;
};

export type LeadManagerSearchParameters = {
  acceptableLimit?: InputMaybe<Scalars['String']>;
  crmObjectId?: InputMaybe<Array<Scalars['String']>>;
  ids?: InputMaybe<Array<Scalars['LeadManagerGlobalIdOrUuid']>>;
};

export enum LeadMandatoryTasksDone {
  HaveValuationMeeting = 'have_valuation_meeting',
  PlanNotaryDate = 'plan_notary_date',
  PlanValuationMeeting = 'plan_valuation_meeting',
  SendContractCopyToUs = 'send_contract_copy_to_us',
  SendValuationReport = 'send_valuation_report',
  SignContractAtNotary = 'sign_contract_at_notary',
  SignMarketingContract = 'sign_marketing_contract'
}

export enum LeadMandatoryTasksUndone {
  HaveValuationMeeting = 'have_valuation_meeting',
  PlanNotaryDate = 'plan_notary_date',
  PlanValuationMeeting = 'plan_valuation_meeting',
  SendContractCopyToUs = 'send_contract_copy_to_us',
  SendValuationReport = 'send_valuation_report',
  SignContractAtNotary = 'sign_contract_at_notary',
  SignMarketingContract = 'sign_marketing_contract'
}

export type LeadNamespace = {
  __typename?: 'LeadNamespace';
  /** Accept the current lead instance. */
  accept: LeadAcceptPayload;
  /** Adds this entity as an item to the given payment. The payment can be passed as an actual instance of `Hausgold::Payment` via the `payment` argument or its UUID identifier as `payment_id`. One of these variants must be given. */
  addToPayment: LeadAddToPaymentPayload;
  /** Add a user to the current lead instance. You need to pass in the `user_id` as additional parameter. */
  addUser: LeadAddUserPayload;
  /** Change the supervisor for the current lead instance. You need to pass in the `supervisor_id` as additional parameter. */
  changeSupervisor: LeadChangeSupervisorPayload;
  /** Close the current lead instance. You need to pass in the `reason` and `comment` as additional parameters. */
  close: LeadClosePayload;
  /** Creates an object (or multiple objects) and saves it at the remote application, if validations pass. The resulting object is returned whether the object was saved successfully or not. The attributes parameter can be either a Hash or an Array of Hashes. These Hashes describe the attributes on the objects that are to be created. */
  create: LeadCreatePayload;
  /** Delay the current lead instance You need to pass in the `reason` as additional parameters. */
  delay: LeadDelayPayload;
  /** Extend the due date for the current lead instance You need to pass in the `due_date` as additional parameter. */
  extendDueDate: LeadExtendDueDatePayload;
  /** Permit access to the current lead instance. */
  permitAccess: LeadPermitAccessPayload;
  /** Reject the current lead instance. */
  reject: LeadRejectPayload;
  /** Remove delay of the current lead instance. */
  removeDelay: LeadRemoveDelayPayload;
  /** Removes this entity (as payment item) from the given payment. The payment can be passed as an actual instance of `Hausgold::Payment` via the `payment` argument or its UUID identifier as `payment_id`. One of these variants must be given. */
  removeFromPayment: LeadRemoveFromPaymentPayload;
  /** Remove a user from the current lead instance You need to pass in the `user_id` as additional parameter. */
  removeUser: LeadRemoveUserPayload;
  /** Reopen current lead instance. */
  reopen: LeadReopenPayload;
  /** Sell current lead instance. */
  sell: LeadSellPayload;
  /** Updates the attributes of the entity from the passed-in hash and saves the entity. If the object is invalid, the saving will fail and false will be returned. Also aliased as: update_attributes. */
  update: LeadUpdatePayload;
  /** Updates a lead's state properties and optionally triggers the Calendar API mandatory task synchronisation. */
  updateMandatoryTask: LeadUpdateMandatoryTaskPayload;
};


export type LeadNamespaceAcceptArgs = {
  id: Scalars['LeadGlobalIdOrUuid'];
  paymentMethod?: InputMaybe<LeadAcceptPaymentMethod>;
  userId?: InputMaybe<Scalars['ID']>;
  viewingAppointmentSelected?: InputMaybe<Scalars['String']>;
};


export type LeadNamespaceAddToPaymentArgs = {
  id: Scalars['LeadGlobalIdOrUuid'];
  paymentId: Scalars['ID'];
};


export type LeadNamespaceAddUserArgs = {
  id: Scalars['LeadGlobalIdOrUuid'];
  userId: Scalars['ID'];
};


export type LeadNamespaceChangeSupervisorArgs = {
  id: Scalars['LeadGlobalIdOrUuid'];
  supervisorId: Scalars['ID'];
};


export type LeadNamespaceCloseArgs = {
  comment: Scalars['String'];
  id: Scalars['LeadGlobalIdOrUuid'];
  reason: LeadReason;
  userId?: InputMaybe<Scalars['ID']>;
};


export type LeadNamespaceCreateArgs = {
  autoActivation?: InputMaybe<Scalars['Boolean']>;
  contractProperties?: InputMaybe<LeadCreateContractProperties>;
  crmId: Scalars['Int'];
  customerProperties?: InputMaybe<LeadCreateCustomerProperties>;
  dueAt?: InputMaybe<Scalars['DateTime']>;
  identifier: Scalars['String'];
  labels?: InputMaybe<Array<Scalars['String']>>;
  leadManagerId: Scalars['ID'];
  objectProperties?: InputMaybe<LeadCreateObjectProperties>;
  officeId: Scalars['ID'];
  propertyId: Scalars['ID'];
};


export type LeadNamespaceDelayArgs = {
  comment?: InputMaybe<Scalars['String']>;
  date: Scalars['DateTime'];
  id: Scalars['LeadGlobalIdOrUuid'];
  reason: Scalars['String'];
  userId?: InputMaybe<Scalars['ID']>;
};


export type LeadNamespaceExtendDueDateArgs = {
  dueDate: Scalars['DateTime'];
  id: Scalars['LeadGlobalIdOrUuid'];
};


export type LeadNamespacePermitAccessArgs = {
  id: Scalars['LeadGlobalIdOrUuid'];
};


export type LeadNamespaceRejectArgs = {
  comment?: InputMaybe<Scalars['String']>;
  id: Scalars['LeadGlobalIdOrUuid'];
  userId?: InputMaybe<Scalars['ID']>;
};


export type LeadNamespaceRemoveDelayArgs = {
  id: Scalars['LeadGlobalIdOrUuid'];
};


export type LeadNamespaceRemoveFromPaymentArgs = {
  id: Scalars['LeadGlobalIdOrUuid'];
  paymentId: Scalars['ID'];
};


export type LeadNamespaceRemoveUserArgs = {
  id: Scalars['LeadGlobalIdOrUuid'];
  userId: Scalars['ID'];
};


export type LeadNamespaceReopenArgs = {
  id: Scalars['LeadGlobalIdOrUuid'];
};


export type LeadNamespaceSellArgs = {
  id: Scalars['LeadGlobalIdOrUuid'];
  userId?: InputMaybe<Scalars['ID']>;
};


export type LeadNamespaceUpdateArgs = {
  contractProperties?: InputMaybe<LeadUpdateContractProperties>;
  id: Scalars['LeadGlobalIdOrUuid'];
  objectProperties?: InputMaybe<LeadUpdateObjectProperties>;
};


export type LeadNamespaceUpdateMandatoryTaskArgs = {
  closeTask?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['LeadGlobalIdOrUuid'];
  stateProperties?: InputMaybe<LeadUpdateMandatoryTaskStateProperties>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type LeadObjectProperties = {
  __typename?: 'LeadObjectProperties';
  /** The text describing the additional lead info */
  additionalInfo?: Maybe<Scalars['String']>;
  /** The text describing the additional info */
  additionalInfoText?: Maybe<Scalars['String']>;
  /** The selling price the seller wants to achieve */
  askingPrice?: Maybe<Scalars['String']>;
  /** The description text */
  descriptionText?: Maybe<Scalars['String']>;
  /** The text describing the equipment */
  equipmentText?: Maybe<Scalars['String']>;
  /** Why the property should be evaluated */
  evaluationReason?: Maybe<Scalars['String']>;
  /** Flag indicating that the property is for sale. */
  isForSale?: Maybe<Scalars['Boolean']>;
  /** The text describing the location */
  locationText?: Maybe<Scalars['String']>;
  /**
   * The acceptable limit for the lead
   *
   * @deprecated Use `lead.leadManager.acceptableLimit` instead
   *
   */
  opportunityAcceptableLimit?: Maybe<Scalars['Int']>;
  /** Flag indicating that the owner already had contact with a provider. */
  providerContacted?: Maybe<Scalars['Boolean']>;
  /** Flag indicating that the property should be sold by a provider */
  saleByProvider?: Maybe<Scalars['Boolean']>;
  /** The sellers possible viewing appointments. */
  viewingAppointments?: Maybe<Array<Scalars['String']>>;
};

export enum LeadObjectSaleBuyerCommissionTaxation {
  Gross = 'gross',
  Net = 'net'
}

export enum LeadObjectSaleBuyerCommissionUnit {
  Currency = 'currency',
  Percent = 'percent'
}

export enum LeadObjectSaleCommissionTaxation {
  Gross = 'gross',
  Net = 'net'
}

export enum LeadObjectSaleCommissionUnit {
  Currency = 'currency',
  Percent = 'percent'
}

export type LeadPage = {
  __typename?: 'LeadPage';
  /** The total count of results for this search. If this is more than `limit` you may repeat the request with a higher `offset` to get the remaining nodes. */
  count: Scalars['Int'];
  /** The connections to the `Lead` entities that are included in this page. */
  edges: Array<LeadEdge>;
  /** The limit that was used to generate this page. The limit is the upper bound of how many nodes are returned. */
  limit: Scalars['Int'];
  /** The `Lead` entities that are included in this page. */
  nodes: Array<Lead>;
  /** The offset that was used to generate this page. */
  offset: Scalars['Int'];
};

/** Autogenerated return type of LeadPermitAccess */
export type LeadPermitAccessPayload = {
  __typename?: 'LeadPermitAccessPayload';
  error?: Maybe<MutationError>;
  lead?: Maybe<Lead>;
};

export enum LeadReason {
  BrokerRejects = 'broker_rejects',
  CustomerRejects = 'customer_rejects',
  HausgoldClosesRace = 'hausgold_closes_race'
}

/** Autogenerated return type of LeadReject */
export type LeadRejectPayload = {
  __typename?: 'LeadRejectPayload';
  error?: Maybe<MutationError>;
  lead?: Maybe<Lead>;
};

/** Autogenerated return type of LeadRemoveDelay */
export type LeadRemoveDelayPayload = {
  __typename?: 'LeadRemoveDelayPayload';
  error?: Maybe<MutationError>;
  lead?: Maybe<Lead>;
};

/** Autogenerated return type of LeadRemoveFromPayment */
export type LeadRemoveFromPaymentPayload = {
  __typename?: 'LeadRemoveFromPaymentPayload';
  error?: Maybe<MutationError>;
  lead?: Maybe<Lead>;
};

/** Autogenerated return type of LeadRemoveUser */
export type LeadRemoveUserPayload = {
  __typename?: 'LeadRemoveUserPayload';
  error?: Maybe<MutationError>;
  lead?: Maybe<Lead>;
};

/** Autogenerated return type of LeadReopen */
export type LeadReopenPayload = {
  __typename?: 'LeadReopenPayload';
  error?: Maybe<MutationError>;
  lead?: Maybe<Lead>;
};

export type LeadSearchParameters = {
  accepted?: InputMaybe<Scalars['Boolean']>;
  accessPermitted?: InputMaybe<Scalars['Boolean']>;
  activated?: InputMaybe<Scalars['Boolean']>;
  archived?: InputMaybe<Scalars['Boolean']>;
  closed?: InputMaybe<Scalars['Boolean']>;
  closerGid?: InputMaybe<Scalars['GlobalID']>;
  currentMandatoryTasks?: InputMaybe<Array<LeadCurrentMandatoryTasks>>;
  identifier?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['LeadGlobalIdOrUuid']>>;
  labels?: InputMaybe<Array<Scalars['String']>>;
  locked?: InputMaybe<Scalars['Boolean']>;
  mandatoryTasksDone?: InputMaybe<Array<LeadMandatoryTasksDone>>;
  mandatoryTasksUndone?: InputMaybe<Array<LeadMandatoryTasksUndone>>;
  needsUpdate?: InputMaybe<Scalars['Boolean']>;
  needsUpdateEscalated?: InputMaybe<Scalars['Boolean']>;
  objectTypes?: InputMaybe<Array<Scalars['String']>>;
  processed?: InputMaybe<Scalars['Boolean']>;
  processorGid?: InputMaybe<Scalars['GlobalID']>;
  propertyIds?: InputMaybe<Array<Scalars['String']>>;
  propertyUuids?: InputMaybe<Array<Scalars['String']>>;
  receiverGid?: InputMaybe<Scalars['GlobalID']>;
  rejected?: InputMaybe<Scalars['Boolean']>;
  sellerGid?: InputMaybe<Scalars['GlobalID']>;
  sold?: InputMaybe<Scalars['Boolean']>;
  stateUpdateRequested?: InputMaybe<Scalars['Boolean']>;
  stateUpdateSkipped?: InputMaybe<Scalars['Boolean']>;
  supervisorGid?: InputMaybe<Scalars['GlobalID']>;
  text?: InputMaybe<Scalars['String']>;
  userGid?: InputMaybe<Scalars['GlobalID']>;
  warnedDueDate?: InputMaybe<Scalars['Boolean']>;
};

/** Autogenerated return type of LeadSell */
export type LeadSellPayload = {
  __typename?: 'LeadSellPayload';
  error?: Maybe<MutationError>;
  lead?: Maybe<Lead>;
};

/**
 * The fields to be sorted by, and the order in which they should
 * be sorted.
 *
 * An example may be: `sort: [{ numberOfRooms: asc }, { id: desc }]`.
 */
export type LeadSortParameters = {
  accessPermittedAt?: InputMaybe<SortDirection>;
  activatedAt?: InputMaybe<SortDirection>;
  archivedAt?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  critical?: InputMaybe<SortDirection>;
  overdue?: InputMaybe<SortDirection>;
  statePropertiesUpdateSkippedUntil?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type LeadStateProperties = {
  __typename?: 'LeadStateProperties';
  /** Acquisition phase completed time (ISO 8601) */
  acquisitionCompleted?: Maybe<Scalars['DateTime']>;
  /** The broker contract buyer commission price */
  brokerContractBuyerCommission?: Maybe<Scalars['IEEE754Float']>;
  /** The broker contract buyer commission unit */
  brokerContractBuyerCommissionUnit?: Maybe<Scalars['String']>;
  /** The broker contract commission price */
  brokerContractCommission?: Maybe<Scalars['IEEE754Float']>;
  /** The broker contract commission unit */
  brokerContractCommissionUnit?: Maybe<Scalars['String']>;
  /** Broker contract date */
  brokerContractDate?: Maybe<Scalars['String']>;
  /** The broker contract price */
  brokerContractPrice?: Maybe<Scalars['Int']>;
  /** Broker contract term date */
  brokerContractTermDate?: Maybe<Scalars['String']>;
  /** The broker contract type */
  brokerContractType?: Maybe<Scalars['String']>;
  /** The currency used for prices */
  currency?: Maybe<Scalars['String']>;
  /** The customer appointment date */
  customerAppointmentDate?: Maybe<Scalars['String']>;
  /** The GID of the asset containing this property's evaluation */
  evaluationGid?: Maybe<Scalars['String']>;
  /** Expected notary date */
  expectedSaleNotaryDate?: Maybe<Scalars['String']>;
  /** The expected sale price */
  expectedSalePrice?: Maybe<Scalars['Int']>;
  /** Flag indicating invoice sent to HG */
  invoiceSent?: Maybe<Scalars['Boolean']>;
  /** Marketing phase completed time (ISO 8601) */
  marketingCompleted?: Maybe<Scalars['DateTime']>;
  /** When to contact the customer next time */
  nextCustomerContactDate?: Maybe<Scalars['String']>;
  /** The sale buyer commission price */
  objectSaleBuyerCommission?: Maybe<Scalars['IEEE754Float']>;
  /** The sale buyer commission taxation type */
  objectSaleBuyerCommissionTaxation?: Maybe<Scalars['String']>;
  /** The sale buyer commission unit */
  objectSaleBuyerCommissionUnit?: Maybe<Scalars['String']>;
  /** The sale commission price */
  objectSaleCommission?: Maybe<Scalars['IEEE754Float']>;
  /** The sale commission taxation type */
  objectSaleCommissionTaxation?: Maybe<Scalars['String']>;
  /** The sale commission price unit */
  objectSaleCommissionUnit?: Maybe<Scalars['String']>;
  /** Notary date */
  objectSaleNotaryDate?: Maybe<Scalars['String']>;
  /** The sale price */
  objectSalePrice?: Maybe<Scalars['Int']>;
  /** The evaluation date */
  performEvaluationDate?: Maybe<Scalars['String']>;
  /** Flag indicating evaluation date took place */
  performEvaluationDateTookPlace?: Maybe<Scalars['Boolean']>;
  /** The evaluation price */
  performEvaluationPrice?: Maybe<Scalars['Int']>;
  /** Sale phase completed time (ISO 8601) */
  saleCompleted?: Maybe<Scalars['DateTime']>;
  /** Flag indicating whether the uploaded evaluation file is shared with the customer or not */
  shareEvaluation?: Maybe<Scalars['Boolean']>;
  /** The reason why no state update was made */
  updateAlternativeText?: Maybe<Scalars['String']>;
};

export type LeadTaskSearchParameters = {
  from?: InputMaybe<Scalars['DateTime']>;
  ids?: InputMaybe<Array<Scalars['TaskGlobalIdOrUuid']>>;
  referenceIds?: InputMaybe<Array<Scalars['String']>>;
  status?: InputMaybe<Array<Scalars['String']>>;
  text?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Array<Scalars['String']>>;
};

export enum LeadType {
  CreditCard = 'CreditCard',
  Invoice = 'Invoice',
  PayPal = 'PayPal',
  PayPalAccount = 'PayPalAccount',
  PaymentMandate = 'PaymentMandate'
}

export type LeadUpdateContractProperties = {
  contactName?: InputMaybe<Scalars['String']>;
  fee?: InputMaybe<Scalars['IEEE754Float']>;
  freeReason?: InputMaybe<Scalars['String']>;
  leadCategory?: InputMaybe<Scalars['String']>;
  leadChannel?: InputMaybe<Scalars['String']>;
  partner?: InputMaybe<LeadUpdateContractPropertiesPartner>;
  priceSurcharge?: InputMaybe<Scalars['IEEE754Float']>;
  strikeFee?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
  validatedWithVoiceRecord?: InputMaybe<Scalars['Boolean']>;
  version?: InputMaybe<Scalars['String']>;
};

export type LeadUpdateContractPropertiesPartner = {
  address?: InputMaybe<LeadUpdateContractPropertiesPartnerAddress>;
  legalForm?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type LeadUpdateContractPropertiesPartnerAddress = {
  city?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  streetAddition?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of LeadUpdateMandatoryTask */
export type LeadUpdateMandatoryTaskPayload = {
  __typename?: 'LeadUpdateMandatoryTaskPayload';
  error?: Maybe<MutationError>;
  lead?: Maybe<Lead>;
};

export type LeadUpdateMandatoryTaskStateProperties = {
  acquisitionCompleted?: InputMaybe<Scalars['Boolean']>;
  brokerContractBuyerCommission?: InputMaybe<Scalars['IEEE754Float']>;
  brokerContractBuyerCommissionUnit?: InputMaybe<LeadBrokerContractBuyerCommissionUnit>;
  brokerContractCommission?: InputMaybe<Scalars['IEEE754Float']>;
  brokerContractCommissionUnit?: InputMaybe<LeadBrokerContractCommissionUnit>;
  brokerContractDate?: InputMaybe<Scalars['String']>;
  brokerContractPrice?: InputMaybe<Scalars['Int']>;
  brokerContractTermDate?: InputMaybe<Scalars['String']>;
  brokerContractType?: InputMaybe<LeadBrokerContractType>;
  currency?: InputMaybe<LeadCurrency>;
  customerAppointmentDate?: InputMaybe<Scalars['String']>;
  evaluationGid?: InputMaybe<Scalars['String']>;
  expectedSaleNotaryDate?: InputMaybe<Scalars['String']>;
  expectedSalePrice?: InputMaybe<Scalars['Int']>;
  invoiceSent?: InputMaybe<Scalars['Boolean']>;
  marketingCompleted?: InputMaybe<Scalars['Boolean']>;
  nextCustomerContactDate?: InputMaybe<Scalars['String']>;
  objectSaleBuyerCommission?: InputMaybe<Scalars['IEEE754Float']>;
  objectSaleBuyerCommissionTaxation?: InputMaybe<LeadObjectSaleBuyerCommissionTaxation>;
  objectSaleBuyerCommissionUnit?: InputMaybe<LeadObjectSaleBuyerCommissionUnit>;
  objectSaleCommission?: InputMaybe<Scalars['IEEE754Float']>;
  objectSaleCommissionTaxation?: InputMaybe<LeadObjectSaleCommissionTaxation>;
  objectSaleCommissionUnit?: InputMaybe<LeadObjectSaleCommissionUnit>;
  objectSaleNotaryDate?: InputMaybe<Scalars['String']>;
  objectSalePrice?: InputMaybe<Scalars['Int']>;
  performEvaluationDate?: InputMaybe<Scalars['String']>;
  performEvaluationDateTookPlace?: InputMaybe<Scalars['Boolean']>;
  performEvaluationPrice?: InputMaybe<Scalars['Int']>;
  saleCompleted?: InputMaybe<Scalars['Boolean']>;
  shareEvaluation?: InputMaybe<Scalars['Boolean']>;
  updateAlternativeText?: InputMaybe<Scalars['String']>;
};

export type LeadUpdateObjectProperties = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  additionalInfoText?: InputMaybe<Scalars['String']>;
  askingPrice?: InputMaybe<Scalars['String']>;
  descriptionText?: InputMaybe<Scalars['String']>;
  equipmentText?: InputMaybe<Scalars['String']>;
  evaluationReason?: InputMaybe<Scalars['String']>;
  isForSale?: InputMaybe<Scalars['Boolean']>;
  locationText?: InputMaybe<Scalars['String']>;
  providerContacted?: InputMaybe<Scalars['Boolean']>;
  saleByProvider?: InputMaybe<Scalars['Boolean']>;
};

/** Autogenerated return type of LeadUpdate */
export type LeadUpdatePayload = {
  __typename?: 'LeadUpdatePayload';
  error?: Maybe<MutationError>;
  lead?: Maybe<Lead>;
};

/** The centralized representation of failed and successful logins of a user. */
export type Login = {
  __typename?: 'Login';
  /** Whenever the (refresh token) of the login is blacklisted or not */
  blacklisted?: Maybe<Scalars['Boolean']>;
  /** When the login was performed (ISO 8601) */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** When the login expires (ISO 8601) */
  expiresAt?: Maybe<Scalars['DateTime']>;
  /** Global Id of the user (UUID) */
  gid: Scalars['GlobalID'];
  /** Id of the login (UUID) */
  id: Scalars['ID'];
  /** Information about the requester location */
  ip?: Maybe<LoginIp>;
  /** The fingerprint of the origin token */
  token?: Maybe<Scalars['String']>;
  /** Information about the requester browser */
  userAgent?: Maybe<LoginUserAgent>;
};

/** The user context in which way to act, related to the used product. */
export enum LoginContext {
  Broker = 'broker',
  CrmAdmin = 'crm_admin',
  CrmAgent = 'crm_agent',
  Customer = 'customer',
  Developer = 'developer',
  Employee = 'employee',
  ExternalAgent = 'external_agent',
  Human = 'human',
  InsuranceBroker = 'insurance_broker',
  Machine = 'machine',
  QualityAssurance = 'quality_assurance'
}

export type LoginEdge = {
  __typename?: 'LoginEdge';
  /** The `Login` entity that this edge points to. */
  node: Login;
};

export type LoginIp = {
  __typename?: 'LoginIp';
  /** The regular IP address */
  address?: Maybe<Scalars['String']>;
  /** The city name (eg. Berlin) */
  cityName?: Maybe<Scalars['String']>;
  /** The 2-letter continent code (eg. EU) */
  continentCode?: Maybe<Scalars['String']>;
  /** The 2-letter country code (eg. DE) */
  countryCode2?: Maybe<Scalars['String']>;
  /** The 3-letter country code (eg. DEU) */
  countryCode3?: Maybe<Scalars['String']>;
  /** The country name (eg. Germany) */
  countryName?: Maybe<Scalars['String']>;
  /** The latitude (eg. 52.51) */
  latitude?: Maybe<Scalars['IEEE754Float']>;
  /** The longitude (eg. 13.40) */
  longitude?: Maybe<Scalars['IEEE754Float']>;
  /** The postal code (eg. 12529) */
  postalCode?: Maybe<Scalars['String']>;
  /** The region name (eg. Berlin) */
  regionName?: Maybe<Scalars['String']>;
  /** The local timezone (eg. Europe/Berlin) */
  timezone?: Maybe<Scalars['String']>;
};

export type LoginMasquerade = {
  context?: InputMaybe<LoginContext>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type LoginPage = {
  __typename?: 'LoginPage';
  /** The total count of results for this search. If this is more than `limit` you may repeat the request with a higher `offset` to get the remaining nodes. */
  count: Scalars['Int'];
  /** The connections to the `Login` entities that are included in this page. */
  edges: Array<LoginEdge>;
  /** The limit that was used to generate this page. The limit is the upper bound of how many nodes are returned. */
  limit: Scalars['Int'];
  /** The `Login` entities that are included in this page. */
  nodes: Array<Login>;
  /** The offset that was used to generate this page. */
  offset: Scalars['Int'];
};

/** Autogenerated return type of Login */
export type LoginPayload = {
  __typename?: 'LoginPayload';
  error?: Maybe<MutationError>;
  jwt?: Maybe<Jwt>;
};

export type LoginUserAgent = {
  __typename?: 'LoginUserAgent';
  /** Is a bot (eg. false) */
  bot?: Maybe<Scalars['Boolean']>;
  /** The browser name (eg. Chrome) */
  browser?: Maybe<Scalars['String']>;
  /** Is a mobile device (eg. true) */
  mobile?: Maybe<Scalars['Boolean']>;
  /** The operation system (eg. iOS 11.4) */
  os?: Maybe<Scalars['String']>;
  /** The machine platform (eg. iPhone) */
  platform?: Maybe<Scalars['String']>;
  /** The raw user agent */
  raw?: Maybe<Scalars['String']>;
  /** The browser version (eg. 67.0.3396.59) */
  version?: Maybe<Scalars['String']>;
};

/** A mandatory lead task represents a task that we require to be fulfilled at some point in the process of working on a lead. */
export type MandatoryLeadTask = {
  __typename?: 'MandatoryLeadTask';
  /** Description (translation key) of the task */
  description?: Maybe<Scalars['String']>;
  /** The due date should be set to this number of minutes after creation of the task. */
  dueDateFromNow?: Maybe<Scalars['Int']>;
  /** ID of the mandatory task */
  id: Scalars['ID'];
  /** Internal name of the task */
  name?: Maybe<Scalars['String']>;
  /** The reminder settings for the task */
  reminder?: Maybe<Array<MandatoryLeadTaskReminder>>;
  /** Title (translation key) of the task */
  title?: Maybe<Scalars['String']>;
};

export type MandatoryLeadTaskReminder = {
  __typename?: 'MandatoryLeadTaskReminder';
  /** How many minutes before the due date should the reminder be triggered */
  beforeMinutes?: Maybe<Scalars['Int']>;
  /** The notification channel */
  channel?: Maybe<Scalars['String']>;
};

export type MutationError = {
  __typename?: 'MutationError';
  actionDetails: Scalars['String'];
  code?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
  reason?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type Mutations = {
  __typename?: 'Mutations';
  /** The HAUSGOLD ecosystem allows to manage an account balance for invoice addresses. This account balance can be increased by purchasing pre-specified packages. */
  accountBalancePackage: AccountBalancePackageNamespace;
  /** The Activity model stores the latest activity of a user on a per-app basis, which can help us determine when sending notifications is superfluous or not. */
  activity: ActivityNamespace;
  /** The HAUSGOLD ecosystem includes abstract appointments for all kind of entities. Users can have appointments, or even properties if you like to. */
  appointment: AppointmentNamespace;
  /** The HAUSGOLD ecosystem includes abstract assets for all kind of entities. An actual asset is a file (eg. images, documents, etc) which can be upload/downloaded. Beside the raw file data an asset bundles also metadata.  Users can have appointments, or even properties if you like to. */
  asset: AssetNamespace;
  /** A HAUSGOLD ecosystem broker account is a specialization of its identity (`User`). A broker is located at the Maklerportal API and contains personal data like first- and lastnames. All the user objects (`identity-api/User`, `maklerportal-api/User`, `verkaeuferportal-api/User`, etc) share the same id (UUID) to signalize they belong together, but differ on the gid (Global Id). Furthermore, the `maklerportal-api/User` is aliased here to be a `Hausgold::Broker` to distinguish the entities on client sides easily. */
  broker: BrokerNamespace;
  /** A HAUSGOLD ecosystem customer account is a specialization of its identity (`User`). A customer is located at the Kundenportal API and contains personal data like first- and lastnames. All the user objects (`identity-api/User`, `maklerportal-api/User`, `verkaeuferportal-api/User`, etc) share the same id (UUID) to signalize they belong together, but differ on the gid (Global Id). Furthermore, the `verkaeuferportal-api/User` is aliased here to be a `Hausgold::Customer` to distinguish the entities on client sides easily. */
  customer: CustomerNamespace;
  /** The HAUSGOLD ecosystem allows the tracking of metrics in an abstract way. The underlying Analytic API acts as a time series database with rich query functionalities. A single data point represents a point in time (in an entity_gid/context/metric hierarchy). */
  dataPoint: DataPointNamespace;
  /** A HAUSGOLD ecosystem financing lead is located at the Leads API and contains contact information. They can be bought by insurance brokers using the Payment API. */
  financingLead: FinancingLeadNamespace;
  /** The HAUSGOLD ecosystem allows users to manage and run business intelligence queries. These queries are useful for augemented data presentation or analytical processing. The queries are executed by the intelligence application on read-replicated database instances. */
  insightsQuery: InsightsQueryNamespace;
  /** A HAUSGOLD ecosystem insurance lead is located at the Leads API and contains contact information. They can be bought by insurance brokers using the Payment API. */
  insuranceLead: InsuranceLeadNamespace;
  /** The HAUSGOLD ecosystem allows to manage special addresses for invoicing. */
  invoiceAddress: InvoiceAddressNamespace;
  /** A HAUSGOLD ecosystem lead belongs to a Broker (`User`). An lead is located at the Maklerportal API and contains general data like address and contact information.  rubocop:disable Metrics/ClassLength because of the associations and helpers :reek:TooManyMethods because of the number of workflows */
  lead: LeadNamespace;
  /** A HAUSGOLD ecosystem lead_manager groups as set of leads (`Lead`). A lead_manager is located at the Maklerportal API and contains supply relevant data like acceptable leads count. */
  leadManager: LeadManagerNamespace;
  /** Allows the login to the HAUSGOLD ecosystem and retrieve a JWT. */
  login?: Maybe<LoginPayload>;
  /** The HAUSGOLD ecosystem allows the tracking of abstract notification tokens. These tokens are user to device mappings on the external GCP FireBase service. So we allow a single user to have multiple devices per application. (eg. a desktop brower notification and a iOS native app push message for the Maklerportal) */
  notificationToken: NotificationTokenNamespace;
  /** A HAUSGOLD ecosystem office belongs to a Broker (`User`). An office is located at the Maklerportal API and contains general data like address and contact information. */
  office: OfficeNamespace;
  /** A HAUSGOLD ecosystem organization belongs to a Broker (`User`). An organization is located at the Maklerportal API and contains general data like address and contact information. */
  organization: OrganizationNamespace;
  /** The HAUSGOLD ecosystem allows to manage payments and related items. */
  payment: PaymentNamespace;
  /** The HAUSGOLD ecosystem allows to manage payment mandates for debit payments. This payment method can be used for buying leads. */
  paymentMandate: PaymentMandateNamespace;
  /** The HAUSGOLD ecosystem allows users to generate PDF documents directly from a given website (by URL). You can customize the PDF generation as much as you like. The defaults just works good for German documents. The actual PDF generation takes time and is always performed asynchronously. So the client must poll until the document is available or a callback URL can be specified (by `callback_url`) which is called when the generation is done. This is quite handy in HTTP server contexts, otherwise use the polling. */
  pdf: PdfNamespace;
  /** The HAUSGOLD PreferencesSet is the central information blob for all kind of user settings. With the help of it you can tweak the knobs and pulls for a user on the ecosystem. */
  preferencesSet: PreferencesSetNamespace;
  /** The HAUSGOLD Property is the central information blob for a physical real estate object. */
  property: PropertyNamespace;
  /** Reviews are a generic way to collect feedback from any entity on the ecosystem (eg. customers) about any other entity on the ecosystem (eg. brokers) in a free text form. */
  review: ReviewNamespace;
  /** Role Assignments provide users with all policies of the given role for the assignment's configured object. */
  roleAssignment: RoleAssignmentNamespace;
  /** The HAUSGOLD search profile is a loosely coupled entity to formulate a complex set of search criteria for the property search. */
  searchProfile: SearchProfileNamespace;
  /** The HAUSGOLD ecosystem has an abstract task entity which represents a thing which needs to be done. Everybody can have tasks - users, properties, or anything else. The tasks come with a state which defines them as open, resolved or rejected. They bundle titles and descriptions and can also have alarms (reminders) for optional due dates. */
  task: TaskNamespace;
  /** The HAUSGOLD ecosystem allows users to define timeframes. This can be helpful to abstract ranges in time for any kind of reason. A common use case could be an calendar application which should track the possible free time windows of a user. */
  timeframe: TimeframeNamespace;
  /** A HAUSGOLD ecosystem user account with the bare details of his identity. It does not contain any personal data such as first- or lastnames. These information is decentralized located at each applications scope. Say you want to retrive the personal data of a broker, then you need to ask the Maklerportal API. The user entities share all the same id (UUID), but differ on the gid (Global Id) according to the specific application. */
  user: UserNamespace;
};


export type MutationsLoginArgs = {
  context?: InputMaybe<LoginContext>;
  email?: InputMaybe<Scalars['String']>;
  expiresIn?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['LoginGlobalIdOrUuid']>;
  masquerade?: InputMaybe<LoginMasquerade>;
  password: Scalars['String'];
};

/** The HAUSGOLD ecosystem allows the tracking of abstract notification tokens. These tokens are user to device mappings on the external GCP FireBase service. So we allow a single user to have multiple devices per application. (eg. a desktop brower notification and a iOS native app push message for the Maklerportal) */
export type NotificationToken = CanHaveDataPoints & GlobalIdentifiable & {
  __typename?: 'NotificationToken';
  /** The id of the application that should ne notified. */
  appId?: Maybe<Scalars['ID']>;
  /** Searches for referenced `data_points` */
  dataPoints?: Maybe<DataPointsResult>;
  /** Global Id of the notification token (UUID) */
  gid: Scalars['GlobalID'];
  /** Id of the notification token (UUID) */
  id: Scalars['ID'];
  /** The id of the project the token is associated with. */
  projectId?: Maybe<Scalars['ID']>;
  /** The notification token. */
  token?: Maybe<Scalars['String']>;
  /** The id of the user that should be notified. */
  userId?: Maybe<Scalars['ID']>;
};


/** The HAUSGOLD ecosystem allows the tracking of abstract notification tokens. These tokens are user to device mappings on the external GCP FireBase service. So we allow a single user to have multiple devices per application. (eg. a desktop brower notification and a iOS native app push message for the Maklerportal) */
export type NotificationTokenDataPointsArgs = {
  aggregation?: InputMaybe<Aggregation>;
  context: Scalars['String'];
  endAt?: InputMaybe<Scalars['DateTime']>;
  interval?: InputMaybe<Interval>;
  metrics: Array<Scalars['String']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
};

/** Autogenerated return type of NotificationTokenCreate */
export type NotificationTokenCreatePayload = {
  __typename?: 'NotificationTokenCreatePayload';
  error?: Maybe<MutationError>;
  notificationToken?: Maybe<NotificationToken>;
};

/** Autogenerated return type of NotificationTokenDelete */
export type NotificationTokenDeletePayload = {
  __typename?: 'NotificationTokenDeletePayload';
  error?: Maybe<MutationError>;
  notificationToken?: Maybe<NotificationToken>;
};

export type NotificationTokenEdge = {
  __typename?: 'NotificationTokenEdge';
  /** The `NotificationToken` entity that this edge points to. */
  node: NotificationToken;
};

export type NotificationTokenNamespace = {
  __typename?: 'NotificationTokenNamespace';
  /** Creates an object (or multiple objects) and saves it at the remote application, if validations pass. The resulting object is returned whether the object was saved successfully or not. The attributes parameter can be either a Hash or an Array of Hashes. These Hashes describe the attributes on the objects that are to be created. */
  create: NotificationTokenCreatePayload;
  /** Deletes the instance at the remote application and freezes this instance to reflect that no changes should be made (since they can't be persisted). To enforce the object's before_destroy and after_destroy callbacks use #destroy. */
  delete: NotificationTokenDeletePayload;
  /** Updates the attributes of the entity from the passed-in hash and saves the entity. If the object is invalid, the saving will fail and false will be returned. Also aliased as: update_attributes. */
  update: NotificationTokenUpdatePayload;
};


export type NotificationTokenNamespaceCreateArgs = {
  appId: Scalars['ID'];
  projectId: Scalars['ID'];
  token: Scalars['String'];
  userId: Scalars['ID'];
};


export type NotificationTokenNamespaceDeleteArgs = {
  id: Scalars['NotificationTokenGlobalIdOrUuid'];
};


export type NotificationTokenNamespaceUpdateArgs = {
  appId?: InputMaybe<Scalars['ID']>;
  id: Scalars['NotificationTokenGlobalIdOrUuid'];
  projectId?: InputMaybe<Scalars['ID']>;
  token?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type NotificationTokenPage = {
  __typename?: 'NotificationTokenPage';
  /** The total count of results for this search. If this is more than `limit` you may repeat the request with a higher `offset` to get the remaining nodes. */
  count: Scalars['Int'];
  /** The connections to the `NotificationToken` entities that are included in this page. */
  edges: Array<NotificationTokenEdge>;
  /** The limit that was used to generate this page. The limit is the upper bound of how many nodes are returned. */
  limit: Scalars['Int'];
  /** The `NotificationToken` entities that are included in this page. */
  nodes: Array<NotificationToken>;
  /** The offset that was used to generate this page. */
  offset: Scalars['Int'];
};

export type NotificationTokenSearchParameters = {
  appId?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<Scalars['NotificationTokenGlobalIdOrUuid']>>;
  projectId?: InputMaybe<Scalars['ID']>;
  token?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of NotificationTokenUpdate */
export type NotificationTokenUpdatePayload = {
  __typename?: 'NotificationTokenUpdatePayload';
  error?: Maybe<MutationError>;
  notificationToken?: Maybe<NotificationToken>;
};

/** A HAUSGOLD ecosystem office belongs to a Broker (`User`). An office is located at the Maklerportal API and contains general data like address and contact information. */
export type Office = CanHaveDataPoints & GlobalIdentifiable & {
  __typename?: 'Office';
  /** The address of the office */
  address?: Maybe<OfficeAddress>;
  /** The office badges */
  badgeList?: Maybe<Array<Scalars['String']>>;
  /** The brokers GIDs connected to the office */
  brokers?: Maybe<Array<Broker>>;
  /** When the office was created (ISO 8601) */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Id of the office in crm context */
  crmId?: Maybe<Scalars['ID']>;
  /** Searches for referenced `data_points` */
  dataPoints?: Maybe<DataPointsResult>;
  /** Any labels that the office does not want to see on any leads. If leads are being sent that contain any of these labels, they cannot be created. */
  deniedLeadLabels?: Maybe<Array<Scalars['String']>>;
  /** Payment methods that are not allowed for this office */
  disabledPaymentMethods?: Maybe<Array<Scalars['String']>>;
  /** The email of the office */
  email?: Maybe<Scalars['String']>;
  /** The employees count of the office */
  employeesCount?: Maybe<Scalars['Int']>;
  /** The fax of the office */
  fax?: Maybe<Scalars['String']>;
  /** Global Id of the office (UUID) */
  gid: Scalars['GlobalID'];
  /** The homepage of the office */
  homepage?: Maybe<Scalars['String']>;
  /** Id of the office (UUID) */
  id: Scalars['ID'];
  /** The broker who receives new leads on the office */
  leadReceiver?: Maybe<Broker>;
  /** The name of the office */
  name?: Maybe<Scalars['String']>;
  /** Global Id of the connected organization (UUID) */
  organization?: Maybe<Organization>;
  /** The phone of the office */
  phone?: Maybe<Scalars['String']>;
  /** Any labels that are required to be present for any lead that this office is willing to accept. If leads are being sent that are missing this label, they cannot be created. */
  requiredLeadLabels?: Maybe<Array<Scalars['String']>>;
  /** The office services */
  serviceList?: Maybe<Array<Scalars['String']>>;
  /** The office specializations */
  specializationList?: Maybe<Array<Scalars['String']>>;
  /** When the office was updated the last time (ISO 8601) */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** When the office account was verified time (ISO 8601) */
  verifiedAt?: Maybe<Scalars['DateTime']>;
};


/** A HAUSGOLD ecosystem office belongs to a Broker (`User`). An office is located at the Maklerportal API and contains general data like address and contact information. */
export type OfficeDataPointsArgs = {
  aggregation?: InputMaybe<Aggregation>;
  context: Scalars['String'];
  endAt?: InputMaybe<Scalars['DateTime']>;
  interval?: InputMaybe<Interval>;
  metrics: Array<Scalars['String']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
};

/** Autogenerated return type of OfficeAddMember */
export type OfficeAddMemberPayload = {
  __typename?: 'OfficeAddMemberPayload';
  error?: Maybe<MutationError>;
  office?: Maybe<Office>;
};

export type OfficeAddress = {
  __typename?: 'OfficeAddress';
  /** The city name of the address */
  city?: Maybe<Scalars['String']>;
  /** The 2-letter country code (eg. DE) */
  countryCode?: Maybe<Scalars['String']>;
  /** Global Id of the address (UUID) */
  gid: Scalars['String'];
  /** Id of the address (UUID) */
  id: Scalars['String'];
  /** The street/house number of the address */
  street?: Maybe<Scalars['String']>;
  /** A street addition of the address */
  streetAddition?: Maybe<Scalars['String']>;
  /** The postal code of the city/address */
  zipcode?: Maybe<Scalars['String']>;
};

export type OfficeCreateAddress = {
  city?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  streetAddition?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of OfficeCreate */
export type OfficeCreatePayload = {
  __typename?: 'OfficeCreatePayload';
  error?: Maybe<MutationError>;
  office?: Maybe<Office>;
};

export enum OfficeDisabledPaymentMethods {
  BraintreePayment = 'braintree_payment',
  GocardlessPayment = 'gocardless_payment',
  InvoicePayment = 'invoice_payment'
}

export type OfficeEdge = {
  __typename?: 'OfficeEdge';
  /** The `Office` entity that this edge points to. */
  node: Office;
};

export enum OfficeGender {
  Female = 'female',
  Male = 'male',
  Other = 'other'
}

/** Autogenerated return type of OfficeInviteMember */
export type OfficeInviteMemberPayload = {
  __typename?: 'OfficeInviteMemberPayload';
  error?: Maybe<MutationError>;
  office?: Maybe<Office>;
};

export enum OfficeLocale {
  DeAt = 'de_AT',
  DeCh = 'de_CH',
  DeDe = 'de_DE',
  EsEs = 'es_ES',
  FrCh = 'fr_CH',
  FrFr = 'fr_FR'
}

export type OfficeNamespace = {
  __typename?: 'OfficeNamespace';
  /** Adds a member to the office instance. */
  addMember: OfficeAddMemberPayload;
  /** Creates an object (or multiple objects) and saves it at the remote application, if validations pass. The resulting object is returned whether the object was saved successfully or not. The attributes parameter can be either a Hash or an Array of Hashes. These Hashes describe the attributes on the objects that are to be created. */
  create: OfficeCreatePayload;
  /** Invite a new user (inactive account) to the HAUSGOLD ecosystem as broker and directly add the user to this office. When the email of an existing user (broker) is given, the broker will also be added to the office. In case an email of a non-broker user was given, the workflow will fail. */
  inviteMember: OfficeInviteMemberPayload;
  /** Remove a member from the office instance. */
  removeMember: OfficeRemoveMemberPayload;
  /** Updates the attributes of the entity from the passed-in hash and saves the entity. If the object is invalid, the saving will fail and false will be returned. Also aliased as: update_attributes. */
  update: OfficeUpdatePayload;
};


export type OfficeNamespaceAddMemberArgs = {
  id: Scalars['OfficeGlobalIdOrUuid'];
  userGid: Scalars['GlobalID'];
};


export type OfficeNamespaceCreateArgs = {
  address?: InputMaybe<OfficeCreateAddress>;
  badgeList?: InputMaybe<Array<Scalars['String']>>;
  crmId?: InputMaybe<Scalars['Int']>;
  deniedLeadLabels?: InputMaybe<Array<Scalars['String']>>;
  disabledPaymentMethods?: InputMaybe<Array<OfficeDisabledPaymentMethods>>;
  email?: InputMaybe<Scalars['String']>;
  employeesCount?: InputMaybe<Scalars['Int']>;
  fax?: InputMaybe<Scalars['String']>;
  homepage?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  phone?: InputMaybe<Scalars['String']>;
  requiredLeadLabels?: InputMaybe<Array<Scalars['String']>>;
  serviceList?: InputMaybe<Array<Scalars['String']>>;
  specializationList?: InputMaybe<Array<Scalars['String']>>;
  verifiedAt?: InputMaybe<Scalars['String']>;
};


export type OfficeNamespaceInviteMemberArgs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  gender?: InputMaybe<OfficeGender>;
  id: Scalars['OfficeGlobalIdOrUuid'];
  lastName: Scalars['String'];
  locale?: InputMaybe<OfficeLocale>;
};


export type OfficeNamespaceRemoveMemberArgs = {
  id: Scalars['OfficeGlobalIdOrUuid'];
  userGid: Scalars['GlobalID'];
};


export type OfficeNamespaceUpdateArgs = {
  address?: InputMaybe<OfficeUpdateAddress>;
  badgeList?: InputMaybe<Array<Scalars['String']>>;
  crmId?: InputMaybe<Scalars['Int']>;
  deniedLeadLabels?: InputMaybe<Array<Scalars['String']>>;
  disabledPaymentMethods?: InputMaybe<Array<OfficeDisabledPaymentMethods>>;
  email?: InputMaybe<Scalars['String']>;
  employeesCount?: InputMaybe<Scalars['Int']>;
  fax?: InputMaybe<Scalars['String']>;
  homepage?: InputMaybe<Scalars['String']>;
  id: Scalars['OfficeGlobalIdOrUuid'];
  leadReceiverId?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  requiredLeadLabels?: InputMaybe<Array<Scalars['String']>>;
  serviceList?: InputMaybe<Array<Scalars['String']>>;
  specializationList?: InputMaybe<Array<Scalars['String']>>;
};

export type OfficePage = {
  __typename?: 'OfficePage';
  /** The total count of results for this search. If this is more than `limit` you may repeat the request with a higher `offset` to get the remaining nodes. */
  count: Scalars['Int'];
  /** The connections to the `Office` entities that are included in this page. */
  edges: Array<OfficeEdge>;
  /** The limit that was used to generate this page. The limit is the upper bound of how many nodes are returned. */
  limit: Scalars['Int'];
  /** The `Office` entities that are included in this page. */
  nodes: Array<Office>;
  /** The offset that was used to generate this page. */
  offset: Scalars['Int'];
};

/** Autogenerated return type of OfficeRemoveMember */
export type OfficeRemoveMemberPayload = {
  __typename?: 'OfficeRemoveMemberPayload';
  error?: Maybe<MutationError>;
  office?: Maybe<Office>;
};

export type OfficeSearchParameters = {
  crmId?: InputMaybe<Array<Scalars['Int']>>;
  ids?: InputMaybe<Array<Scalars['OfficeGlobalIdOrUuid']>>;
  organizationId?: InputMaybe<Scalars['ID']>;
  text?: InputMaybe<Scalars['String']>;
  userGid?: InputMaybe<Scalars['GlobalID']>;
};

export type OfficeUpdateAddress = {
  city?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  streetAddition?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of OfficeUpdate */
export type OfficeUpdatePayload = {
  __typename?: 'OfficeUpdatePayload';
  error?: Maybe<MutationError>;
  office?: Maybe<Office>;
};

/** A HAUSGOLD ecosystem organization belongs to a Broker (`User`). An organization is located at the Maklerportal API and contains general data like address and contact information. */
export type Organization = CanHaveDataPoints & GlobalIdentifiable & {
  __typename?: 'Organization';
  /** The address of the office */
  address?: Maybe<OrganizationAddress>;
  /** The board_chairman of the organization */
  boardChairman?: Maybe<Scalars['String']>;
  /** The board_members of the organization */
  boardMembers?: Maybe<Scalars['String']>;
  /** The ceo of the organization */
  ceo?: Maybe<Scalars['String']>;
  /** When the organization was created (ISO 8601) */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Id of the organization in crm context */
  crmId?: Maybe<Scalars['ID']>;
  /** Searches for referenced `data_points` */
  dataPoints?: Maybe<DataPointsResult>;
  /** The description of the organization */
  description?: Maybe<Scalars['String']>;
  /** The email of the organization */
  email?: Maybe<Scalars['String']>;
  /** The founding_year of the organization */
  foundingYear?: Maybe<Scalars['Int']>;
  /** Global Id of the organization (UUID) */
  gid: Scalars['GlobalID'];
  /** Id of the organization (UUID) */
  id: Scalars['ID'];
  /** The legal_form of the organization */
  legalForm?: Maybe<Scalars['String']>;
  logo?: Maybe<Asset>;
  /** The managers of the organization */
  managers?: Maybe<Scalars['String']>;
  /** The name of the organization */
  name?: Maybe<Scalars['String']>;
  /** Searches for referenced `offices` */
  offices?: Maybe<OfficePage>;
  /** The register_court of the organization */
  registerCourt?: Maybe<Scalars['String']>;
  /** The trade_register_number of the organization */
  tradeRegisterNumber?: Maybe<Scalars['String']>;
  /** When the organization was updated the last time (ISO 8601) */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The vat_number of the organization */
  vatNumber?: Maybe<Scalars['String']>;
  /** When the organization account was verified time (ISO 8601) */
  verifiedAt?: Maybe<Scalars['DateTime']>;
};


/** A HAUSGOLD ecosystem organization belongs to a Broker (`User`). An organization is located at the Maklerportal API and contains general data like address and contact information. */
export type OrganizationDataPointsArgs = {
  aggregation?: InputMaybe<Aggregation>;
  context: Scalars['String'];
  endAt?: InputMaybe<Scalars['DateTime']>;
  interval?: InputMaybe<Interval>;
  metrics: Array<Scalars['String']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
};


/** A HAUSGOLD ecosystem organization belongs to a Broker (`User`). An organization is located at the Maklerportal API and contains general data like address and contact information. */
export type OrganizationOfficesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<OrganizationOfficeSearchParameters>;
};

/** Autogenerated return type of OrganizationAddMember */
export type OrganizationAddMemberPayload = {
  __typename?: 'OrganizationAddMemberPayload';
  error?: Maybe<MutationError>;
  organization?: Maybe<Organization>;
};

export type OrganizationAddress = {
  __typename?: 'OrganizationAddress';
  /** The city name of the address */
  city?: Maybe<Scalars['String']>;
  /** The 2-letter country code (eg. DE) */
  countryCode?: Maybe<Scalars['String']>;
  /** Global Id of the address (UUID) */
  gid: Scalars['String'];
  /** Id of the address (UUID) */
  id: Scalars['String'];
  /** The street/house number of the address */
  street?: Maybe<Scalars['String']>;
  /** A street addition of the address */
  streetAddition?: Maybe<Scalars['String']>;
  /** The postal code of the city/address */
  zipcode?: Maybe<Scalars['String']>;
};

export type OrganizationCreateAddress = {
  city?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  streetAddition?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of OrganizationCreate */
export type OrganizationCreatePayload = {
  __typename?: 'OrganizationCreatePayload';
  error?: Maybe<MutationError>;
  organization?: Maybe<Organization>;
};

export type OrganizationEdge = {
  __typename?: 'OrganizationEdge';
  /** The `Organization` entity that this edge points to. */
  node: Organization;
};

export enum OrganizationLegalForm {
  Ag = 'AG',
  AoR = 'AoR',
  Einzelunternehmer = 'Einzelunternehmer',
  GbR = 'GbR',
  Gen = 'Gen',
  GesbR = 'GesbR',
  GmbH = 'GmbH',
  GmbHCoKg = 'GmbH_Co_KG',
  Kg = 'KG',
  Og = 'OG',
  Ohg = 'OHG',
  PartG = 'PartG',
  Ug = 'UG',
  UgCoKg = 'UG_Co_KG',
  EG = 'eG',
  EK = 'e_K',
  StG = 'stG'
}

export type OrganizationNamespace = {
  __typename?: 'OrganizationNamespace';
  /** Adds a member to the organization instance. */
  addMember: OrganizationAddMemberPayload;
  /** Creates an object (or multiple objects) and saves it at the remote application, if validations pass. The resulting object is returned whether the object was saved successfully or not. The attributes parameter can be either a Hash or an Array of Hashes. These Hashes describe the attributes on the objects that are to be created. */
  create: OrganizationCreatePayload;
  /** Remove a member from the organization instance. */
  removeMember: OrganizationRemoveMemberPayload;
  /** Updates the attributes of the entity from the passed-in hash and saves the entity. If the object is invalid, the saving will fail and false will be returned. Also aliased as: update_attributes. */
  update: OrganizationUpdatePayload;
};


export type OrganizationNamespaceAddMemberArgs = {
  id: Scalars['OrganizationGlobalIdOrUuid'];
  userGid: Scalars['GlobalID'];
};


export type OrganizationNamespaceCreateArgs = {
  address?: InputMaybe<OrganizationCreateAddress>;
  boardChairman?: InputMaybe<Scalars['String']>;
  boardMembers?: InputMaybe<Scalars['String']>;
  ceo?: InputMaybe<Scalars['String']>;
  crmId?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  foundingYear?: InputMaybe<Scalars['Int']>;
  legalForm: OrganizationLegalForm;
  managers?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  registerCourt?: InputMaybe<Scalars['String']>;
  tradeRegisterNumber?: InputMaybe<Scalars['String']>;
  vatNumber?: InputMaybe<Scalars['String']>;
  verifiedAt?: InputMaybe<Scalars['String']>;
};


export type OrganizationNamespaceRemoveMemberArgs = {
  id: Scalars['OrganizationGlobalIdOrUuid'];
  userGid: Scalars['GlobalID'];
};


export type OrganizationNamespaceUpdateArgs = {
  address?: InputMaybe<OrganizationUpdateAddress>;
  boardChairman?: InputMaybe<Scalars['String']>;
  boardMembers?: InputMaybe<Scalars['String']>;
  ceo?: InputMaybe<Scalars['String']>;
  crmId?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  foundingYear?: InputMaybe<Scalars['Int']>;
  id: Scalars['OrganizationGlobalIdOrUuid'];
  legalForm?: InputMaybe<OrganizationLegalForm>;
  managers?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  registerCourt?: InputMaybe<Scalars['String']>;
  tradeRegisterNumber?: InputMaybe<Scalars['String']>;
  vatNumber?: InputMaybe<Scalars['String']>;
};

export type OrganizationOfficeSearchParameters = {
  crmId?: InputMaybe<Array<Scalars['Int']>>;
  ids?: InputMaybe<Array<Scalars['OfficeGlobalIdOrUuid']>>;
  text?: InputMaybe<Scalars['String']>;
  userGid?: InputMaybe<Scalars['GlobalID']>;
};

export type OrganizationPage = {
  __typename?: 'OrganizationPage';
  /** The total count of results for this search. If this is more than `limit` you may repeat the request with a higher `offset` to get the remaining nodes. */
  count: Scalars['Int'];
  /** The connections to the `Organization` entities that are included in this page. */
  edges: Array<OrganizationEdge>;
  /** The limit that was used to generate this page. The limit is the upper bound of how many nodes are returned. */
  limit: Scalars['Int'];
  /** The `Organization` entities that are included in this page. */
  nodes: Array<Organization>;
  /** The offset that was used to generate this page. */
  offset: Scalars['Int'];
};

/** Autogenerated return type of OrganizationRemoveMember */
export type OrganizationRemoveMemberPayload = {
  __typename?: 'OrganizationRemoveMemberPayload';
  error?: Maybe<MutationError>;
  organization?: Maybe<Organization>;
};

export type OrganizationSearchParameters = {
  crmId?: InputMaybe<Array<Scalars['Int']>>;
  ids?: InputMaybe<Array<Scalars['OrganizationGlobalIdOrUuid']>>;
  text?: InputMaybe<Scalars['String']>;
  userGid?: InputMaybe<Scalars['GlobalID']>;
};

export type OrganizationUpdateAddress = {
  city?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  streetAddition?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of OrganizationUpdate */
export type OrganizationUpdatePayload = {
  __typename?: 'OrganizationUpdatePayload';
  error?: Maybe<MutationError>;
  organization?: Maybe<Organization>;
};

/** The HAUSGOLD ecosystem allows to manage payments and related items. */
export type Payment = CanHaveDataPoints & GlobalIdentifiable & {
  __typename?: 'Payment';
  /** When the payment was created (ISO 8601) */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The currency used for the payment */
  currency?: Maybe<PaymentCurrency>;
  /** Searches for referenced `data_points` */
  dataPoints?: Maybe<DataPointsResult>;
  /** Global Id of the payment (UUID) */
  gid: Scalars['GlobalID'];
  /** The price sum with tax included in cents */
  grossAmountSum?: Maybe<Scalars['Int']>;
  /** Id of the payment (UUID) */
  id: Scalars['ID'];
  /** All connected payment items */
  items?: Maybe<Array<PaymentItem>>;
  /** The price sum without tax in cents */
  netAmountSum?: Maybe<Scalars['Int']>;
  /** The visible order ID used for some payment providers */
  orderId?: Maybe<Scalars['ID']>;
  /** The visible order name/description used for some payment providers */
  orderName?: Maybe<Scalars['String']>;
  /** Global Id of the owner/creator of the payment (UUID) */
  owner?: Maybe<GlobalIdentifiable>;
  /** The payment provider specific data like nounces, etc. */
  paymentProvider?: Maybe<Scalars['JsonBlob']>;
  /** The name of the payment provider */
  paymentProviderType?: Maybe<Scalars['String']>;
  /** When the payment was successfully settled (ISO 8601) */
  settledAt?: Maybe<Scalars['DateTime']>;
  /** When the payment settlement has started (ISO 8601) */
  settlementStartedAt?: Maybe<Scalars['DateTime']>;
  /** The current state of the payment */
  state?: Maybe<PaymentState>;
  /** The total tax amount of this payment in cents */
  taxAmountSum?: Maybe<Scalars['Int']>;
  /** When the payment was updated last time (ISO 8601) */
  updatedAt?: Maybe<Scalars['DateTime']>;
};


/** The HAUSGOLD ecosystem allows to manage payments and related items. */
export type PaymentDataPointsArgs = {
  aggregation?: InputMaybe<Aggregation>;
  context: Scalars['String'];
  endAt?: InputMaybe<Scalars['DateTime']>;
  interval?: InputMaybe<Interval>;
  metrics: Array<Scalars['String']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
};

export type PaymentAuthorizeBraintreePayment = {
  description?: InputMaybe<Scalars['String']>;
  details?: InputMaybe<PaymentAuthorizeBraintreePaymentDetails>;
  liabilityShiftPossible?: InputMaybe<Scalars['Boolean']>;
  liabilityShifted?: InputMaybe<Scalars['Boolean']>;
  nonce: Scalars['String'];
  type: PaymentType;
  vaulted?: InputMaybe<Scalars['Boolean']>;
};

export type PaymentAuthorizeBraintreePaymentDetails = {
  bin?: InputMaybe<Scalars['String']>;
  cardType?: InputMaybe<Scalars['String']>;
  cardholderName?: InputMaybe<Scalars['String']>;
  correlationId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  expirationMonth?: InputMaybe<Scalars['String']>;
  expirationYear?: InputMaybe<Scalars['String']>;
  lastFour?: InputMaybe<Scalars['String']>;
  lastTwo?: InputMaybe<Scalars['String']>;
  payerInfo?: InputMaybe<PaymentAuthorizeBraintreePaymentDetailsPayerInfo>;
};

export type PaymentAuthorizeBraintreePaymentDetailsPayerInfo = {
  billingAgreementId?: InputMaybe<Scalars['String']>;
};

export type PaymentAuthorizeGocardlessPayment = {
  paymentMandateId: Scalars['String'];
};

/** Autogenerated return type of PaymentAuthorize */
export type PaymentAuthorizePayload = {
  __typename?: 'PaymentAuthorizePayload';
  error?: Maybe<MutationError>;
  payment?: Maybe<Payment>;
};

/** Autogenerated return type of PaymentChangeProvider */
export type PaymentChangeProviderPayload = {
  __typename?: 'PaymentChangeProviderPayload';
  error?: Maybe<MutationError>;
  payment?: Maybe<Payment>;
};

/** Autogenerated return type of PaymentCreate */
export type PaymentCreatePayload = {
  __typename?: 'PaymentCreatePayload';
  error?: Maybe<MutationError>;
  payment?: Maybe<Payment>;
};

/** The currency used for the payment */
export enum PaymentCurrency {
  Aed = 'AED',
  Afa = 'AFA',
  All = 'ALL',
  Amd = 'AMD',
  Ang = 'ANG',
  Aon = 'AON',
  Arp = 'ARP',
  Ats = 'ATS',
  Aud = 'AUD',
  Awf = 'AWF',
  Azm = 'AZM',
  Bak = 'BAK',
  Bbd = 'BBD',
  Bdt = 'BDT',
  Bef = 'BEF',
  Bes = 'BES',
  Bgl = 'BGL',
  Bhd = 'BHD',
  Bif = 'BIF',
  Bmd = 'BMD',
  Bnd = 'BND',
  Bob = 'BOB',
  Brl = 'BRL',
  Bsd = 'BSD',
  Btr = 'BTR',
  Bwp = 'BWP',
  Byr = 'BYR',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Chf = 'CHF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Crc = 'CRC',
  Cup = 'CUP',
  Cuw = 'CUW',
  Cve = 'CVE',
  Cyp = 'CYP',
  Czk = 'CZK',
  Dem = 'DEM',
  Djf = 'DJF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Ecs = 'ECS',
  Eek = 'EEK',
  Egp = 'EGP',
  Ern = 'ERN',
  Esp = 'ESP',
  Etb = 'ETB',
  Eur = 'EUR',
  Fim = 'FIM',
  Fjd = 'FJD',
  Fkp = 'FKP',
  Frf = 'FRF',
  Gbp = 'GBP',
  Gel = 'GEL',
  Ghc = 'GHC',
  Gip = 'GIP',
  Gmd = 'GMD',
  Gnf = 'GNF',
  Grd = 'GRD',
  Gtq = 'GTQ',
  Gyd = 'GYD',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Hrk = 'HRK',
  Htg = 'HTG',
  Huf = 'HUF',
  Idr = 'IDR',
  Iep = 'IEP',
  Ils = 'ILS',
  Inr = 'INR',
  Iqd = 'IQD',
  Irr = 'IRR',
  Isk = 'ISK',
  Itl = 'ITL',
  Jmd = 'JMD',
  Jod = 'JOD',
  Jpy = 'JPY',
  Kes = 'KES',
  Kgs = 'KGS',
  Khr = 'KHR',
  Kmf = 'KMF',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kyd = 'KYD',
  Kzt = 'KZT',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Lrd = 'LRD',
  Lsl = 'LSL',
  Ltl = 'LTL',
  Luf = 'LUF',
  Lvl = 'LVL',
  Lyd = 'LYD',
  Mad = 'MAD',
  Mdl = 'MDL',
  Mgf = 'MGF',
  Mmk = 'MMK',
  Mnt = 'MNT',
  Mop = 'MOP',
  Mro = 'MRO',
  Mtl = 'MTL',
  Mur = 'MUR',
  Mvr = 'MVR',
  Mwk = 'MWK',
  Mxp = 'MXP',
  Mzm = 'MZM',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nio = 'NIO',
  Nlg = 'NLG',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pab = 'PAB',
  Pen = 'PEN',
  Pgk = 'PGK',
  Php = 'PHP',
  Pkr = 'PKR',
  Plz = 'PLZ',
  Pte = 'PTE',
  Pyg = 'PYG',
  Qar = 'QAR',
  Rol = 'ROL',
  Rur = 'RUR',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sbd = 'SBD',
  Sbl = 'SBL',
  Scr = 'SCR',
  Sdd = 'SDD',
  Sek = 'SEK',
  Sgd = 'SGD',
  Shp = 'SHP',
  Sit = 'SIT',
  Skk = 'SKK',
  Sll = 'SLL',
  Sos = 'SOS',
  Srg = 'SRG',
  Std = 'STD',
  Svc = 'SVC',
  Syp = 'SYP',
  Szl = 'SZL',
  Thb = 'THB',
  Tjr = 'TJR',
  Tmm = 'TMM',
  Tnd = 'TND',
  Top = 'TOP',
  Trl = 'TRL',
  Ttd = 'TTD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Ugx = 'UGX',
  Usd = 'USD',
  Uyu = 'UYU',
  Uzs = 'UZS',
  Veb = 'VEB',
  Vnd = 'VND',
  Vuv = 'VUV',
  Wst = 'WST',
  Xaf = 'XAF',
  Xag = 'XAG',
  Xau = 'XAU',
  Xcd = 'XCD',
  Xco = 'XCO',
  Xdr = 'XDR',
  Xpd = 'XPD',
  Xpf = 'XPF',
  Xpt = 'XPT',
  Yer = 'YER',
  Yun = 'YUN',
  Zar = 'ZAR',
  Zmk = 'ZMK',
  Zwd = 'ZWD'
}

/** Autogenerated return type of PaymentDelete */
export type PaymentDeletePayload = {
  __typename?: 'PaymentDeletePayload';
  error?: Maybe<MutationError>;
  payment?: Maybe<Payment>;
};

export type PaymentEdge = {
  __typename?: 'PaymentEdge';
  /** The `Payment` entity that this edge points to. */
  node: Payment;
};

export type PaymentItem = {
  __typename?: 'PaymentItem';
  /** When the item was added to the payment (ISO 8601) */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** A description for this item */
  description?: Maybe<Scalars['String']>;
  /** The price of the item in cents (with tax) */
  grossAmount?: Maybe<Scalars['Int']>;
  /** The name of this item */
  name?: Maybe<Scalars['String']>;
  /** The price of the item in cents (without tax) */
  netAmount?: Maybe<Scalars['Int']>;
  /** The item that is being sold */
  sellable?: Maybe<GlobalIdentifiable>;
  /** The tax rate for the item (percent) */
  taxRate?: Maybe<Scalars['IEEE754Float']>;
  /** When the item was updated last time at the payment (ISO 8601) */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** The HAUSGOLD ecosystem allows to manage payment mandates for debit payments. This payment method can be used for buying leads. */
export type PaymentMandate = CanHaveDataPoints & GlobalIdentifiable & {
  __typename?: 'PaymentMandate';
  /** When the payment mandate was confirmed (ISO 8601) */
  confirmedAt?: Maybe<Scalars['DateTime']>;
  /** When the payment mandate was created (ISO 8601) */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Searches for referenced `data_points` */
  dataPoints?: Maybe<DataPointsResult>;
  /** Global Id of the payment mandate (UUID) */
  gid: Scalars['GlobalID'];
  /** Id of the payment mandate (UUID) */
  id: Scalars['ID'];
  /** Per provider specific additional data */
  metadata?: Maybe<Scalars['JsonBlob']>;
  /** Global Id of the owning user (UUID) */
  owner?: Maybe<GlobalIdentifiable>;
  /** The payment mandate provider id */
  remoteId?: Maybe<Scalars['ID']>;
  /** The payment mandate status */
  status?: Maybe<Scalars['String']>;
  /** When the payment mandate was updated the last time (ISO 8601) */
  updatedAt?: Maybe<Scalars['DateTime']>;
};


/** The HAUSGOLD ecosystem allows to manage payment mandates for debit payments. This payment method can be used for buying leads. */
export type PaymentMandateDataPointsArgs = {
  aggregation?: InputMaybe<Aggregation>;
  context: Scalars['String'];
  endAt?: InputMaybe<Scalars['DateTime']>;
  interval?: InputMaybe<Interval>;
  metrics: Array<Scalars['String']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
};

/** Autogenerated return type of PaymentMandateConfirm */
export type PaymentMandateConfirmPayload = {
  __typename?: 'PaymentMandateConfirmPayload';
  error?: Maybe<MutationError>;
  paymentMandate?: Maybe<PaymentMandate>;
};

/** Autogenerated return type of PaymentMandateCreate */
export type PaymentMandateCreatePayload = {
  __typename?: 'PaymentMandateCreatePayload';
  error?: Maybe<MutationError>;
  paymentMandate?: Maybe<PaymentMandate>;
};

/** Autogenerated return type of PaymentMandateDelete */
export type PaymentMandateDeletePayload = {
  __typename?: 'PaymentMandateDeletePayload';
  error?: Maybe<MutationError>;
  paymentMandate?: Maybe<PaymentMandate>;
};

export type PaymentMandateEdge = {
  __typename?: 'PaymentMandateEdge';
  /** The `PaymentMandate` entity that this edge points to. */
  node: PaymentMandate;
};

export type PaymentMandateNamespace = {
  __typename?: 'PaymentMandateNamespace';
  /** Confirm the payment mandate. */
  confirm: PaymentMandateConfirmPayload;
  /** Creates an object (or multiple objects) and saves it at the remote application, if validations pass. The resulting object is returned whether the object was saved successfully or not. The attributes parameter can be either a Hash or an Array of Hashes. These Hashes describe the attributes on the objects that are to be created. */
  create: PaymentMandateCreatePayload;
  /** Deletes the instance at the remote application and freezes this instance to reflect that no changes should be made (since they can't be persisted). To enforce the object's before_destroy and after_destroy callbacks use #destroy. */
  delete: PaymentMandateDeletePayload;
  /** Updates the attributes of the entity from the passed-in hash and saves the entity. If the object is invalid, the saving will fail and false will be returned. Also aliased as: update_attributes. */
  update: PaymentMandateUpdatePayload;
};


export type PaymentMandateNamespaceConfirmArgs = {
  id: Scalars['PaymentMandateGlobalIdOrUuid'];
};


export type PaymentMandateNamespaceCreateArgs = {
  metadata?: InputMaybe<Scalars['JsonBlob']>;
  ownerGid?: InputMaybe<Scalars['GlobalID']>;
  redirectUrlTemplate: Scalars['String'];
  remoteId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<PaymentMandateStatus>;
};


export type PaymentMandateNamespaceDeleteArgs = {
  id: Scalars['PaymentMandateGlobalIdOrUuid'];
};


export type PaymentMandateNamespaceUpdateArgs = {
  id: Scalars['PaymentMandateGlobalIdOrUuid'];
  metadata?: InputMaybe<Scalars['JsonBlob']>;
  ownerGid?: InputMaybe<Scalars['GlobalID']>;
  remoteId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<PaymentMandateStatus>;
};

export type PaymentMandatePage = {
  __typename?: 'PaymentMandatePage';
  /** The total count of results for this search. If this is more than `limit` you may repeat the request with a higher `offset` to get the remaining nodes. */
  count: Scalars['Int'];
  /** The connections to the `PaymentMandate` entities that are included in this page. */
  edges: Array<PaymentMandateEdge>;
  /** The limit that was used to generate this page. The limit is the upper bound of how many nodes are returned. */
  limit: Scalars['Int'];
  /** The `PaymentMandate` entities that are included in this page. */
  nodes: Array<PaymentMandate>;
  /** The offset that was used to generate this page. */
  offset: Scalars['Int'];
};

export type PaymentMandateSearchParameters = {
  ids?: InputMaybe<Array<Scalars['PaymentMandateGlobalIdOrUuid']>>;
  ownerGid?: InputMaybe<Scalars['GlobalID']>;
  status?: InputMaybe<Array<Scalars['String']>>;
  text?: InputMaybe<Scalars['String']>;
};

export enum PaymentMandateStatus {
  Active = 'active',
  Inactive = 'inactive'
}

/** Autogenerated return type of PaymentMandateUpdate */
export type PaymentMandateUpdatePayload = {
  __typename?: 'PaymentMandateUpdatePayload';
  error?: Maybe<MutationError>;
  paymentMandate?: Maybe<PaymentMandate>;
};

export type PaymentNamespace = {
  __typename?: 'PaymentNamespace';
  /** Authorize the payment. */
  authorize: PaymentAuthorizePayload;
  /** Change the provider of the payment. */
  changeProvider: PaymentChangeProviderPayload;
  /** Creates an object (or multiple objects) and saves it at the remote application, if validations pass. The resulting object is returned whether the object was saved successfully or not. The attributes parameter can be either a Hash or an Array of Hashes. These Hashes describe the attributes on the objects that are to be created. */
  create: PaymentCreatePayload;
  /** Deletes the instance at the remote application and freezes this instance to reflect that no changes should be made (since they can't be persisted). To enforce the object's before_destroy and after_destroy callbacks use #destroy. */
  delete: PaymentDeletePayload;
  /** Updates the attributes of the entity from the passed-in hash and saves the entity. If the object is invalid, the saving will fail and false will be returned. Also aliased as: update_attributes. */
  update: PaymentUpdatePayload;
};


export type PaymentNamespaceAuthorizeArgs = {
  braintreePayment?: InputMaybe<PaymentAuthorizeBraintreePayment>;
  gocardlessPayment?: InputMaybe<PaymentAuthorizeGocardlessPayment>;
  id: Scalars['PaymentGlobalIdOrUuid'];
};


export type PaymentNamespaceChangeProviderArgs = {
  id: Scalars['PaymentGlobalIdOrUuid'];
  paymentProvider: PaymentPaymentProvider;
};


export type PaymentNamespaceCreateArgs = {
  currency?: InputMaybe<PaymentCurrency>;
  ownerGid: Scalars['GlobalID'];
};


export type PaymentNamespaceDeleteArgs = {
  id: Scalars['PaymentGlobalIdOrUuid'];
};


export type PaymentNamespaceUpdateArgs = {
  currency?: InputMaybe<PaymentCurrency>;
  id: Scalars['PaymentGlobalIdOrUuid'];
  ownerGid?: InputMaybe<Scalars['GlobalID']>;
};

export type PaymentPage = {
  __typename?: 'PaymentPage';
  /** The total count of results for this search. If this is more than `limit` you may repeat the request with a higher `offset` to get the remaining nodes. */
  count: Scalars['Int'];
  /** The connections to the `Payment` entities that are included in this page. */
  edges: Array<PaymentEdge>;
  /** The limit that was used to generate this page. The limit is the upper bound of how many nodes are returned. */
  limit: Scalars['Int'];
  /** The `Payment` entities that are included in this page. */
  nodes: Array<Payment>;
  /** The offset that was used to generate this page. */
  offset: Scalars['Int'];
};

export enum PaymentPaymentProvider {
  BraintreePayment = 'braintree_payment',
  GocardlessPayment = 'gocardless_payment',
  InvoicePayment = 'invoice_payment'
}

export type PaymentSearchParameters = {
  ids?: InputMaybe<Array<Scalars['PaymentGlobalIdOrUuid']>>;
  ownerGid?: InputMaybe<Scalars['GlobalID']>;
};

/** The current state of the payment */
export enum PaymentState {
  Authorized = 'authorized',
  Cancelled = 'cancelled',
  Expired = 'expired',
  Failed = 'failed',
  Pending = 'pending',
  Settled = 'settled',
  Settling = 'settling'
}

export enum PaymentType {
  CreditCard = 'credit_card',
  PayPal = 'pay_pal',
  PayPalAccount = 'pay_pal_account'
}

/** Autogenerated return type of PaymentUpdate */
export type PaymentUpdatePayload = {
  __typename?: 'PaymentUpdatePayload';
  error?: Maybe<MutationError>;
  payment?: Maybe<Payment>;
};

/** The HAUSGOLD ecosystem allows users to generate PDF documents directly from a given website (by URL). You can customize the PDF generation as much as you like. The defaults just works good for German documents. The actual PDF generation takes time and is always performed asynchronously. So the client must poll until the document is available or a callback URL can be specified (by `callback_url`) which is called when the generation is done. This is quite handy in HTTP server contexts, otherwise use the polling. */
export type Pdf = CanHaveDataPoints & GlobalIdentifiable & {
  __typename?: 'Pdf';
  /** Searches for referenced `data_points` */
  dataPoints?: Maybe<DataPointsResult>;
  gid: Scalars['GlobalID'];
  id: Scalars['ID'];
};


/** The HAUSGOLD ecosystem allows users to generate PDF documents directly from a given website (by URL). You can customize the PDF generation as much as you like. The defaults just works good for German documents. The actual PDF generation takes time and is always performed asynchronously. So the client must poll until the document is available or a callback URL can be specified (by `callback_url`) which is called when the generation is done. This is quite handy in HTTP server contexts, otherwise use the polling. */
export type PdfDataPointsArgs = {
  aggregation?: InputMaybe<Aggregation>;
  context: Scalars['String'];
  endAt?: InputMaybe<Scalars['DateTime']>;
  interval?: InputMaybe<Interval>;
  metrics: Array<Scalars['String']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
};

/** Autogenerated return type of PdfCreate */
export type PdfCreatePayload = {
  __typename?: 'PdfCreatePayload';
  error?: Maybe<MutationError>;
  pdf?: Maybe<Pdf>;
};

/** Changes the CSS media type of the page. */
export enum PdfMedia {
  Print = 'print',
  Screen = 'screen'
}

export type PdfNamespace = {
  __typename?: 'PdfNamespace';
  /** Creates an object (or multiple objects) and saves it at the remote application, if validations pass. The resulting object is returned whether the object was saved successfully or not. The attributes parameter can be either a Hash or an Array of Hashes. These Hashes describe the attributes on the objects that are to be created. */
  create: PdfCreatePayload;
};


export type PdfNamespaceCreateArgs = {
  background?: InputMaybe<Scalars['Boolean']>;
  callbackUrl?: InputMaybe<Scalars['String']>;
  delay?: InputMaybe<Scalars['Int']>;
  format?: InputMaybe<Scalars['String']>;
  headerFooter?: InputMaybe<Scalars['Boolean']>;
  height?: InputMaybe<Scalars['String']>;
  landscape?: InputMaybe<Scalars['Boolean']>;
  margin?: InputMaybe<Scalars['String']>;
  marginBottom?: InputMaybe<Scalars['String']>;
  marginLeft?: InputMaybe<Scalars['String']>;
  marginRight?: InputMaybe<Scalars['String']>;
  marginTop?: InputMaybe<Scalars['String']>;
  media?: InputMaybe<PdfMedia>;
  range?: InputMaybe<Scalars['String']>;
  scale?: InputMaybe<Scalars['Int']>;
  timeout?: InputMaybe<Scalars['Int']>;
  url: Scalars['String'];
  width?: InputMaybe<Scalars['String']>;
};

/** An access control policy that provides a subject with a specific permission for its configured object. */
export type Policy = CanHaveDataPoints & GlobalIdentifiable & {
  __typename?: 'Policy';
  /** When the policy was created (ISO 8601) */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Searches for referenced `data_points` */
  dataPoints?: Maybe<DataPointsResult>;
  /** Global Id of the policy (UUID) */
  gid: Scalars['GlobalID'];
  /** Id of the policy (UUID) */
  id: Scalars['ID'];
  /** The Global Id of the object */
  object?: Maybe<Scalars['String']>;
  /** The client-defined predicate */
  predicate?: Maybe<Scalars['String']>;
  /** The Global Id of the subject */
  subject?: Maybe<Scalars['String']>;
  /** When the policy was updated last time (ISO 8601) */
  updatedAt?: Maybe<Scalars['DateTime']>;
};


/** An access control policy that provides a subject with a specific permission for its configured object. */
export type PolicyDataPointsArgs = {
  aggregation?: InputMaybe<Aggregation>;
  context: Scalars['String'];
  endAt?: InputMaybe<Scalars['DateTime']>;
  interval?: InputMaybe<Interval>;
  metrics: Array<Scalars['String']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
};

export type PolicyEdge = {
  __typename?: 'PolicyEdge';
  /** The `Policy` entity that this edge points to. */
  node: Policy;
};

export type PolicyPage = {
  __typename?: 'PolicyPage';
  /** The total count of results for this search. If this is more than `limit` you may repeat the request with a higher `offset` to get the remaining nodes. */
  count: Scalars['Int'];
  /** The connections to the `Policy` entities that are included in this page. */
  edges: Array<PolicyEdge>;
  /** The limit that was used to generate this page. The limit is the upper bound of how many nodes are returned. */
  limit: Scalars['Int'];
  /** The `Policy` entities that are included in this page. */
  nodes: Array<Policy>;
  /** The offset that was used to generate this page. */
  offset: Scalars['Int'];
};

export type PolicySearchParameters = {
  ids?: InputMaybe<Array<Scalars['PolicyGlobalIdOrUuid']>>;
  object?: InputMaybe<Scalars['String']>;
  predicate?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

/** The HAUSGOLD PreferencesSet is the central information blob for all kind of user settings. With the help of it you can tweak the knobs and pulls for a user on the ecosystem. */
export type PreferencesSet = CanHaveDataPoints & GlobalIdentifiable & {
  __typename?: 'PreferencesSet';
  /** The address of the user */
  address?: Maybe<PreferencesSetAddress>;
  /** When the preferences set was created (ISO 8601) */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The preferred currency (ISO 4217) */
  currency?: Maybe<Scalars['String']>;
  /** Searches for referenced `data_points` */
  dataPoints?: Maybe<DataPointsResult>;
  /** All the double opt-in sources (email channel), empty when not confirmed */
  doiEmail?: Maybe<Scalars['Boolean']>;
  /** When the double opt-in (email channel) was subscribed (ISO 8601) */
  doiEmailSubscribedAt?: Maybe<Scalars['DateTime']>;
  /** When the double opt-in (email channel) was unsubscribed (ISO 8601) */
  doiEmailUnsubscribedAt?: Maybe<Scalars['DateTime']>;
  /** When the double opt-in (email channel) setting was changed last time (ISO 8601) */
  doiEmailUpdatedAt?: Maybe<Scalars['DateTime']>;
  /** All the double opt-in sources (phone channel), empty when not confirmed */
  doiPhone?: Maybe<Scalars['Boolean']>;
  /** When the double opt-in (phone channel) was subscribed (ISO 8601) */
  doiPhoneSubscribedAt?: Maybe<Scalars['DateTime']>;
  /** When the double opt-in (phone channel) was unsubscribed (ISO 8601) */
  doiPhoneUnsubscribedAt?: Maybe<Scalars['DateTime']>;
  /** When the double opt-in (phone channel) setting was changed last time (ISO 8601) */
  doiPhoneUpdatedAt?: Maybe<Scalars['DateTime']>;
  /** The first name of the user */
  firstName?: Maybe<Scalars['String']>;
  /** The gender of the user */
  gender?: Maybe<Scalars['String']>;
  /** Global Id of the preferences set (GID) */
  gid: Scalars['GlobalID'];
  /** Id of the preferences set (UUID) */
  id: Scalars['ID'];
  /** The last name of the user */
  lastName?: Maybe<Scalars['String']>;
  /** The preferred locale (RFC 5646) */
  locale?: Maybe<Scalars['String']>;
  /** The mobile phone number of the user */
  mobile?: Maybe<Scalars['String']>;
  /** A list of notifications the user wishes to receive */
  notifications?: Maybe<Array<Scalars['String']>>;
  /** The phone number of the user */
  phone?: Maybe<Scalars['String']>;
  /** Whenever the user can be contact */
  reachability?: Maybe<Scalars['String']>;
  /** When the preferences set was updated last time (ISO 8601) */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The user that this preferences set belongs to. */
  user?: Maybe<User>;
};


/** The HAUSGOLD PreferencesSet is the central information blob for all kind of user settings. With the help of it you can tweak the knobs and pulls for a user on the ecosystem. */
export type PreferencesSetDataPointsArgs = {
  aggregation?: InputMaybe<Aggregation>;
  context: Scalars['String'];
  endAt?: InputMaybe<Scalars['DateTime']>;
  interval?: InputMaybe<Interval>;
  metrics: Array<Scalars['String']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
};

export type PreferencesSetAddress = {
  __typename?: 'PreferencesSetAddress';
  /** The city name of the address */
  city?: Maybe<Scalars['String']>;
  /** The 2-letter country code (eg. DE) */
  countryCode?: Maybe<Scalars['String']>;
  /** The street/house number of the address */
  street?: Maybe<Scalars['String']>;
  /** A street addition of the address */
  streetAddition?: Maybe<Scalars['String']>;
  /** The postal code of the city/address */
  zipcode?: Maybe<Scalars['String']>;
};

export type PreferencesSetCreateAddress = {
  city?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  streetAddition?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of PreferencesSetCreate */
export type PreferencesSetCreatePayload = {
  __typename?: 'PreferencesSetCreatePayload';
  error?: Maybe<MutationError>;
  preferencesSet?: Maybe<PreferencesSet>;
};

export enum PreferencesSetCurrency {
  Aed = 'AED',
  Afa = 'AFA',
  All = 'ALL',
  Amd = 'AMD',
  Ang = 'ANG',
  Aon = 'AON',
  Arp = 'ARP',
  Ats = 'ATS',
  Aud = 'AUD',
  Awf = 'AWF',
  Azm = 'AZM',
  Bak = 'BAK',
  Bbd = 'BBD',
  Bdt = 'BDT',
  Bef = 'BEF',
  Bes = 'BES',
  Bgl = 'BGL',
  Bhd = 'BHD',
  Bif = 'BIF',
  Bmd = 'BMD',
  Bnd = 'BND',
  Bob = 'BOB',
  Brl = 'BRL',
  Bsd = 'BSD',
  Btr = 'BTR',
  Bwp = 'BWP',
  Byr = 'BYR',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Chf = 'CHF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Crc = 'CRC',
  Cup = 'CUP',
  Cuw = 'CUW',
  Cve = 'CVE',
  Cyp = 'CYP',
  Czk = 'CZK',
  Dem = 'DEM',
  Djf = 'DJF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Ecs = 'ECS',
  Eek = 'EEK',
  Egp = 'EGP',
  Ern = 'ERN',
  Esp = 'ESP',
  Etb = 'ETB',
  Eur = 'EUR',
  Fim = 'FIM',
  Fjd = 'FJD',
  Fkp = 'FKP',
  Frf = 'FRF',
  Gbp = 'GBP',
  Gel = 'GEL',
  Ghc = 'GHC',
  Gip = 'GIP',
  Gmd = 'GMD',
  Gnf = 'GNF',
  Grd = 'GRD',
  Gtq = 'GTQ',
  Gyd = 'GYD',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Hrk = 'HRK',
  Htg = 'HTG',
  Huf = 'HUF',
  Idr = 'IDR',
  Iep = 'IEP',
  Ils = 'ILS',
  Inr = 'INR',
  Iqd = 'IQD',
  Irr = 'IRR',
  Isk = 'ISK',
  Itl = 'ITL',
  Jmd = 'JMD',
  Jod = 'JOD',
  Jpy = 'JPY',
  Kes = 'KES',
  Kgs = 'KGS',
  Khr = 'KHR',
  Kmf = 'KMF',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kyd = 'KYD',
  Kzt = 'KZT',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Lrd = 'LRD',
  Lsl = 'LSL',
  Ltl = 'LTL',
  Luf = 'LUF',
  Lvl = 'LVL',
  Lyd = 'LYD',
  Mad = 'MAD',
  Mdl = 'MDL',
  Mgf = 'MGF',
  Mmk = 'MMK',
  Mnt = 'MNT',
  Mop = 'MOP',
  Mro = 'MRO',
  Mtl = 'MTL',
  Mur = 'MUR',
  Mvr = 'MVR',
  Mwk = 'MWK',
  Mxp = 'MXP',
  Mzm = 'MZM',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nio = 'NIO',
  Nlg = 'NLG',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pab = 'PAB',
  Pen = 'PEN',
  Pgk = 'PGK',
  Php = 'PHP',
  Pkr = 'PKR',
  Plz = 'PLZ',
  Pte = 'PTE',
  Pyg = 'PYG',
  Qar = 'QAR',
  Rol = 'ROL',
  Rur = 'RUR',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sbd = 'SBD',
  Sbl = 'SBL',
  Scr = 'SCR',
  Sdd = 'SDD',
  Sek = 'SEK',
  Sgd = 'SGD',
  Shp = 'SHP',
  Sit = 'SIT',
  Skk = 'SKK',
  Sll = 'SLL',
  Sos = 'SOS',
  Srg = 'SRG',
  Std = 'STD',
  Svc = 'SVC',
  Syp = 'SYP',
  Szl = 'SZL',
  Thb = 'THB',
  Tjr = 'TJR',
  Tmm = 'TMM',
  Tnd = 'TND',
  Top = 'TOP',
  Trl = 'TRL',
  Ttd = 'TTD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Ugx = 'UGX',
  Usd = 'USD',
  Uyu = 'UYU',
  Uzs = 'UZS',
  Veb = 'VEB',
  Vnd = 'VND',
  Vuv = 'VUV',
  Wst = 'WST',
  Xaf = 'XAF',
  Xag = 'XAG',
  Xau = 'XAU',
  Xcd = 'XCD',
  Xco = 'XCO',
  Xdr = 'XDR',
  Xpd = 'XPD',
  Xpf = 'XPF',
  Xpt = 'XPT',
  Yer = 'YER',
  Yun = 'YUN',
  Zar = 'ZAR',
  Zmk = 'ZMK',
  Zwd = 'ZWD'
}

/** Autogenerated return type of PreferencesSetDelete */
export type PreferencesSetDeletePayload = {
  __typename?: 'PreferencesSetDeletePayload';
  error?: Maybe<MutationError>;
  preferencesSet?: Maybe<PreferencesSet>;
};

export enum PreferencesSetDoiEmail {
  Agent = 'agent',
  User = 'user'
}

export enum PreferencesSetDoiPhone {
  Agent = 'agent',
  User = 'user'
}

export type PreferencesSetEdge = {
  __typename?: 'PreferencesSetEdge';
  /** The `PreferencesSet` entity that this edge points to. */
  node: PreferencesSet;
};

export enum PreferencesSetGender {
  Female = 'female',
  Male = 'male'
}

export enum PreferencesSetLocale {
  AaDj = 'aa_DJ',
  AaEr = 'aa_ER',
  AaEt = 'aa_ET',
  AfZa = 'af_ZA',
  AmEt = 'am_ET',
  AnEs = 'an_ES',
  ArAe = 'ar_AE',
  ArBh = 'ar_BH',
  ArDz = 'ar_DZ',
  ArEg = 'ar_EG',
  ArIn = 'ar_IN',
  ArIq = 'ar_IQ',
  ArJo = 'ar_JO',
  ArKw = 'ar_KW',
  ArLb = 'ar_LB',
  ArLy = 'ar_LY',
  ArMa = 'ar_MA',
  ArOm = 'ar_OM',
  ArQa = 'ar_QA',
  ArSa = 'ar_SA',
  ArSd = 'ar_SD',
  ArSy = 'ar_SY',
  ArTn = 'ar_TN',
  ArYe = 'ar_YE',
  AsIn = 'as_IN',
  AstEs = 'ast_ES',
  AycPe = 'ayc_PE',
  AzAz = 'az_AZ',
  BeBy = 'be_BY',
  BemZm = 'bem_ZM',
  BerDz = 'ber_DZ',
  BerMa = 'ber_MA',
  BgBg = 'bg_BG',
  BhoIn = 'bho_IN',
  BnBd = 'bn_BD',
  BnIn = 'bn_IN',
  BoCn = 'bo_CN',
  BoIn = 'bo_IN',
  BrFr = 'br_FR',
  BrxIn = 'brx_IN',
  BsBa = 'bs_BA',
  BynEr = 'byn_ER',
  CaAd = 'ca_AD',
  CaEs = 'ca_ES',
  CaFr = 'ca_FR',
  CaIt = 'ca_IT',
  CrhUa = 'crh_UA',
  CsCz = 'cs_CZ',
  CsbPl = 'csb_PL',
  CvRu = 'cv_RU',
  CyGb = 'cy_GB',
  DaDk = 'da_DK',
  DeAt = 'de_AT',
  DeBe = 'de_BE',
  DeCh = 'de_CH',
  DeDe = 'de_DE',
  DeLu = 'de_LU',
  DoiIn = 'doi_IN',
  DvMv = 'dv_MV',
  DzBt = 'dz_BT',
  ElCy = 'el_CY',
  ElGr = 'el_GR',
  EnAg = 'en_AG',
  EnAu = 'en_AU',
  EnBw = 'en_BW',
  EnCa = 'en_CA',
  EnDk = 'en_DK',
  EnGb = 'en_GB',
  EnHk = 'en_HK',
  EnIe = 'en_IE',
  EnIn = 'en_IN',
  EnNg = 'en_NG',
  EnNz = 'en_NZ',
  EnPh = 'en_PH',
  EnSg = 'en_SG',
  EnUs = 'en_US',
  EnZa = 'en_ZA',
  EnZm = 'en_ZM',
  EnZw = 'en_ZW',
  EsAr = 'es_AR',
  EsBo = 'es_BO',
  EsCl = 'es_CL',
  EsCo = 'es_CO',
  EsCr = 'es_CR',
  EsCu = 'es_CU',
  EsDo = 'es_DO',
  EsEc = 'es_EC',
  EsEs = 'es_ES',
  EsGt = 'es_GT',
  EsHn = 'es_HN',
  EsMx = 'es_MX',
  EsNi = 'es_NI',
  EsPa = 'es_PA',
  EsPe = 'es_PE',
  EsPr = 'es_PR',
  EsPy = 'es_PY',
  EsSv = 'es_SV',
  EsUs = 'es_US',
  EsUy = 'es_UY',
  EsVe = 'es_VE',
  EtEe = 'et_EE',
  EuEs = 'eu_ES',
  FaIr = 'fa_IR',
  FfSn = 'ff_SN',
  FiFi = 'fi_FI',
  FilPh = 'fil_PH',
  FoFo = 'fo_FO',
  FrBe = 'fr_BE',
  FrCa = 'fr_CA',
  FrCh = 'fr_CH',
  FrFr = 'fr_FR',
  FrLu = 'fr_LU',
  FurIt = 'fur_IT',
  FyDe = 'fy_DE',
  FyNl = 'fy_NL',
  GaIe = 'ga_IE',
  GdGb = 'gd_GB',
  GezEr = 'gez_ER',
  GezEt = 'gez_ET',
  GlEs = 'gl_ES',
  GuIn = 'gu_IN',
  GvGb = 'gv_GB',
  HaNg = 'ha_NG',
  HeIl = 'he_IL',
  HiIn = 'hi_IN',
  HneIn = 'hne_IN',
  HrHr = 'hr_HR',
  HsbDe = 'hsb_DE',
  HtHt = 'ht_HT',
  HuHu = 'hu_HU',
  HyAm = 'hy_AM',
  IaFr = 'ia_FR',
  IdId = 'id_ID',
  IgNg = 'ig_NG',
  IkCa = 'ik_CA',
  IsIs = 'is_IS',
  ItCh = 'it_CH',
  ItIt = 'it_IT',
  IuCa = 'iu_CA',
  IwIl = 'iw_IL',
  JaJp = 'ja_JP',
  KaGe = 'ka_GE',
  KkKz = 'kk_KZ',
  KlGl = 'kl_GL',
  KmKh = 'km_KH',
  KnIn = 'kn_IN',
  KoKr = 'ko_KR',
  KokIn = 'kok_IN',
  KsIn = 'ks_IN',
  KuTr = 'ku_TR',
  KwGb = 'kw_GB',
  KyKg = 'ky_KG',
  LbLu = 'lb_LU',
  LgUg = 'lg_UG',
  LiBe = 'li_BE',
  LiNl = 'li_NL',
  LijIt = 'lij_IT',
  LoLa = 'lo_LA',
  LtLt = 'lt_LT',
  LvLv = 'lv_LV',
  MagIn = 'mag_IN',
  MaiIn = 'mai_IN',
  MgMg = 'mg_MG',
  MhrRu = 'mhr_RU',
  MiNz = 'mi_NZ',
  MkMk = 'mk_MK',
  MlIn = 'ml_IN',
  MnMn = 'mn_MN',
  MniIn = 'mni_IN',
  MrIn = 'mr_IN',
  MsMy = 'ms_MY',
  MtMt = 'mt_MT',
  MyMm = 'my_MM',
  NanTw = 'nan_TW',
  NbNo = 'nb_NO',
  NdsDe = 'nds_DE',
  NdsNl = 'nds_NL',
  NeNp = 'ne_NP',
  NhnMx = 'nhn_MX',
  NiuNu = 'niu_NU',
  NiuNz = 'niu_NZ',
  NlAw = 'nl_AW',
  NlBe = 'nl_BE',
  NlNl = 'nl_NL',
  NnNo = 'nn_NO',
  NrZa = 'nr_ZA',
  NsoZa = 'nso_ZA',
  OcFr = 'oc_FR',
  OmEt = 'om_ET',
  OmKe = 'om_KE',
  OrIn = 'or_IN',
  OsRu = 'os_RU',
  PaIn = 'pa_IN',
  PaPk = 'pa_PK',
  PapAn = 'pap_AN',
  PlPl = 'pl_PL',
  PsAf = 'ps_AF',
  PtBr = 'pt_BR',
  PtPt = 'pt_PT',
  RoRo = 'ro_RO',
  RuRu = 'ru_RU',
  RuUa = 'ru_UA',
  RwRw = 'rw_RW',
  SaIn = 'sa_IN',
  SatIn = 'sat_IN',
  ScIt = 'sc_IT',
  SdIn = 'sd_IN',
  SeNo = 'se_NO',
  ShsCa = 'shs_CA',
  SiLk = 'si_LK',
  SidEt = 'sid_ET',
  SkSk = 'sk_SK',
  SlSi = 'sl_SI',
  SoDj = 'so_DJ',
  SoEt = 'so_ET',
  SoKe = 'so_KE',
  SoSo = 'so_SO',
  SqAl = 'sq_AL',
  SqMk = 'sq_MK',
  SrMe = 'sr_ME',
  SrRs = 'sr_RS',
  SsZa = 'ss_ZA',
  StZa = 'st_ZA',
  SvFi = 'sv_FI',
  SvSe = 'sv_SE',
  SwKe = 'sw_KE',
  SwTz = 'sw_TZ',
  SzlPl = 'szl_PL',
  TaIn = 'ta_IN',
  TaLk = 'ta_LK',
  TeIn = 'te_IN',
  TgTj = 'tg_TJ',
  ThTh = 'th_TH',
  TiEr = 'ti_ER',
  TiEt = 'ti_ET',
  TigEr = 'tig_ER',
  TkTm = 'tk_TM',
  TlPh = 'tl_PH',
  TnZa = 'tn_ZA',
  TrCy = 'tr_CY',
  TrTr = 'tr_TR',
  TsZa = 'ts_ZA',
  TtRu = 'tt_RU',
  UgCn = 'ug_CN',
  UkUa = 'uk_UA',
  UnmUs = 'unm_US',
  UrIn = 'ur_IN',
  UrPk = 'ur_PK',
  UzUz = 'uz_UZ',
  VeZa = 've_ZA',
  ViVn = 'vi_VN',
  WaBe = 'wa_BE',
  WaeCh = 'wae_CH',
  WalEt = 'wal_ET',
  WoSn = 'wo_SN',
  XhZa = 'xh_ZA',
  YiUs = 'yi_US',
  YoNg = 'yo_NG',
  YueHk = 'yue_HK',
  ZhCn = 'zh_CN',
  ZhHk = 'zh_HK',
  ZhSg = 'zh_SG',
  ZhTw = 'zh_TW',
  ZuZa = 'zu_ZA'
}

export type PreferencesSetNamespace = {
  __typename?: 'PreferencesSetNamespace';
  /** Creates an object (or multiple objects) and saves it at the remote application, if validations pass. The resulting object is returned whether the object was saved successfully or not. The attributes parameter can be either a Hash or an Array of Hashes. These Hashes describe the attributes on the objects that are to be created. */
  create: PreferencesSetCreatePayload;
  /** Deletes the instance at the remote application and freezes this instance to reflect that no changes should be made (since they can't be persisted). To enforce the object's before_destroy and after_destroy callbacks use #destroy. */
  delete: PreferencesSetDeletePayload;
  /** Updates the attributes of the entity from the passed-in hash and saves the entity. If the object is invalid, the saving will fail and false will be returned. Also aliased as: update_attributes. */
  update: PreferencesSetUpdatePayload;
};


export type PreferencesSetNamespaceCreateArgs = {
  address?: InputMaybe<PreferencesSetCreateAddress>;
  currency?: InputMaybe<PreferencesSetCurrency>;
  doiChangeSource?: InputMaybe<Scalars['String']>;
  doiEmail?: InputMaybe<Array<PreferencesSetDoiEmail>>;
  doiEmailSubscribedAt?: InputMaybe<Scalars['DateTime']>;
  doiEmailUnsubscribedAt?: InputMaybe<Scalars['DateTime']>;
  doiPhone?: InputMaybe<Array<PreferencesSetDoiPhone>>;
  doiPhoneSubscribedAt?: InputMaybe<Scalars['DateTime']>;
  doiPhoneUnsubscribedAt?: InputMaybe<Scalars['DateTime']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<PreferencesSetGender>;
  lastName?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<PreferencesSetLocale>;
  mobile?: InputMaybe<Scalars['String']>;
  notifications?: InputMaybe<Array<PreferencesSetNotifications>>;
  phone?: InputMaybe<Scalars['String']>;
  reachability?: InputMaybe<Scalars['String']>;
  userGid: Scalars['GlobalID'];
};


export type PreferencesSetNamespaceDeleteArgs = {
  id: Scalars['PreferencesSetGlobalIdOrUuid'];
};


export type PreferencesSetNamespaceUpdateArgs = {
  address?: InputMaybe<PreferencesSetUpdateAddress>;
  currency?: InputMaybe<PreferencesSetCurrency>;
  doiChangeSource?: InputMaybe<Scalars['String']>;
  doiEmail?: InputMaybe<Array<PreferencesSetDoiEmail>>;
  doiEmailSubscribedAt?: InputMaybe<Scalars['DateTime']>;
  doiEmailUnsubscribedAt?: InputMaybe<Scalars['DateTime']>;
  doiPhone?: InputMaybe<Array<PreferencesSetDoiPhone>>;
  doiPhoneSubscribedAt?: InputMaybe<Scalars['DateTime']>;
  doiPhoneUnsubscribedAt?: InputMaybe<Scalars['DateTime']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<PreferencesSetGender>;
  id: Scalars['PreferencesSetGlobalIdOrUuid'];
  lastName?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<PreferencesSetLocale>;
  mobile?: InputMaybe<Scalars['String']>;
  notifications?: InputMaybe<Array<PreferencesSetNotifications>>;
  phone?: InputMaybe<Scalars['String']>;
  reachability?: InputMaybe<Scalars['String']>;
  userGid?: InputMaybe<Scalars['GlobalID']>;
};

export enum PreferencesSetNotifications {
  CalendarReminder = 'calendar_reminder',
  MessageReceived = 'message_received',
  NewProperty = 'new_property'
}

export type PreferencesSetPage = {
  __typename?: 'PreferencesSetPage';
  /** The total count of results for this search. If this is more than `limit` you may repeat the request with a higher `offset` to get the remaining nodes. */
  count: Scalars['Int'];
  /** The connections to the `PreferencesSet` entities that are included in this page. */
  edges: Array<PreferencesSetEdge>;
  /** The limit that was used to generate this page. The limit is the upper bound of how many nodes are returned. */
  limit: Scalars['Int'];
  /** The `PreferencesSet` entities that are included in this page. */
  nodes: Array<PreferencesSet>;
  /** The offset that was used to generate this page. */
  offset: Scalars['Int'];
};

export type PreferencesSetSearchParameters = {
  doiEmail?: InputMaybe<Array<PreferencesSetDoiEmail>>;
  doiPhone?: InputMaybe<Array<PreferencesSetDoiPhone>>;
  ids?: InputMaybe<Array<Scalars['PreferencesSetGlobalIdOrUuid']>>;
  locale?: InputMaybe<Scalars['String']>;
  userGid?: InputMaybe<Scalars['GlobalID']>;
  userGids?: InputMaybe<Array<Scalars['GlobalID']>>;
};

export type PreferencesSetUpdateAddress = {
  city?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  streetAddition?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of PreferencesSetUpdate */
export type PreferencesSetUpdatePayload = {
  __typename?: 'PreferencesSetUpdatePayload';
  error?: Maybe<MutationError>;
  preferencesSet?: Maybe<PreferencesSet>;
};

/** The HAUSGOLD Property is the central information blob for a physical real estate object. */
export type Property = CanHaveDataPoints & GlobalIdentifiable & {
  __typename?: 'Property';
  /** The calculated completeness percentage based on the object details. */
  completenessPercentage?: Maybe<Scalars['IEEE754Float']>;
  /** When the property was created (ISO 8601) */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Searches for referenced `data_points` */
  dataPoints?: Maybe<DataPointsResult>;
  /** The PriceHubble dossier sharing link */
  dossierLink?: Maybe<Scalars['String']>;
  /** Global Id of the property (UUID) */
  gid: Scalars['GlobalID'];
  /** Id of the property (UUID) */
  id: Scalars['ID'];
  /** The lead id that is the source for the property */
  leadId?: Maybe<Scalars['ID']>;
  /** Searches for referenced `leads` */
  leads?: Maybe<LeadPage>;
  /** The metadata of the property */
  metadata?: Maybe<Scalars['JsonBlob']>;
  /** The object details */
  objectDetails?: Maybe<PropertyObjectDetails>;
  /** Global Id of the owner (UUID) */
  owner?: Maybe<User>;
  /** Whenever the property is searchable */
  searchable?: Maybe<Scalars['Boolean']>;
  /** The source of the property */
  source?: Maybe<Scalars['String']>;
  /** When the property was updated last time (ISO 8601) */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The property valuation */
  valuation?: Maybe<PropertyValuation>;
  /** When the property was valuated last time (ISO 8601) */
  valuationAt?: Maybe<Scalars['DateTime']>;
};


/** The HAUSGOLD Property is the central information blob for a physical real estate object. */
export type PropertyDataPointsArgs = {
  aggregation?: InputMaybe<Aggregation>;
  context: Scalars['String'];
  endAt?: InputMaybe<Scalars['DateTime']>;
  interval?: InputMaybe<Interval>;
  metrics: Array<Scalars['String']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
};


/** The HAUSGOLD Property is the central information blob for a physical real estate object. */
export type PropertyLeadsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<PropertyLeadSearchParameters>;
  sort?: InputMaybe<Array<LeadSortParameters>>;
};

export enum PropertyBalconyOrientation {
  E = 'E',
  N = 'N',
  Ne = 'NE',
  Nw = 'NW',
  S = 'S',
  Se = 'SE',
  Sw = 'SW',
  W = 'W'
}

export enum PropertyBathroom {
  Bathtub = 'bathtub',
  Bidet = 'bidet',
  Shower = 'shower',
  Urinal = 'urinal',
  Window = 'window'
}

export type PropertyBroadbandConnection = {
  __typename?: 'PropertyBroadbandConnection';
  /** The broadband connection type */
  broadbandType?: Maybe<PropertyBroadbandType>;
  /** The speed of the broadband connection */
  speed?: Maybe<Scalars['IEEE754Float']>;
};

/** The broadband connection type */
export enum PropertyBroadbandType {
  Adsl = 'ADSL',
  Dsl = 'DSL',
  Iptv = 'IPTV',
  SkyDsl = 'SkyDSL',
  Vdsl = 'VDSL'
}

/** Whether the property has a cellar */
export enum PropertyCellar {
  No = 'no',
  Partly = 'partly',
  Yes = 'yes'
}

/** The current condition of the property */
export enum PropertyCondition {
  BuildingShell = 'building_shell',
  DemolitionObject = 'demolition_object',
  Dilapidated = 'dilapidated',
  FirstTimeUse = 'first_time_use',
  Modernized = 'modernized',
  NeedsRenovation = 'needs_renovation',
  NeedsRestructuring = 'needs_restructuring',
  Negotiable = 'negotiable',
  Projected = 'projected',
  WellMaintained = 'well_maintained'
}

/** The confidence of the valuation */
export enum PropertyConfidence {
  Good = 'good',
  Medium = 'medium',
  Poor = 'poor'
}

/** The construction type of the property */
export enum PropertyConstruction {
  BPlan = 'b_plan',
  BuildingLandWithoutBPlan = 'building_land_without_b_plan',
  ExpectedBuild = 'expected_build',
  Neighborhood = 'neighborhood',
  NoBuildingLand = 'no_building_land',
  RemoteArea = 'remote_area'
}

/** The construction type of the property */
export enum PropertyConstructionType {
  Prefabricated = 'prefabricated',
  Solid = 'solid',
  Wood = 'wood'
}

export type PropertyCreateObjectDetails = {
  additionalInfoText?: InputMaybe<Scalars['String']>;
  amountBalcony?: InputMaybe<Scalars['IEEE754Float']>;
  amountBathrooms?: InputMaybe<Scalars['IEEE754Float']>;
  amountFloors?: InputMaybe<Scalars['IEEE754Float']>;
  amountKitchens?: InputMaybe<Scalars['IEEE754Float']>;
  amountParkingSpaces?: InputMaybe<Scalars['IEEE754Float']>;
  amountRooms?: InputMaybe<Scalars['IEEE754Float']>;
  amountTerrace?: InputMaybe<Scalars['IEEE754Float']>;
  amountUnits?: InputMaybe<Scalars['IEEE754Float']>;
  city?: InputMaybe<Scalars['String']>;
  condition?: InputMaybe<PropertyCondition>;
  construction?: InputMaybe<PropertyConstruction>;
  countryCode?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<PropertyCurrency>;
  descriptionText?: InputMaybe<Scalars['String']>;
  energyPass?: InputMaybe<PropertyCreateObjectDetailsEnergyPass>;
  equipment?: InputMaybe<PropertyCreateObjectDetailsEquipment>;
  equipmentText?: InputMaybe<Scalars['String']>;
  exploitation?: InputMaybe<PropertyExploitation>;
  floor?: InputMaybe<Scalars['IEEE754Float']>;
  freeFrom?: InputMaybe<Scalars['DateTime']>;
  inhabited?: InputMaybe<Scalars['Boolean']>;
  interiorQuality?: InputMaybe<PropertyInteriorQuality>;
  landSize?: InputMaybe<Scalars['IEEE754Float']>;
  latitude?: InputMaybe<Scalars['IEEE754Float']>;
  listed?: InputMaybe<Scalars['Boolean']>;
  livingSpace?: InputMaybe<Scalars['IEEE754Float']>;
  locationClassificationType?: InputMaybe<PropertyLocationClassificationType>;
  locationText?: InputMaybe<Scalars['String']>;
  lodgerFlat?: InputMaybe<Scalars['Boolean']>;
  longitude?: InputMaybe<Scalars['IEEE754Float']>;
  marketingType?: InputMaybe<Array<PropertyMarketingType>>;
  netRent?: InputMaybe<Scalars['IEEE754Float']>;
  ownershipStructure?: InputMaybe<Scalars['String']>;
  petsAllowed?: InputMaybe<Scalars['Boolean']>;
  postalCode?: InputMaybe<Scalars['String']>;
  propertySubtype?: InputMaybe<PropertyPropertySubtype>;
  propertyType?: InputMaybe<PropertyPropertyType>;
  rented?: InputMaybe<Scalars['Boolean']>;
  saleHorizon?: InputMaybe<PropertySaleHorizon>;
  saleReason?: InputMaybe<Scalars['String']>;
  sellingStatus?: InputMaybe<PropertySellingStatus>;
  shopWindowLength?: InputMaybe<Scalars['IEEE754Float']>;
  street?: InputMaybe<Scalars['String']>;
  streetAddition?: InputMaybe<Scalars['String']>;
  suggestedPrice?: InputMaybe<Scalars['IEEE754Float']>;
  title?: InputMaybe<Scalars['String']>;
  totalFloorSpace?: InputMaybe<Scalars['IEEE754Float']>;
  usage?: InputMaybe<Array<PropertyUsage>>;
  useableFloorSpace?: InputMaybe<Scalars['IEEE754Float']>;
  yearOfConstruction?: InputMaybe<Scalars['String']>;
  yearOfModernization?: InputMaybe<Scalars['String']>;
};

export type PropertyCreateObjectDetailsEnergyPass = {
  passType?: InputMaybe<PropertyPassType>;
  value?: InputMaybe<Scalars['IEEE754Float']>;
  warmWaterIncluded?: InputMaybe<Scalars['Boolean']>;
};

export type PropertyCreateObjectDetailsEquipment = {
  airConditioning?: InputMaybe<Scalars['Boolean']>;
  attic?: InputMaybe<Scalars['Boolean']>;
  balconyOrientation?: InputMaybe<Array<PropertyBalconyOrientation>>;
  bathroom?: InputMaybe<Array<PropertyBathroom>>;
  bikeRoom?: InputMaybe<Scalars['Boolean']>;
  broadbandConnection?: InputMaybe<PropertyCreateObjectDetailsEquipmentBroadbandConnection>;
  cableTv?: InputMaybe<Scalars['Boolean']>;
  canteen?: InputMaybe<Scalars['Boolean']>;
  cellar?: InputMaybe<PropertyCellar>;
  changeableRooms?: InputMaybe<Scalars['Boolean']>;
  conservatory?: InputMaybe<Scalars['Boolean']>;
  constructionType?: InputMaybe<PropertyConstructionType>;
  crane?: InputMaybe<Scalars['Boolean']>;
  dvbt?: InputMaybe<Scalars['Boolean']>;
  energyType?: InputMaybe<PropertyEnergyType>;
  fireplace?: InputMaybe<Scalars['Boolean']>;
  firingType?: InputMaybe<Array<PropertyFiringType>>;
  floorType?: InputMaybe<Array<PropertyFloorType>>;
  furnished?: InputMaybe<PropertyFurnished>;
  garden?: InputMaybe<Scalars['Boolean']>;
  guestTerrace?: InputMaybe<Scalars['Boolean']>;
  guestToilet?: InputMaybe<Scalars['Boolean']>;
  hallHeight?: InputMaybe<Scalars['IEEE754Float']>;
  handicappedAccessible?: InputMaybe<Scalars['Boolean']>;
  heatingType?: InputMaybe<PropertyHeatingType>;
  hydraulicRamp?: InputMaybe<Scalars['Boolean']>;
  kitchen?: InputMaybe<Array<PropertyKitchen>>;
  kitchenette?: InputMaybe<Scalars['Boolean']>;
  lanCables?: InputMaybe<Scalars['Boolean']>;
  laundryDryingRoom?: InputMaybe<Scalars['Boolean']>;
  library?: InputMaybe<Scalars['Boolean']>;
  lift?: InputMaybe<Array<PropertyLift>>;
  parkingSpaceType?: InputMaybe<Array<PropertyParkingSpaceType>>;
  powerConsumption?: InputMaybe<Scalars['String']>;
  ramp?: InputMaybe<Scalars['Boolean']>;
  roofShape?: InputMaybe<PropertyRoofShape>;
  sauna?: InputMaybe<Scalars['Boolean']>;
  securityServices?: InputMaybe<Array<PropertySecurityServices>>;
  seniorFriendly?: InputMaybe<Scalars['Boolean']>;
  services?: InputMaybe<Array<PropertyServices>>;
  sharedFlat?: InputMaybe<Scalars['Boolean']>;
  shutters?: InputMaybe<Scalars['Boolean']>;
  spa?: InputMaybe<Scalars['Boolean']>;
  sportsFacilities?: InputMaybe<Scalars['Boolean']>;
  stageOfExpansion?: InputMaybe<PropertyStageOfExpansion>;
  storeRoom?: InputMaybe<Scalars['Boolean']>;
  swimmingpool?: InputMaybe<Scalars['Boolean']>;
  umts?: InputMaybe<Scalars['Boolean']>;
  wheelchairAccessible?: InputMaybe<Scalars['Boolean']>;
};

export type PropertyCreateObjectDetailsEquipmentBroadbandConnection = {
  broadbandType?: InputMaybe<PropertyBroadbandType>;
  speed?: InputMaybe<Scalars['IEEE754Float']>;
};

/** Autogenerated return type of PropertyCreate */
export type PropertyCreatePayload = {
  __typename?: 'PropertyCreatePayload';
  error?: Maybe<MutationError>;
  property?: Maybe<Property>;
};

/** The currency used by all fields related to prices */
export enum PropertyCurrency {
  Aed = 'AED',
  Afa = 'AFA',
  All = 'ALL',
  Amd = 'AMD',
  Ang = 'ANG',
  Aon = 'AON',
  Arp = 'ARP',
  Ats = 'ATS',
  Aud = 'AUD',
  Awf = 'AWF',
  Azm = 'AZM',
  Bak = 'BAK',
  Bbd = 'BBD',
  Bdt = 'BDT',
  Bef = 'BEF',
  Bes = 'BES',
  Bgl = 'BGL',
  Bhd = 'BHD',
  Bif = 'BIF',
  Bmd = 'BMD',
  Bnd = 'BND',
  Bob = 'BOB',
  Brl = 'BRL',
  Bsd = 'BSD',
  Btr = 'BTR',
  Bwp = 'BWP',
  Byr = 'BYR',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Chf = 'CHF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Crc = 'CRC',
  Cup = 'CUP',
  Cuw = 'CUW',
  Cve = 'CVE',
  Cyp = 'CYP',
  Czk = 'CZK',
  Dem = 'DEM',
  Djf = 'DJF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Ecs = 'ECS',
  Eek = 'EEK',
  Egp = 'EGP',
  Ern = 'ERN',
  Esp = 'ESP',
  Etb = 'ETB',
  Eur = 'EUR',
  Fim = 'FIM',
  Fjd = 'FJD',
  Fkp = 'FKP',
  Frf = 'FRF',
  Gbp = 'GBP',
  Gel = 'GEL',
  Ghc = 'GHC',
  Gip = 'GIP',
  Gmd = 'GMD',
  Gnf = 'GNF',
  Grd = 'GRD',
  Gtq = 'GTQ',
  Gyd = 'GYD',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Hrk = 'HRK',
  Htg = 'HTG',
  Huf = 'HUF',
  Idr = 'IDR',
  Iep = 'IEP',
  Ils = 'ILS',
  Inr = 'INR',
  Iqd = 'IQD',
  Irr = 'IRR',
  Isk = 'ISK',
  Itl = 'ITL',
  Jmd = 'JMD',
  Jod = 'JOD',
  Jpy = 'JPY',
  Kes = 'KES',
  Kgs = 'KGS',
  Khr = 'KHR',
  Kmf = 'KMF',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kyd = 'KYD',
  Kzt = 'KZT',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Lrd = 'LRD',
  Lsl = 'LSL',
  Ltl = 'LTL',
  Luf = 'LUF',
  Lvl = 'LVL',
  Lyd = 'LYD',
  Mad = 'MAD',
  Mdl = 'MDL',
  Mgf = 'MGF',
  Mmk = 'MMK',
  Mnt = 'MNT',
  Mop = 'MOP',
  Mro = 'MRO',
  Mtl = 'MTL',
  Mur = 'MUR',
  Mvr = 'MVR',
  Mwk = 'MWK',
  Mxp = 'MXP',
  Mzm = 'MZM',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nio = 'NIO',
  Nlg = 'NLG',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pab = 'PAB',
  Pen = 'PEN',
  Pgk = 'PGK',
  Php = 'PHP',
  Pkr = 'PKR',
  Plz = 'PLZ',
  Pte = 'PTE',
  Pyg = 'PYG',
  Qar = 'QAR',
  Rol = 'ROL',
  Rur = 'RUR',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sbd = 'SBD',
  Sbl = 'SBL',
  Scr = 'SCR',
  Sdd = 'SDD',
  Sek = 'SEK',
  Sgd = 'SGD',
  Shp = 'SHP',
  Sit = 'SIT',
  Skk = 'SKK',
  Sll = 'SLL',
  Sos = 'SOS',
  Srg = 'SRG',
  Std = 'STD',
  Svc = 'SVC',
  Syp = 'SYP',
  Szl = 'SZL',
  Thb = 'THB',
  Tjr = 'TJR',
  Tmm = 'TMM',
  Tnd = 'TND',
  Top = 'TOP',
  Trl = 'TRL',
  Ttd = 'TTD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Ugx = 'UGX',
  Usd = 'USD',
  Uyu = 'UYU',
  Uzs = 'UZS',
  Veb = 'VEB',
  Vnd = 'VND',
  Vuv = 'VUV',
  Wst = 'WST',
  Xaf = 'XAF',
  Xag = 'XAG',
  Xau = 'XAU',
  Xcd = 'XCD',
  Xco = 'XCO',
  Xdr = 'XDR',
  Xpd = 'XPD',
  Xpf = 'XPF',
  Xpt = 'XPT',
  Yer = 'YER',
  Yun = 'YUN',
  Zar = 'ZAR',
  Zmk = 'ZMK',
  Zwd = 'ZWD'
}

/** The deal type of the valuation */
export enum PropertyDealType {
  Rent = 'rent',
  Sale = 'sale'
}

/** Autogenerated return type of PropertyDelete */
export type PropertyDeletePayload = {
  __typename?: 'PropertyDeletePayload';
  error?: Maybe<MutationError>;
  property?: Maybe<Property>;
};

export type PropertyEdge = {
  __typename?: 'PropertyEdge';
  /** The `Property` entity that this edge points to. */
  node: Property;
};

export type PropertyEnergyPass = {
  __typename?: 'PropertyEnergyPass';
  /** The type of energy pass */
  passType?: Maybe<PropertyPassType>;
  /** The value of the energy pass */
  value?: Maybe<Scalars['IEEE754Float']>;
  /** Whether warm water is included or not */
  warmWaterIncluded?: Maybe<Scalars['Boolean']>;
};

/** The energy type of the property */
export enum PropertyEnergyType {
  Kfw40 = 'kfw40',
  Kfw55 = 'kfw55',
  Kfw60 = 'kfw60',
  Kfw70 = 'kfw70',
  LowEnergy = 'low_energy',
  MinEnergyBuilt = 'min_energy_built',
  MinEnergyCertified = 'min_energy_certified',
  NewBuilding = 'new_building',
  Passiv = 'passiv'
}

export type PropertyEquipment = {
  __typename?: 'PropertyEquipment';
  /** Whether the property has air conditioning */
  airConditioning?: Maybe<Scalars['Boolean']>;
  /** Whether the property has an attic */
  attic?: Maybe<Scalars['Boolean']>;
  /** The balcony orientations */
  balconyOrientation?: Maybe<Array<PropertyBalconyOrientation>>;
  /** The bathroom equipment */
  bathroom?: Maybe<Array<PropertyBathroom>>;
  /** Whether the property has a bike room */
  bikeRoom?: Maybe<Scalars['Boolean']>;
  /** The available broadband connection */
  broadbandConnection?: Maybe<PropertyBroadbandConnection>;
  /** Whether the property has cable TV */
  cableTv?: Maybe<Scalars['Boolean']>;
  /** Whether the property has a canteen */
  canteen?: Maybe<Scalars['Boolean']>;
  /** Whether the property has a cellar */
  cellar?: Maybe<PropertyCellar>;
  /** Whether the property has changeable rooms */
  changeableRooms?: Maybe<Scalars['Boolean']>;
  /** Whether the property has a conservatory */
  conservatory?: Maybe<Scalars['Boolean']>;
  /** The construction type of the property */
  constructionType?: Maybe<PropertyConstructionType>;
  /** Whether the property has a crane */
  crane?: Maybe<Scalars['Boolean']>;
  /** Whether the property has DVBT */
  dvbt?: Maybe<Scalars['Boolean']>;
  /** The energy type of the property */
  energyType?: Maybe<PropertyEnergyType>;
  /** Whether the property has a fireplace */
  fireplace?: Maybe<Scalars['Boolean']>;
  /** The firing type of the property */
  firingType?: Maybe<Array<PropertyFiringType>>;
  /** The available floor types */
  floorType?: Maybe<Array<PropertyFloorType>>;
  /** Whether the property is furnished */
  furnished?: Maybe<PropertyFurnished>;
  /** Whether the property has a garden */
  garden?: Maybe<Scalars['Boolean']>;
  /** Whether the property has a guest terrace */
  guestTerrace?: Maybe<Scalars['Boolean']>;
  /** Whether the property has a guest toilet */
  guestToilet?: Maybe<Scalars['Boolean']>;
  /** The property's hall height */
  hallHeight?: Maybe<Scalars['IEEE754Float']>;
  /** Whether the property is accessible for handicapped */
  handicappedAccessible?: Maybe<Scalars['Boolean']>;
  /** The heating type of the property */
  heatingType?: Maybe<PropertyHeatingType>;
  /** Whether the property has a hydraulic ramp */
  hydraulicRamp?: Maybe<Scalars['Boolean']>;
  /** The kitchen equipment */
  kitchen?: Maybe<Array<PropertyKitchen>>;
  /** Whether the property has a kitchenette */
  kitchenette?: Maybe<Scalars['Boolean']>;
  /** Whether the property has LAN cables */
  lanCables?: Maybe<Scalars['Boolean']>;
  /** Whether the property has a laundry drying room */
  laundryDryingRoom?: Maybe<Scalars['Boolean']>;
  /** Whether the property has a library */
  library?: Maybe<Scalars['Boolean']>;
  /** The lifts the property has */
  lift?: Maybe<Array<PropertyLift>>;
  /** The types of parking spaces */
  parkingSpaceType?: Maybe<Array<PropertyParkingSpaceType>>;
  /** The property's power consumption */
  powerConsumption?: Maybe<Scalars['String']>;
  /** Whether the property has a ramp */
  ramp?: Maybe<Scalars['Boolean']>;
  /** The property's roof shape */
  roofShape?: Maybe<PropertyRoofShape>;
  /** Whether the property has a sauna */
  sauna?: Maybe<Scalars['Boolean']>;
  /** The available security services */
  securityServices?: Maybe<Array<PropertySecurityServices>>;
  /** Whether the property is senior friendly */
  seniorFriendly?: Maybe<Scalars['Boolean']>;
  /** The available services */
  services?: Maybe<Array<PropertyServices>>;
  /** Whether the property has a shared flat */
  sharedFlat?: Maybe<Scalars['Boolean']>;
  /** Whether the property has shutters */
  shutters?: Maybe<Scalars['Boolean']>;
  /** Whether the property has a spa */
  spa?: Maybe<Scalars['Boolean']>;
  /** Whether the property has sports facilities */
  sportsFacilities?: Maybe<Scalars['Boolean']>;
  /** The current stage of expansion */
  stageOfExpansion?: Maybe<PropertyStageOfExpansion>;
  /** Whether the property has a storage room */
  storeRoom?: Maybe<Scalars['Boolean']>;
  /** Whether the property has a swimming pool */
  swimmingpool?: Maybe<Scalars['Boolean']>;
  /** Whether the property has UMTS */
  umts?: Maybe<Scalars['Boolean']>;
  /** Whether the property is wheelchair accessible */
  wheelchairAccessible?: Maybe<Scalars['Boolean']>;
};


export type PropertyEquipmentBroadbandConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** The exploitation of the property */
export enum PropertyExploitation {
  Customary = 'customary',
  FullyDeveloped = 'fully_developed',
  PartiallyDeveloped = 'partially_developed',
  Undeveloped = 'undeveloped'
}

export enum PropertyFiringType {
  AirSource = 'air_source',
  Chp = 'chp',
  Coal = 'coal',
  Electric = 'electric',
  Gas = 'gas',
  Geothermal = 'geothermal',
  Lpg = 'lpg',
  Oil = 'oil',
  Pellet = 'pellet',
  Solar = 'solar',
  WaterElectric = 'water_electric',
  Wood = 'wood'
}

export enum PropertyFloorType {
  Carpet = 'carpet',
  EngineeredFlooring = 'engineered_flooring',
  Granite = 'granite',
  Hallway = 'hallway',
  Laminate = 'laminate',
  Linoleum = 'linoleum',
  Marble = 'marble',
  Parquet = 'parquet',
  Plastic = 'plastic',
  Raised = 'raised',
  Screed = 'screed',
  Stone = 'stone',
  Terracotta = 'terracotta',
  Tiles = 'tiles'
}

/** Whether the property is furnished */
export enum PropertyFurnished {
  Fully = 'fully',
  No = 'no',
  Partly = 'partly'
}

/** The heating type of the property */
export enum PropertyHeatingType {
  Central = 'central',
  District = 'district',
  Furnace = 'furnace',
  SelfContained = 'self_contained',
  Underfloor = 'underfloor'
}

/** The quality of the property's interior */
export enum PropertyInteriorQuality {
  Luxary = 'luxary',
  Standard = 'standard',
  Upscale = 'upscale'
}

export enum PropertyKitchen {
  Fitted = 'fitted',
  Open = 'open',
  Pantry = 'pantry'
}

export type PropertyLeadSearchParameters = {
  accepted?: InputMaybe<Scalars['Boolean']>;
  accessPermitted?: InputMaybe<Scalars['Boolean']>;
  activated?: InputMaybe<Scalars['Boolean']>;
  archived?: InputMaybe<Scalars['Boolean']>;
  closed?: InputMaybe<Scalars['Boolean']>;
  closerGid?: InputMaybe<Scalars['GlobalID']>;
  currentMandatoryTasks?: InputMaybe<Array<LeadCurrentMandatoryTasks>>;
  identifier?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['LeadGlobalIdOrUuid']>>;
  labels?: InputMaybe<Array<Scalars['String']>>;
  locked?: InputMaybe<Scalars['Boolean']>;
  mandatoryTasksDone?: InputMaybe<Array<LeadMandatoryTasksDone>>;
  mandatoryTasksUndone?: InputMaybe<Array<LeadMandatoryTasksUndone>>;
  needsUpdate?: InputMaybe<Scalars['Boolean']>;
  needsUpdateEscalated?: InputMaybe<Scalars['Boolean']>;
  objectTypes?: InputMaybe<Array<Scalars['String']>>;
  processed?: InputMaybe<Scalars['Boolean']>;
  processorGid?: InputMaybe<Scalars['GlobalID']>;
  propertyIds?: InputMaybe<Array<Scalars['String']>>;
  receiverGid?: InputMaybe<Scalars['GlobalID']>;
  rejected?: InputMaybe<Scalars['Boolean']>;
  sellerGid?: InputMaybe<Scalars['GlobalID']>;
  sold?: InputMaybe<Scalars['Boolean']>;
  stateUpdateRequested?: InputMaybe<Scalars['Boolean']>;
  stateUpdateSkipped?: InputMaybe<Scalars['Boolean']>;
  supervisorGid?: InputMaybe<Scalars['GlobalID']>;
  text?: InputMaybe<Scalars['String']>;
  userGid?: InputMaybe<Scalars['GlobalID']>;
  warnedDueDate?: InputMaybe<Scalars['Boolean']>;
};

export enum PropertyLift {
  Goods = 'goods',
  People = 'people'
}

/** How the location of the property is classified */
export enum PropertyLocationClassificationType {
  '1a' = '_1a',
  '1b' = '_1b',
  District = 'district',
  DistrictCenter = 'district_center',
  Industry = 'industry',
  Mixed = 'mixed',
  NewHousing = 'new_housing',
  Residential = 'residential',
  Suburb = 'suburb',
  TownCenter = 'town_center'
}

export enum PropertyMarketingType {
  Buy = 'buy',
  Emphyteusis = 'emphyteusis',
  Leasing = 'leasing',
  Rent = 'rent'
}

export type PropertyNamespace = {
  __typename?: 'PropertyNamespace';
  /** Creates an object (or multiple objects) and saves it at the remote application, if validations pass. The resulting object is returned whether the object was saved successfully or not. The attributes parameter can be either a Hash or an Array of Hashes. These Hashes describe the attributes on the objects that are to be created. */
  create: PropertyCreatePayload;
  /** Deletes the instance at the remote application and freezes this instance to reflect that no changes should be made (since they can't be persisted). To enforce the object's before_destroy and after_destroy callbacks use #destroy. */
  delete: PropertyDeletePayload;
  /** Updates the attributes of the entity from the passed-in hash and saves the entity. If the object is invalid, the saving will fail and false will be returned. Also aliased as: update_attributes. */
  update: PropertyUpdatePayload;
};


export type PropertyNamespaceCreateArgs = {
  id?: InputMaybe<Scalars['PropertyGlobalIdOrUuid']>;
  leadId?: InputMaybe<Scalars['ID']>;
  metadata?: InputMaybe<Scalars['JsonBlob']>;
  objectDetails?: InputMaybe<PropertyCreateObjectDetails>;
  ownerGid?: InputMaybe<Scalars['GlobalID']>;
  searchable?: InputMaybe<Scalars['Boolean']>;
  source: Scalars['String'];
};


export type PropertyNamespaceDeleteArgs = {
  id: Scalars['PropertyGlobalIdOrUuid'];
};


export type PropertyNamespaceUpdateArgs = {
  id: Scalars['PropertyGlobalIdOrUuid'];
  leadId?: InputMaybe<Scalars['ID']>;
  metadata?: InputMaybe<Scalars['JsonBlob']>;
  objectDetails?: InputMaybe<PropertyUpdateObjectDetails>;
  ownerGid?: InputMaybe<Scalars['GlobalID']>;
  searchable?: InputMaybe<Scalars['Boolean']>;
  source?: InputMaybe<Scalars['String']>;
};

export type PropertyObjectDetails = {
  __typename?: 'PropertyObjectDetails';
  /** Any additional info about the property */
  additionalInfoText?: Maybe<Scalars['String']>;
  /** The amount of balconies the property has */
  amountBalcony?: Maybe<Scalars['IEEE754Float']>;
  /** The amount of bathrooms the property has */
  amountBathrooms?: Maybe<Scalars['IEEE754Float']>;
  /** The amount of floors the property has */
  amountFloors?: Maybe<Scalars['Int']>;
  /** The amount of kitchens the property has */
  amountKitchens?: Maybe<Scalars['IEEE754Float']>;
  /** The amount of parking spaces the property has */
  amountParkingSpaces?: Maybe<Scalars['Int']>;
  /** The amount of rooms the property has */
  amountRooms?: Maybe<Scalars['IEEE754Float']>;
  /** The amount of terraces the property has */
  amountTerrace?: Maybe<Scalars['IEEE754Float']>;
  /** The amount of units (living/business) in a multi-unit building */
  amountUnits?: Maybe<Scalars['Int']>;
  /** The city of the property */
  city?: Maybe<Scalars['String']>;
  /** The current condition of the property */
  condition?: Maybe<PropertyCondition>;
  /** The construction type of the property */
  construction?: Maybe<PropertyConstruction>;
  /** The property's country code */
  countryCode?: Maybe<Scalars['String']>;
  /** The currency used by all fields related to prices */
  currency?: Maybe<PropertyCurrency>;
  /** A description of the property */
  descriptionText?: Maybe<Scalars['String']>;
  /** The property's energy pass */
  energyPass?: Maybe<PropertyEnergyPass>;
  /** The property's equipment */
  equipment?: Maybe<PropertyEquipment>;
  /** A description of the property's equipment */
  equipmentText?: Maybe<Scalars['String']>;
  /** The exploitation of the property */
  exploitation?: Maybe<PropertyExploitation>;
  /** The floor the properts is on */
  floor?: Maybe<Scalars['Int']>;
  /** When the property is free (ISO 8601) */
  freeFrom?: Maybe<Scalars['DateTime']>;
  /** Whether the property is inhabited */
  inhabited?: Maybe<Scalars['Boolean']>;
  /** The quality of the property's interior */
  interiorQuality?: Maybe<PropertyInteriorQuality>;
  /** The land size of the property */
  landSize?: Maybe<Scalars['IEEE754Float']>;
  /** The latitude of the property */
  latitude?: Maybe<Scalars['IEEE754Float']>;
  /** Whether the property is listed or not */
  listed?: Maybe<Scalars['Boolean']>;
  /** The amount of living space */
  livingSpace?: Maybe<Scalars['IEEE754Float']>;
  /** How the location of the property is classified */
  locationClassificationType?: Maybe<PropertyLocationClassificationType>;
  /** A description of the property's location */
  locationText?: Maybe<Scalars['String']>;
  /** Whether the property has a lodger flat or not */
  lodgerFlat?: Maybe<Scalars['Boolean']>;
  /** The longitude of the property */
  longitude?: Maybe<Scalars['IEEE754Float']>;
  /** How the property is marketed */
  marketingType?: Maybe<Array<PropertyMarketingType>>;
  /** The netto cold rent */
  netRent?: Maybe<Scalars['IEEE754Float']>;
  /** How the ownership structure is organized */
  ownershipStructure?: Maybe<Scalars['String']>;
  /** Whether pets are allowed or not */
  petsAllowed?: Maybe<Scalars['Boolean']>;
  /** The postal code of the property */
  postalCode?: Maybe<Scalars['String']>;
  /** The more specialized subtype of the property */
  propertySubtype?: Maybe<PropertyPropertySubtype>;
  /** The top-level type of the property */
  propertyType?: Maybe<PropertyPropertyType>;
  /** Whether the property is rented or not */
  rented?: Maybe<Scalars['Boolean']>;
  /** The sale horizon of the property owner */
  saleHorizon?: Maybe<PropertySaleHorizon>;
  /** The reason why the property is being sold */
  saleReason?: Maybe<Scalars['String']>;
  /** The selling status of the property */
  sellingStatus?: Maybe<PropertySellingStatus>;
  /** The length of the property's shop window */
  shopWindowLength?: Maybe<Scalars['IEEE754Float']>;
  /** The street address of the property */
  street?: Maybe<Scalars['String']>;
  /** Additions to the property's street address */
  streetAddition?: Maybe<Scalars['String']>;
  /** The property's suggested price */
  suggestedPrice?: Maybe<Scalars['IEEE754Float']>;
  /** A title for the property */
  title?: Maybe<Scalars['String']>;
  /** The total amount of floor space */
  totalFloorSpace?: Maybe<Scalars['IEEE754Float']>;
  /** What the property is used for */
  usage?: Maybe<Array<PropertyUsage>>;
  /** The amount of useable floor space */
  useableFloorSpace?: Maybe<Scalars['IEEE754Float']>;
  /** When the property was constructed */
  yearOfConstruction?: Maybe<Scalars['String']>;
  /** When the property was last modernized */
  yearOfModernization?: Maybe<Scalars['String']>;
};

export type PropertyPage = {
  __typename?: 'PropertyPage';
  /** The total count of results for this search. If this is more than `limit` you may repeat the request with a higher `offset` to get the remaining nodes. */
  count: Scalars['Int'];
  /** The connections to the `Property` entities that are included in this page. */
  edges: Array<PropertyEdge>;
  /** The limit that was used to generate this page. The limit is the upper bound of how many nodes are returned. */
  limit: Scalars['Int'];
  /** The `Property` entities that are included in this page. */
  nodes: Array<Property>;
  /** The offset that was used to generate this page. */
  offset: Scalars['Int'];
};

export enum PropertyParkingSpaceType {
  Carport = 'carport',
  Duplex = 'duplex',
  Garage = 'garage',
  Open = 'open',
  Park = 'park',
  Underground = 'underground'
}

/** The type of energy pass */
export enum PropertyPassType {
  Consumption = 'consumption',
  EnergyRequired = 'energy_required'
}

/** The more specialized subtype of the property */
export enum PropertyPropertySubtype {
  Agriculture = 'agriculture',
  AgricultureBusiness = 'agriculture_business',
  AgricultureForestry = 'agriculture_forestry',
  AmusementPark = 'amusement_park',
  Apartment = 'apartment',
  ApartmentHouse = 'apartment_house',
  ArborDachaGardenHouse = 'arbor_dacha_garden_house',
  AssisstedLiving = 'assissted_living',
  Attic = 'attic',
  Bar = 'bar',
  Barns = 'barns',
  Basement = 'basement',
  BeachHouse = 'beach_house',
  BigStore = 'big_store',
  BoatDock = 'boat_dock',
  Bungalow = 'bungalow',
  Business = 'business',
  BusinessPark = 'business_park',
  Cafe = 'cafe',
  CarPark = 'car_park',
  Carport = 'carport',
  Castle = 'castle',
  Chalet = 'chalet',
  Club = 'club',
  ColdStorage = 'cold_storage',
  ConvenienceStore = 'convenience_store',
  CountryHouse = 'country_house',
  Coworking = 'coworking',
  Detached = 'detached',
  DoubleGarage = 'double_garage',
  Duplex = 'duplex',
  EndOfTerrace = 'end_of_terrace',
  Estate = 'estate',
  ExhibitionSpace = 'exhibition_space',
  FactoryBuilding = 'factory_building',
  FactoryOutlet = 'factory_outlet',
  Farm = 'farm',
  Farmhouse = 'farmhouse',
  Farmstead = 'farmstead',
  Finca = 'finca',
  Floor = 'floor',
  ForwardingWarehouse = 'forwarding_warehouse',
  Gallery = 'gallery',
  Gastronomy = 'gastronomy',
  GastronomyWithApartment = 'gastronomy_with_apartment',
  GroundFloor = 'ground_floor',
  Guesthouse = 'guesthouse',
  Hall = 'hall',
  HighRackWarehouse = 'high_rack_warehouse',
  HolidayFlat = 'holiday_flat',
  HolidayHome = 'holiday_home',
  HorseRiding = 'horse_riding',
  Horticulture = 'horticulture',
  Hospital = 'hospital',
  Hotels = 'hotels',
  HousingArea = 'housing_area',
  HuntingGround = 'hunting_ground',
  HuntingIndustryAndForestry = 'hunting_industry_and_forestry',
  IndustrialPlant = 'industrial_plant',
  Industry = 'industry',
  IsolatedFarm = 'isolated_farm',
  Kiosk = 'kiosk',
  Lakefront = 'lakefront',
  Leisure = 'leisure',
  LeisureFacility = 'leisure_facility',
  Living = 'living',
  LivingAndOfficeHouse = 'living_and_office_house',
  Maisonette = 'maisonette',
  ManorHouse = 'manor_house',
  Mansion = 'mansion',
  MiddleOfTerrace = 'middle_of_terrace',
  Mixed = 'mixed',
  MountainShelter = 'mountain_shelter',
  NursingHome = 'nursing_home',
  OfficeBlock = 'office_block',
  OfficeBuilding = 'office_building',
  OfficeCenter = 'office_center',
  OfficeHouse = 'office_house',
  OfficeSpace = 'office_space',
  OpenSpace = 'open_space',
  Other = 'other',
  OtherLodging = 'other_lodging',
  Palace = 'palace',
  ParkingSpace = 'parking_space',
  Penthouse = 'penthouse',
  PetrolStation = 'petrol_station',
  PondAndFishIndustry = 'pond_and_fish_industry',
  Practice = 'practice',
  PracticeBuilding = 'practice_building',
  PracticeSpace = 'practice_space',
  Prefabricated = 'prefabricated',
  Production = 'production',
  Ranching = 'ranching',
  RawAttic = 'raw_attic',
  Restaurant = 'restaurant',
  RetailShop = 'retail_shop',
  Room = 'room',
  RowHouse = 'row_house',
  Rustico = 'rustico',
  SalesArea = 'sales_area',
  Sanatorium = 'sanatorium',
  SelfServiceMarket = 'self_service_market',
  SemiDetached = 'semi_detached',
  Service = 'service',
  SharedOffice = 'shared_office',
  Shop = 'shop',
  ShoppingCenter = 'shopping_center',
  SingleGarage = 'single_garage',
  SingleRoom = 'single_room',
  SmokersPlace = 'smokers_place',
  SpecialUse = 'special_use',
  SportsFacility = 'sports_facility',
  Storage = 'storage',
  StorageArea = 'storage_area',
  StorageWithOpenSpace = 'storage_with_open_space',
  Studio = 'studio',
  Terrace = 'terrace',
  Townhouse = 'townhouse',
  TwoFamilyHouse = 'two_family_house',
  UndergroundParking = 'underground_parking',
  UndergroundParkingSpace = 'underground_parking_space',
  Viniculture = 'viniculture',
  WithChargingStation = 'with_charging_station',
  Workshop = 'workshop'
}

/** The top-level type of the property */
export enum PropertyPropertyType {
  Agriculture = 'agriculture',
  Apartment = 'apartment',
  HospitalityIndustry = 'hospitality_industry',
  House = 'house',
  InterestHouse = 'interest_house',
  LeisureCommercial = 'leisure_commercial',
  Office = 'office',
  Other = 'other',
  Parking = 'parking',
  Retail = 'retail',
  Room = 'room',
  Site = 'site',
  Warehouse = 'warehouse'
}

/** The property's roof shape */
export enum PropertyRoofShape {
  Flat = 'flat',
  Gable = 'gable',
  Gambrel = 'gambrel',
  Hip = 'hip',
  MonoPitched = 'mono_pitched',
  Pyramidal = 'pyramidal'
}

/** The sale horizon of the property owner */
export enum PropertySaleHorizon {
  AfterThreeYears = 'after_three_years',
  FromNow = 'from_now',
  WithinOneAndTwoYears = 'within_one_and_two_years',
  WithinOneYear = 'within_one_year',
  WithinTwoAndThreeYears = 'within_two_and_three_years'
}

export type PropertySearchParameters = {
  ids?: InputMaybe<Array<Scalars['PropertyGlobalIdOrUuid']>>;
  leadId?: InputMaybe<Scalars['ID']>;
  ownerGid?: InputMaybe<Scalars['GlobalID']>;
  source?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Array<PropertySellingStatus>>;
};

export enum PropertySecurityServices {
  AlarmSystem = 'alarm_system',
  Camera = 'camera',
  PoliceCall = 'police_call'
}

/** The selling status of the property */
export enum PropertySellingStatus {
  Closed = 'closed',
  Marketing = 'marketing',
  New = 'new',
  Open = 'open',
  Reserved = 'reserved',
  Sold = 'sold'
}

export enum PropertyServices {
  AssistedLiving = 'assisted_living',
  Catering = 'catering',
  Cleaning = 'cleaning',
  Purchases = 'purchases',
  SecurityGuard = 'security_guard'
}

/**
 * The fields to be sorted by, and the order in which they should
 * be sorted.
 *
 * An example may be: `sort: [{ numberOfRooms: asc }, { id: desc }]`.
 */
export type PropertySortParameters = {
  additionalInfoText?: InputMaybe<SortDirection>;
  amountBalcony?: InputMaybe<SortDirection>;
  amountBathrooms?: InputMaybe<SortDirection>;
  amountFloors?: InputMaybe<SortDirection>;
  amountKitchens?: InputMaybe<SortDirection>;
  amountParkingSpaces?: InputMaybe<SortDirection>;
  amountRooms?: InputMaybe<SortDirection>;
  amountTerrace?: InputMaybe<SortDirection>;
  city?: InputMaybe<SortDirection>;
  condition?: InputMaybe<SortDirection>;
  construction?: InputMaybe<SortDirection>;
  countryCode?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  currency?: InputMaybe<SortDirection>;
  descriptionText?: InputMaybe<SortDirection>;
  equipmentText?: InputMaybe<SortDirection>;
  exploitation?: InputMaybe<SortDirection>;
  floor?: InputMaybe<SortDirection>;
  freeFrom?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  inhabited?: InputMaybe<SortDirection>;
  interiorQuality?: InputMaybe<SortDirection>;
  landSize?: InputMaybe<SortDirection>;
  latitude?: InputMaybe<SortDirection>;
  leadId?: InputMaybe<SortDirection>;
  listed?: InputMaybe<SortDirection>;
  livingSpace?: InputMaybe<SortDirection>;
  locationClassificationType?: InputMaybe<SortDirection>;
  locationText?: InputMaybe<SortDirection>;
  lodgerFlat?: InputMaybe<SortDirection>;
  longitude?: InputMaybe<SortDirection>;
  lonlat?: InputMaybe<SortDirection>;
  ownerGid?: InputMaybe<SortDirection>;
  ownershipStructure?: InputMaybe<SortDirection>;
  petsAllowed?: InputMaybe<SortDirection>;
  postalCode?: InputMaybe<SortDirection>;
  propertySubtype?: InputMaybe<SortDirection>;
  propertyType?: InputMaybe<SortDirection>;
  rented?: InputMaybe<SortDirection>;
  saleHorizon?: InputMaybe<SortDirection>;
  saleReason?: InputMaybe<SortDirection>;
  sellingStatus?: InputMaybe<SortDirection>;
  shopWindowLength?: InputMaybe<SortDirection>;
  source?: InputMaybe<SortDirection>;
  street?: InputMaybe<SortDirection>;
  streetAddition?: InputMaybe<SortDirection>;
  suggestedPrice?: InputMaybe<SortDirection>;
  title?: InputMaybe<SortDirection>;
  totalFloorSpace?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
  useableFloorSpace?: InputMaybe<SortDirection>;
  yearOfConstruction?: InputMaybe<SortDirection>;
  yearOfModernization?: InputMaybe<SortDirection>;
};

/** The current stage of expansion */
export enum PropertyStageOfExpansion {
  BuildingKit = 'building_kit',
  Expandable = 'expandable',
  ReadyWithBaseplate = 'ready_with_baseplate',
  ReadyWithCellar = 'ready_with_cellar',
  ReadyWithoutBaseplate = 'ready_without_baseplate'
}

export type PropertyUpdateObjectDetails = {
  additionalInfoText?: InputMaybe<Scalars['String']>;
  amountBalcony?: InputMaybe<Scalars['IEEE754Float']>;
  amountBathrooms?: InputMaybe<Scalars['IEEE754Float']>;
  amountFloors?: InputMaybe<Scalars['IEEE754Float']>;
  amountKitchens?: InputMaybe<Scalars['IEEE754Float']>;
  amountParkingSpaces?: InputMaybe<Scalars['IEEE754Float']>;
  amountRooms?: InputMaybe<Scalars['IEEE754Float']>;
  amountTerrace?: InputMaybe<Scalars['IEEE754Float']>;
  amountUnits?: InputMaybe<Scalars['IEEE754Float']>;
  city?: InputMaybe<Scalars['String']>;
  condition?: InputMaybe<PropertyCondition>;
  construction?: InputMaybe<PropertyConstruction>;
  countryCode?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<PropertyCurrency>;
  descriptionText?: InputMaybe<Scalars['String']>;
  energyPass?: InputMaybe<PropertyUpdateObjectDetailsEnergyPass>;
  equipment?: InputMaybe<PropertyUpdateObjectDetailsEquipment>;
  equipmentText?: InputMaybe<Scalars['String']>;
  exploitation?: InputMaybe<PropertyExploitation>;
  floor?: InputMaybe<Scalars['IEEE754Float']>;
  freeFrom?: InputMaybe<Scalars['DateTime']>;
  inhabited?: InputMaybe<Scalars['Boolean']>;
  interiorQuality?: InputMaybe<PropertyInteriorQuality>;
  landSize?: InputMaybe<Scalars['IEEE754Float']>;
  latitude?: InputMaybe<Scalars['IEEE754Float']>;
  listed?: InputMaybe<Scalars['Boolean']>;
  livingSpace?: InputMaybe<Scalars['IEEE754Float']>;
  locationClassificationType?: InputMaybe<PropertyLocationClassificationType>;
  locationText?: InputMaybe<Scalars['String']>;
  lodgerFlat?: InputMaybe<Scalars['Boolean']>;
  longitude?: InputMaybe<Scalars['IEEE754Float']>;
  marketingType?: InputMaybe<Array<PropertyMarketingType>>;
  netRent?: InputMaybe<Scalars['IEEE754Float']>;
  ownershipStructure?: InputMaybe<Scalars['String']>;
  petsAllowed?: InputMaybe<Scalars['Boolean']>;
  postalCode?: InputMaybe<Scalars['String']>;
  propertySubtype?: InputMaybe<PropertyPropertySubtype>;
  propertyType?: InputMaybe<PropertyPropertyType>;
  rented?: InputMaybe<Scalars['Boolean']>;
  saleHorizon?: InputMaybe<PropertySaleHorizon>;
  saleReason?: InputMaybe<Scalars['String']>;
  sellingStatus?: InputMaybe<PropertySellingStatus>;
  shopWindowLength?: InputMaybe<Scalars['IEEE754Float']>;
  street?: InputMaybe<Scalars['String']>;
  streetAddition?: InputMaybe<Scalars['String']>;
  suggestedPrice?: InputMaybe<Scalars['IEEE754Float']>;
  title?: InputMaybe<Scalars['String']>;
  totalFloorSpace?: InputMaybe<Scalars['IEEE754Float']>;
  usage?: InputMaybe<Array<PropertyUsage>>;
  useableFloorSpace?: InputMaybe<Scalars['IEEE754Float']>;
  yearOfConstruction?: InputMaybe<Scalars['String']>;
  yearOfModernization?: InputMaybe<Scalars['String']>;
};

export type PropertyUpdateObjectDetailsEnergyPass = {
  passType?: InputMaybe<PropertyPassType>;
  value?: InputMaybe<Scalars['IEEE754Float']>;
  warmWaterIncluded?: InputMaybe<Scalars['Boolean']>;
};

export type PropertyUpdateObjectDetailsEquipment = {
  airConditioning?: InputMaybe<Scalars['Boolean']>;
  attic?: InputMaybe<Scalars['Boolean']>;
  balconyOrientation?: InputMaybe<Array<PropertyBalconyOrientation>>;
  bathroom?: InputMaybe<Array<PropertyBathroom>>;
  bikeRoom?: InputMaybe<Scalars['Boolean']>;
  broadbandConnection?: InputMaybe<PropertyUpdateObjectDetailsEquipmentBroadbandConnection>;
  cableTv?: InputMaybe<Scalars['Boolean']>;
  canteen?: InputMaybe<Scalars['Boolean']>;
  cellar?: InputMaybe<PropertyCellar>;
  changeableRooms?: InputMaybe<Scalars['Boolean']>;
  conservatory?: InputMaybe<Scalars['Boolean']>;
  constructionType?: InputMaybe<PropertyConstructionType>;
  crane?: InputMaybe<Scalars['Boolean']>;
  dvbt?: InputMaybe<Scalars['Boolean']>;
  energyType?: InputMaybe<PropertyEnergyType>;
  fireplace?: InputMaybe<Scalars['Boolean']>;
  firingType?: InputMaybe<Array<PropertyFiringType>>;
  floorType?: InputMaybe<Array<PropertyFloorType>>;
  furnished?: InputMaybe<PropertyFurnished>;
  garden?: InputMaybe<Scalars['Boolean']>;
  guestTerrace?: InputMaybe<Scalars['Boolean']>;
  guestToilet?: InputMaybe<Scalars['Boolean']>;
  hallHeight?: InputMaybe<Scalars['IEEE754Float']>;
  handicappedAccessible?: InputMaybe<Scalars['Boolean']>;
  heatingType?: InputMaybe<PropertyHeatingType>;
  hydraulicRamp?: InputMaybe<Scalars['Boolean']>;
  kitchen?: InputMaybe<Array<PropertyKitchen>>;
  kitchenette?: InputMaybe<Scalars['Boolean']>;
  lanCables?: InputMaybe<Scalars['Boolean']>;
  laundryDryingRoom?: InputMaybe<Scalars['Boolean']>;
  library?: InputMaybe<Scalars['Boolean']>;
  lift?: InputMaybe<Array<PropertyLift>>;
  parkingSpaceType?: InputMaybe<Array<PropertyParkingSpaceType>>;
  powerConsumption?: InputMaybe<Scalars['String']>;
  ramp?: InputMaybe<Scalars['Boolean']>;
  roofShape?: InputMaybe<PropertyRoofShape>;
  sauna?: InputMaybe<Scalars['Boolean']>;
  securityServices?: InputMaybe<Array<PropertySecurityServices>>;
  seniorFriendly?: InputMaybe<Scalars['Boolean']>;
  services?: InputMaybe<Array<PropertyServices>>;
  sharedFlat?: InputMaybe<Scalars['Boolean']>;
  shutters?: InputMaybe<Scalars['Boolean']>;
  spa?: InputMaybe<Scalars['Boolean']>;
  sportsFacilities?: InputMaybe<Scalars['Boolean']>;
  stageOfExpansion?: InputMaybe<PropertyStageOfExpansion>;
  storeRoom?: InputMaybe<Scalars['Boolean']>;
  swimmingpool?: InputMaybe<Scalars['Boolean']>;
  umts?: InputMaybe<Scalars['Boolean']>;
  wheelchairAccessible?: InputMaybe<Scalars['Boolean']>;
};

export type PropertyUpdateObjectDetailsEquipmentBroadbandConnection = {
  broadbandType?: InputMaybe<PropertyBroadbandType>;
  speed?: InputMaybe<Scalars['IEEE754Float']>;
};

/** Autogenerated return type of PropertyUpdate */
export type PropertyUpdatePayload = {
  __typename?: 'PropertyUpdatePayload';
  error?: Maybe<MutationError>;
  property?: Maybe<Property>;
};

export enum PropertyUsage {
  Commercial = 'commercial',
  Investment = 'investment',
  Residential = 'residential',
  TemporaryLiving = 'temporary_living'
}

export type PropertyValuation = {
  __typename?: 'PropertyValuation';
  /** The confidence of the valuation */
  confidence?: Maybe<PropertyConfidence>;
  /** The deal type of the valuation */
  dealType?: Maybe<PropertyDealType>;
  /** The date of the valuation, which may differ from the last time the property was valuated (ISO 8601) */
  valuationDate?: Maybe<Scalars['DateTime']>;
  /** The valuated value of the property, in the currency of the property. Sale price if deal type is +sale+ or gross rent value if deal type is +rent+. */
  value?: Maybe<Scalars['IEEE754Float']>;
  /** Same as the value, but reflects the upper value limit range */
  valueRangeEnd?: Maybe<Scalars['IEEE754Float']>;
  /** Same as the value, but reflects the lower value limit range */
  valueRangeStart?: Maybe<Scalars['IEEE754Float']>;
};

export type Queries = {
  __typename?: 'Queries';
  /** Tries to find a `AccountBalancePackage` by its UUID or GlobalID. */
  accountBalancePackage?: Maybe<AccountBalancePackage>;
  /** Searches for referenced `account_balance_packages` */
  accountBalancePackages?: Maybe<AccountBalancePackagePage>;
  /** Tries to find a `AccountBalanceTransaction` by its UUID or GlobalID. */
  accountBalanceTransaction?: Maybe<AccountBalanceTransaction>;
  /** Searches for referenced `account_balance_transactions` */
  accountBalanceTransactions?: Maybe<AccountBalanceTransactionPage>;
  /** Searches for referenced `activities` */
  activities?: Maybe<ActivityPage>;
  /** Tries to find a `Activity` by its UUID or GlobalID. */
  activity?: Maybe<Activity>;
  /** Tries to find a `Appointment` by its UUID or GlobalID. */
  appointment?: Maybe<Appointment>;
  /** Searches for referenced `appointments` */
  appointments?: Maybe<AppointmentPage>;
  /** Tries to find a `Asset` by its UUID or GlobalID. */
  asset?: Maybe<Asset>;
  /** Searches for referenced `assets` */
  assets?: Maybe<AssetPage>;
  /**
   * Tries to find a `Broker` by its UUID or GlobalID.
   * When the identifier is omitted we try to resolve the
   * `Broker` with the JWT subject.
   */
  broker?: Maybe<Broker>;
  /** Searches for referenced `brokers` */
  brokers?: Maybe<BrokerPage>;
  /**
   * Tries to find a `Customer` by its UUID or GlobalID.
   * When the identifier is omitted we try to resolve the
   * `Customer` with the JWT subject.
   */
  customer?: Maybe<Customer>;
  /** Searches for referenced `customers` */
  customers?: Maybe<CustomerPage>;
  /** Tries to find a `DataPoint` by its UUID or GlobalID. */
  dataPoint?: Maybe<DataPoint>;
  /** Searches for referenced `data_points` */
  dataPoints?: Maybe<DataPointsResult>;
  /** Tries to find a `FinancingLead` by its UUID or GlobalID. */
  financingLead?: Maybe<FinancingLead>;
  /** Searches for referenced `financing_leads` */
  financingLeads?: Maybe<FinancingLeadPage>;
  /** Searches for referenced `insights_queries` */
  insightsQueries?: Maybe<InsightsQueryPage>;
  /** Tries to find a `InsightsQuery` by its UUID or GlobalID. */
  insightsQuery?: Maybe<InsightsQuery>;
  /** Tries to find a `InsuranceLead` by its UUID or GlobalID. */
  insuranceLead?: Maybe<InsuranceLead>;
  /** Searches for referenced `insurance_leads` */
  insuranceLeads?: Maybe<InsuranceLeadPage>;
  /** Tries to find a `InvoiceAddress` by its UUID or GlobalID. */
  invoiceAddress?: Maybe<InvoiceAddress>;
  /** Searches for referenced `invoice_addresses` */
  invoiceAddresses?: Maybe<InvoiceAddressPage>;
  /** Tries to find a `Lead` by its UUID or GlobalID. */
  lead?: Maybe<Lead>;
  /** Tries to find a `LeadManager` by its UUID or GlobalID. */
  leadManager?: Maybe<LeadManager>;
  /** Searches for referenced `lead_managers` */
  leadManagers?: Maybe<LeadManagerPage>;
  /** Searches for referenced `leads` */
  leads?: Maybe<LeadPage>;
  /** Locate any Hausgold entity from our ecosystem by its GlobalID. */
  locate?: Maybe<GlobalIdentifiable>;
  /**
   * Fetch the global mandatory lead task configuration.
   *
   * Note that this field simply returns an array, unlike other search
   * fields. This is due to internal implementation details, which
   * doesn't include pagination.
   */
  mandatoryLeadTasks?: Maybe<Array<MandatoryLeadTask>>;
  /** Tries to find a `NotificationToken` by its UUID or GlobalID. */
  notificationToken?: Maybe<NotificationToken>;
  /** Searches for referenced `notification_tokens` */
  notificationTokens?: Maybe<NotificationTokenPage>;
  /** Tries to find a `Office` by its UUID or GlobalID. */
  office?: Maybe<Office>;
  /** Searches for referenced `offices` */
  offices?: Maybe<OfficePage>;
  /** Tries to find a `Organization` by its UUID or GlobalID. */
  organization?: Maybe<Organization>;
  /** Searches for referenced `organizations` */
  organizations?: Maybe<OrganizationPage>;
  /** Tries to find a `Payment` by its UUID or GlobalID. */
  payment?: Maybe<Payment>;
  /** Tries to find a `PaymentMandate` by its UUID or GlobalID. */
  paymentMandate?: Maybe<PaymentMandate>;
  /** Searches for referenced `payment_mandates` */
  paymentMandates?: Maybe<PaymentMandatePage>;
  /** Searches for referenced `payments` */
  payments?: Maybe<PaymentPage>;
  /** Tries to find a `Pdf` by its UUID or GlobalID. */
  pdf?: Maybe<Pdf>;
  /** Searches for referenced `policies` */
  policies?: Maybe<PolicyPage>;
  /** Tries to find a `Policy` by its UUID or GlobalID. */
  policy?: Maybe<Policy>;
  /** Tries to find a `PreferencesSet` by its UUID or GlobalID. */
  preferencesSet?: Maybe<PreferencesSet>;
  /** Searches for referenced `preferences_sets` */
  preferencesSets?: Maybe<PreferencesSetPage>;
  /** Searches for referenced `properties` */
  properties?: Maybe<PropertyPage>;
  /** Tries to find a `Property` by its UUID or GlobalID. */
  property?: Maybe<Property>;
  /** Tries to find a `Review` by its UUID or GlobalID. */
  review?: Maybe<Review>;
  /** Searches for referenced `reviews` */
  reviews?: Maybe<ReviewPage>;
  /** Tries to find a `Role` by its UUID or GlobalID. */
  role?: Maybe<Role>;
  /** Searches for referenced `role_assignments` */
  roleAssignments?: Maybe<RoleAssignmentPage>;
  /** Searches for referenced `roles` */
  roles?: Maybe<RolePage>;
  /** Tries to find a `SearchProfile` by its UUID or GlobalID. */
  searchProfile?: Maybe<SearchProfile>;
  /** Searches for referenced `search_profiles` */
  searchProfiles?: Maybe<SearchProfilePage>;
  /** Tries to find a `Task` by its UUID or GlobalID. */
  task?: Maybe<Task>;
  /** Searches for referenced `tasks` */
  tasks?: Maybe<TaskPage>;
  /** Tries to find a `Timeframe` by its UUID or GlobalID. */
  timeframe?: Maybe<Timeframe>;
  /** Searches for referenced `timeframes` */
  timeframes?: Maybe<TimeframePage>;
  /**
   * Tries to find a `User` by its UUID or GlobalID.
   * When the identifier is omitted we try to resolve the
   * `User` with the JWT subject.
   */
  user?: Maybe<User>;
  /** Searches for referenced `users` */
  users?: Maybe<UserPage>;
};


export type QueriesAccountBalancePackageArgs = {
  id: Scalars['AccountBalancePackageGlobalIdOrUuid'];
};


export type QueriesAccountBalancePackagesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<AccountBalancePackageSearchParameters>;
};


export type QueriesAccountBalanceTransactionArgs = {
  id: Scalars['AccountBalanceTransactionGlobalIdOrUuid'];
};


export type QueriesAccountBalanceTransactionsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<AccountBalanceTransactionSearchParameters>;
};


export type QueriesActivitiesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<ActivitySearchParameters>;
};


export type QueriesActivityArgs = {
  id: Scalars['ActivityGlobalIdOrUuid'];
};


export type QueriesAppointmentArgs = {
  id: Scalars['AppointmentGlobalIdOrUuid'];
};


export type QueriesAppointmentsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<AppointmentSearchParameters>;
};


export type QueriesAssetArgs = {
  id: Scalars['AssetGlobalIdOrUuid'];
};


export type QueriesAssetsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<AssetSearchParameters>;
};


export type QueriesBrokerArgs = {
  id?: InputMaybe<Scalars['BrokerGlobalIdOrUuid']>;
};


export type QueriesBrokersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<BrokerSearchParameters>;
};


export type QueriesCustomerArgs = {
  id?: InputMaybe<Scalars['CustomerGlobalIdOrUuid']>;
};


export type QueriesCustomersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<CustomerSearchParameters>;
};


export type QueriesDataPointArgs = {
  id: Scalars['DataPointGlobalIdOrUuid'];
};


export type QueriesDataPointsArgs = {
  aggregation?: InputMaybe<Aggregation>;
  context: Scalars['String'];
  endAt?: InputMaybe<Scalars['DateTime']>;
  entity: Scalars['GlobalID'];
  interval?: InputMaybe<Interval>;
  metrics: Array<Scalars['String']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
};


export type QueriesFinancingLeadArgs = {
  id: Scalars['FinancingLeadGlobalIdOrUuid'];
};


export type QueriesFinancingLeadsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<FinancingLeadSearchParameters>;
};


export type QueriesInsightsQueriesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<InsightsQuerySearchParameters>;
};


export type QueriesInsightsQueryArgs = {
  id: Scalars['InsightsQueryGlobalIdOrUuid'];
};


export type QueriesInsuranceLeadArgs = {
  id: Scalars['InsuranceLeadGlobalIdOrUuid'];
};


export type QueriesInsuranceLeadsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<InsuranceLeadSearchParameters>;
};


export type QueriesInvoiceAddressArgs = {
  id: Scalars['InvoiceAddressGlobalIdOrUuid'];
};


export type QueriesInvoiceAddressesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<InvoiceAddressSearchParameters>;
};


export type QueriesLeadArgs = {
  id: Scalars['LeadGlobalIdOrUuid'];
};


export type QueriesLeadManagerArgs = {
  id: Scalars['LeadManagerGlobalIdOrUuid'];
};


export type QueriesLeadManagersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<LeadManagerSearchParameters>;
};


export type QueriesLeadsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<LeadSearchParameters>;
  sort?: InputMaybe<Array<LeadSortParameters>>;
};


export type QueriesLocateArgs = {
  gid: Scalars['GlobalID'];
};


export type QueriesNotificationTokenArgs = {
  id: Scalars['NotificationTokenGlobalIdOrUuid'];
};


export type QueriesNotificationTokensArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<NotificationTokenSearchParameters>;
};


export type QueriesOfficeArgs = {
  id: Scalars['OfficeGlobalIdOrUuid'];
};


export type QueriesOfficesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<OfficeSearchParameters>;
};


export type QueriesOrganizationArgs = {
  id: Scalars['OrganizationGlobalIdOrUuid'];
};


export type QueriesOrganizationsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<OrganizationSearchParameters>;
};


export type QueriesPaymentArgs = {
  id: Scalars['PaymentGlobalIdOrUuid'];
};


export type QueriesPaymentMandateArgs = {
  id: Scalars['PaymentMandateGlobalIdOrUuid'];
};


export type QueriesPaymentMandatesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<PaymentMandateSearchParameters>;
};


export type QueriesPaymentsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<PaymentSearchParameters>;
};


export type QueriesPdfArgs = {
  id: Scalars['PdfGlobalIdOrUuid'];
};


export type QueriesPoliciesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<PolicySearchParameters>;
};


export type QueriesPolicyArgs = {
  id: Scalars['PolicyGlobalIdOrUuid'];
};


export type QueriesPreferencesSetArgs = {
  id: Scalars['PreferencesSetGlobalIdOrUuid'];
};


export type QueriesPreferencesSetsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<PreferencesSetSearchParameters>;
};


export type QueriesPropertiesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<PropertySearchParameters>;
  sort?: InputMaybe<Array<PropertySortParameters>>;
};


export type QueriesPropertyArgs = {
  id: Scalars['PropertyGlobalIdOrUuid'];
};


export type QueriesReviewArgs = {
  id: Scalars['ReviewGlobalIdOrUuid'];
};


export type QueriesReviewsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<ReviewSearchParameters>;
};


export type QueriesRoleArgs = {
  id: Scalars['RoleGlobalIdOrUuid'];
};


export type QueriesRoleAssignmentsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<RoleAssignmentSearchParameters>;
};


export type QueriesRolesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<RoleSearchParameters>;
};


export type QueriesSearchProfileArgs = {
  id: Scalars['SearchProfileGlobalIdOrUuid'];
};


export type QueriesSearchProfilesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<SearchProfileSearchParameters>;
};


export type QueriesTaskArgs = {
  id: Scalars['TaskGlobalIdOrUuid'];
};


export type QueriesTasksArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<TaskSearchParameters>;
};


export type QueriesTimeframeArgs = {
  id: Scalars['TimeframeGlobalIdOrUuid'];
};


export type QueriesTimeframesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<TimeframeSearchParameters>;
};


export type QueriesUserArgs = {
  id?: InputMaybe<Scalars['UserGlobalIdOrUuid']>;
};


export type QueriesUsersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<UserSearchParameters>;
};

/** Reviews are a generic way to collect feedback from any entity on the ecosystem (eg. customers) about any other entity on the ecosystem (eg. brokers) in a free text form. */
export type Review = GlobalIdentifiable & {
  __typename?: 'Review';
  /** A free to choose category for the review/object combination (eg. usable for the progess in funnel indication, etc) */
  category?: Maybe<Scalars['String']>;
  /** When the review was created (ISO 8601) */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** A list of all related data points as Global Id references */
  dataPoints?: Maybe<Array<DataPoint>>;
  /** Global Id of the review (UUID) */
  gid: Scalars['GlobalID'];
  /** The user written headline/text summary */
  headline?: Maybe<Scalars['String']>;
  /** Id of the review (UUID) */
  id: Scalars['ID'];
  /** The locale of the textual contents (RFC 5646) */
  locale?: Maybe<Scalars['String']>;
  /** A simple text classification which reflects the mood of the free text */
  mood?: Maybe<Scalars['String']>;
  /** Whether this review can be publicly used */
  publicAccess?: Maybe<Scalars['Boolean']>;
  /** Global Id of the object which was reviewed (UUID) */
  reviewedObject?: Maybe<GlobalIdentifiable>;
  /** Global Id of the creator of the review (UUID) */
  reviewer?: Maybe<GlobalIdentifiable>;
  /** The name of the reviewer, may be masked (eg. M. Mustermann from Berlin) */
  reviewerName?: Maybe<Scalars['String']>;
  /** The user written free text of the review */
  text?: Maybe<Scalars['String']>;
  /** When the review was updated last time (ISO 8601) */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** When the review was moderated/validated (ISO 8601) */
  validatedAt?: Maybe<Scalars['DateTime']>;
};

/** Autogenerated return type of ReviewCreate */
export type ReviewCreatePayload = {
  __typename?: 'ReviewCreatePayload';
  error?: Maybe<MutationError>;
  review?: Maybe<Review>;
};

/** Autogenerated return type of ReviewDelete */
export type ReviewDeletePayload = {
  __typename?: 'ReviewDeletePayload';
  error?: Maybe<MutationError>;
  review?: Maybe<Review>;
};

export type ReviewEdge = {
  __typename?: 'ReviewEdge';
  /** The `Review` entity that this edge points to. */
  node: Review;
};

export enum ReviewLocale {
  AaDj = 'aa_DJ',
  AaEr = 'aa_ER',
  AaEt = 'aa_ET',
  AfZa = 'af_ZA',
  AmEt = 'am_ET',
  AnEs = 'an_ES',
  ArAe = 'ar_AE',
  ArBh = 'ar_BH',
  ArDz = 'ar_DZ',
  ArEg = 'ar_EG',
  ArIn = 'ar_IN',
  ArIq = 'ar_IQ',
  ArJo = 'ar_JO',
  ArKw = 'ar_KW',
  ArLb = 'ar_LB',
  ArLy = 'ar_LY',
  ArMa = 'ar_MA',
  ArOm = 'ar_OM',
  ArQa = 'ar_QA',
  ArSa = 'ar_SA',
  ArSd = 'ar_SD',
  ArSy = 'ar_SY',
  ArTn = 'ar_TN',
  ArYe = 'ar_YE',
  AsIn = 'as_IN',
  AstEs = 'ast_ES',
  AycPe = 'ayc_PE',
  AzAz = 'az_AZ',
  BeBy = 'be_BY',
  BemZm = 'bem_ZM',
  BerDz = 'ber_DZ',
  BerMa = 'ber_MA',
  BgBg = 'bg_BG',
  BhoIn = 'bho_IN',
  BnBd = 'bn_BD',
  BnIn = 'bn_IN',
  BoCn = 'bo_CN',
  BoIn = 'bo_IN',
  BrFr = 'br_FR',
  BrxIn = 'brx_IN',
  BsBa = 'bs_BA',
  BynEr = 'byn_ER',
  CaAd = 'ca_AD',
  CaEs = 'ca_ES',
  CaFr = 'ca_FR',
  CaIt = 'ca_IT',
  CrhUa = 'crh_UA',
  CsCz = 'cs_CZ',
  CsbPl = 'csb_PL',
  CvRu = 'cv_RU',
  CyGb = 'cy_GB',
  DaDk = 'da_DK',
  DeAt = 'de_AT',
  DeBe = 'de_BE',
  DeCh = 'de_CH',
  DeDe = 'de_DE',
  DeLu = 'de_LU',
  DoiIn = 'doi_IN',
  DvMv = 'dv_MV',
  DzBt = 'dz_BT',
  ElCy = 'el_CY',
  ElGr = 'el_GR',
  EnAg = 'en_AG',
  EnAu = 'en_AU',
  EnBw = 'en_BW',
  EnCa = 'en_CA',
  EnDk = 'en_DK',
  EnGb = 'en_GB',
  EnHk = 'en_HK',
  EnIe = 'en_IE',
  EnIn = 'en_IN',
  EnNg = 'en_NG',
  EnNz = 'en_NZ',
  EnPh = 'en_PH',
  EnSg = 'en_SG',
  EnUs = 'en_US',
  EnZa = 'en_ZA',
  EnZm = 'en_ZM',
  EnZw = 'en_ZW',
  EsAr = 'es_AR',
  EsBo = 'es_BO',
  EsCl = 'es_CL',
  EsCo = 'es_CO',
  EsCr = 'es_CR',
  EsCu = 'es_CU',
  EsDo = 'es_DO',
  EsEc = 'es_EC',
  EsEs = 'es_ES',
  EsGt = 'es_GT',
  EsHn = 'es_HN',
  EsMx = 'es_MX',
  EsNi = 'es_NI',
  EsPa = 'es_PA',
  EsPe = 'es_PE',
  EsPr = 'es_PR',
  EsPy = 'es_PY',
  EsSv = 'es_SV',
  EsUs = 'es_US',
  EsUy = 'es_UY',
  EsVe = 'es_VE',
  EtEe = 'et_EE',
  EuEs = 'eu_ES',
  FaIr = 'fa_IR',
  FfSn = 'ff_SN',
  FiFi = 'fi_FI',
  FilPh = 'fil_PH',
  FoFo = 'fo_FO',
  FrBe = 'fr_BE',
  FrCa = 'fr_CA',
  FrCh = 'fr_CH',
  FrFr = 'fr_FR',
  FrLu = 'fr_LU',
  FurIt = 'fur_IT',
  FyDe = 'fy_DE',
  FyNl = 'fy_NL',
  GaIe = 'ga_IE',
  GdGb = 'gd_GB',
  GezEr = 'gez_ER',
  GezEt = 'gez_ET',
  GlEs = 'gl_ES',
  GuIn = 'gu_IN',
  GvGb = 'gv_GB',
  HaNg = 'ha_NG',
  HeIl = 'he_IL',
  HiIn = 'hi_IN',
  HneIn = 'hne_IN',
  HrHr = 'hr_HR',
  HsbDe = 'hsb_DE',
  HtHt = 'ht_HT',
  HuHu = 'hu_HU',
  HyAm = 'hy_AM',
  IaFr = 'ia_FR',
  IdId = 'id_ID',
  IgNg = 'ig_NG',
  IkCa = 'ik_CA',
  IsIs = 'is_IS',
  ItCh = 'it_CH',
  ItIt = 'it_IT',
  IuCa = 'iu_CA',
  IwIl = 'iw_IL',
  JaJp = 'ja_JP',
  KaGe = 'ka_GE',
  KkKz = 'kk_KZ',
  KlGl = 'kl_GL',
  KmKh = 'km_KH',
  KnIn = 'kn_IN',
  KoKr = 'ko_KR',
  KokIn = 'kok_IN',
  KsIn = 'ks_IN',
  KuTr = 'ku_TR',
  KwGb = 'kw_GB',
  KyKg = 'ky_KG',
  LbLu = 'lb_LU',
  LgUg = 'lg_UG',
  LiBe = 'li_BE',
  LiNl = 'li_NL',
  LijIt = 'lij_IT',
  LoLa = 'lo_LA',
  LtLt = 'lt_LT',
  LvLv = 'lv_LV',
  MagIn = 'mag_IN',
  MaiIn = 'mai_IN',
  MgMg = 'mg_MG',
  MhrRu = 'mhr_RU',
  MiNz = 'mi_NZ',
  MkMk = 'mk_MK',
  MlIn = 'ml_IN',
  MnMn = 'mn_MN',
  MniIn = 'mni_IN',
  MrIn = 'mr_IN',
  MsMy = 'ms_MY',
  MtMt = 'mt_MT',
  MyMm = 'my_MM',
  NanTw = 'nan_TW',
  NbNo = 'nb_NO',
  NdsDe = 'nds_DE',
  NdsNl = 'nds_NL',
  NeNp = 'ne_NP',
  NhnMx = 'nhn_MX',
  NiuNu = 'niu_NU',
  NiuNz = 'niu_NZ',
  NlAw = 'nl_AW',
  NlBe = 'nl_BE',
  NlNl = 'nl_NL',
  NnNo = 'nn_NO',
  NrZa = 'nr_ZA',
  NsoZa = 'nso_ZA',
  OcFr = 'oc_FR',
  OmEt = 'om_ET',
  OmKe = 'om_KE',
  OrIn = 'or_IN',
  OsRu = 'os_RU',
  PaIn = 'pa_IN',
  PaPk = 'pa_PK',
  PapAn = 'pap_AN',
  PlPl = 'pl_PL',
  PsAf = 'ps_AF',
  PtBr = 'pt_BR',
  PtPt = 'pt_PT',
  RoRo = 'ro_RO',
  RuRu = 'ru_RU',
  RuUa = 'ru_UA',
  RwRw = 'rw_RW',
  SaIn = 'sa_IN',
  SatIn = 'sat_IN',
  ScIt = 'sc_IT',
  SdIn = 'sd_IN',
  SeNo = 'se_NO',
  ShsCa = 'shs_CA',
  SiLk = 'si_LK',
  SidEt = 'sid_ET',
  SkSk = 'sk_SK',
  SlSi = 'sl_SI',
  SoDj = 'so_DJ',
  SoEt = 'so_ET',
  SoKe = 'so_KE',
  SoSo = 'so_SO',
  SqAl = 'sq_AL',
  SqMk = 'sq_MK',
  SrMe = 'sr_ME',
  SrRs = 'sr_RS',
  SsZa = 'ss_ZA',
  StZa = 'st_ZA',
  SvFi = 'sv_FI',
  SvSe = 'sv_SE',
  SwKe = 'sw_KE',
  SwTz = 'sw_TZ',
  SzlPl = 'szl_PL',
  TaIn = 'ta_IN',
  TaLk = 'ta_LK',
  TeIn = 'te_IN',
  TgTj = 'tg_TJ',
  ThTh = 'th_TH',
  TiEr = 'ti_ER',
  TiEt = 'ti_ET',
  TigEr = 'tig_ER',
  TkTm = 'tk_TM',
  TlPh = 'tl_PH',
  TnZa = 'tn_ZA',
  TrCy = 'tr_CY',
  TrTr = 'tr_TR',
  TsZa = 'ts_ZA',
  TtRu = 'tt_RU',
  UgCn = 'ug_CN',
  UkUa = 'uk_UA',
  UnmUs = 'unm_US',
  UrIn = 'ur_IN',
  UrPk = 'ur_PK',
  UzUz = 'uz_UZ',
  VeZa = 've_ZA',
  ViVn = 'vi_VN',
  WaBe = 'wa_BE',
  WaeCh = 'wae_CH',
  WalEt = 'wal_ET',
  WoSn = 'wo_SN',
  XhZa = 'xh_ZA',
  YiUs = 'yi_US',
  YoNg = 'yo_NG',
  YueHk = 'yue_HK',
  ZhCn = 'zh_CN',
  ZhHk = 'zh_HK',
  ZhSg = 'zh_SG',
  ZhTw = 'zh_TW',
  ZuZa = 'zu_ZA'
}

export enum ReviewMood {
  Negative = 'negative',
  Neutral = 'neutral',
  Positive = 'positive'
}

export type ReviewNamespace = {
  __typename?: 'ReviewNamespace';
  /** Creates an object (or multiple objects) and saves it at the remote application, if validations pass. The resulting object is returned whether the object was saved successfully or not. The attributes parameter can be either a Hash or an Array of Hashes. These Hashes describe the attributes on the objects that are to be created. */
  create: ReviewCreatePayload;
  /** Deletes the instance at the remote application and freezes this instance to reflect that no changes should be made (since they can't be persisted). To enforce the object's before_destroy and after_destroy callbacks use #destroy. */
  delete: ReviewDeletePayload;
  /** Updates the attributes of the entity from the passed-in hash and saves the entity. If the object is invalid, the saving will fail and false will be returned. Also aliased as: update_attributes. */
  update: ReviewUpdatePayload;
};


export type ReviewNamespaceCreateArgs = {
  category?: InputMaybe<Scalars['String']>;
  dataPointGids?: InputMaybe<Array<Scalars['GlobalID']>>;
  headline?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<ReviewLocale>;
  mood?: InputMaybe<ReviewMood>;
  publicAccess?: InputMaybe<Scalars['Boolean']>;
  reviewedObjectGid: Scalars['GlobalID'];
  reviewerGid: Scalars['GlobalID'];
  reviewerName: Scalars['String'];
  text: Scalars['String'];
  validatedAt?: InputMaybe<Scalars['DateTime']>;
};


export type ReviewNamespaceDeleteArgs = {
  id: Scalars['ReviewGlobalIdOrUuid'];
};


export type ReviewNamespaceUpdateArgs = {
  category?: InputMaybe<Scalars['String']>;
  dataPointGids?: InputMaybe<Array<Scalars['GlobalID']>>;
  headline?: InputMaybe<Scalars['String']>;
  id: Scalars['ReviewGlobalIdOrUuid'];
  locale?: InputMaybe<ReviewLocale>;
  mood?: InputMaybe<ReviewMood>;
  publicAccess?: InputMaybe<Scalars['Boolean']>;
  reviewerName?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  validatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ReviewPage = {
  __typename?: 'ReviewPage';
  /** The total count of results for this search. If this is more than `limit` you may repeat the request with a higher `offset` to get the remaining nodes. */
  count: Scalars['Int'];
  /** The connections to the `Review` entities that are included in this page. */
  edges: Array<ReviewEdge>;
  /** The limit that was used to generate this page. The limit is the upper bound of how many nodes are returned. */
  limit: Scalars['Int'];
  /** The `Review` entities that are included in this page. */
  nodes: Array<Review>;
  /** The offset that was used to generate this page. */
  offset: Scalars['Int'];
};

export type ReviewSearchParameters = {
  ids?: InputMaybe<Array<Scalars['ReviewGlobalIdOrUuid']>>;
  mood?: InputMaybe<Array<ReviewMood>>;
  publicAccess?: InputMaybe<Scalars['Boolean']>;
  reviewedObjectGid?: InputMaybe<Scalars['GlobalID']>;
  reviewerGid?: InputMaybe<Scalars['GlobalID']>;
  text?: InputMaybe<Scalars['String']>;
  validated?: InputMaybe<Scalars['Boolean']>;
};

/** Autogenerated return type of ReviewUpdate */
export type ReviewUpdatePayload = {
  __typename?: 'ReviewUpdatePayload';
  error?: Maybe<MutationError>;
  review?: Maybe<Review>;
};

/** An access control role. Roles themselves aren't entirely useful, but have attached policies that can resolve to permissions for specific objects. */
export type Role = CanHaveDataPoints & GlobalIdentifiable & {
  __typename?: 'Role';
  /** When the role was created (ISO 8601) */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Searches for referenced `data_points` */
  dataPoints?: Maybe<DataPointsResult>;
  /** Global Id of the role (UUID) */
  gid: Scalars['GlobalID'];
  /** Id of the role (UUID) */
  id: Scalars['ID'];
  /** When the role was updated last time (ISO 8601) */
  updatedAt?: Maybe<Scalars['DateTime']>;
};


/** An access control role. Roles themselves aren't entirely useful, but have attached policies that can resolve to permissions for specific objects. */
export type RoleDataPointsArgs = {
  aggregation?: InputMaybe<Aggregation>;
  context: Scalars['String'];
  endAt?: InputMaybe<Scalars['DateTime']>;
  interval?: InputMaybe<Interval>;
  metrics: Array<Scalars['String']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
};

/** Role Assignments provide users with all policies of the given role for the assignment's configured object. */
export type RoleAssignment = {
  __typename?: 'RoleAssignment';
  /** When the role_assignment was created (ISO 8601) */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The Global Id of the object */
  object?: Maybe<Scalars['String']>;
  /** The identifier of the role */
  roleId?: Maybe<Scalars['ID']>;
  /** The Global Id of the subject */
  subject?: Maybe<Scalars['String']>;
  /** When the role_assignment was updated last time (ISO 8601) */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Autogenerated return type of RoleAssignmentCreate */
export type RoleAssignmentCreatePayload = {
  __typename?: 'RoleAssignmentCreatePayload';
  error?: Maybe<MutationError>;
  roleAssignment?: Maybe<RoleAssignment>;
};

/** Autogenerated return type of RoleAssignmentDelete */
export type RoleAssignmentDeletePayload = {
  __typename?: 'RoleAssignmentDeletePayload';
  error?: Maybe<MutationError>;
  roleAssignment?: Maybe<RoleAssignment>;
};

export type RoleAssignmentEdge = {
  __typename?: 'RoleAssignmentEdge';
  /** The `RoleAssignment` entity that this edge points to. */
  node: RoleAssignment;
};

export type RoleAssignmentNamespace = {
  __typename?: 'RoleAssignmentNamespace';
  /** Creates an object (or multiple objects) and saves it at the remote application, if validations pass. The resulting object is returned whether the object was saved successfully or not. The attributes parameter can be either a Hash or an Array of Hashes. These Hashes describe the attributes on the objects that are to be created. */
  create: RoleAssignmentCreatePayload;
  /** Deletes the instance at the remote application and freezes this instance to reflect that no changes should be made (since they can't be persisted). To enforce the object's before_destroy and after_destroy callbacks use #destroy. */
  delete: RoleAssignmentDeletePayload;
};


export type RoleAssignmentNamespaceCreateArgs = {
  object?: InputMaybe<Scalars['String']>;
  roleId: Scalars['ID'];
  subject: Scalars['String'];
};


export type RoleAssignmentNamespaceDeleteArgs = {
  object?: InputMaybe<Scalars['String']>;
  roleId: Scalars['ID'];
  subject: Scalars['String'];
};

export type RoleAssignmentPage = {
  __typename?: 'RoleAssignmentPage';
  /** The total count of results for this search. If this is more than `limit` you may repeat the request with a higher `offset` to get the remaining nodes. */
  count: Scalars['Int'];
  /** The connections to the `RoleAssignment` entities that are included in this page. */
  edges: Array<RoleAssignmentEdge>;
  /** The limit that was used to generate this page. The limit is the upper bound of how many nodes are returned. */
  limit: Scalars['Int'];
  /** The `RoleAssignment` entities that are included in this page. */
  nodes: Array<RoleAssignment>;
  /** The offset that was used to generate this page. */
  offset: Scalars['Int'];
};

export type RoleAssignmentSearchParameters = {
  object?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type RoleEdge = {
  __typename?: 'RoleEdge';
  /** The `Role` entity that this edge points to. */
  node: Role;
};

export type RolePage = {
  __typename?: 'RolePage';
  /** The total count of results for this search. If this is more than `limit` you may repeat the request with a higher `offset` to get the remaining nodes. */
  count: Scalars['Int'];
  /** The connections to the `Role` entities that are included in this page. */
  edges: Array<RoleEdge>;
  /** The limit that was used to generate this page. The limit is the upper bound of how many nodes are returned. */
  limit: Scalars['Int'];
  /** The `Role` entities that are included in this page. */
  nodes: Array<Role>;
  /** The offset that was used to generate this page. */
  offset: Scalars['Int'];
};

export type RoleSearchParameters = {
  rootRoles?: InputMaybe<Scalars['Boolean']>;
  text?: InputMaybe<Scalars['String']>;
};

/** The HAUSGOLD search profile is a loosely coupled entity to formulate a complex set of search criteria for the property search. */
export type SearchProfile = CanHaveDataPoints & GlobalIdentifiable & {
  __typename?: 'SearchProfile';
  /** Lower amount of rooms range of the search profile. */
  amountRoomsFrom?: Maybe<Scalars['IEEE754Float']>;
  /** Upper amount of rooms range of the search profile. */
  amountRoomsTo?: Maybe<Scalars['IEEE754Float']>;
  /** The city name of the search profile. */
  city?: Maybe<Scalars['String']>;
  /** When the search profile was created (ISO 8601) */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The currency of the search profile. */
  currency?: Maybe<Scalars['String']>;
  /** Searches for referenced `data_points` */
  dataPoints?: Maybe<DataPointsResult>;
  /** Global Id of the search profile (GID) */
  gid: Scalars['GlobalID'];
  /** Id of the search profile (UUID) */
  id: Scalars['ID'];
  /** Lower land size range of the search profile. */
  landSizeFrom?: Maybe<Scalars['IEEE754Float']>;
  /** Upper land size range of the search profile. */
  landSizeTo?: Maybe<Scalars['IEEE754Float']>;
  /** Lower living space range of the search profile. */
  livingSpaceFrom?: Maybe<Scalars['IEEE754Float']>;
  /** Upper living space range of the search profile. */
  livingSpaceTo?: Maybe<Scalars['IEEE754Float']>;
  /** External search profile owner gid */
  owner?: Maybe<GlobalIdentifiable>;
  /** The perimeter of the search profile. */
  perimeter?: Maybe<Scalars['IEEE754Float']>;
  /** Lower price range of the search profile. */
  priceFrom?: Maybe<Scalars['IEEE754Float']>;
  /** Upper price range of the search profile. */
  priceTo?: Maybe<Scalars['IEEE754Float']>;
  /** All the property subtypes of the search profile. */
  propertySubtypes?: Maybe<Array<Scalars['String']>>;
  /** All the property types of the search profile. */
  propertyTypes?: Maybe<Array<Scalars['String']>>;
  /** When the search profile was updated last time (ISO 8601) */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All the usages of the search profile. */
  usages?: Maybe<Array<Scalars['String']>>;
  /** Id of the search profile owner (deprecated) */
  userId?: Maybe<Scalars['ID']>;
  /** Lower construction year range of the search profile. */
  yearOfConstructionFrom?: Maybe<Scalars['IEEE754Float']>;
  /** Upper construction year range of the search profile. */
  yearOfConstructionTo?: Maybe<Scalars['IEEE754Float']>;
  /** The postal code of the search profile. */
  zipcode?: Maybe<Scalars['String']>;
};


/** The HAUSGOLD search profile is a loosely coupled entity to formulate a complex set of search criteria for the property search. */
export type SearchProfileDataPointsArgs = {
  aggregation?: InputMaybe<Aggregation>;
  context: Scalars['String'];
  endAt?: InputMaybe<Scalars['DateTime']>;
  interval?: InputMaybe<Interval>;
  metrics: Array<Scalars['String']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
};

/** Autogenerated return type of SearchProfileCreate */
export type SearchProfileCreatePayload = {
  __typename?: 'SearchProfileCreatePayload';
  error?: Maybe<MutationError>;
  searchProfile?: Maybe<SearchProfile>;
};

export enum SearchProfileCurrency {
  Aed = 'AED',
  Afa = 'AFA',
  All = 'ALL',
  Amd = 'AMD',
  Ang = 'ANG',
  Aon = 'AON',
  Arp = 'ARP',
  Ats = 'ATS',
  Aud = 'AUD',
  Awf = 'AWF',
  Azm = 'AZM',
  Bak = 'BAK',
  Bbd = 'BBD',
  Bdt = 'BDT',
  Bef = 'BEF',
  Bes = 'BES',
  Bgl = 'BGL',
  Bhd = 'BHD',
  Bif = 'BIF',
  Bmd = 'BMD',
  Bnd = 'BND',
  Bob = 'BOB',
  Brl = 'BRL',
  Bsd = 'BSD',
  Btr = 'BTR',
  Bwp = 'BWP',
  Byr = 'BYR',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Chf = 'CHF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Crc = 'CRC',
  Cup = 'CUP',
  Cuw = 'CUW',
  Cve = 'CVE',
  Cyp = 'CYP',
  Czk = 'CZK',
  Dem = 'DEM',
  Djf = 'DJF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Ecs = 'ECS',
  Eek = 'EEK',
  Egp = 'EGP',
  Ern = 'ERN',
  Esp = 'ESP',
  Etb = 'ETB',
  Eur = 'EUR',
  Fim = 'FIM',
  Fjd = 'FJD',
  Fkp = 'FKP',
  Frf = 'FRF',
  Gbp = 'GBP',
  Gel = 'GEL',
  Ghc = 'GHC',
  Gip = 'GIP',
  Gmd = 'GMD',
  Gnf = 'GNF',
  Grd = 'GRD',
  Gtq = 'GTQ',
  Gyd = 'GYD',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Hrk = 'HRK',
  Htg = 'HTG',
  Huf = 'HUF',
  Idr = 'IDR',
  Iep = 'IEP',
  Ils = 'ILS',
  Inr = 'INR',
  Iqd = 'IQD',
  Irr = 'IRR',
  Isk = 'ISK',
  Itl = 'ITL',
  Jmd = 'JMD',
  Jod = 'JOD',
  Jpy = 'JPY',
  Kes = 'KES',
  Kgs = 'KGS',
  Khr = 'KHR',
  Kmf = 'KMF',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kyd = 'KYD',
  Kzt = 'KZT',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Lrd = 'LRD',
  Lsl = 'LSL',
  Ltl = 'LTL',
  Luf = 'LUF',
  Lvl = 'LVL',
  Lyd = 'LYD',
  Mad = 'MAD',
  Mdl = 'MDL',
  Mgf = 'MGF',
  Mmk = 'MMK',
  Mnt = 'MNT',
  Mop = 'MOP',
  Mro = 'MRO',
  Mtl = 'MTL',
  Mur = 'MUR',
  Mvr = 'MVR',
  Mwk = 'MWK',
  Mxp = 'MXP',
  Mzm = 'MZM',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nio = 'NIO',
  Nlg = 'NLG',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pab = 'PAB',
  Pen = 'PEN',
  Pgk = 'PGK',
  Php = 'PHP',
  Pkr = 'PKR',
  Plz = 'PLZ',
  Pte = 'PTE',
  Pyg = 'PYG',
  Qar = 'QAR',
  Rol = 'ROL',
  Rur = 'RUR',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sbd = 'SBD',
  Sbl = 'SBL',
  Scr = 'SCR',
  Sdd = 'SDD',
  Sek = 'SEK',
  Sgd = 'SGD',
  Shp = 'SHP',
  Sit = 'SIT',
  Skk = 'SKK',
  Sll = 'SLL',
  Sos = 'SOS',
  Srg = 'SRG',
  Std = 'STD',
  Svc = 'SVC',
  Syp = 'SYP',
  Szl = 'SZL',
  Thb = 'THB',
  Tjr = 'TJR',
  Tmm = 'TMM',
  Tnd = 'TND',
  Top = 'TOP',
  Trl = 'TRL',
  Ttd = 'TTD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Ugx = 'UGX',
  Usd = 'USD',
  Uyu = 'UYU',
  Uzs = 'UZS',
  Veb = 'VEB',
  Vnd = 'VND',
  Vuv = 'VUV',
  Wst = 'WST',
  Xaf = 'XAF',
  Xag = 'XAG',
  Xau = 'XAU',
  Xcd = 'XCD',
  Xco = 'XCO',
  Xdr = 'XDR',
  Xpd = 'XPD',
  Xpf = 'XPF',
  Xpt = 'XPT',
  Yer = 'YER',
  Yun = 'YUN',
  Zar = 'ZAR',
  Zmk = 'ZMK',
  Zwd = 'ZWD'
}

/** Autogenerated return type of SearchProfileDelete */
export type SearchProfileDeletePayload = {
  __typename?: 'SearchProfileDeletePayload';
  error?: Maybe<MutationError>;
  searchProfile?: Maybe<SearchProfile>;
};

export type SearchProfileEdge = {
  __typename?: 'SearchProfileEdge';
  /** The `SearchProfile` entity that this edge points to. */
  node: SearchProfile;
};

export type SearchProfileNamespace = {
  __typename?: 'SearchProfileNamespace';
  /** Creates an object (or multiple objects) and saves it at the remote application, if validations pass. The resulting object is returned whether the object was saved successfully or not. The attributes parameter can be either a Hash or an Array of Hashes. These Hashes describe the attributes on the objects that are to be created. */
  create: SearchProfileCreatePayload;
  /** Deletes the instance at the remote application and freezes this instance to reflect that no changes should be made (since they can't be persisted). To enforce the object's before_destroy and after_destroy callbacks use #destroy. */
  delete: SearchProfileDeletePayload;
  /** Updates the attributes of the entity from the passed-in hash and saves the entity. If the object is invalid, the saving will fail and false will be returned. Also aliased as: update_attributes. */
  update: SearchProfileUpdatePayload;
};


export type SearchProfileNamespaceCreateArgs = {
  amountRoomsFrom?: InputMaybe<Scalars['IEEE754Float']>;
  amountRoomsTo?: InputMaybe<Scalars['IEEE754Float']>;
  city?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<SearchProfileCurrency>;
  landSizeFrom?: InputMaybe<Scalars['IEEE754Float']>;
  landSizeTo?: InputMaybe<Scalars['IEEE754Float']>;
  livingSpaceFrom?: InputMaybe<Scalars['IEEE754Float']>;
  livingSpaceTo?: InputMaybe<Scalars['IEEE754Float']>;
  ownerGid?: InputMaybe<Scalars['GlobalID']>;
  perimeter?: InputMaybe<Scalars['IEEE754Float']>;
  priceFrom?: InputMaybe<Scalars['IEEE754Float']>;
  priceTo?: InputMaybe<Scalars['IEEE754Float']>;
  propertySubtypes?: InputMaybe<Array<SearchProfilePropertySubtypes>>;
  propertyTypes: Array<SearchProfilePropertyTypes>;
  usages?: InputMaybe<Array<SearchProfileUsages>>;
  userId?: InputMaybe<Scalars['ID']>;
  yearOfConstructionFrom?: InputMaybe<Scalars['IEEE754Float']>;
  yearOfConstructionTo?: InputMaybe<Scalars['IEEE754Float']>;
  zipcode?: InputMaybe<Scalars['String']>;
};


export type SearchProfileNamespaceDeleteArgs = {
  id: Scalars['SearchProfileGlobalIdOrUuid'];
};


export type SearchProfileNamespaceUpdateArgs = {
  amountRoomsFrom?: InputMaybe<Scalars['IEEE754Float']>;
  amountRoomsTo?: InputMaybe<Scalars['IEEE754Float']>;
  city?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<SearchProfileCurrency>;
  id: Scalars['SearchProfileGlobalIdOrUuid'];
  landSizeFrom?: InputMaybe<Scalars['IEEE754Float']>;
  landSizeTo?: InputMaybe<Scalars['IEEE754Float']>;
  livingSpaceFrom?: InputMaybe<Scalars['IEEE754Float']>;
  livingSpaceTo?: InputMaybe<Scalars['IEEE754Float']>;
  perimeter?: InputMaybe<Scalars['IEEE754Float']>;
  priceFrom?: InputMaybe<Scalars['IEEE754Float']>;
  priceTo?: InputMaybe<Scalars['IEEE754Float']>;
  propertySubtypes?: InputMaybe<Array<SearchProfilePropertySubtypes>>;
  propertyTypes?: InputMaybe<Array<SearchProfilePropertyTypes>>;
  usages?: InputMaybe<Array<SearchProfileUsages>>;
  yearOfConstructionFrom?: InputMaybe<Scalars['IEEE754Float']>;
  yearOfConstructionTo?: InputMaybe<Scalars['IEEE754Float']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type SearchProfilePage = {
  __typename?: 'SearchProfilePage';
  /** The total count of results for this search. If this is more than `limit` you may repeat the request with a higher `offset` to get the remaining nodes. */
  count: Scalars['Int'];
  /** The connections to the `SearchProfile` entities that are included in this page. */
  edges: Array<SearchProfileEdge>;
  /** The limit that was used to generate this page. The limit is the upper bound of how many nodes are returned. */
  limit: Scalars['Int'];
  /** The `SearchProfile` entities that are included in this page. */
  nodes: Array<SearchProfile>;
  /** The offset that was used to generate this page. */
  offset: Scalars['Int'];
};

export enum SearchProfilePropertySubtypes {
  Agriculture = 'agriculture',
  AgricultureBusiness = 'agriculture_business',
  AgricultureForestry = 'agriculture_forestry',
  AmusementPark = 'amusement_park',
  Apartment = 'apartment',
  ApartmentHouse = 'apartment_house',
  ArborDachaGardenHouse = 'arbor_dacha_garden_house',
  AssisstedLiving = 'assissted_living',
  Attic = 'attic',
  Bar = 'bar',
  Barns = 'barns',
  Basement = 'basement',
  BeachHouse = 'beach_house',
  BigStore = 'big_store',
  BoatDock = 'boat_dock',
  Bungalow = 'bungalow',
  Business = 'business',
  BusinessPark = 'business_park',
  Cafe = 'cafe',
  CarPark = 'car_park',
  Carport = 'carport',
  Castle = 'castle',
  Chalet = 'chalet',
  Club = 'club',
  ColdStorage = 'cold_storage',
  ConvenienceStore = 'convenience_store',
  CountryHouse = 'country_house',
  Coworking = 'coworking',
  Detached = 'detached',
  DoubleGarage = 'double_garage',
  Duplex = 'duplex',
  EndOfTerrace = 'end_of_terrace',
  Estate = 'estate',
  ExhibitionSpace = 'exhibition_space',
  FactoryBuilding = 'factory_building',
  FactoryOutlet = 'factory_outlet',
  Farm = 'farm',
  Farmhouse = 'farmhouse',
  Farmstead = 'farmstead',
  Finca = 'finca',
  Floor = 'floor',
  ForwardingWarehouse = 'forwarding_warehouse',
  Gallery = 'gallery',
  Gastronomy = 'gastronomy',
  GastronomyWithApartment = 'gastronomy_with_apartment',
  GroundFloor = 'ground_floor',
  Guesthouse = 'guesthouse',
  Hall = 'hall',
  HighRackWarehouse = 'high_rack_warehouse',
  HolidayFlat = 'holiday_flat',
  HolidayHome = 'holiday_home',
  HorseRiding = 'horse_riding',
  Horticulture = 'horticulture',
  Hospital = 'hospital',
  Hotels = 'hotels',
  HousingArea = 'housing_area',
  HuntingGround = 'hunting_ground',
  HuntingIndustryAndForestry = 'hunting_industry_and_forestry',
  IndustrialPlant = 'industrial_plant',
  Industry = 'industry',
  IsolatedFarm = 'isolated_farm',
  Kiosk = 'kiosk',
  Lakefront = 'lakefront',
  Leisure = 'leisure',
  LeisureFacility = 'leisure_facility',
  Living = 'living',
  LivingAndOfficeHouse = 'living_and_office_house',
  Maisonette = 'maisonette',
  ManorHouse = 'manor_house',
  Mansion = 'mansion',
  MiddleOfTerrace = 'middle_of_terrace',
  Mixed = 'mixed',
  MountainShelter = 'mountain_shelter',
  NursingHome = 'nursing_home',
  OfficeBlock = 'office_block',
  OfficeBuilding = 'office_building',
  OfficeCenter = 'office_center',
  OfficeHouse = 'office_house',
  OfficeSpace = 'office_space',
  OpenSpace = 'open_space',
  Other = 'other',
  OtherLodging = 'other_lodging',
  Palace = 'palace',
  ParkingSpace = 'parking_space',
  Penthouse = 'penthouse',
  PetrolStation = 'petrol_station',
  PondAndFishIndustry = 'pond_and_fish_industry',
  Practice = 'practice',
  PracticeBuilding = 'practice_building',
  PracticeSpace = 'practice_space',
  Prefabricated = 'prefabricated',
  Production = 'production',
  Ranching = 'ranching',
  RawAttic = 'raw_attic',
  Restaurant = 'restaurant',
  RetailShop = 'retail_shop',
  Room = 'room',
  RowHouse = 'row_house',
  Rustico = 'rustico',
  SalesArea = 'sales_area',
  Sanatorium = 'sanatorium',
  SelfServiceMarket = 'self_service_market',
  SemiDetached = 'semi_detached',
  Service = 'service',
  SharedOffice = 'shared_office',
  Shop = 'shop',
  ShoppingCenter = 'shopping_center',
  SingleGarage = 'single_garage',
  SingleRoom = 'single_room',
  SmokersPlace = 'smokers_place',
  SpecialUse = 'special_use',
  SportsFacility = 'sports_facility',
  Storage = 'storage',
  StorageArea = 'storage_area',
  StorageWithOpenSpace = 'storage_with_open_space',
  Studio = 'studio',
  Terrace = 'terrace',
  Townhouse = 'townhouse',
  TwoFamilyHouse = 'two_family_house',
  UndergroundParking = 'underground_parking',
  UndergroundParkingSpace = 'underground_parking_space',
  Viniculture = 'viniculture',
  WithChargingStation = 'with_charging_station',
  Workshop = 'workshop'
}

export enum SearchProfilePropertyTypes {
  Agriculture = 'agriculture',
  Apartment = 'apartment',
  HospitalityIndustry = 'hospitality_industry',
  House = 'house',
  InterestHouse = 'interest_house',
  LeisureCommercial = 'leisure_commercial',
  Office = 'office',
  Other = 'other',
  Parking = 'parking',
  Retail = 'retail',
  Room = 'room',
  Site = 'site',
  Warehouse = 'warehouse'
}

export type SearchProfileSearchParameters = {
  ids?: InputMaybe<Array<Scalars['SearchProfileGlobalIdOrUuid']>>;
  text?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of SearchProfileUpdate */
export type SearchProfileUpdatePayload = {
  __typename?: 'SearchProfileUpdatePayload';
  error?: Maybe<MutationError>;
  searchProfile?: Maybe<SearchProfile>;
};

export enum SearchProfileUsages {
  Commercial = 'commercial',
  Investment = 'investment',
  Residential = 'residential',
  TemporaryLiving = 'temporary_living'
}

export enum SortDirection {
  /** Sort in ascending order (e.g. lowest/oldest first) */
  Asc = 'asc',
  /** Sort in descending order (e.g. highest/latest first) */
  Desc = 'desc'
}

/** The HAUSGOLD ecosystem has an abstract task entity which represents a thing which needs to be done. Everybody can have tasks - users, properties, or anything else. The tasks come with a state which defines them as open, resolved or rejected. They bundle titles and descriptions and can also have alarms (reminders) for optional due dates. */
export type Task = CanHaveDataPoints & GlobalIdentifiable & {
  __typename?: 'Task';
  /** All the defined alarms. */
  alarms?: Maybe<Array<TaskAlarms>>;
  /** When the task was created (ISO 8601) */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Searches for referenced `data_points` */
  dataPoints?: Maybe<DataPointsResult>;
  /** Full description of the task */
  description?: Maybe<Scalars['String']>;
  /** When the task starts (ISO 8601) */
  dueDate?: Maybe<Scalars['DateTime']>;
  /** Whenever the data can be edited */
  editable?: Maybe<Scalars['Boolean']>;
  /** Global Id of the task (UUID) */
  gid: Scalars['GlobalID'];
  /** Id of the task (UUID) */
  id: Scalars['ID'];
  /** A free typed location/address */
  location?: Maybe<Scalars['String']>;
  /** External defined additional data */
  metadata?: Maybe<Scalars['JsonBlob']>;
  /** External defined owner gid */
  owner?: Maybe<GlobalIdentifiable>;
  /** External defined references gids */
  references?: Maybe<Array<GlobalIdentifiable>>;
  /** A free typed result */
  result?: Maybe<Scalars['String']>;
  /** [open resolved rejected] */
  status?: Maybe<Scalars['String']>;
  /** Descriptive title of the task */
  title?: Maybe<Scalars['String']>;
  /** The given task classification */
  type?: Maybe<Scalars['String']>;
  /** When the task was updated last time (ISO 8601) */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** External defined user id (deprecated) */
  userId?: Maybe<Scalars['ID']>;
};


/** The HAUSGOLD ecosystem has an abstract task entity which represents a thing which needs to be done. Everybody can have tasks - users, properties, or anything else. The tasks come with a state which defines them as open, resolved or rejected. They bundle titles and descriptions and can also have alarms (reminders) for optional due dates. */
export type TaskDataPointsArgs = {
  aggregation?: InputMaybe<Aggregation>;
  context: Scalars['String'];
  endAt?: InputMaybe<Scalars['DateTime']>;
  interval?: InputMaybe<Interval>;
  metrics: Array<Scalars['String']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
};

export type TaskAlarms = {
  __typename?: 'TaskAlarms';
  /** Relative minutes to the time, when the alarm should occur (eg. 15 minutes before the due date) */
  beforeMinutes?: Maybe<Scalars['Int']>;
  /** The user defined alarm channel (eg. email) */
  channel?: Maybe<Scalars['String']>;
};

export enum TaskChannel {
  Email = 'email',
  PushNotification = 'push_notification',
  WebNotification = 'web_notification'
}

export type TaskCreateAlarms = {
  beforeMinutes: Scalars['Int'];
  channel: TaskChannel;
};

/** Autogenerated return type of TaskCreate */
export type TaskCreatePayload = {
  __typename?: 'TaskCreatePayload';
  error?: Maybe<MutationError>;
  task?: Maybe<Task>;
};

/** Autogenerated return type of TaskDelete */
export type TaskDeletePayload = {
  __typename?: 'TaskDeletePayload';
  error?: Maybe<MutationError>;
  task?: Maybe<Task>;
};

export type TaskEdge = {
  __typename?: 'TaskEdge';
  /** The `Task` entity that this edge points to. */
  node: Task;
};

export type TaskNamespace = {
  __typename?: 'TaskNamespace';
  /** Creates an object (or multiple objects) and saves it at the remote application, if validations pass. The resulting object is returned whether the object was saved successfully or not. The attributes parameter can be either a Hash or an Array of Hashes. These Hashes describe the attributes on the objects that are to be created. */
  create: TaskCreatePayload;
  /** Deletes the instance at the remote application and freezes this instance to reflect that no changes should be made (since they can't be persisted). To enforce the object's before_destroy and after_destroy callbacks use #destroy. */
  delete: TaskDeletePayload;
  /** Updates the attributes of the entity from the passed-in hash and saves the entity. If the object is invalid, the saving will fail and false will be returned. Also aliased as: update_attributes. */
  update: TaskUpdatePayload;
};


export type TaskNamespaceCreateArgs = {
  alarms?: InputMaybe<Array<TaskCreateAlarms>>;
  description?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['DateTime']>;
  editable?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['JsonBlob']>;
  ownerGid?: InputMaybe<Scalars['GlobalID']>;
  referenceGids?: InputMaybe<Array<Scalars['GlobalID']>>;
  referenceIds?: InputMaybe<Array<Scalars['String']>>;
  result?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<TaskStatus>;
  title: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['ID']>;
};


export type TaskNamespaceDeleteArgs = {
  id: Scalars['TaskGlobalIdOrUuid'];
};


export type TaskNamespaceUpdateArgs = {
  alarms?: InputMaybe<Array<TaskUpdateAlarms>>;
  description?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['TaskGlobalIdOrUuid'];
  location?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['JsonBlob']>;
  referenceGids?: InputMaybe<Array<Scalars['GlobalID']>>;
  referenceIds?: InputMaybe<Array<Scalars['String']>>;
  result?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<TaskStatus>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type TaskPage = {
  __typename?: 'TaskPage';
  /** The total count of results for this search. If this is more than `limit` you may repeat the request with a higher `offset` to get the remaining nodes. */
  count: Scalars['Int'];
  /** The connections to the `Task` entities that are included in this page. */
  edges: Array<TaskEdge>;
  /** The limit that was used to generate this page. The limit is the upper bound of how many nodes are returned. */
  limit: Scalars['Int'];
  /** The `Task` entities that are included in this page. */
  nodes: Array<Task>;
  /** The offset that was used to generate this page. */
  offset: Scalars['Int'];
};

export type TaskSearchParameters = {
  from?: InputMaybe<Scalars['DateTime']>;
  ids?: InputMaybe<Array<Scalars['TaskGlobalIdOrUuid']>>;
  ownerGid?: InputMaybe<Array<Scalars['String']>>;
  referenceIds?: InputMaybe<Array<Scalars['String']>>;
  status?: InputMaybe<Array<Scalars['String']>>;
  text?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Array<Scalars['String']>>;
};

export enum TaskStatus {
  Open = 'open',
  Rejected = 'rejected',
  Resolved = 'resolved'
}

export type TaskUpdateAlarms = {
  beforeMinutes: Scalars['Int'];
  channel: TaskChannel;
};

/** Autogenerated return type of TaskUpdate */
export type TaskUpdatePayload = {
  __typename?: 'TaskUpdatePayload';
  error?: Maybe<MutationError>;
  task?: Maybe<Task>;
};

/** The HAUSGOLD ecosystem allows users to define timeframes. This can be helpful to abstract ranges in time for any kind of reason. A common use case could be an calendar application which should track the possible free time windows of a user. */
export type Timeframe = CanHaveDataPoints & GlobalIdentifiable & {
  __typename?: 'Timeframe';
  /** When the timeframe was created (ISO 8601) */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Searches for referenced `data_points` */
  dataPoints?: Maybe<DataPointsResult>;
  /** When the timeframe ends (ISO 8601) */
  endAt?: Maybe<Scalars['DateTime']>;
  /** Global Id of the timeframe (UUID) */
  gid: Scalars['GlobalID'];
  /** Id of the appointment (UUID) */
  id: Scalars['ID'];
  /** External defined additional data */
  metadata?: Maybe<Scalars['JsonBlob']>;
  /** External defined owner gid */
  owner?: Maybe<GlobalIdentifiable>;
  /** External defined references gids */
  references?: Maybe<Array<GlobalIdentifiable>>;
  /** When the timeframe starts (ISO 8601) */
  startAt?: Maybe<Scalars['DateTime']>;
  /** When the timeframe was updated last time (ISO 8601) */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** External defined user id (deprecated) */
  userId?: Maybe<Scalars['ID']>;
};


/** The HAUSGOLD ecosystem allows users to define timeframes. This can be helpful to abstract ranges in time for any kind of reason. A common use case could be an calendar application which should track the possible free time windows of a user. */
export type TimeframeDataPointsArgs = {
  aggregation?: InputMaybe<Aggregation>;
  context: Scalars['String'];
  endAt?: InputMaybe<Scalars['DateTime']>;
  interval?: InputMaybe<Interval>;
  metrics: Array<Scalars['String']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
};

/** Autogenerated return type of TimeframeCreate */
export type TimeframeCreatePayload = {
  __typename?: 'TimeframeCreatePayload';
  error?: Maybe<MutationError>;
  timeframe?: Maybe<Timeframe>;
};

/** Autogenerated return type of TimeframeDelete */
export type TimeframeDeletePayload = {
  __typename?: 'TimeframeDeletePayload';
  error?: Maybe<MutationError>;
  timeframe?: Maybe<Timeframe>;
};

export type TimeframeEdge = {
  __typename?: 'TimeframeEdge';
  /** The `Timeframe` entity that this edge points to. */
  node: Timeframe;
};

export type TimeframeNamespace = {
  __typename?: 'TimeframeNamespace';
  /** Creates an object (or multiple objects) and saves it at the remote application, if validations pass. The resulting object is returned whether the object was saved successfully or not. The attributes parameter can be either a Hash or an Array of Hashes. These Hashes describe the attributes on the objects that are to be created. */
  create: TimeframeCreatePayload;
  /** Deletes the instance at the remote application and freezes this instance to reflect that no changes should be made (since they can't be persisted). To enforce the object's before_destroy and after_destroy callbacks use #destroy. */
  delete: TimeframeDeletePayload;
  /** Updates the attributes of the entity from the passed-in hash and saves the entity. If the object is invalid, the saving will fail and false will be returned. Also aliased as: update_attributes. */
  update: TimeframeUpdatePayload;
};


export type TimeframeNamespaceCreateArgs = {
  endAt: Scalars['DateTime'];
  metadata?: InputMaybe<Scalars['JsonBlob']>;
  ownerGid?: InputMaybe<Scalars['GlobalID']>;
  referenceGids?: InputMaybe<Array<Scalars['GlobalID']>>;
  referenceIds?: InputMaybe<Array<Scalars['String']>>;
  startAt: Scalars['DateTime'];
  userId?: InputMaybe<Scalars['ID']>;
};


export type TimeframeNamespaceDeleteArgs = {
  id: Scalars['TimeframeGlobalIdOrUuid'];
};


export type TimeframeNamespaceUpdateArgs = {
  endAt?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['TimeframeGlobalIdOrUuid'];
  metadata?: InputMaybe<Scalars['JsonBlob']>;
  referenceGids?: InputMaybe<Array<Scalars['GlobalID']>>;
  referenceIds?: InputMaybe<Array<Scalars['String']>>;
  startAt?: InputMaybe<Scalars['DateTime']>;
};

export type TimeframePage = {
  __typename?: 'TimeframePage';
  /** The total count of results for this search. If this is more than `limit` you may repeat the request with a higher `offset` to get the remaining nodes. */
  count: Scalars['Int'];
  /** The connections to the `Timeframe` entities that are included in this page. */
  edges: Array<TimeframeEdge>;
  /** The limit that was used to generate this page. The limit is the upper bound of how many nodes are returned. */
  limit: Scalars['Int'];
  /** The `Timeframe` entities that are included in this page. */
  nodes: Array<Timeframe>;
  /** The offset that was used to generate this page. */
  offset: Scalars['Int'];
};

export type TimeframeSearchParameters = {
  from?: InputMaybe<Scalars['DateTime']>;
  ids?: InputMaybe<Array<Scalars['TimeframeGlobalIdOrUuid']>>;
  ownerGid?: InputMaybe<Array<Scalars['String']>>;
  referenceIds?: InputMaybe<Array<Scalars['String']>>;
  to?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Array<Scalars['String']>>;
};

/** Autogenerated return type of TimeframeUpdate */
export type TimeframeUpdatePayload = {
  __typename?: 'TimeframeUpdatePayload';
  error?: Maybe<MutationError>;
  timeframe?: Maybe<Timeframe>;
};

/** A HAUSGOLD ecosystem user account with the bare details of his identity. It does not contain any personal data such as first- or lastnames. These information is decentralized located at each applications scope. Say you want to retrive the personal data of a broker, then you need to ask the Maklerportal API. The user entities share all the same id (UUID), but differ on the gid (Global Id) according to the specific application. */
export type User = CanHaveDataPoints & GlobalIdentifiable & {
  __typename?: 'User';
  /** When the user completed the account activation (ISO 8601) or null when not */
  activatedAt?: Maybe<Scalars['DateTime']>;
  /** When the user started an account activation (ISO 8601) or null when not */
  activationAt?: Maybe<Scalars['DateTime']>;
  /** Searches for referenced `assets` */
  assets?: Maybe<AssetPage>;
  /** The user avatar */
  avatar?: Maybe<Asset>;
  /** When the user account was confirmed (ISO 8601) or null when not */
  confirmedAt?: Maybe<Scalars['DateTime']>;
  /** When the user was created (ISO 8601) */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Searches for referenced `data_points` */
  dataPoints?: Maybe<DataPointsResult>;
  /** The email of the user */
  email?: Maybe<Scalars['String']>;
  /** Global Id of the user (UUID) */
  gid: Scalars['GlobalID'];
  /** Id of the user (UUID) */
  id: Scalars['ID'];
  /** When the user logged in last time (ISO 8601) */
  lastLoginAt?: Maybe<Scalars['DateTime']>;
  /** When the user account was locked (ISO 8601) or null when not */
  lockedAt?: Maybe<Scalars['DateTime']>;
  /** Searches for referenced `logins` */
  logins?: Maybe<LoginPage>;
  /** The user settings, including personal data */
  preferences: PreferencesSet;
  /** When the user started an account recovery (ISO 8601) or null when not */
  recoveryAt?: Maybe<Scalars['DateTime']>;
  /** The root roles of this user */
  roles?: Maybe<Array<Scalars['String']>>;
  /** The status of the user */
  status?: Maybe<UserStatus>;
  /** Searches for referenced `tasks` */
  tasks?: Maybe<TaskPage>;
  /** When the user was updated last time (ISO 8601) */
  updatedAt?: Maybe<Scalars['DateTime']>;
};


/** A HAUSGOLD ecosystem user account with the bare details of his identity. It does not contain any personal data such as first- or lastnames. These information is decentralized located at each applications scope. Say you want to retrive the personal data of a broker, then you need to ask the Maklerportal API. The user entities share all the same id (UUID), but differ on the gid (Global Id) according to the specific application. */
export type UserAssetsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<UserAssetSearchParameters>;
};


/** A HAUSGOLD ecosystem user account with the bare details of his identity. It does not contain any personal data such as first- or lastnames. These information is decentralized located at each applications scope. Say you want to retrive the personal data of a broker, then you need to ask the Maklerportal API. The user entities share all the same id (UUID), but differ on the gid (Global Id) according to the specific application. */
export type UserDataPointsArgs = {
  aggregation?: InputMaybe<Aggregation>;
  context: Scalars['String'];
  endAt?: InputMaybe<Scalars['DateTime']>;
  interval?: InputMaybe<Interval>;
  metrics: Array<Scalars['String']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
};


/** A HAUSGOLD ecosystem user account with the bare details of his identity. It does not contain any personal data such as first- or lastnames. These information is decentralized located at each applications scope. Say you want to retrive the personal data of a broker, then you need to ask the Maklerportal API. The user entities share all the same id (UUID), but differ on the gid (Global Id) according to the specific application. */
export type UserLoginsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<UserLoginSearchParameters>;
};


/** A HAUSGOLD ecosystem user account with the bare details of his identity. It does not contain any personal data such as first- or lastnames. These information is decentralized located at each applications scope. Say you want to retrive the personal data of a broker, then you need to ask the Maklerportal API. The user entities share all the same id (UUID), but differ on the gid (Global Id) according to the specific application. */
export type UserTasksArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<UserTaskSearchParameters>;
};

/** Autogenerated return type of UserActivate */
export type UserActivatePayload = {
  __typename?: 'UserActivatePayload';
  error?: Maybe<MutationError>;
  user?: Maybe<User>;
};

/** Autogenerated return type of UserActivated */
export type UserActivatedPayload = {
  __typename?: 'UserActivatedPayload';
  error?: Maybe<MutationError>;
  user?: Maybe<User>;
};

export type UserAssetSearchParameters = {
  category?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['AssetGlobalIdOrUuid']>>;
  text?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UserConfirm */
export type UserConfirmPayload = {
  __typename?: 'UserConfirmPayload';
  error?: Maybe<MutationError>;
  user?: Maybe<User>;
};

/** Autogenerated return type of UserCreate */
export type UserCreatePayload = {
  __typename?: 'UserCreatePayload';
  error?: Maybe<MutationError>;
  user?: Maybe<User>;
};

/** Autogenerated return type of UserDelete */
export type UserDeletePayload = {
  __typename?: 'UserDeletePayload';
  error?: Maybe<MutationError>;
  user?: Maybe<User>;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  /** The `User` entity that this edge points to. */
  node: User;
};

/** Autogenerated return type of UserLock */
export type UserLockPayload = {
  __typename?: 'UserLockPayload';
  error?: Maybe<MutationError>;
  user?: Maybe<User>;
};

export type UserLoginSearchParameters = {
  expired?: InputMaybe<Scalars['Boolean']>;
  failed?: InputMaybe<Scalars['Boolean']>;
  fingerprint?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

export type UserNamespace = {
  __typename?: 'UserNamespace';
  /** Start the account activation for the current user instance. No additional options required for this, but you can pass in a `metadata` argument as hash with additional workflow data. */
  activate: UserActivatePayload;
  /** Finish the account activation for the current user instance. You need to pass in the `token` and `password` as additional parameters. This method returns a valid `Hausgold::Jwt` instance which can directly be used to operated as the activated user. This is especially handy on frontend applications. */
  activated: UserActivatedPayload;
  /** Confirm the current user instance. No additional options required for this, but you can pass in a `metadata` argument as hash with additional workflow data. */
  confirm: UserConfirmPayload;
  /** Creates an object (or multiple objects) and saves it at the remote application, if validations pass. The resulting object is returned whether the object was saved successfully or not. The attributes parameter can be either a Hash or an Array of Hashes. These Hashes describe the attributes on the objects that are to be created. */
  create: UserCreatePayload;
  /** Deletes the instance at the remote application and freezes this instance to reflect that no changes should be made (since they can't be persisted). To enforce the object's before_destroy and after_destroy callbacks use #destroy. */
  delete: UserDeletePayload;
  /** Lock the current user instance. No additional options required for this, but you can pass in a `metadata` argument as hash with additional workflow data. */
  lock: UserLockPayload;
  /** Start the account recovery for the current user instance. No additional options required for this, but you can pass in a `metadata` argument as hash with additional workflow data. */
  recover: UserRecoverPayload;
  /** Finish the account recovery for the current user instance. You need to pass in the `token` and `password` as additional parameters. */
  recovered: UserRecoveredPayload;
  /** Revoke the confirmation of the current user instance. No additional options required for this. */
  unconfirm: UserUnconfirmPayload;
  /** Unlock the current user instance. You need to pass in the `token` and `password` as additional parameters. */
  unlock: UserUnlockPayload;
  /** Updates the attributes of the entity from the passed-in hash and saves the entity. If the object is invalid, the saving will fail and false will be returned. Also aliased as: update_attributes. */
  update: UserUpdatePayload;
};


export type UserNamespaceActivateArgs = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UserGlobalIdOrUuid']>;
};


export type UserNamespaceActivatedArgs = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UserGlobalIdOrUuid']>;
  password?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
};


export type UserNamespaceConfirmArgs = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UserGlobalIdOrUuid']>;
};


export type UserNamespaceCreateArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  status?: InputMaybe<UserStatus>;
};


export type UserNamespaceDeleteArgs = {
  id: Scalars['UserGlobalIdOrUuid'];
};


export type UserNamespaceLockArgs = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UserGlobalIdOrUuid']>;
};


export type UserNamespaceRecoverArgs = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UserGlobalIdOrUuid']>;
};


export type UserNamespaceRecoveredArgs = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UserGlobalIdOrUuid']>;
  password: Scalars['String'];
  token: Scalars['String'];
};


export type UserNamespaceUnconfirmArgs = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UserGlobalIdOrUuid']>;
};


export type UserNamespaceUnlockArgs = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UserGlobalIdOrUuid']>;
  password: Scalars['String'];
  token: Scalars['String'];
};


export type UserNamespaceUpdateArgs = {
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['UserGlobalIdOrUuid'];
  password?: InputMaybe<Scalars['String']>;
  passwordConfirmation?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
};

export type UserPage = {
  __typename?: 'UserPage';
  /** The total count of results for this search. If this is more than `limit` you may repeat the request with a higher `offset` to get the remaining nodes. */
  count: Scalars['Int'];
  /** The connections to the `User` entities that are included in this page. */
  edges: Array<UserEdge>;
  /** The limit that was used to generate this page. The limit is the upper bound of how many nodes are returned. */
  limit: Scalars['Int'];
  /** The `User` entities that are included in this page. */
  nodes: Array<User>;
  /** The offset that was used to generate this page. */
  offset: Scalars['Int'];
};

/** Autogenerated return type of UserRecover */
export type UserRecoverPayload = {
  __typename?: 'UserRecoverPayload';
  error?: Maybe<MutationError>;
  user?: Maybe<User>;
};

/** Autogenerated return type of UserRecovered */
export type UserRecoveredPayload = {
  __typename?: 'UserRecoveredPayload';
  error?: Maybe<MutationError>;
  user?: Maybe<User>;
};

export type UserSearchParameters = {
  email?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['UserGlobalIdOrUuid']>>;
  text?: InputMaybe<Scalars['String']>;
};

/** The status of the user */
export enum UserStatus {
  Active = 'active',
  Inactive = 'inactive',
  Locked = 'locked'
}

export type UserTaskSearchParameters = {
  from?: InputMaybe<Scalars['DateTime']>;
  ids?: InputMaybe<Array<Scalars['TaskGlobalIdOrUuid']>>;
  ownerGid?: InputMaybe<Array<Scalars['String']>>;
  referenceIds?: InputMaybe<Array<Scalars['String']>>;
  status?: InputMaybe<Array<Scalars['String']>>;
  text?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

/** Autogenerated return type of UserUnconfirm */
export type UserUnconfirmPayload = {
  __typename?: 'UserUnconfirmPayload';
  error?: Maybe<MutationError>;
  user?: Maybe<User>;
};

/** Autogenerated return type of UserUnlock */
export type UserUnlockPayload = {
  __typename?: 'UserUnlockPayload';
  error?: Maybe<MutationError>;
  user?: Maybe<User>;
};

/** Autogenerated return type of UserUpdate */
export type UserUpdatePayload = {
  __typename?: 'UserUpdatePayload';
  error?: Maybe<MutationError>;
  user?: Maybe<User>;
};

export type MutationErrorFragment = (
  { __typename: 'MutationError' }
  & Pick<MutationError, 'type' | 'actionDetails' | 'message'>
);

export type LeadFragment = (
  { __typename: 'Lead' }
  & Pick<Lead, 'id' | 'gid' | 'identifier' | 'createdAt' | 'processedAt' | 'accessPermittedAt' | 'activatedAt' | 'closedAt' | 'lockedAt' | 'soldAt' | 'dueAt' | 'labels' | 'statePropertiesUpdatedAt' | 'statePropertiesUpdateRequestedAt' | 'statePropertiesUpdateSkippedUntil' | 'contractProperties' | 'currentMandatoryTaskName' | 'processedWith' | 'processedComment'>
  & { leadManager?: Maybe<(
    { __typename: 'LeadManager' }
    & Pick<LeadManager, 'id' | 'acceptableLimit'>
  )>, objectProperties?: Maybe<(
    { __typename?: 'LeadObjectProperties' }
    & Pick<LeadObjectProperties, 'isForSale'>
  )>, property?: Maybe<(
    { __typename: 'Property' }
    & Pick<Property, 'id'>
    & { owner?: Maybe<(
      { __typename: 'User' }
      & Pick<User, 'id'>
      & { preferences: (
        { __typename: 'PreferencesSet' }
        & Pick<PreferencesSet, 'id' | 'firstName' | 'lastName'>
      ) }
    )>, objectDetails?: Maybe<(
      { __typename: 'PropertyObjectDetails' }
      & Pick<PropertyObjectDetails, 'propertyType' | 'propertySubtype' | 'city' | 'postalCode' | 'street' | 'streetAddition' | 'saleHorizon' | 'latitude' | 'longitude'>
    )>, valuation?: Maybe<(
      { __typename: 'PropertyValuation' }
      & Pick<PropertyValuation, 'value'>
    )> }
  )>, supervisor?: Maybe<(
    { __typename: 'Broker' }
    & Pick<Broker, 'id'>
    & { preferences: (
      { __typename: 'PreferencesSet' }
      & Pick<PreferencesSet, 'id' | 'firstName' | 'lastName'>
    ) }
  )> }
);

export type TaskFragment = (
  { __typename: 'Task' }
  & Pick<Task, 'id' | 'title' | 'description' | 'type' | 'status' | 'updatedAt' | 'createdAt' | 'editable' | 'dueDate' | 'result'>
  & { references?: Maybe<Array<(
    { __typename?: 'AccountBalancePackage' }
    & Pick<AccountBalancePackage, 'gid' | 'id'>
  ) | (
    { __typename?: 'AccountBalanceTransaction' }
    & Pick<AccountBalanceTransaction, 'gid' | 'id'>
  ) | (
    { __typename?: 'Activity' }
    & Pick<Activity, 'gid' | 'id'>
  ) | (
    { __typename?: 'Appointment' }
    & Pick<Appointment, 'gid' | 'id'>
  ) | (
    { __typename?: 'Asset' }
    & Pick<Asset, 'gid' | 'id'>
  ) | (
    { __typename?: 'Broker' }
    & Pick<Broker, 'gid' | 'id'>
  ) | (
    { __typename?: 'Customer' }
    & Pick<Customer, 'gid' | 'id'>
  ) | (
    { __typename?: 'DataPoint' }
    & Pick<DataPoint, 'gid' | 'id'>
  ) | (
    { __typename?: 'FinancingLead' }
    & Pick<FinancingLead, 'gid' | 'id'>
  ) | (
    { __typename?: 'InsightsQuery' }
    & Pick<InsightsQuery, 'gid' | 'id'>
  ) | (
    { __typename?: 'InsuranceLead' }
    & Pick<InsuranceLead, 'gid' | 'id'>
  ) | (
    { __typename?: 'InvoiceAddress' }
    & Pick<InvoiceAddress, 'gid' | 'id'>
  ) | (
    { __typename?: 'Lead' }
    & Pick<Lead, 'gid' | 'id'>
  ) | (
    { __typename?: 'LeadManager' }
    & Pick<LeadManager, 'gid' | 'id'>
  ) | (
    { __typename?: 'NotificationToken' }
    & Pick<NotificationToken, 'gid' | 'id'>
  ) | (
    { __typename?: 'Office' }
    & Pick<Office, 'gid' | 'id'>
  ) | (
    { __typename?: 'Organization' }
    & Pick<Organization, 'gid' | 'id'>
  ) | (
    { __typename?: 'Payment' }
    & Pick<Payment, 'gid' | 'id'>
  ) | (
    { __typename?: 'PaymentMandate' }
    & Pick<PaymentMandate, 'gid' | 'id'>
  ) | (
    { __typename?: 'Pdf' }
    & Pick<Pdf, 'gid' | 'id'>
  ) | (
    { __typename?: 'Policy' }
    & Pick<Policy, 'gid' | 'id'>
  ) | (
    { __typename?: 'PreferencesSet' }
    & Pick<PreferencesSet, 'gid' | 'id'>
  ) | (
    { __typename?: 'Property' }
    & Pick<Property, 'gid' | 'id'>
  ) | (
    { __typename?: 'Review' }
    & Pick<Review, 'gid' | 'id'>
  ) | (
    { __typename?: 'Role' }
    & Pick<Role, 'gid' | 'id'>
  ) | (
    { __typename?: 'SearchProfile' }
    & Pick<SearchProfile, 'gid' | 'id'>
  ) | (
    { __typename?: 'Task' }
    & Pick<Task, 'gid' | 'id'>
  ) | (
    { __typename?: 'Timeframe' }
    & Pick<Timeframe, 'gid' | 'id'>
  ) | (
    { __typename?: 'User' }
    & Pick<User, 'gid' | 'id'>
  )>>, owner?: Maybe<(
    { __typename?: 'AccountBalancePackage' }
    & Pick<AccountBalancePackage, 'id' | 'gid'>
  ) | (
    { __typename?: 'AccountBalanceTransaction' }
    & Pick<AccountBalanceTransaction, 'id' | 'gid'>
  ) | (
    { __typename?: 'Activity' }
    & Pick<Activity, 'id' | 'gid'>
  ) | (
    { __typename?: 'Appointment' }
    & Pick<Appointment, 'id' | 'gid'>
  ) | (
    { __typename?: 'Asset' }
    & Pick<Asset, 'id' | 'gid'>
  ) | (
    { __typename?: 'Broker' }
    & Pick<Broker, 'id' | 'gid'>
  ) | (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id' | 'gid'>
  ) | (
    { __typename?: 'DataPoint' }
    & Pick<DataPoint, 'id' | 'gid'>
  ) | (
    { __typename?: 'FinancingLead' }
    & Pick<FinancingLead, 'id' | 'gid'>
  ) | (
    { __typename?: 'InsightsQuery' }
    & Pick<InsightsQuery, 'id' | 'gid'>
  ) | (
    { __typename?: 'InsuranceLead' }
    & Pick<InsuranceLead, 'id' | 'gid'>
  ) | (
    { __typename?: 'InvoiceAddress' }
    & Pick<InvoiceAddress, 'id' | 'gid'>
  ) | (
    { __typename?: 'Lead' }
    & Pick<Lead, 'id' | 'gid'>
  ) | (
    { __typename?: 'LeadManager' }
    & Pick<LeadManager, 'id' | 'gid'>
  ) | (
    { __typename?: 'NotificationToken' }
    & Pick<NotificationToken, 'id' | 'gid'>
  ) | (
    { __typename?: 'Office' }
    & Pick<Office, 'id' | 'gid'>
  ) | (
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'gid'>
  ) | (
    { __typename?: 'Payment' }
    & Pick<Payment, 'id' | 'gid'>
  ) | (
    { __typename?: 'PaymentMandate' }
    & Pick<PaymentMandate, 'id' | 'gid'>
  ) | (
    { __typename?: 'Pdf' }
    & Pick<Pdf, 'id' | 'gid'>
  ) | (
    { __typename?: 'Policy' }
    & Pick<Policy, 'id' | 'gid'>
  ) | (
    { __typename?: 'PreferencesSet' }
    & Pick<PreferencesSet, 'id' | 'gid'>
  ) | (
    { __typename?: 'Property' }
    & Pick<Property, 'id' | 'gid'>
  ) | (
    { __typename?: 'Review' }
    & Pick<Review, 'id' | 'gid'>
  ) | (
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'gid'>
  ) | (
    { __typename?: 'SearchProfile' }
    & Pick<SearchProfile, 'id' | 'gid'>
  ) | (
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'gid'>
  ) | (
    { __typename?: 'Timeframe' }
    & Pick<Timeframe, 'id' | 'gid'>
  ) | (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'gid'>
  )> }
);

export type LeadWithDetailsAndPropertyAndStateFragment = (
  { __typename: 'Lead' }
  & Pick<Lead, 'closedReason' | 'id' | 'gid' | 'identifier' | 'createdAt' | 'processedAt' | 'accessPermittedAt' | 'activatedAt' | 'closedAt' | 'lockedAt' | 'soldAt' | 'dueAt' | 'labels' | 'statePropertiesUpdatedAt' | 'statePropertiesUpdateRequestedAt' | 'statePropertiesUpdateSkippedUntil' | 'contractProperties' | 'currentMandatoryTaskName' | 'processedWith' | 'processedComment'>
  & { property?: Maybe<(
    { __typename: 'Property' }
    & Pick<Property, 'dossierLink' | 'id'>
    & { objectDetails?: Maybe<(
      { __typename: 'PropertyObjectDetails' }
      & Pick<PropertyObjectDetails, 'saleHorizon' | 'condition' | 'landSize' | 'livingSpace' | 'amountRooms' | 'amountBathrooms' | 'amountParkingSpaces' | 'saleReason' | 'ownershipStructure' | 'freeFrom' | 'amountBalcony' | 'amountTerrace' | 'listed' | 'equipmentText' | 'descriptionText' | 'locationText' | 'additionalInfoText' | 'yearOfConstruction' | 'construction' | 'exploitation' | 'yearOfModernization' | 'rented' | 'inhabited' | 'amountUnits' | 'netRent' | 'propertyType' | 'propertySubtype' | 'city' | 'postalCode' | 'street' | 'streetAddition' | 'latitude' | 'longitude'>
      & { equipment?: Maybe<(
        { __typename?: 'PropertyEquipment' }
        & Pick<PropertyEquipment, 'attic' | 'cellar' | 'lift' | 'kitchen' | 'handicappedAccessible' | 'seniorFriendly' | 'garden' | 'guestToilet' | 'sauna' | 'parkingSpaceType'>
      )> }
    )>, owner?: Maybe<(
      { __typename: 'User' }
      & Pick<User, 'id'>
      & { preferences: (
        { __typename: 'PreferencesSet' }
        & Pick<PreferencesSet, 'id' | 'firstName' | 'lastName'>
      ) }
    )>, valuation?: Maybe<(
      { __typename: 'PropertyValuation' }
      & Pick<PropertyValuation, 'value'>
    )> }
  )>, objectProperties?: Maybe<(
    { __typename?: 'LeadObjectProperties' }
    & Pick<LeadObjectProperties, 'additionalInfo' | 'evaluationReason' | 'isForSale'>
  )>, contacts?: Maybe<Array<(
    { __typename: 'LeadContact' }
    & Pick<LeadContact, 'firstName' | 'lastName' | 'emails' | 'gender' | 'phoneNumbers' | 'reachability'>
    & { customer?: Maybe<(
      { __typename: 'Customer' }
      & Pick<Customer, 'id'>
      & { avatar?: Maybe<(
        { __typename?: 'Asset' }
        & Pick<Asset, 'fileUrl'>
      )> }
    )> }
  )>>, brokers?: Maybe<Array<(
    { __typename: 'Broker' }
    & Pick<Broker, 'id'>
  )>>, assets?: Maybe<(
    { __typename?: 'AssetPage' }
    & { nodes: Array<(
      { __typename: 'Asset' }
      & Pick<Asset, 'gid' | 'id' | 'filename' | 'fileUrl' | 'contentType' | 'title' | 'metadata'>
    )> }
  )>, stateProperties?: Maybe<(
    { __typename: 'LeadStateProperties' }
    & Pick<LeadStateProperties, 'currency' | 'customerAppointmentDate' | 'performEvaluationPrice' | 'performEvaluationDate' | 'performEvaluationDateTookPlace' | 'evaluationGid' | 'shareEvaluation' | 'nextCustomerContactDate' | 'acquisitionCompleted' | 'updateAlternativeText' | 'brokerContractCommissionUnit' | 'brokerContractCommission' | 'brokerContractBuyerCommission' | 'brokerContractBuyerCommissionUnit' | 'brokerContractDate' | 'brokerContractPrice' | 'brokerContractType' | 'expectedSalePrice' | 'brokerContractTermDate' | 'expectedSaleNotaryDate' | 'objectSaleBuyerCommission' | 'objectSaleBuyerCommissionTaxation' | 'objectSaleBuyerCommissionUnit' | 'objectSaleCommission' | 'objectSaleCommissionTaxation' | 'objectSaleCommissionUnit' | 'objectSaleNotaryDate' | 'objectSalePrice' | 'marketingCompleted' | 'saleCompleted' | 'invoiceSent'>
  )>, tasks?: Maybe<(
    { __typename: 'TaskPage' }
    & { nodes: Array<(
      { __typename: 'Task' }
      & Pick<Task, 'id' | 'title' | 'description' | 'type' | 'status' | 'updatedAt' | 'createdAt' | 'editable' | 'dueDate' | 'result'>
      & { references?: Maybe<Array<(
        { __typename?: 'AccountBalancePackage' }
        & Pick<AccountBalancePackage, 'gid' | 'id'>
      ) | (
        { __typename?: 'AccountBalanceTransaction' }
        & Pick<AccountBalanceTransaction, 'gid' | 'id'>
      ) | (
        { __typename?: 'Activity' }
        & Pick<Activity, 'gid' | 'id'>
      ) | (
        { __typename?: 'Appointment' }
        & Pick<Appointment, 'gid' | 'id'>
      ) | (
        { __typename?: 'Asset' }
        & Pick<Asset, 'gid' | 'id'>
      ) | (
        { __typename?: 'Broker' }
        & Pick<Broker, 'gid' | 'id'>
      ) | (
        { __typename?: 'Customer' }
        & Pick<Customer, 'gid' | 'id'>
      ) | (
        { __typename?: 'DataPoint' }
        & Pick<DataPoint, 'gid' | 'id'>
      ) | (
        { __typename?: 'FinancingLead' }
        & Pick<FinancingLead, 'gid' | 'id'>
      ) | (
        { __typename?: 'InsightsQuery' }
        & Pick<InsightsQuery, 'gid' | 'id'>
      ) | (
        { __typename?: 'InsuranceLead' }
        & Pick<InsuranceLead, 'gid' | 'id'>
      ) | (
        { __typename?: 'InvoiceAddress' }
        & Pick<InvoiceAddress, 'gid' | 'id'>
      ) | (
        { __typename?: 'Lead' }
        & Pick<Lead, 'gid' | 'id'>
      ) | (
        { __typename?: 'LeadManager' }
        & Pick<LeadManager, 'gid' | 'id'>
      ) | (
        { __typename?: 'NotificationToken' }
        & Pick<NotificationToken, 'gid' | 'id'>
      ) | (
        { __typename?: 'Office' }
        & Pick<Office, 'gid' | 'id'>
      ) | (
        { __typename?: 'Organization' }
        & Pick<Organization, 'gid' | 'id'>
      ) | (
        { __typename?: 'Payment' }
        & Pick<Payment, 'gid' | 'id'>
      ) | (
        { __typename?: 'PaymentMandate' }
        & Pick<PaymentMandate, 'gid' | 'id'>
      ) | (
        { __typename?: 'Pdf' }
        & Pick<Pdf, 'gid' | 'id'>
      ) | (
        { __typename?: 'Policy' }
        & Pick<Policy, 'gid' | 'id'>
      ) | (
        { __typename?: 'PreferencesSet' }
        & Pick<PreferencesSet, 'gid' | 'id'>
      ) | (
        { __typename?: 'Property' }
        & Pick<Property, 'gid' | 'id'>
      ) | (
        { __typename?: 'Review' }
        & Pick<Review, 'gid' | 'id'>
      ) | (
        { __typename?: 'Role' }
        & Pick<Role, 'gid' | 'id'>
      ) | (
        { __typename?: 'SearchProfile' }
        & Pick<SearchProfile, 'gid' | 'id'>
      ) | (
        { __typename?: 'Task' }
        & Pick<Task, 'gid' | 'id'>
      ) | (
        { __typename?: 'Timeframe' }
        & Pick<Timeframe, 'gid' | 'id'>
      ) | (
        { __typename?: 'User' }
        & Pick<User, 'gid' | 'id'>
      )>>, owner?: Maybe<(
        { __typename?: 'AccountBalancePackage' }
        & Pick<AccountBalancePackage, 'id' | 'gid'>
      ) | (
        { __typename?: 'AccountBalanceTransaction' }
        & Pick<AccountBalanceTransaction, 'id' | 'gid'>
      ) | (
        { __typename?: 'Activity' }
        & Pick<Activity, 'id' | 'gid'>
      ) | (
        { __typename?: 'Appointment' }
        & Pick<Appointment, 'id' | 'gid'>
      ) | (
        { __typename?: 'Asset' }
        & Pick<Asset, 'id' | 'gid'>
      ) | (
        { __typename?: 'Broker' }
        & Pick<Broker, 'id' | 'gid'>
      ) | (
        { __typename?: 'Customer' }
        & Pick<Customer, 'id' | 'gid'>
      ) | (
        { __typename?: 'DataPoint' }
        & Pick<DataPoint, 'id' | 'gid'>
      ) | (
        { __typename?: 'FinancingLead' }
        & Pick<FinancingLead, 'id' | 'gid'>
      ) | (
        { __typename?: 'InsightsQuery' }
        & Pick<InsightsQuery, 'id' | 'gid'>
      ) | (
        { __typename?: 'InsuranceLead' }
        & Pick<InsuranceLead, 'id' | 'gid'>
      ) | (
        { __typename?: 'InvoiceAddress' }
        & Pick<InvoiceAddress, 'id' | 'gid'>
      ) | (
        { __typename?: 'Lead' }
        & Pick<Lead, 'id' | 'gid'>
      ) | (
        { __typename?: 'LeadManager' }
        & Pick<LeadManager, 'id' | 'gid'>
      ) | (
        { __typename?: 'NotificationToken' }
        & Pick<NotificationToken, 'id' | 'gid'>
      ) | (
        { __typename?: 'Office' }
        & Pick<Office, 'id' | 'gid'>
      ) | (
        { __typename?: 'Organization' }
        & Pick<Organization, 'id' | 'gid'>
      ) | (
        { __typename?: 'Payment' }
        & Pick<Payment, 'id' | 'gid'>
      ) | (
        { __typename?: 'PaymentMandate' }
        & Pick<PaymentMandate, 'id' | 'gid'>
      ) | (
        { __typename?: 'Pdf' }
        & Pick<Pdf, 'id' | 'gid'>
      ) | (
        { __typename?: 'Policy' }
        & Pick<Policy, 'id' | 'gid'>
      ) | (
        { __typename?: 'PreferencesSet' }
        & Pick<PreferencesSet, 'id' | 'gid'>
      ) | (
        { __typename?: 'Property' }
        & Pick<Property, 'id' | 'gid'>
      ) | (
        { __typename?: 'Review' }
        & Pick<Review, 'id' | 'gid'>
      ) | (
        { __typename?: 'Role' }
        & Pick<Role, 'id' | 'gid'>
      ) | (
        { __typename?: 'SearchProfile' }
        & Pick<SearchProfile, 'id' | 'gid'>
      ) | (
        { __typename?: 'Task' }
        & Pick<Task, 'id' | 'gid'>
      ) | (
        { __typename?: 'Timeframe' }
        & Pick<Timeframe, 'id' | 'gid'>
      ) | (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'gid'>
      )> }
    )> }
  )>, office?: Maybe<(
    { __typename: 'Office' }
    & Pick<Office, 'id' | 'disabledPaymentMethods'>
  )>, leadManager?: Maybe<(
    { __typename: 'LeadManager' }
    & Pick<LeadManager, 'id' | 'acceptableLimit'>
  )>, supervisor?: Maybe<(
    { __typename: 'Broker' }
    & Pick<Broker, 'id'>
    & { preferences: (
      { __typename: 'PreferencesSet' }
      & Pick<PreferencesSet, 'id' | 'firstName' | 'lastName'>
    ) }
  )> }
);

export type AssetFragment = (
  { __typename: 'Asset' }
  & Pick<Asset, 'gid' | 'id' | 'filename' | 'fileUrl' | 'contentType' | 'title' | 'metadata'>
);

export type PaymentFragment = (
  { __typename?: 'Payment' }
  & Pick<Payment, 'id' | 'currency' | 'paymentProvider' | 'paymentProviderType' | 'state' | 'settlementStartedAt' | 'settledAt'>
  & { owner?: Maybe<(
    { __typename?: 'AccountBalancePackage' }
    & Pick<AccountBalancePackage, 'id'>
  ) | (
    { __typename?: 'AccountBalanceTransaction' }
    & Pick<AccountBalanceTransaction, 'id'>
  ) | (
    { __typename?: 'Activity' }
    & Pick<Activity, 'id'>
  ) | (
    { __typename?: 'Appointment' }
    & Pick<Appointment, 'id'>
  ) | (
    { __typename?: 'Asset' }
    & Pick<Asset, 'id'>
  ) | (
    { __typename?: 'Broker' }
    & Pick<Broker, 'id'>
  ) | (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id'>
  ) | (
    { __typename?: 'DataPoint' }
    & Pick<DataPoint, 'id'>
  ) | (
    { __typename?: 'FinancingLead' }
    & Pick<FinancingLead, 'id'>
  ) | (
    { __typename?: 'InsightsQuery' }
    & Pick<InsightsQuery, 'id'>
  ) | (
    { __typename?: 'InsuranceLead' }
    & Pick<InsuranceLead, 'id'>
  ) | (
    { __typename?: 'InvoiceAddress' }
    & Pick<InvoiceAddress, 'id'>
  ) | (
    { __typename?: 'Lead' }
    & Pick<Lead, 'id'>
  ) | (
    { __typename?: 'LeadManager' }
    & Pick<LeadManager, 'id'>
  ) | (
    { __typename?: 'NotificationToken' }
    & Pick<NotificationToken, 'id'>
  ) | (
    { __typename?: 'Office' }
    & Pick<Office, 'id'>
  ) | (
    { __typename?: 'Organization' }
    & Pick<Organization, 'id'>
  ) | (
    { __typename?: 'Payment' }
    & Pick<Payment, 'id'>
  ) | (
    { __typename?: 'PaymentMandate' }
    & Pick<PaymentMandate, 'id'>
  ) | (
    { __typename?: 'Pdf' }
    & Pick<Pdf, 'id'>
  ) | (
    { __typename?: 'Policy' }
    & Pick<Policy, 'id'>
  ) | (
    { __typename?: 'PreferencesSet' }
    & Pick<PreferencesSet, 'id'>
  ) | (
    { __typename?: 'Property' }
    & Pick<Property, 'id'>
  ) | (
    { __typename?: 'Review' }
    & Pick<Review, 'id'>
  ) | (
    { __typename?: 'Role' }
    & Pick<Role, 'id'>
  ) | (
    { __typename?: 'SearchProfile' }
    & Pick<SearchProfile, 'id'>
  ) | (
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
  ) | (
    { __typename?: 'Timeframe' }
    & Pick<Timeframe, 'id'>
  ) | (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )>, items?: Maybe<Array<(
    { __typename?: 'PaymentItem' }
    & Pick<PaymentItem, 'name' | 'netAmount' | 'grossAmount' | 'taxRate'>
    & { sellable?: Maybe<(
      { __typename?: 'AccountBalancePackage' }
      & Pick<AccountBalancePackage, 'id'>
    ) | (
      { __typename?: 'AccountBalanceTransaction' }
      & Pick<AccountBalanceTransaction, 'id'>
    ) | (
      { __typename?: 'Activity' }
      & Pick<Activity, 'id'>
    ) | (
      { __typename?: 'Appointment' }
      & Pick<Appointment, 'id'>
    ) | (
      { __typename?: 'Asset' }
      & Pick<Asset, 'id'>
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
    ) | (
      { __typename?: 'Customer' }
      & Pick<Customer, 'id'>
    ) | (
      { __typename?: 'DataPoint' }
      & Pick<DataPoint, 'id'>
    ) | (
      { __typename?: 'FinancingLead' }
      & Pick<FinancingLead, 'id'>
    ) | (
      { __typename?: 'InsightsQuery' }
      & Pick<InsightsQuery, 'id'>
    ) | (
      { __typename?: 'InsuranceLead' }
      & Pick<InsuranceLead, 'id'>
    ) | (
      { __typename?: 'InvoiceAddress' }
      & Pick<InvoiceAddress, 'id'>
    ) | (
      { __typename?: 'Lead' }
      & Pick<Lead, 'id'>
    ) | (
      { __typename?: 'LeadManager' }
      & Pick<LeadManager, 'id'>
    ) | (
      { __typename?: 'NotificationToken' }
      & Pick<NotificationToken, 'id'>
    ) | (
      { __typename?: 'Office' }
      & Pick<Office, 'id'>
    ) | (
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
    ) | (
      { __typename?: 'Payment' }
      & Pick<Payment, 'id'>
    ) | (
      { __typename?: 'PaymentMandate' }
      & Pick<PaymentMandate, 'id'>
    ) | (
      { __typename?: 'Pdf' }
      & Pick<Pdf, 'id'>
    ) | (
      { __typename?: 'Policy' }
      & Pick<Policy, 'id'>
    ) | (
      { __typename?: 'PreferencesSet' }
      & Pick<PreferencesSet, 'id'>
    ) | (
      { __typename?: 'Property' }
      & Pick<Property, 'id'>
    ) | (
      { __typename?: 'Review' }
      & Pick<Review, 'id'>
    ) | (
      { __typename?: 'Role' }
      & Pick<Role, 'id'>
    ) | (
      { __typename?: 'SearchProfile' }
      & Pick<SearchProfile, 'id'>
    ) | (
      { __typename?: 'Task' }
      & Pick<Task, 'id'>
    ) | (
      { __typename?: 'Timeframe' }
      & Pick<Timeframe, 'id'>
    ) | (
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )> }
  )>> }
);

export type RejectLeadMutationVariables = Exact<{
  id: Scalars['LeadGlobalIdOrUuid'];
  userId: Scalars['ID'];
  comment?: InputMaybe<Scalars['String']>;
}>;


export type RejectLeadMutation = (
  { __typename?: 'Mutations' }
  & { lead: (
    { __typename?: 'LeadNamespace' }
    & { reject: (
      { __typename?: 'LeadRejectPayload' }
      & { lead?: Maybe<(
        { __typename: 'Lead' }
        & Pick<Lead, 'closedReason' | 'id' | 'gid' | 'identifier' | 'createdAt' | 'processedAt' | 'accessPermittedAt' | 'activatedAt' | 'closedAt' | 'lockedAt' | 'soldAt' | 'dueAt' | 'labels' | 'statePropertiesUpdatedAt' | 'statePropertiesUpdateRequestedAt' | 'statePropertiesUpdateSkippedUntil' | 'contractProperties' | 'currentMandatoryTaskName' | 'processedWith' | 'processedComment'>
        & { property?: Maybe<(
          { __typename: 'Property' }
          & Pick<Property, 'dossierLink' | 'id'>
          & { objectDetails?: Maybe<(
            { __typename: 'PropertyObjectDetails' }
            & Pick<PropertyObjectDetails, 'saleHorizon' | 'condition' | 'landSize' | 'livingSpace' | 'amountRooms' | 'amountBathrooms' | 'amountParkingSpaces' | 'saleReason' | 'ownershipStructure' | 'freeFrom' | 'amountBalcony' | 'amountTerrace' | 'listed' | 'equipmentText' | 'descriptionText' | 'locationText' | 'additionalInfoText' | 'yearOfConstruction' | 'construction' | 'exploitation' | 'yearOfModernization' | 'rented' | 'inhabited' | 'amountUnits' | 'netRent' | 'propertyType' | 'propertySubtype' | 'city' | 'postalCode' | 'street' | 'streetAddition' | 'latitude' | 'longitude'>
            & { equipment?: Maybe<(
              { __typename?: 'PropertyEquipment' }
              & Pick<PropertyEquipment, 'attic' | 'cellar' | 'lift' | 'kitchen' | 'handicappedAccessible' | 'seniorFriendly' | 'garden' | 'guestToilet' | 'sauna' | 'parkingSpaceType'>
            )> }
          )>, owner?: Maybe<(
            { __typename: 'User' }
            & Pick<User, 'id'>
            & { preferences: (
              { __typename: 'PreferencesSet' }
              & Pick<PreferencesSet, 'id' | 'firstName' | 'lastName'>
            ) }
          )>, valuation?: Maybe<(
            { __typename: 'PropertyValuation' }
            & Pick<PropertyValuation, 'value'>
          )> }
        )>, objectProperties?: Maybe<(
          { __typename?: 'LeadObjectProperties' }
          & Pick<LeadObjectProperties, 'additionalInfo' | 'evaluationReason' | 'isForSale'>
        )>, contacts?: Maybe<Array<(
          { __typename: 'LeadContact' }
          & Pick<LeadContact, 'firstName' | 'lastName' | 'emails' | 'gender' | 'phoneNumbers' | 'reachability'>
          & { customer?: Maybe<(
            { __typename: 'Customer' }
            & Pick<Customer, 'id'>
            & { avatar?: Maybe<(
              { __typename?: 'Asset' }
              & Pick<Asset, 'fileUrl'>
            )> }
          )> }
        )>>, brokers?: Maybe<Array<(
          { __typename: 'Broker' }
          & Pick<Broker, 'id'>
        )>>, assets?: Maybe<(
          { __typename?: 'AssetPage' }
          & { nodes: Array<(
            { __typename: 'Asset' }
            & Pick<Asset, 'gid' | 'id' | 'filename' | 'fileUrl' | 'contentType' | 'title' | 'metadata'>
          )> }
        )>, stateProperties?: Maybe<(
          { __typename: 'LeadStateProperties' }
          & Pick<LeadStateProperties, 'currency' | 'customerAppointmentDate' | 'performEvaluationPrice' | 'performEvaluationDate' | 'performEvaluationDateTookPlace' | 'evaluationGid' | 'shareEvaluation' | 'nextCustomerContactDate' | 'acquisitionCompleted' | 'updateAlternativeText' | 'brokerContractCommissionUnit' | 'brokerContractCommission' | 'brokerContractBuyerCommission' | 'brokerContractBuyerCommissionUnit' | 'brokerContractDate' | 'brokerContractPrice' | 'brokerContractType' | 'expectedSalePrice' | 'brokerContractTermDate' | 'expectedSaleNotaryDate' | 'objectSaleBuyerCommission' | 'objectSaleBuyerCommissionTaxation' | 'objectSaleBuyerCommissionUnit' | 'objectSaleCommission' | 'objectSaleCommissionTaxation' | 'objectSaleCommissionUnit' | 'objectSaleNotaryDate' | 'objectSalePrice' | 'marketingCompleted' | 'saleCompleted' | 'invoiceSent'>
        )>, tasks?: Maybe<(
          { __typename: 'TaskPage' }
          & { nodes: Array<(
            { __typename: 'Task' }
            & Pick<Task, 'id' | 'title' | 'description' | 'type' | 'status' | 'updatedAt' | 'createdAt' | 'editable' | 'dueDate' | 'result'>
            & { references?: Maybe<Array<(
              { __typename?: 'AccountBalancePackage' }
              & Pick<AccountBalancePackage, 'gid' | 'id'>
            ) | (
              { __typename?: 'AccountBalanceTransaction' }
              & Pick<AccountBalanceTransaction, 'gid' | 'id'>
            ) | (
              { __typename?: 'Activity' }
              & Pick<Activity, 'gid' | 'id'>
            ) | (
              { __typename?: 'Appointment' }
              & Pick<Appointment, 'gid' | 'id'>
            ) | (
              { __typename?: 'Asset' }
              & Pick<Asset, 'gid' | 'id'>
            ) | (
              { __typename?: 'Broker' }
              & Pick<Broker, 'gid' | 'id'>
            ) | (
              { __typename?: 'Customer' }
              & Pick<Customer, 'gid' | 'id'>
            ) | (
              { __typename?: 'DataPoint' }
              & Pick<DataPoint, 'gid' | 'id'>
            ) | (
              { __typename?: 'FinancingLead' }
              & Pick<FinancingLead, 'gid' | 'id'>
            ) | (
              { __typename?: 'InsightsQuery' }
              & Pick<InsightsQuery, 'gid' | 'id'>
            ) | (
              { __typename?: 'InsuranceLead' }
              & Pick<InsuranceLead, 'gid' | 'id'>
            ) | (
              { __typename?: 'InvoiceAddress' }
              & Pick<InvoiceAddress, 'gid' | 'id'>
            ) | (
              { __typename?: 'Lead' }
              & Pick<Lead, 'gid' | 'id'>
            ) | (
              { __typename?: 'LeadManager' }
              & Pick<LeadManager, 'gid' | 'id'>
            ) | (
              { __typename?: 'NotificationToken' }
              & Pick<NotificationToken, 'gid' | 'id'>
            ) | (
              { __typename?: 'Office' }
              & Pick<Office, 'gid' | 'id'>
            ) | (
              { __typename?: 'Organization' }
              & Pick<Organization, 'gid' | 'id'>
            ) | (
              { __typename?: 'Payment' }
              & Pick<Payment, 'gid' | 'id'>
            ) | (
              { __typename?: 'PaymentMandate' }
              & Pick<PaymentMandate, 'gid' | 'id'>
            ) | (
              { __typename?: 'Pdf' }
              & Pick<Pdf, 'gid' | 'id'>
            ) | (
              { __typename?: 'Policy' }
              & Pick<Policy, 'gid' | 'id'>
            ) | (
              { __typename?: 'PreferencesSet' }
              & Pick<PreferencesSet, 'gid' | 'id'>
            ) | (
              { __typename?: 'Property' }
              & Pick<Property, 'gid' | 'id'>
            ) | (
              { __typename?: 'Review' }
              & Pick<Review, 'gid' | 'id'>
            ) | (
              { __typename?: 'Role' }
              & Pick<Role, 'gid' | 'id'>
            ) | (
              { __typename?: 'SearchProfile' }
              & Pick<SearchProfile, 'gid' | 'id'>
            ) | (
              { __typename?: 'Task' }
              & Pick<Task, 'gid' | 'id'>
            ) | (
              { __typename?: 'Timeframe' }
              & Pick<Timeframe, 'gid' | 'id'>
            ) | (
              { __typename?: 'User' }
              & Pick<User, 'gid' | 'id'>
            )>>, owner?: Maybe<(
              { __typename?: 'AccountBalancePackage' }
              & Pick<AccountBalancePackage, 'id' | 'gid'>
            ) | (
              { __typename?: 'AccountBalanceTransaction' }
              & Pick<AccountBalanceTransaction, 'id' | 'gid'>
            ) | (
              { __typename?: 'Activity' }
              & Pick<Activity, 'id' | 'gid'>
            ) | (
              { __typename?: 'Appointment' }
              & Pick<Appointment, 'id' | 'gid'>
            ) | (
              { __typename?: 'Asset' }
              & Pick<Asset, 'id' | 'gid'>
            ) | (
              { __typename?: 'Broker' }
              & Pick<Broker, 'id' | 'gid'>
            ) | (
              { __typename?: 'Customer' }
              & Pick<Customer, 'id' | 'gid'>
            ) | (
              { __typename?: 'DataPoint' }
              & Pick<DataPoint, 'id' | 'gid'>
            ) | (
              { __typename?: 'FinancingLead' }
              & Pick<FinancingLead, 'id' | 'gid'>
            ) | (
              { __typename?: 'InsightsQuery' }
              & Pick<InsightsQuery, 'id' | 'gid'>
            ) | (
              { __typename?: 'InsuranceLead' }
              & Pick<InsuranceLead, 'id' | 'gid'>
            ) | (
              { __typename?: 'InvoiceAddress' }
              & Pick<InvoiceAddress, 'id' | 'gid'>
            ) | (
              { __typename?: 'Lead' }
              & Pick<Lead, 'id' | 'gid'>
            ) | (
              { __typename?: 'LeadManager' }
              & Pick<LeadManager, 'id' | 'gid'>
            ) | (
              { __typename?: 'NotificationToken' }
              & Pick<NotificationToken, 'id' | 'gid'>
            ) | (
              { __typename?: 'Office' }
              & Pick<Office, 'id' | 'gid'>
            ) | (
              { __typename?: 'Organization' }
              & Pick<Organization, 'id' | 'gid'>
            ) | (
              { __typename?: 'Payment' }
              & Pick<Payment, 'id' | 'gid'>
            ) | (
              { __typename?: 'PaymentMandate' }
              & Pick<PaymentMandate, 'id' | 'gid'>
            ) | (
              { __typename?: 'Pdf' }
              & Pick<Pdf, 'id' | 'gid'>
            ) | (
              { __typename?: 'Policy' }
              & Pick<Policy, 'id' | 'gid'>
            ) | (
              { __typename?: 'PreferencesSet' }
              & Pick<PreferencesSet, 'id' | 'gid'>
            ) | (
              { __typename?: 'Property' }
              & Pick<Property, 'id' | 'gid'>
            ) | (
              { __typename?: 'Review' }
              & Pick<Review, 'id' | 'gid'>
            ) | (
              { __typename?: 'Role' }
              & Pick<Role, 'id' | 'gid'>
            ) | (
              { __typename?: 'SearchProfile' }
              & Pick<SearchProfile, 'id' | 'gid'>
            ) | (
              { __typename?: 'Task' }
              & Pick<Task, 'id' | 'gid'>
            ) | (
              { __typename?: 'Timeframe' }
              & Pick<Timeframe, 'id' | 'gid'>
            ) | (
              { __typename?: 'User' }
              & Pick<User, 'id' | 'gid'>
            )> }
          )> }
        )>, office?: Maybe<(
          { __typename: 'Office' }
          & Pick<Office, 'id' | 'disabledPaymentMethods'>
        )>, leadManager?: Maybe<(
          { __typename: 'LeadManager' }
          & Pick<LeadManager, 'id' | 'acceptableLimit'>
        )>, supervisor?: Maybe<(
          { __typename: 'Broker' }
          & Pick<Broker, 'id'>
          & { preferences: (
            { __typename: 'PreferencesSet' }
            & Pick<PreferencesSet, 'id' | 'firstName' | 'lastName'>
          ) }
        )> }
      )>, error?: Maybe<(
        { __typename?: 'MutationError' }
        & Pick<MutationError, 'actionDetails' | 'type' | 'message'>
      )> }
    ) }
  ) }
);

export type CreatePaymentMandateMutationVariables = Exact<{
  redirectUrlTemplate: Scalars['String'];
}>;


export type CreatePaymentMandateMutation = (
  { __typename?: 'Mutations' }
  & { paymentMandate: (
    { __typename?: 'PaymentMandateNamespace' }
    & { create: (
      { __typename?: 'PaymentMandateCreatePayload' }
      & { paymentMandate?: Maybe<(
        { __typename?: 'PaymentMandate' }
        & Pick<PaymentMandate, 'id' | 'metadata'>
      )>, error?: Maybe<(
        { __typename: 'MutationError' }
        & Pick<MutationError, 'type' | 'actionDetails' | 'message'>
      )> }
    ) }
  ) }
);

export type ConfirmPaymentMandateMutationVariables = Exact<{
  id: Scalars['PaymentMandateGlobalIdOrUuid'];
}>;


export type ConfirmPaymentMandateMutation = (
  { __typename?: 'Mutations' }
  & { paymentMandate: (
    { __typename?: 'PaymentMandateNamespace' }
    & { confirm: (
      { __typename?: 'PaymentMandateConfirmPayload' }
      & { paymentMandate?: Maybe<(
        { __typename?: 'PaymentMandate' }
        & Pick<PaymentMandate, 'id' | 'metadata'>
      )>, error?: Maybe<(
        { __typename: 'MutationError' }
        & Pick<MutationError, 'type' | 'actionDetails' | 'message'>
      )> }
    ) }
  ) }
);

export type CreateAssetMutationVariables = Exact<{
  attachable: Scalars['GlobalID'];
  title?: InputMaybe<Scalars['String']>;
  file: Scalars['File'];
  description?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  public?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['JsonBlob']>;
}>;


export type CreateAssetMutation = (
  { __typename?: 'Mutations' }
  & { asset: (
    { __typename?: 'AssetNamespace' }
    & { create: (
      { __typename?: 'AssetCreatePayload' }
      & { asset?: Maybe<(
        { __typename: 'Asset' }
        & Pick<Asset, 'gid' | 'id' | 'filename' | 'fileUrl' | 'contentType' | 'title' | 'metadata'>
      )>, error?: Maybe<(
        { __typename: 'MutationError' }
        & Pick<MutationError, 'type' | 'actionDetails' | 'message'>
      )> }
    ) }
  ) }
);

export type DeleteAssetMutationVariables = Exact<{
  id: Scalars['AssetGlobalIdOrUuid'];
}>;


export type DeleteAssetMutation = (
  { __typename?: 'Mutations' }
  & { asset: (
    { __typename?: 'AssetNamespace' }
    & { delete: (
      { __typename?: 'AssetDeletePayload' }
      & { asset?: Maybe<(
        { __typename: 'Asset' }
        & Pick<Asset, 'gid' | 'id' | 'filename' | 'fileUrl' | 'contentType' | 'title' | 'metadata'>
      )>, error?: Maybe<(
        { __typename: 'MutationError' }
        & Pick<MutationError, 'type' | 'actionDetails' | 'message'>
      )> }
    ) }
  ) }
);

export type UpdateMandatoryTaskMutationVariables = Exact<{
  id: Scalars['LeadGlobalIdOrUuid'];
  stateProperties?: InputMaybe<LeadUpdateMandatoryTaskStateProperties>;
  closeTask?: InputMaybe<Scalars['Boolean']>;
}>;


export type UpdateMandatoryTaskMutation = (
  { __typename?: 'Mutations' }
  & { lead: (
    { __typename?: 'LeadNamespace' }
    & { updateMandatoryTask: (
      { __typename?: 'LeadUpdateMandatoryTaskPayload' }
      & { lead?: Maybe<(
        { __typename: 'Lead' }
        & Pick<Lead, 'closedReason' | 'id' | 'gid' | 'identifier' | 'createdAt' | 'processedAt' | 'accessPermittedAt' | 'activatedAt' | 'closedAt' | 'lockedAt' | 'soldAt' | 'dueAt' | 'labels' | 'statePropertiesUpdatedAt' | 'statePropertiesUpdateRequestedAt' | 'statePropertiesUpdateSkippedUntil' | 'contractProperties' | 'currentMandatoryTaskName' | 'processedWith' | 'processedComment'>
        & { property?: Maybe<(
          { __typename: 'Property' }
          & Pick<Property, 'dossierLink' | 'id'>
          & { objectDetails?: Maybe<(
            { __typename: 'PropertyObjectDetails' }
            & Pick<PropertyObjectDetails, 'saleHorizon' | 'condition' | 'landSize' | 'livingSpace' | 'amountRooms' | 'amountBathrooms' | 'amountParkingSpaces' | 'saleReason' | 'ownershipStructure' | 'freeFrom' | 'amountBalcony' | 'amountTerrace' | 'listed' | 'equipmentText' | 'descriptionText' | 'locationText' | 'additionalInfoText' | 'yearOfConstruction' | 'construction' | 'exploitation' | 'yearOfModernization' | 'rented' | 'inhabited' | 'amountUnits' | 'netRent' | 'propertyType' | 'propertySubtype' | 'city' | 'postalCode' | 'street' | 'streetAddition' | 'latitude' | 'longitude'>
            & { equipment?: Maybe<(
              { __typename?: 'PropertyEquipment' }
              & Pick<PropertyEquipment, 'attic' | 'cellar' | 'lift' | 'kitchen' | 'handicappedAccessible' | 'seniorFriendly' | 'garden' | 'guestToilet' | 'sauna' | 'parkingSpaceType'>
            )> }
          )>, owner?: Maybe<(
            { __typename: 'User' }
            & Pick<User, 'id'>
            & { preferences: (
              { __typename: 'PreferencesSet' }
              & Pick<PreferencesSet, 'id' | 'firstName' | 'lastName'>
            ) }
          )>, valuation?: Maybe<(
            { __typename: 'PropertyValuation' }
            & Pick<PropertyValuation, 'value'>
          )> }
        )>, objectProperties?: Maybe<(
          { __typename?: 'LeadObjectProperties' }
          & Pick<LeadObjectProperties, 'additionalInfo' | 'evaluationReason' | 'isForSale'>
        )>, contacts?: Maybe<Array<(
          { __typename: 'LeadContact' }
          & Pick<LeadContact, 'firstName' | 'lastName' | 'emails' | 'gender' | 'phoneNumbers' | 'reachability'>
          & { customer?: Maybe<(
            { __typename: 'Customer' }
            & Pick<Customer, 'id'>
            & { avatar?: Maybe<(
              { __typename?: 'Asset' }
              & Pick<Asset, 'fileUrl'>
            )> }
          )> }
        )>>, brokers?: Maybe<Array<(
          { __typename: 'Broker' }
          & Pick<Broker, 'id'>
        )>>, assets?: Maybe<(
          { __typename?: 'AssetPage' }
          & { nodes: Array<(
            { __typename: 'Asset' }
            & Pick<Asset, 'gid' | 'id' | 'filename' | 'fileUrl' | 'contentType' | 'title' | 'metadata'>
          )> }
        )>, stateProperties?: Maybe<(
          { __typename: 'LeadStateProperties' }
          & Pick<LeadStateProperties, 'currency' | 'customerAppointmentDate' | 'performEvaluationPrice' | 'performEvaluationDate' | 'performEvaluationDateTookPlace' | 'evaluationGid' | 'shareEvaluation' | 'nextCustomerContactDate' | 'acquisitionCompleted' | 'updateAlternativeText' | 'brokerContractCommissionUnit' | 'brokerContractCommission' | 'brokerContractBuyerCommission' | 'brokerContractBuyerCommissionUnit' | 'brokerContractDate' | 'brokerContractPrice' | 'brokerContractType' | 'expectedSalePrice' | 'brokerContractTermDate' | 'expectedSaleNotaryDate' | 'objectSaleBuyerCommission' | 'objectSaleBuyerCommissionTaxation' | 'objectSaleBuyerCommissionUnit' | 'objectSaleCommission' | 'objectSaleCommissionTaxation' | 'objectSaleCommissionUnit' | 'objectSaleNotaryDate' | 'objectSalePrice' | 'marketingCompleted' | 'saleCompleted' | 'invoiceSent'>
        )>, tasks?: Maybe<(
          { __typename: 'TaskPage' }
          & { nodes: Array<(
            { __typename: 'Task' }
            & Pick<Task, 'id' | 'title' | 'description' | 'type' | 'status' | 'updatedAt' | 'createdAt' | 'editable' | 'dueDate' | 'result'>
            & { references?: Maybe<Array<(
              { __typename?: 'AccountBalancePackage' }
              & Pick<AccountBalancePackage, 'gid' | 'id'>
            ) | (
              { __typename?: 'AccountBalanceTransaction' }
              & Pick<AccountBalanceTransaction, 'gid' | 'id'>
            ) | (
              { __typename?: 'Activity' }
              & Pick<Activity, 'gid' | 'id'>
            ) | (
              { __typename?: 'Appointment' }
              & Pick<Appointment, 'gid' | 'id'>
            ) | (
              { __typename?: 'Asset' }
              & Pick<Asset, 'gid' | 'id'>
            ) | (
              { __typename?: 'Broker' }
              & Pick<Broker, 'gid' | 'id'>
            ) | (
              { __typename?: 'Customer' }
              & Pick<Customer, 'gid' | 'id'>
            ) | (
              { __typename?: 'DataPoint' }
              & Pick<DataPoint, 'gid' | 'id'>
            ) | (
              { __typename?: 'FinancingLead' }
              & Pick<FinancingLead, 'gid' | 'id'>
            ) | (
              { __typename?: 'InsightsQuery' }
              & Pick<InsightsQuery, 'gid' | 'id'>
            ) | (
              { __typename?: 'InsuranceLead' }
              & Pick<InsuranceLead, 'gid' | 'id'>
            ) | (
              { __typename?: 'InvoiceAddress' }
              & Pick<InvoiceAddress, 'gid' | 'id'>
            ) | (
              { __typename?: 'Lead' }
              & Pick<Lead, 'gid' | 'id'>
            ) | (
              { __typename?: 'LeadManager' }
              & Pick<LeadManager, 'gid' | 'id'>
            ) | (
              { __typename?: 'NotificationToken' }
              & Pick<NotificationToken, 'gid' | 'id'>
            ) | (
              { __typename?: 'Office' }
              & Pick<Office, 'gid' | 'id'>
            ) | (
              { __typename?: 'Organization' }
              & Pick<Organization, 'gid' | 'id'>
            ) | (
              { __typename?: 'Payment' }
              & Pick<Payment, 'gid' | 'id'>
            ) | (
              { __typename?: 'PaymentMandate' }
              & Pick<PaymentMandate, 'gid' | 'id'>
            ) | (
              { __typename?: 'Pdf' }
              & Pick<Pdf, 'gid' | 'id'>
            ) | (
              { __typename?: 'Policy' }
              & Pick<Policy, 'gid' | 'id'>
            ) | (
              { __typename?: 'PreferencesSet' }
              & Pick<PreferencesSet, 'gid' | 'id'>
            ) | (
              { __typename?: 'Property' }
              & Pick<Property, 'gid' | 'id'>
            ) | (
              { __typename?: 'Review' }
              & Pick<Review, 'gid' | 'id'>
            ) | (
              { __typename?: 'Role' }
              & Pick<Role, 'gid' | 'id'>
            ) | (
              { __typename?: 'SearchProfile' }
              & Pick<SearchProfile, 'gid' | 'id'>
            ) | (
              { __typename?: 'Task' }
              & Pick<Task, 'gid' | 'id'>
            ) | (
              { __typename?: 'Timeframe' }
              & Pick<Timeframe, 'gid' | 'id'>
            ) | (
              { __typename?: 'User' }
              & Pick<User, 'gid' | 'id'>
            )>>, owner?: Maybe<(
              { __typename?: 'AccountBalancePackage' }
              & Pick<AccountBalancePackage, 'id' | 'gid'>
            ) | (
              { __typename?: 'AccountBalanceTransaction' }
              & Pick<AccountBalanceTransaction, 'id' | 'gid'>
            ) | (
              { __typename?: 'Activity' }
              & Pick<Activity, 'id' | 'gid'>
            ) | (
              { __typename?: 'Appointment' }
              & Pick<Appointment, 'id' | 'gid'>
            ) | (
              { __typename?: 'Asset' }
              & Pick<Asset, 'id' | 'gid'>
            ) | (
              { __typename?: 'Broker' }
              & Pick<Broker, 'id' | 'gid'>
            ) | (
              { __typename?: 'Customer' }
              & Pick<Customer, 'id' | 'gid'>
            ) | (
              { __typename?: 'DataPoint' }
              & Pick<DataPoint, 'id' | 'gid'>
            ) | (
              { __typename?: 'FinancingLead' }
              & Pick<FinancingLead, 'id' | 'gid'>
            ) | (
              { __typename?: 'InsightsQuery' }
              & Pick<InsightsQuery, 'id' | 'gid'>
            ) | (
              { __typename?: 'InsuranceLead' }
              & Pick<InsuranceLead, 'id' | 'gid'>
            ) | (
              { __typename?: 'InvoiceAddress' }
              & Pick<InvoiceAddress, 'id' | 'gid'>
            ) | (
              { __typename?: 'Lead' }
              & Pick<Lead, 'id' | 'gid'>
            ) | (
              { __typename?: 'LeadManager' }
              & Pick<LeadManager, 'id' | 'gid'>
            ) | (
              { __typename?: 'NotificationToken' }
              & Pick<NotificationToken, 'id' | 'gid'>
            ) | (
              { __typename?: 'Office' }
              & Pick<Office, 'id' | 'gid'>
            ) | (
              { __typename?: 'Organization' }
              & Pick<Organization, 'id' | 'gid'>
            ) | (
              { __typename?: 'Payment' }
              & Pick<Payment, 'id' | 'gid'>
            ) | (
              { __typename?: 'PaymentMandate' }
              & Pick<PaymentMandate, 'id' | 'gid'>
            ) | (
              { __typename?: 'Pdf' }
              & Pick<Pdf, 'id' | 'gid'>
            ) | (
              { __typename?: 'Policy' }
              & Pick<Policy, 'id' | 'gid'>
            ) | (
              { __typename?: 'PreferencesSet' }
              & Pick<PreferencesSet, 'id' | 'gid'>
            ) | (
              { __typename?: 'Property' }
              & Pick<Property, 'id' | 'gid'>
            ) | (
              { __typename?: 'Review' }
              & Pick<Review, 'id' | 'gid'>
            ) | (
              { __typename?: 'Role' }
              & Pick<Role, 'id' | 'gid'>
            ) | (
              { __typename?: 'SearchProfile' }
              & Pick<SearchProfile, 'id' | 'gid'>
            ) | (
              { __typename?: 'Task' }
              & Pick<Task, 'id' | 'gid'>
            ) | (
              { __typename?: 'Timeframe' }
              & Pick<Timeframe, 'id' | 'gid'>
            ) | (
              { __typename?: 'User' }
              & Pick<User, 'id' | 'gid'>
            )> }
          )> }
        )>, office?: Maybe<(
          { __typename: 'Office' }
          & Pick<Office, 'id' | 'disabledPaymentMethods'>
        )>, leadManager?: Maybe<(
          { __typename: 'LeadManager' }
          & Pick<LeadManager, 'id' | 'acceptableLimit'>
        )>, supervisor?: Maybe<(
          { __typename: 'Broker' }
          & Pick<Broker, 'id'>
          & { preferences: (
            { __typename: 'PreferencesSet' }
            & Pick<PreferencesSet, 'id' | 'firstName' | 'lastName'>
          ) }
        )> }
      )>, error?: Maybe<(
        { __typename: 'MutationError' }
        & Pick<MutationError, 'type' | 'actionDetails' | 'message'>
      )> }
    ) }
  ) }
);

export type CreateTaskMutationVariables = Exact<{
  title: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  result?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<TaskStatus>;
  dueDate?: InputMaybe<Scalars['DateTime']>;
  referenceGids?: InputMaybe<Array<Scalars['GlobalID']> | Scalars['GlobalID']>;
  ownerGid: Scalars['GlobalID'];
  editable?: InputMaybe<Scalars['Boolean']>;
}>;


export type CreateTaskMutation = (
  { __typename?: 'Mutations' }
  & { task: (
    { __typename?: 'TaskNamespace' }
    & { create: (
      { __typename?: 'TaskCreatePayload' }
      & { task?: Maybe<(
        { __typename: 'Task' }
        & Pick<Task, 'id' | 'title' | 'description' | 'type' | 'status' | 'updatedAt' | 'createdAt' | 'editable' | 'dueDate' | 'result'>
        & { references?: Maybe<Array<(
          { __typename?: 'AccountBalancePackage' }
          & Pick<AccountBalancePackage, 'gid' | 'id'>
        ) | (
          { __typename?: 'AccountBalanceTransaction' }
          & Pick<AccountBalanceTransaction, 'gid' | 'id'>
        ) | (
          { __typename?: 'Activity' }
          & Pick<Activity, 'gid' | 'id'>
        ) | (
          { __typename?: 'Appointment' }
          & Pick<Appointment, 'gid' | 'id'>
        ) | (
          { __typename?: 'Asset' }
          & Pick<Asset, 'gid' | 'id'>
        ) | (
          { __typename?: 'Broker' }
          & Pick<Broker, 'gid' | 'id'>
        ) | (
          { __typename?: 'Customer' }
          & Pick<Customer, 'gid' | 'id'>
        ) | (
          { __typename?: 'DataPoint' }
          & Pick<DataPoint, 'gid' | 'id'>
        ) | (
          { __typename?: 'FinancingLead' }
          & Pick<FinancingLead, 'gid' | 'id'>
        ) | (
          { __typename?: 'InsightsQuery' }
          & Pick<InsightsQuery, 'gid' | 'id'>
        ) | (
          { __typename?: 'InsuranceLead' }
          & Pick<InsuranceLead, 'gid' | 'id'>
        ) | (
          { __typename?: 'InvoiceAddress' }
          & Pick<InvoiceAddress, 'gid' | 'id'>
        ) | (
          { __typename?: 'Lead' }
          & Pick<Lead, 'gid' | 'id'>
        ) | (
          { __typename?: 'LeadManager' }
          & Pick<LeadManager, 'gid' | 'id'>
        ) | (
          { __typename?: 'NotificationToken' }
          & Pick<NotificationToken, 'gid' | 'id'>
        ) | (
          { __typename?: 'Office' }
          & Pick<Office, 'gid' | 'id'>
        ) | (
          { __typename?: 'Organization' }
          & Pick<Organization, 'gid' | 'id'>
        ) | (
          { __typename?: 'Payment' }
          & Pick<Payment, 'gid' | 'id'>
        ) | (
          { __typename?: 'PaymentMandate' }
          & Pick<PaymentMandate, 'gid' | 'id'>
        ) | (
          { __typename?: 'Pdf' }
          & Pick<Pdf, 'gid' | 'id'>
        ) | (
          { __typename?: 'Policy' }
          & Pick<Policy, 'gid' | 'id'>
        ) | (
          { __typename?: 'PreferencesSet' }
          & Pick<PreferencesSet, 'gid' | 'id'>
        ) | (
          { __typename?: 'Property' }
          & Pick<Property, 'gid' | 'id'>
        ) | (
          { __typename?: 'Review' }
          & Pick<Review, 'gid' | 'id'>
        ) | (
          { __typename?: 'Role' }
          & Pick<Role, 'gid' | 'id'>
        ) | (
          { __typename?: 'SearchProfile' }
          & Pick<SearchProfile, 'gid' | 'id'>
        ) | (
          { __typename?: 'Task' }
          & Pick<Task, 'gid' | 'id'>
        ) | (
          { __typename?: 'Timeframe' }
          & Pick<Timeframe, 'gid' | 'id'>
        ) | (
          { __typename?: 'User' }
          & Pick<User, 'gid' | 'id'>
        )>>, owner?: Maybe<(
          { __typename?: 'AccountBalancePackage' }
          & Pick<AccountBalancePackage, 'id' | 'gid'>
        ) | (
          { __typename?: 'AccountBalanceTransaction' }
          & Pick<AccountBalanceTransaction, 'id' | 'gid'>
        ) | (
          { __typename?: 'Activity' }
          & Pick<Activity, 'id' | 'gid'>
        ) | (
          { __typename?: 'Appointment' }
          & Pick<Appointment, 'id' | 'gid'>
        ) | (
          { __typename?: 'Asset' }
          & Pick<Asset, 'id' | 'gid'>
        ) | (
          { __typename?: 'Broker' }
          & Pick<Broker, 'id' | 'gid'>
        ) | (
          { __typename?: 'Customer' }
          & Pick<Customer, 'id' | 'gid'>
        ) | (
          { __typename?: 'DataPoint' }
          & Pick<DataPoint, 'id' | 'gid'>
        ) | (
          { __typename?: 'FinancingLead' }
          & Pick<FinancingLead, 'id' | 'gid'>
        ) | (
          { __typename?: 'InsightsQuery' }
          & Pick<InsightsQuery, 'id' | 'gid'>
        ) | (
          { __typename?: 'InsuranceLead' }
          & Pick<InsuranceLead, 'id' | 'gid'>
        ) | (
          { __typename?: 'InvoiceAddress' }
          & Pick<InvoiceAddress, 'id' | 'gid'>
        ) | (
          { __typename?: 'Lead' }
          & Pick<Lead, 'id' | 'gid'>
        ) | (
          { __typename?: 'LeadManager' }
          & Pick<LeadManager, 'id' | 'gid'>
        ) | (
          { __typename?: 'NotificationToken' }
          & Pick<NotificationToken, 'id' | 'gid'>
        ) | (
          { __typename?: 'Office' }
          & Pick<Office, 'id' | 'gid'>
        ) | (
          { __typename?: 'Organization' }
          & Pick<Organization, 'id' | 'gid'>
        ) | (
          { __typename?: 'Payment' }
          & Pick<Payment, 'id' | 'gid'>
        ) | (
          { __typename?: 'PaymentMandate' }
          & Pick<PaymentMandate, 'id' | 'gid'>
        ) | (
          { __typename?: 'Pdf' }
          & Pick<Pdf, 'id' | 'gid'>
        ) | (
          { __typename?: 'Policy' }
          & Pick<Policy, 'id' | 'gid'>
        ) | (
          { __typename?: 'PreferencesSet' }
          & Pick<PreferencesSet, 'id' | 'gid'>
        ) | (
          { __typename?: 'Property' }
          & Pick<Property, 'id' | 'gid'>
        ) | (
          { __typename?: 'Review' }
          & Pick<Review, 'id' | 'gid'>
        ) | (
          { __typename?: 'Role' }
          & Pick<Role, 'id' | 'gid'>
        ) | (
          { __typename?: 'SearchProfile' }
          & Pick<SearchProfile, 'id' | 'gid'>
        ) | (
          { __typename?: 'Task' }
          & Pick<Task, 'id' | 'gid'>
        ) | (
          { __typename?: 'Timeframe' }
          & Pick<Timeframe, 'id' | 'gid'>
        ) | (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'gid'>
        )> }
      )>, error?: Maybe<(
        { __typename: 'MutationError' }
        & Pick<MutationError, 'type' | 'actionDetails' | 'message'>
      )> }
    ) }
  ) }
);

export type UpdateTaskMutationVariables = Exact<{
  id: Scalars['TaskGlobalIdOrUuid'];
  title?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  result?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<TaskStatus>;
  dueDate?: InputMaybe<Scalars['DateTime']>;
  referenceGids?: InputMaybe<Array<Scalars['GlobalID']> | Scalars['GlobalID']>;
}>;


export type UpdateTaskMutation = (
  { __typename?: 'Mutations' }
  & { task: (
    { __typename?: 'TaskNamespace' }
    & { update: (
      { __typename?: 'TaskUpdatePayload' }
      & { task?: Maybe<(
        { __typename: 'Task' }
        & Pick<Task, 'id' | 'title' | 'description' | 'type' | 'status' | 'updatedAt' | 'createdAt' | 'editable' | 'dueDate' | 'result'>
        & { references?: Maybe<Array<(
          { __typename?: 'AccountBalancePackage' }
          & Pick<AccountBalancePackage, 'gid' | 'id'>
        ) | (
          { __typename?: 'AccountBalanceTransaction' }
          & Pick<AccountBalanceTransaction, 'gid' | 'id'>
        ) | (
          { __typename?: 'Activity' }
          & Pick<Activity, 'gid' | 'id'>
        ) | (
          { __typename?: 'Appointment' }
          & Pick<Appointment, 'gid' | 'id'>
        ) | (
          { __typename?: 'Asset' }
          & Pick<Asset, 'gid' | 'id'>
        ) | (
          { __typename?: 'Broker' }
          & Pick<Broker, 'gid' | 'id'>
        ) | (
          { __typename?: 'Customer' }
          & Pick<Customer, 'gid' | 'id'>
        ) | (
          { __typename?: 'DataPoint' }
          & Pick<DataPoint, 'gid' | 'id'>
        ) | (
          { __typename?: 'FinancingLead' }
          & Pick<FinancingLead, 'gid' | 'id'>
        ) | (
          { __typename?: 'InsightsQuery' }
          & Pick<InsightsQuery, 'gid' | 'id'>
        ) | (
          { __typename?: 'InsuranceLead' }
          & Pick<InsuranceLead, 'gid' | 'id'>
        ) | (
          { __typename?: 'InvoiceAddress' }
          & Pick<InvoiceAddress, 'gid' | 'id'>
        ) | (
          { __typename?: 'Lead' }
          & Pick<Lead, 'gid' | 'id'>
        ) | (
          { __typename?: 'LeadManager' }
          & Pick<LeadManager, 'gid' | 'id'>
        ) | (
          { __typename?: 'NotificationToken' }
          & Pick<NotificationToken, 'gid' | 'id'>
        ) | (
          { __typename?: 'Office' }
          & Pick<Office, 'gid' | 'id'>
        ) | (
          { __typename?: 'Organization' }
          & Pick<Organization, 'gid' | 'id'>
        ) | (
          { __typename?: 'Payment' }
          & Pick<Payment, 'gid' | 'id'>
        ) | (
          { __typename?: 'PaymentMandate' }
          & Pick<PaymentMandate, 'gid' | 'id'>
        ) | (
          { __typename?: 'Pdf' }
          & Pick<Pdf, 'gid' | 'id'>
        ) | (
          { __typename?: 'Policy' }
          & Pick<Policy, 'gid' | 'id'>
        ) | (
          { __typename?: 'PreferencesSet' }
          & Pick<PreferencesSet, 'gid' | 'id'>
        ) | (
          { __typename?: 'Property' }
          & Pick<Property, 'gid' | 'id'>
        ) | (
          { __typename?: 'Review' }
          & Pick<Review, 'gid' | 'id'>
        ) | (
          { __typename?: 'Role' }
          & Pick<Role, 'gid' | 'id'>
        ) | (
          { __typename?: 'SearchProfile' }
          & Pick<SearchProfile, 'gid' | 'id'>
        ) | (
          { __typename?: 'Task' }
          & Pick<Task, 'gid' | 'id'>
        ) | (
          { __typename?: 'Timeframe' }
          & Pick<Timeframe, 'gid' | 'id'>
        ) | (
          { __typename?: 'User' }
          & Pick<User, 'gid' | 'id'>
        )>>, owner?: Maybe<(
          { __typename?: 'AccountBalancePackage' }
          & Pick<AccountBalancePackage, 'id' | 'gid'>
        ) | (
          { __typename?: 'AccountBalanceTransaction' }
          & Pick<AccountBalanceTransaction, 'id' | 'gid'>
        ) | (
          { __typename?: 'Activity' }
          & Pick<Activity, 'id' | 'gid'>
        ) | (
          { __typename?: 'Appointment' }
          & Pick<Appointment, 'id' | 'gid'>
        ) | (
          { __typename?: 'Asset' }
          & Pick<Asset, 'id' | 'gid'>
        ) | (
          { __typename?: 'Broker' }
          & Pick<Broker, 'id' | 'gid'>
        ) | (
          { __typename?: 'Customer' }
          & Pick<Customer, 'id' | 'gid'>
        ) | (
          { __typename?: 'DataPoint' }
          & Pick<DataPoint, 'id' | 'gid'>
        ) | (
          { __typename?: 'FinancingLead' }
          & Pick<FinancingLead, 'id' | 'gid'>
        ) | (
          { __typename?: 'InsightsQuery' }
          & Pick<InsightsQuery, 'id' | 'gid'>
        ) | (
          { __typename?: 'InsuranceLead' }
          & Pick<InsuranceLead, 'id' | 'gid'>
        ) | (
          { __typename?: 'InvoiceAddress' }
          & Pick<InvoiceAddress, 'id' | 'gid'>
        ) | (
          { __typename?: 'Lead' }
          & Pick<Lead, 'id' | 'gid'>
        ) | (
          { __typename?: 'LeadManager' }
          & Pick<LeadManager, 'id' | 'gid'>
        ) | (
          { __typename?: 'NotificationToken' }
          & Pick<NotificationToken, 'id' | 'gid'>
        ) | (
          { __typename?: 'Office' }
          & Pick<Office, 'id' | 'gid'>
        ) | (
          { __typename?: 'Organization' }
          & Pick<Organization, 'id' | 'gid'>
        ) | (
          { __typename?: 'Payment' }
          & Pick<Payment, 'id' | 'gid'>
        ) | (
          { __typename?: 'PaymentMandate' }
          & Pick<PaymentMandate, 'id' | 'gid'>
        ) | (
          { __typename?: 'Pdf' }
          & Pick<Pdf, 'id' | 'gid'>
        ) | (
          { __typename?: 'Policy' }
          & Pick<Policy, 'id' | 'gid'>
        ) | (
          { __typename?: 'PreferencesSet' }
          & Pick<PreferencesSet, 'id' | 'gid'>
        ) | (
          { __typename?: 'Property' }
          & Pick<Property, 'id' | 'gid'>
        ) | (
          { __typename?: 'Review' }
          & Pick<Review, 'id' | 'gid'>
        ) | (
          { __typename?: 'Role' }
          & Pick<Role, 'id' | 'gid'>
        ) | (
          { __typename?: 'SearchProfile' }
          & Pick<SearchProfile, 'id' | 'gid'>
        ) | (
          { __typename?: 'Task' }
          & Pick<Task, 'id' | 'gid'>
        ) | (
          { __typename?: 'Timeframe' }
          & Pick<Timeframe, 'id' | 'gid'>
        ) | (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'gid'>
        )> }
      )>, error?: Maybe<(
        { __typename: 'MutationError' }
        & Pick<MutationError, 'type' | 'actionDetails' | 'message'>
      )> }
    ) }
  ) }
);

export type CancelLeadMutationVariables = Exact<{
  id: Scalars['LeadGlobalIdOrUuid'];
  comment: Scalars['String'];
  reason: LeadReason;
}>;


export type CancelLeadMutation = (
  { __typename?: 'Mutations' }
  & { lead: (
    { __typename?: 'LeadNamespace' }
    & { close: (
      { __typename?: 'LeadClosePayload' }
      & { lead?: Maybe<(
        { __typename: 'Lead' }
        & Pick<Lead, 'closedReason' | 'id' | 'gid' | 'identifier' | 'createdAt' | 'processedAt' | 'accessPermittedAt' | 'activatedAt' | 'closedAt' | 'lockedAt' | 'soldAt' | 'dueAt' | 'labels' | 'statePropertiesUpdatedAt' | 'statePropertiesUpdateRequestedAt' | 'statePropertiesUpdateSkippedUntil' | 'contractProperties' | 'currentMandatoryTaskName' | 'processedWith' | 'processedComment'>
        & { property?: Maybe<(
          { __typename: 'Property' }
          & Pick<Property, 'dossierLink' | 'id'>
          & { objectDetails?: Maybe<(
            { __typename: 'PropertyObjectDetails' }
            & Pick<PropertyObjectDetails, 'saleHorizon' | 'condition' | 'landSize' | 'livingSpace' | 'amountRooms' | 'amountBathrooms' | 'amountParkingSpaces' | 'saleReason' | 'ownershipStructure' | 'freeFrom' | 'amountBalcony' | 'amountTerrace' | 'listed' | 'equipmentText' | 'descriptionText' | 'locationText' | 'additionalInfoText' | 'yearOfConstruction' | 'construction' | 'exploitation' | 'yearOfModernization' | 'rented' | 'inhabited' | 'amountUnits' | 'netRent' | 'propertyType' | 'propertySubtype' | 'city' | 'postalCode' | 'street' | 'streetAddition' | 'latitude' | 'longitude'>
            & { equipment?: Maybe<(
              { __typename?: 'PropertyEquipment' }
              & Pick<PropertyEquipment, 'attic' | 'cellar' | 'lift' | 'kitchen' | 'handicappedAccessible' | 'seniorFriendly' | 'garden' | 'guestToilet' | 'sauna' | 'parkingSpaceType'>
            )> }
          )>, owner?: Maybe<(
            { __typename: 'User' }
            & Pick<User, 'id'>
            & { preferences: (
              { __typename: 'PreferencesSet' }
              & Pick<PreferencesSet, 'id' | 'firstName' | 'lastName'>
            ) }
          )>, valuation?: Maybe<(
            { __typename: 'PropertyValuation' }
            & Pick<PropertyValuation, 'value'>
          )> }
        )>, objectProperties?: Maybe<(
          { __typename?: 'LeadObjectProperties' }
          & Pick<LeadObjectProperties, 'additionalInfo' | 'evaluationReason' | 'isForSale'>
        )>, contacts?: Maybe<Array<(
          { __typename: 'LeadContact' }
          & Pick<LeadContact, 'firstName' | 'lastName' | 'emails' | 'gender' | 'phoneNumbers' | 'reachability'>
          & { customer?: Maybe<(
            { __typename: 'Customer' }
            & Pick<Customer, 'id'>
            & { avatar?: Maybe<(
              { __typename?: 'Asset' }
              & Pick<Asset, 'fileUrl'>
            )> }
          )> }
        )>>, brokers?: Maybe<Array<(
          { __typename: 'Broker' }
          & Pick<Broker, 'id'>
        )>>, assets?: Maybe<(
          { __typename?: 'AssetPage' }
          & { nodes: Array<(
            { __typename: 'Asset' }
            & Pick<Asset, 'gid' | 'id' | 'filename' | 'fileUrl' | 'contentType' | 'title' | 'metadata'>
          )> }
        )>, stateProperties?: Maybe<(
          { __typename: 'LeadStateProperties' }
          & Pick<LeadStateProperties, 'currency' | 'customerAppointmentDate' | 'performEvaluationPrice' | 'performEvaluationDate' | 'performEvaluationDateTookPlace' | 'evaluationGid' | 'shareEvaluation' | 'nextCustomerContactDate' | 'acquisitionCompleted' | 'updateAlternativeText' | 'brokerContractCommissionUnit' | 'brokerContractCommission' | 'brokerContractBuyerCommission' | 'brokerContractBuyerCommissionUnit' | 'brokerContractDate' | 'brokerContractPrice' | 'brokerContractType' | 'expectedSalePrice' | 'brokerContractTermDate' | 'expectedSaleNotaryDate' | 'objectSaleBuyerCommission' | 'objectSaleBuyerCommissionTaxation' | 'objectSaleBuyerCommissionUnit' | 'objectSaleCommission' | 'objectSaleCommissionTaxation' | 'objectSaleCommissionUnit' | 'objectSaleNotaryDate' | 'objectSalePrice' | 'marketingCompleted' | 'saleCompleted' | 'invoiceSent'>
        )>, tasks?: Maybe<(
          { __typename: 'TaskPage' }
          & { nodes: Array<(
            { __typename: 'Task' }
            & Pick<Task, 'id' | 'title' | 'description' | 'type' | 'status' | 'updatedAt' | 'createdAt' | 'editable' | 'dueDate' | 'result'>
            & { references?: Maybe<Array<(
              { __typename?: 'AccountBalancePackage' }
              & Pick<AccountBalancePackage, 'gid' | 'id'>
            ) | (
              { __typename?: 'AccountBalanceTransaction' }
              & Pick<AccountBalanceTransaction, 'gid' | 'id'>
            ) | (
              { __typename?: 'Activity' }
              & Pick<Activity, 'gid' | 'id'>
            ) | (
              { __typename?: 'Appointment' }
              & Pick<Appointment, 'gid' | 'id'>
            ) | (
              { __typename?: 'Asset' }
              & Pick<Asset, 'gid' | 'id'>
            ) | (
              { __typename?: 'Broker' }
              & Pick<Broker, 'gid' | 'id'>
            ) | (
              { __typename?: 'Customer' }
              & Pick<Customer, 'gid' | 'id'>
            ) | (
              { __typename?: 'DataPoint' }
              & Pick<DataPoint, 'gid' | 'id'>
            ) | (
              { __typename?: 'FinancingLead' }
              & Pick<FinancingLead, 'gid' | 'id'>
            ) | (
              { __typename?: 'InsightsQuery' }
              & Pick<InsightsQuery, 'gid' | 'id'>
            ) | (
              { __typename?: 'InsuranceLead' }
              & Pick<InsuranceLead, 'gid' | 'id'>
            ) | (
              { __typename?: 'InvoiceAddress' }
              & Pick<InvoiceAddress, 'gid' | 'id'>
            ) | (
              { __typename?: 'Lead' }
              & Pick<Lead, 'gid' | 'id'>
            ) | (
              { __typename?: 'LeadManager' }
              & Pick<LeadManager, 'gid' | 'id'>
            ) | (
              { __typename?: 'NotificationToken' }
              & Pick<NotificationToken, 'gid' | 'id'>
            ) | (
              { __typename?: 'Office' }
              & Pick<Office, 'gid' | 'id'>
            ) | (
              { __typename?: 'Organization' }
              & Pick<Organization, 'gid' | 'id'>
            ) | (
              { __typename?: 'Payment' }
              & Pick<Payment, 'gid' | 'id'>
            ) | (
              { __typename?: 'PaymentMandate' }
              & Pick<PaymentMandate, 'gid' | 'id'>
            ) | (
              { __typename?: 'Pdf' }
              & Pick<Pdf, 'gid' | 'id'>
            ) | (
              { __typename?: 'Policy' }
              & Pick<Policy, 'gid' | 'id'>
            ) | (
              { __typename?: 'PreferencesSet' }
              & Pick<PreferencesSet, 'gid' | 'id'>
            ) | (
              { __typename?: 'Property' }
              & Pick<Property, 'gid' | 'id'>
            ) | (
              { __typename?: 'Review' }
              & Pick<Review, 'gid' | 'id'>
            ) | (
              { __typename?: 'Role' }
              & Pick<Role, 'gid' | 'id'>
            ) | (
              { __typename?: 'SearchProfile' }
              & Pick<SearchProfile, 'gid' | 'id'>
            ) | (
              { __typename?: 'Task' }
              & Pick<Task, 'gid' | 'id'>
            ) | (
              { __typename?: 'Timeframe' }
              & Pick<Timeframe, 'gid' | 'id'>
            ) | (
              { __typename?: 'User' }
              & Pick<User, 'gid' | 'id'>
            )>>, owner?: Maybe<(
              { __typename?: 'AccountBalancePackage' }
              & Pick<AccountBalancePackage, 'id' | 'gid'>
            ) | (
              { __typename?: 'AccountBalanceTransaction' }
              & Pick<AccountBalanceTransaction, 'id' | 'gid'>
            ) | (
              { __typename?: 'Activity' }
              & Pick<Activity, 'id' | 'gid'>
            ) | (
              { __typename?: 'Appointment' }
              & Pick<Appointment, 'id' | 'gid'>
            ) | (
              { __typename?: 'Asset' }
              & Pick<Asset, 'id' | 'gid'>
            ) | (
              { __typename?: 'Broker' }
              & Pick<Broker, 'id' | 'gid'>
            ) | (
              { __typename?: 'Customer' }
              & Pick<Customer, 'id' | 'gid'>
            ) | (
              { __typename?: 'DataPoint' }
              & Pick<DataPoint, 'id' | 'gid'>
            ) | (
              { __typename?: 'FinancingLead' }
              & Pick<FinancingLead, 'id' | 'gid'>
            ) | (
              { __typename?: 'InsightsQuery' }
              & Pick<InsightsQuery, 'id' | 'gid'>
            ) | (
              { __typename?: 'InsuranceLead' }
              & Pick<InsuranceLead, 'id' | 'gid'>
            ) | (
              { __typename?: 'InvoiceAddress' }
              & Pick<InvoiceAddress, 'id' | 'gid'>
            ) | (
              { __typename?: 'Lead' }
              & Pick<Lead, 'id' | 'gid'>
            ) | (
              { __typename?: 'LeadManager' }
              & Pick<LeadManager, 'id' | 'gid'>
            ) | (
              { __typename?: 'NotificationToken' }
              & Pick<NotificationToken, 'id' | 'gid'>
            ) | (
              { __typename?: 'Office' }
              & Pick<Office, 'id' | 'gid'>
            ) | (
              { __typename?: 'Organization' }
              & Pick<Organization, 'id' | 'gid'>
            ) | (
              { __typename?: 'Payment' }
              & Pick<Payment, 'id' | 'gid'>
            ) | (
              { __typename?: 'PaymentMandate' }
              & Pick<PaymentMandate, 'id' | 'gid'>
            ) | (
              { __typename?: 'Pdf' }
              & Pick<Pdf, 'id' | 'gid'>
            ) | (
              { __typename?: 'Policy' }
              & Pick<Policy, 'id' | 'gid'>
            ) | (
              { __typename?: 'PreferencesSet' }
              & Pick<PreferencesSet, 'id' | 'gid'>
            ) | (
              { __typename?: 'Property' }
              & Pick<Property, 'id' | 'gid'>
            ) | (
              { __typename?: 'Review' }
              & Pick<Review, 'id' | 'gid'>
            ) | (
              { __typename?: 'Role' }
              & Pick<Role, 'id' | 'gid'>
            ) | (
              { __typename?: 'SearchProfile' }
              & Pick<SearchProfile, 'id' | 'gid'>
            ) | (
              { __typename?: 'Task' }
              & Pick<Task, 'id' | 'gid'>
            ) | (
              { __typename?: 'Timeframe' }
              & Pick<Timeframe, 'id' | 'gid'>
            ) | (
              { __typename?: 'User' }
              & Pick<User, 'id' | 'gid'>
            )> }
          )> }
        )>, office?: Maybe<(
          { __typename: 'Office' }
          & Pick<Office, 'id' | 'disabledPaymentMethods'>
        )>, leadManager?: Maybe<(
          { __typename: 'LeadManager' }
          & Pick<LeadManager, 'id' | 'acceptableLimit'>
        )>, supervisor?: Maybe<(
          { __typename: 'Broker' }
          & Pick<Broker, 'id'>
          & { preferences: (
            { __typename: 'PreferencesSet' }
            & Pick<PreferencesSet, 'id' | 'firstName' | 'lastName'>
          ) }
        )> }
      )>, error?: Maybe<(
        { __typename: 'MutationError' }
        & Pick<MutationError, 'type' | 'actionDetails' | 'message'>
      )> }
    ) }
  ) }
);

export type PauseLeadMutationVariables = Exact<{
  id: Scalars['LeadGlobalIdOrUuid'];
  reason: Scalars['String'];
  date: Scalars['DateTime'];
}>;


export type PauseLeadMutation = (
  { __typename?: 'Mutations' }
  & { lead: (
    { __typename?: 'LeadNamespace' }
    & { delay: (
      { __typename?: 'LeadDelayPayload' }
      & { lead?: Maybe<(
        { __typename: 'Lead' }
        & Pick<Lead, 'closedReason' | 'id' | 'gid' | 'identifier' | 'createdAt' | 'processedAt' | 'accessPermittedAt' | 'activatedAt' | 'closedAt' | 'lockedAt' | 'soldAt' | 'dueAt' | 'labels' | 'statePropertiesUpdatedAt' | 'statePropertiesUpdateRequestedAt' | 'statePropertiesUpdateSkippedUntil' | 'contractProperties' | 'currentMandatoryTaskName' | 'processedWith' | 'processedComment'>
        & { property?: Maybe<(
          { __typename: 'Property' }
          & Pick<Property, 'dossierLink' | 'id'>
          & { objectDetails?: Maybe<(
            { __typename: 'PropertyObjectDetails' }
            & Pick<PropertyObjectDetails, 'saleHorizon' | 'condition' | 'landSize' | 'livingSpace' | 'amountRooms' | 'amountBathrooms' | 'amountParkingSpaces' | 'saleReason' | 'ownershipStructure' | 'freeFrom' | 'amountBalcony' | 'amountTerrace' | 'listed' | 'equipmentText' | 'descriptionText' | 'locationText' | 'additionalInfoText' | 'yearOfConstruction' | 'construction' | 'exploitation' | 'yearOfModernization' | 'rented' | 'inhabited' | 'amountUnits' | 'netRent' | 'propertyType' | 'propertySubtype' | 'city' | 'postalCode' | 'street' | 'streetAddition' | 'latitude' | 'longitude'>
            & { equipment?: Maybe<(
              { __typename?: 'PropertyEquipment' }
              & Pick<PropertyEquipment, 'attic' | 'cellar' | 'lift' | 'kitchen' | 'handicappedAccessible' | 'seniorFriendly' | 'garden' | 'guestToilet' | 'sauna' | 'parkingSpaceType'>
            )> }
          )>, owner?: Maybe<(
            { __typename: 'User' }
            & Pick<User, 'id'>
            & { preferences: (
              { __typename: 'PreferencesSet' }
              & Pick<PreferencesSet, 'id' | 'firstName' | 'lastName'>
            ) }
          )>, valuation?: Maybe<(
            { __typename: 'PropertyValuation' }
            & Pick<PropertyValuation, 'value'>
          )> }
        )>, objectProperties?: Maybe<(
          { __typename?: 'LeadObjectProperties' }
          & Pick<LeadObjectProperties, 'additionalInfo' | 'evaluationReason' | 'isForSale'>
        )>, contacts?: Maybe<Array<(
          { __typename: 'LeadContact' }
          & Pick<LeadContact, 'firstName' | 'lastName' | 'emails' | 'gender' | 'phoneNumbers' | 'reachability'>
          & { customer?: Maybe<(
            { __typename: 'Customer' }
            & Pick<Customer, 'id'>
            & { avatar?: Maybe<(
              { __typename?: 'Asset' }
              & Pick<Asset, 'fileUrl'>
            )> }
          )> }
        )>>, brokers?: Maybe<Array<(
          { __typename: 'Broker' }
          & Pick<Broker, 'id'>
        )>>, assets?: Maybe<(
          { __typename?: 'AssetPage' }
          & { nodes: Array<(
            { __typename: 'Asset' }
            & Pick<Asset, 'gid' | 'id' | 'filename' | 'fileUrl' | 'contentType' | 'title' | 'metadata'>
          )> }
        )>, stateProperties?: Maybe<(
          { __typename: 'LeadStateProperties' }
          & Pick<LeadStateProperties, 'currency' | 'customerAppointmentDate' | 'performEvaluationPrice' | 'performEvaluationDate' | 'performEvaluationDateTookPlace' | 'evaluationGid' | 'shareEvaluation' | 'nextCustomerContactDate' | 'acquisitionCompleted' | 'updateAlternativeText' | 'brokerContractCommissionUnit' | 'brokerContractCommission' | 'brokerContractBuyerCommission' | 'brokerContractBuyerCommissionUnit' | 'brokerContractDate' | 'brokerContractPrice' | 'brokerContractType' | 'expectedSalePrice' | 'brokerContractTermDate' | 'expectedSaleNotaryDate' | 'objectSaleBuyerCommission' | 'objectSaleBuyerCommissionTaxation' | 'objectSaleBuyerCommissionUnit' | 'objectSaleCommission' | 'objectSaleCommissionTaxation' | 'objectSaleCommissionUnit' | 'objectSaleNotaryDate' | 'objectSalePrice' | 'marketingCompleted' | 'saleCompleted' | 'invoiceSent'>
        )>, tasks?: Maybe<(
          { __typename: 'TaskPage' }
          & { nodes: Array<(
            { __typename: 'Task' }
            & Pick<Task, 'id' | 'title' | 'description' | 'type' | 'status' | 'updatedAt' | 'createdAt' | 'editable' | 'dueDate' | 'result'>
            & { references?: Maybe<Array<(
              { __typename?: 'AccountBalancePackage' }
              & Pick<AccountBalancePackage, 'gid' | 'id'>
            ) | (
              { __typename?: 'AccountBalanceTransaction' }
              & Pick<AccountBalanceTransaction, 'gid' | 'id'>
            ) | (
              { __typename?: 'Activity' }
              & Pick<Activity, 'gid' | 'id'>
            ) | (
              { __typename?: 'Appointment' }
              & Pick<Appointment, 'gid' | 'id'>
            ) | (
              { __typename?: 'Asset' }
              & Pick<Asset, 'gid' | 'id'>
            ) | (
              { __typename?: 'Broker' }
              & Pick<Broker, 'gid' | 'id'>
            ) | (
              { __typename?: 'Customer' }
              & Pick<Customer, 'gid' | 'id'>
            ) | (
              { __typename?: 'DataPoint' }
              & Pick<DataPoint, 'gid' | 'id'>
            ) | (
              { __typename?: 'FinancingLead' }
              & Pick<FinancingLead, 'gid' | 'id'>
            ) | (
              { __typename?: 'InsightsQuery' }
              & Pick<InsightsQuery, 'gid' | 'id'>
            ) | (
              { __typename?: 'InsuranceLead' }
              & Pick<InsuranceLead, 'gid' | 'id'>
            ) | (
              { __typename?: 'InvoiceAddress' }
              & Pick<InvoiceAddress, 'gid' | 'id'>
            ) | (
              { __typename?: 'Lead' }
              & Pick<Lead, 'gid' | 'id'>
            ) | (
              { __typename?: 'LeadManager' }
              & Pick<LeadManager, 'gid' | 'id'>
            ) | (
              { __typename?: 'NotificationToken' }
              & Pick<NotificationToken, 'gid' | 'id'>
            ) | (
              { __typename?: 'Office' }
              & Pick<Office, 'gid' | 'id'>
            ) | (
              { __typename?: 'Organization' }
              & Pick<Organization, 'gid' | 'id'>
            ) | (
              { __typename?: 'Payment' }
              & Pick<Payment, 'gid' | 'id'>
            ) | (
              { __typename?: 'PaymentMandate' }
              & Pick<PaymentMandate, 'gid' | 'id'>
            ) | (
              { __typename?: 'Pdf' }
              & Pick<Pdf, 'gid' | 'id'>
            ) | (
              { __typename?: 'Policy' }
              & Pick<Policy, 'gid' | 'id'>
            ) | (
              { __typename?: 'PreferencesSet' }
              & Pick<PreferencesSet, 'gid' | 'id'>
            ) | (
              { __typename?: 'Property' }
              & Pick<Property, 'gid' | 'id'>
            ) | (
              { __typename?: 'Review' }
              & Pick<Review, 'gid' | 'id'>
            ) | (
              { __typename?: 'Role' }
              & Pick<Role, 'gid' | 'id'>
            ) | (
              { __typename?: 'SearchProfile' }
              & Pick<SearchProfile, 'gid' | 'id'>
            ) | (
              { __typename?: 'Task' }
              & Pick<Task, 'gid' | 'id'>
            ) | (
              { __typename?: 'Timeframe' }
              & Pick<Timeframe, 'gid' | 'id'>
            ) | (
              { __typename?: 'User' }
              & Pick<User, 'gid' | 'id'>
            )>>, owner?: Maybe<(
              { __typename?: 'AccountBalancePackage' }
              & Pick<AccountBalancePackage, 'id' | 'gid'>
            ) | (
              { __typename?: 'AccountBalanceTransaction' }
              & Pick<AccountBalanceTransaction, 'id' | 'gid'>
            ) | (
              { __typename?: 'Activity' }
              & Pick<Activity, 'id' | 'gid'>
            ) | (
              { __typename?: 'Appointment' }
              & Pick<Appointment, 'id' | 'gid'>
            ) | (
              { __typename?: 'Asset' }
              & Pick<Asset, 'id' | 'gid'>
            ) | (
              { __typename?: 'Broker' }
              & Pick<Broker, 'id' | 'gid'>
            ) | (
              { __typename?: 'Customer' }
              & Pick<Customer, 'id' | 'gid'>
            ) | (
              { __typename?: 'DataPoint' }
              & Pick<DataPoint, 'id' | 'gid'>
            ) | (
              { __typename?: 'FinancingLead' }
              & Pick<FinancingLead, 'id' | 'gid'>
            ) | (
              { __typename?: 'InsightsQuery' }
              & Pick<InsightsQuery, 'id' | 'gid'>
            ) | (
              { __typename?: 'InsuranceLead' }
              & Pick<InsuranceLead, 'id' | 'gid'>
            ) | (
              { __typename?: 'InvoiceAddress' }
              & Pick<InvoiceAddress, 'id' | 'gid'>
            ) | (
              { __typename?: 'Lead' }
              & Pick<Lead, 'id' | 'gid'>
            ) | (
              { __typename?: 'LeadManager' }
              & Pick<LeadManager, 'id' | 'gid'>
            ) | (
              { __typename?: 'NotificationToken' }
              & Pick<NotificationToken, 'id' | 'gid'>
            ) | (
              { __typename?: 'Office' }
              & Pick<Office, 'id' | 'gid'>
            ) | (
              { __typename?: 'Organization' }
              & Pick<Organization, 'id' | 'gid'>
            ) | (
              { __typename?: 'Payment' }
              & Pick<Payment, 'id' | 'gid'>
            ) | (
              { __typename?: 'PaymentMandate' }
              & Pick<PaymentMandate, 'id' | 'gid'>
            ) | (
              { __typename?: 'Pdf' }
              & Pick<Pdf, 'id' | 'gid'>
            ) | (
              { __typename?: 'Policy' }
              & Pick<Policy, 'id' | 'gid'>
            ) | (
              { __typename?: 'PreferencesSet' }
              & Pick<PreferencesSet, 'id' | 'gid'>
            ) | (
              { __typename?: 'Property' }
              & Pick<Property, 'id' | 'gid'>
            ) | (
              { __typename?: 'Review' }
              & Pick<Review, 'id' | 'gid'>
            ) | (
              { __typename?: 'Role' }
              & Pick<Role, 'id' | 'gid'>
            ) | (
              { __typename?: 'SearchProfile' }
              & Pick<SearchProfile, 'id' | 'gid'>
            ) | (
              { __typename?: 'Task' }
              & Pick<Task, 'id' | 'gid'>
            ) | (
              { __typename?: 'Timeframe' }
              & Pick<Timeframe, 'id' | 'gid'>
            ) | (
              { __typename?: 'User' }
              & Pick<User, 'id' | 'gid'>
            )> }
          )> }
        )>, office?: Maybe<(
          { __typename: 'Office' }
          & Pick<Office, 'id' | 'disabledPaymentMethods'>
        )>, leadManager?: Maybe<(
          { __typename: 'LeadManager' }
          & Pick<LeadManager, 'id' | 'acceptableLimit'>
        )>, supervisor?: Maybe<(
          { __typename: 'Broker' }
          & Pick<Broker, 'id'>
          & { preferences: (
            { __typename: 'PreferencesSet' }
            & Pick<PreferencesSet, 'id' | 'firstName' | 'lastName'>
          ) }
        )> }
      )>, error?: Maybe<(
        { __typename: 'MutationError' }
        & Pick<MutationError, 'type' | 'actionDetails' | 'message'>
      )> }
    ) }
  ) }
);

export type ResumeLeadMutationVariables = Exact<{
  id: Scalars['LeadGlobalIdOrUuid'];
}>;


export type ResumeLeadMutation = (
  { __typename?: 'Mutations' }
  & { lead: (
    { __typename?: 'LeadNamespace' }
    & { removeDelay: (
      { __typename?: 'LeadRemoveDelayPayload' }
      & { lead?: Maybe<(
        { __typename: 'Lead' }
        & Pick<Lead, 'closedReason' | 'id' | 'gid' | 'identifier' | 'createdAt' | 'processedAt' | 'accessPermittedAt' | 'activatedAt' | 'closedAt' | 'lockedAt' | 'soldAt' | 'dueAt' | 'labels' | 'statePropertiesUpdatedAt' | 'statePropertiesUpdateRequestedAt' | 'statePropertiesUpdateSkippedUntil' | 'contractProperties' | 'currentMandatoryTaskName' | 'processedWith' | 'processedComment'>
        & { property?: Maybe<(
          { __typename: 'Property' }
          & Pick<Property, 'dossierLink' | 'id'>
          & { objectDetails?: Maybe<(
            { __typename: 'PropertyObjectDetails' }
            & Pick<PropertyObjectDetails, 'saleHorizon' | 'condition' | 'landSize' | 'livingSpace' | 'amountRooms' | 'amountBathrooms' | 'amountParkingSpaces' | 'saleReason' | 'ownershipStructure' | 'freeFrom' | 'amountBalcony' | 'amountTerrace' | 'listed' | 'equipmentText' | 'descriptionText' | 'locationText' | 'additionalInfoText' | 'yearOfConstruction' | 'construction' | 'exploitation' | 'yearOfModernization' | 'rented' | 'inhabited' | 'amountUnits' | 'netRent' | 'propertyType' | 'propertySubtype' | 'city' | 'postalCode' | 'street' | 'streetAddition' | 'latitude' | 'longitude'>
            & { equipment?: Maybe<(
              { __typename?: 'PropertyEquipment' }
              & Pick<PropertyEquipment, 'attic' | 'cellar' | 'lift' | 'kitchen' | 'handicappedAccessible' | 'seniorFriendly' | 'garden' | 'guestToilet' | 'sauna' | 'parkingSpaceType'>
            )> }
          )>, owner?: Maybe<(
            { __typename: 'User' }
            & Pick<User, 'id'>
            & { preferences: (
              { __typename: 'PreferencesSet' }
              & Pick<PreferencesSet, 'id' | 'firstName' | 'lastName'>
            ) }
          )>, valuation?: Maybe<(
            { __typename: 'PropertyValuation' }
            & Pick<PropertyValuation, 'value'>
          )> }
        )>, objectProperties?: Maybe<(
          { __typename?: 'LeadObjectProperties' }
          & Pick<LeadObjectProperties, 'additionalInfo' | 'evaluationReason' | 'isForSale'>
        )>, contacts?: Maybe<Array<(
          { __typename: 'LeadContact' }
          & Pick<LeadContact, 'firstName' | 'lastName' | 'emails' | 'gender' | 'phoneNumbers' | 'reachability'>
          & { customer?: Maybe<(
            { __typename: 'Customer' }
            & Pick<Customer, 'id'>
            & { avatar?: Maybe<(
              { __typename?: 'Asset' }
              & Pick<Asset, 'fileUrl'>
            )> }
          )> }
        )>>, brokers?: Maybe<Array<(
          { __typename: 'Broker' }
          & Pick<Broker, 'id'>
        )>>, assets?: Maybe<(
          { __typename?: 'AssetPage' }
          & { nodes: Array<(
            { __typename: 'Asset' }
            & Pick<Asset, 'gid' | 'id' | 'filename' | 'fileUrl' | 'contentType' | 'title' | 'metadata'>
          )> }
        )>, stateProperties?: Maybe<(
          { __typename: 'LeadStateProperties' }
          & Pick<LeadStateProperties, 'currency' | 'customerAppointmentDate' | 'performEvaluationPrice' | 'performEvaluationDate' | 'performEvaluationDateTookPlace' | 'evaluationGid' | 'shareEvaluation' | 'nextCustomerContactDate' | 'acquisitionCompleted' | 'updateAlternativeText' | 'brokerContractCommissionUnit' | 'brokerContractCommission' | 'brokerContractBuyerCommission' | 'brokerContractBuyerCommissionUnit' | 'brokerContractDate' | 'brokerContractPrice' | 'brokerContractType' | 'expectedSalePrice' | 'brokerContractTermDate' | 'expectedSaleNotaryDate' | 'objectSaleBuyerCommission' | 'objectSaleBuyerCommissionTaxation' | 'objectSaleBuyerCommissionUnit' | 'objectSaleCommission' | 'objectSaleCommissionTaxation' | 'objectSaleCommissionUnit' | 'objectSaleNotaryDate' | 'objectSalePrice' | 'marketingCompleted' | 'saleCompleted' | 'invoiceSent'>
        )>, tasks?: Maybe<(
          { __typename: 'TaskPage' }
          & { nodes: Array<(
            { __typename: 'Task' }
            & Pick<Task, 'id' | 'title' | 'description' | 'type' | 'status' | 'updatedAt' | 'createdAt' | 'editable' | 'dueDate' | 'result'>
            & { references?: Maybe<Array<(
              { __typename?: 'AccountBalancePackage' }
              & Pick<AccountBalancePackage, 'gid' | 'id'>
            ) | (
              { __typename?: 'AccountBalanceTransaction' }
              & Pick<AccountBalanceTransaction, 'gid' | 'id'>
            ) | (
              { __typename?: 'Activity' }
              & Pick<Activity, 'gid' | 'id'>
            ) | (
              { __typename?: 'Appointment' }
              & Pick<Appointment, 'gid' | 'id'>
            ) | (
              { __typename?: 'Asset' }
              & Pick<Asset, 'gid' | 'id'>
            ) | (
              { __typename?: 'Broker' }
              & Pick<Broker, 'gid' | 'id'>
            ) | (
              { __typename?: 'Customer' }
              & Pick<Customer, 'gid' | 'id'>
            ) | (
              { __typename?: 'DataPoint' }
              & Pick<DataPoint, 'gid' | 'id'>
            ) | (
              { __typename?: 'FinancingLead' }
              & Pick<FinancingLead, 'gid' | 'id'>
            ) | (
              { __typename?: 'InsightsQuery' }
              & Pick<InsightsQuery, 'gid' | 'id'>
            ) | (
              { __typename?: 'InsuranceLead' }
              & Pick<InsuranceLead, 'gid' | 'id'>
            ) | (
              { __typename?: 'InvoiceAddress' }
              & Pick<InvoiceAddress, 'gid' | 'id'>
            ) | (
              { __typename?: 'Lead' }
              & Pick<Lead, 'gid' | 'id'>
            ) | (
              { __typename?: 'LeadManager' }
              & Pick<LeadManager, 'gid' | 'id'>
            ) | (
              { __typename?: 'NotificationToken' }
              & Pick<NotificationToken, 'gid' | 'id'>
            ) | (
              { __typename?: 'Office' }
              & Pick<Office, 'gid' | 'id'>
            ) | (
              { __typename?: 'Organization' }
              & Pick<Organization, 'gid' | 'id'>
            ) | (
              { __typename?: 'Payment' }
              & Pick<Payment, 'gid' | 'id'>
            ) | (
              { __typename?: 'PaymentMandate' }
              & Pick<PaymentMandate, 'gid' | 'id'>
            ) | (
              { __typename?: 'Pdf' }
              & Pick<Pdf, 'gid' | 'id'>
            ) | (
              { __typename?: 'Policy' }
              & Pick<Policy, 'gid' | 'id'>
            ) | (
              { __typename?: 'PreferencesSet' }
              & Pick<PreferencesSet, 'gid' | 'id'>
            ) | (
              { __typename?: 'Property' }
              & Pick<Property, 'gid' | 'id'>
            ) | (
              { __typename?: 'Review' }
              & Pick<Review, 'gid' | 'id'>
            ) | (
              { __typename?: 'Role' }
              & Pick<Role, 'gid' | 'id'>
            ) | (
              { __typename?: 'SearchProfile' }
              & Pick<SearchProfile, 'gid' | 'id'>
            ) | (
              { __typename?: 'Task' }
              & Pick<Task, 'gid' | 'id'>
            ) | (
              { __typename?: 'Timeframe' }
              & Pick<Timeframe, 'gid' | 'id'>
            ) | (
              { __typename?: 'User' }
              & Pick<User, 'gid' | 'id'>
            )>>, owner?: Maybe<(
              { __typename?: 'AccountBalancePackage' }
              & Pick<AccountBalancePackage, 'id' | 'gid'>
            ) | (
              { __typename?: 'AccountBalanceTransaction' }
              & Pick<AccountBalanceTransaction, 'id' | 'gid'>
            ) | (
              { __typename?: 'Activity' }
              & Pick<Activity, 'id' | 'gid'>
            ) | (
              { __typename?: 'Appointment' }
              & Pick<Appointment, 'id' | 'gid'>
            ) | (
              { __typename?: 'Asset' }
              & Pick<Asset, 'id' | 'gid'>
            ) | (
              { __typename?: 'Broker' }
              & Pick<Broker, 'id' | 'gid'>
            ) | (
              { __typename?: 'Customer' }
              & Pick<Customer, 'id' | 'gid'>
            ) | (
              { __typename?: 'DataPoint' }
              & Pick<DataPoint, 'id' | 'gid'>
            ) | (
              { __typename?: 'FinancingLead' }
              & Pick<FinancingLead, 'id' | 'gid'>
            ) | (
              { __typename?: 'InsightsQuery' }
              & Pick<InsightsQuery, 'id' | 'gid'>
            ) | (
              { __typename?: 'InsuranceLead' }
              & Pick<InsuranceLead, 'id' | 'gid'>
            ) | (
              { __typename?: 'InvoiceAddress' }
              & Pick<InvoiceAddress, 'id' | 'gid'>
            ) | (
              { __typename?: 'Lead' }
              & Pick<Lead, 'id' | 'gid'>
            ) | (
              { __typename?: 'LeadManager' }
              & Pick<LeadManager, 'id' | 'gid'>
            ) | (
              { __typename?: 'NotificationToken' }
              & Pick<NotificationToken, 'id' | 'gid'>
            ) | (
              { __typename?: 'Office' }
              & Pick<Office, 'id' | 'gid'>
            ) | (
              { __typename?: 'Organization' }
              & Pick<Organization, 'id' | 'gid'>
            ) | (
              { __typename?: 'Payment' }
              & Pick<Payment, 'id' | 'gid'>
            ) | (
              { __typename?: 'PaymentMandate' }
              & Pick<PaymentMandate, 'id' | 'gid'>
            ) | (
              { __typename?: 'Pdf' }
              & Pick<Pdf, 'id' | 'gid'>
            ) | (
              { __typename?: 'Policy' }
              & Pick<Policy, 'id' | 'gid'>
            ) | (
              { __typename?: 'PreferencesSet' }
              & Pick<PreferencesSet, 'id' | 'gid'>
            ) | (
              { __typename?: 'Property' }
              & Pick<Property, 'id' | 'gid'>
            ) | (
              { __typename?: 'Review' }
              & Pick<Review, 'id' | 'gid'>
            ) | (
              { __typename?: 'Role' }
              & Pick<Role, 'id' | 'gid'>
            ) | (
              { __typename?: 'SearchProfile' }
              & Pick<SearchProfile, 'id' | 'gid'>
            ) | (
              { __typename?: 'Task' }
              & Pick<Task, 'id' | 'gid'>
            ) | (
              { __typename?: 'Timeframe' }
              & Pick<Timeframe, 'id' | 'gid'>
            ) | (
              { __typename?: 'User' }
              & Pick<User, 'id' | 'gid'>
            )> }
          )> }
        )>, office?: Maybe<(
          { __typename: 'Office' }
          & Pick<Office, 'id' | 'disabledPaymentMethods'>
        )>, leadManager?: Maybe<(
          { __typename: 'LeadManager' }
          & Pick<LeadManager, 'id' | 'acceptableLimit'>
        )>, supervisor?: Maybe<(
          { __typename: 'Broker' }
          & Pick<Broker, 'id'>
          & { preferences: (
            { __typename: 'PreferencesSet' }
            & Pick<PreferencesSet, 'id' | 'firstName' | 'lastName'>
          ) }
        )> }
      )>, error?: Maybe<(
        { __typename: 'MutationError' }
        & Pick<MutationError, 'type' | 'actionDetails' | 'message'>
      )> }
    ) }
  ) }
);

export type SellLeadMutationVariables = Exact<{
  id: Scalars['LeadGlobalIdOrUuid'];
  userId: Scalars['ID'];
}>;


export type SellLeadMutation = (
  { __typename?: 'Mutations' }
  & { lead: (
    { __typename?: 'LeadNamespace' }
    & { sell: (
      { __typename?: 'LeadSellPayload' }
      & { lead?: Maybe<(
        { __typename: 'Lead' }
        & Pick<Lead, 'closedReason' | 'id' | 'gid' | 'identifier' | 'createdAt' | 'processedAt' | 'accessPermittedAt' | 'activatedAt' | 'closedAt' | 'lockedAt' | 'soldAt' | 'dueAt' | 'labels' | 'statePropertiesUpdatedAt' | 'statePropertiesUpdateRequestedAt' | 'statePropertiesUpdateSkippedUntil' | 'contractProperties' | 'currentMandatoryTaskName' | 'processedWith' | 'processedComment'>
        & { property?: Maybe<(
          { __typename: 'Property' }
          & Pick<Property, 'dossierLink' | 'id'>
          & { objectDetails?: Maybe<(
            { __typename: 'PropertyObjectDetails' }
            & Pick<PropertyObjectDetails, 'saleHorizon' | 'condition' | 'landSize' | 'livingSpace' | 'amountRooms' | 'amountBathrooms' | 'amountParkingSpaces' | 'saleReason' | 'ownershipStructure' | 'freeFrom' | 'amountBalcony' | 'amountTerrace' | 'listed' | 'equipmentText' | 'descriptionText' | 'locationText' | 'additionalInfoText' | 'yearOfConstruction' | 'construction' | 'exploitation' | 'yearOfModernization' | 'rented' | 'inhabited' | 'amountUnits' | 'netRent' | 'propertyType' | 'propertySubtype' | 'city' | 'postalCode' | 'street' | 'streetAddition' | 'latitude' | 'longitude'>
            & { equipment?: Maybe<(
              { __typename?: 'PropertyEquipment' }
              & Pick<PropertyEquipment, 'attic' | 'cellar' | 'lift' | 'kitchen' | 'handicappedAccessible' | 'seniorFriendly' | 'garden' | 'guestToilet' | 'sauna' | 'parkingSpaceType'>
            )> }
          )>, owner?: Maybe<(
            { __typename: 'User' }
            & Pick<User, 'id'>
            & { preferences: (
              { __typename: 'PreferencesSet' }
              & Pick<PreferencesSet, 'id' | 'firstName' | 'lastName'>
            ) }
          )>, valuation?: Maybe<(
            { __typename: 'PropertyValuation' }
            & Pick<PropertyValuation, 'value'>
          )> }
        )>, objectProperties?: Maybe<(
          { __typename?: 'LeadObjectProperties' }
          & Pick<LeadObjectProperties, 'additionalInfo' | 'evaluationReason' | 'isForSale'>
        )>, contacts?: Maybe<Array<(
          { __typename: 'LeadContact' }
          & Pick<LeadContact, 'firstName' | 'lastName' | 'emails' | 'gender' | 'phoneNumbers' | 'reachability'>
          & { customer?: Maybe<(
            { __typename: 'Customer' }
            & Pick<Customer, 'id'>
            & { avatar?: Maybe<(
              { __typename?: 'Asset' }
              & Pick<Asset, 'fileUrl'>
            )> }
          )> }
        )>>, brokers?: Maybe<Array<(
          { __typename: 'Broker' }
          & Pick<Broker, 'id'>
        )>>, assets?: Maybe<(
          { __typename?: 'AssetPage' }
          & { nodes: Array<(
            { __typename: 'Asset' }
            & Pick<Asset, 'gid' | 'id' | 'filename' | 'fileUrl' | 'contentType' | 'title' | 'metadata'>
          )> }
        )>, stateProperties?: Maybe<(
          { __typename: 'LeadStateProperties' }
          & Pick<LeadStateProperties, 'currency' | 'customerAppointmentDate' | 'performEvaluationPrice' | 'performEvaluationDate' | 'performEvaluationDateTookPlace' | 'evaluationGid' | 'shareEvaluation' | 'nextCustomerContactDate' | 'acquisitionCompleted' | 'updateAlternativeText' | 'brokerContractCommissionUnit' | 'brokerContractCommission' | 'brokerContractBuyerCommission' | 'brokerContractBuyerCommissionUnit' | 'brokerContractDate' | 'brokerContractPrice' | 'brokerContractType' | 'expectedSalePrice' | 'brokerContractTermDate' | 'expectedSaleNotaryDate' | 'objectSaleBuyerCommission' | 'objectSaleBuyerCommissionTaxation' | 'objectSaleBuyerCommissionUnit' | 'objectSaleCommission' | 'objectSaleCommissionTaxation' | 'objectSaleCommissionUnit' | 'objectSaleNotaryDate' | 'objectSalePrice' | 'marketingCompleted' | 'saleCompleted' | 'invoiceSent'>
        )>, tasks?: Maybe<(
          { __typename: 'TaskPage' }
          & { nodes: Array<(
            { __typename: 'Task' }
            & Pick<Task, 'id' | 'title' | 'description' | 'type' | 'status' | 'updatedAt' | 'createdAt' | 'editable' | 'dueDate' | 'result'>
            & { references?: Maybe<Array<(
              { __typename?: 'AccountBalancePackage' }
              & Pick<AccountBalancePackage, 'gid' | 'id'>
            ) | (
              { __typename?: 'AccountBalanceTransaction' }
              & Pick<AccountBalanceTransaction, 'gid' | 'id'>
            ) | (
              { __typename?: 'Activity' }
              & Pick<Activity, 'gid' | 'id'>
            ) | (
              { __typename?: 'Appointment' }
              & Pick<Appointment, 'gid' | 'id'>
            ) | (
              { __typename?: 'Asset' }
              & Pick<Asset, 'gid' | 'id'>
            ) | (
              { __typename?: 'Broker' }
              & Pick<Broker, 'gid' | 'id'>
            ) | (
              { __typename?: 'Customer' }
              & Pick<Customer, 'gid' | 'id'>
            ) | (
              { __typename?: 'DataPoint' }
              & Pick<DataPoint, 'gid' | 'id'>
            ) | (
              { __typename?: 'FinancingLead' }
              & Pick<FinancingLead, 'gid' | 'id'>
            ) | (
              { __typename?: 'InsightsQuery' }
              & Pick<InsightsQuery, 'gid' | 'id'>
            ) | (
              { __typename?: 'InsuranceLead' }
              & Pick<InsuranceLead, 'gid' | 'id'>
            ) | (
              { __typename?: 'InvoiceAddress' }
              & Pick<InvoiceAddress, 'gid' | 'id'>
            ) | (
              { __typename?: 'Lead' }
              & Pick<Lead, 'gid' | 'id'>
            ) | (
              { __typename?: 'LeadManager' }
              & Pick<LeadManager, 'gid' | 'id'>
            ) | (
              { __typename?: 'NotificationToken' }
              & Pick<NotificationToken, 'gid' | 'id'>
            ) | (
              { __typename?: 'Office' }
              & Pick<Office, 'gid' | 'id'>
            ) | (
              { __typename?: 'Organization' }
              & Pick<Organization, 'gid' | 'id'>
            ) | (
              { __typename?: 'Payment' }
              & Pick<Payment, 'gid' | 'id'>
            ) | (
              { __typename?: 'PaymentMandate' }
              & Pick<PaymentMandate, 'gid' | 'id'>
            ) | (
              { __typename?: 'Pdf' }
              & Pick<Pdf, 'gid' | 'id'>
            ) | (
              { __typename?: 'Policy' }
              & Pick<Policy, 'gid' | 'id'>
            ) | (
              { __typename?: 'PreferencesSet' }
              & Pick<PreferencesSet, 'gid' | 'id'>
            ) | (
              { __typename?: 'Property' }
              & Pick<Property, 'gid' | 'id'>
            ) | (
              { __typename?: 'Review' }
              & Pick<Review, 'gid' | 'id'>
            ) | (
              { __typename?: 'Role' }
              & Pick<Role, 'gid' | 'id'>
            ) | (
              { __typename?: 'SearchProfile' }
              & Pick<SearchProfile, 'gid' | 'id'>
            ) | (
              { __typename?: 'Task' }
              & Pick<Task, 'gid' | 'id'>
            ) | (
              { __typename?: 'Timeframe' }
              & Pick<Timeframe, 'gid' | 'id'>
            ) | (
              { __typename?: 'User' }
              & Pick<User, 'gid' | 'id'>
            )>>, owner?: Maybe<(
              { __typename?: 'AccountBalancePackage' }
              & Pick<AccountBalancePackage, 'id' | 'gid'>
            ) | (
              { __typename?: 'AccountBalanceTransaction' }
              & Pick<AccountBalanceTransaction, 'id' | 'gid'>
            ) | (
              { __typename?: 'Activity' }
              & Pick<Activity, 'id' | 'gid'>
            ) | (
              { __typename?: 'Appointment' }
              & Pick<Appointment, 'id' | 'gid'>
            ) | (
              { __typename?: 'Asset' }
              & Pick<Asset, 'id' | 'gid'>
            ) | (
              { __typename?: 'Broker' }
              & Pick<Broker, 'id' | 'gid'>
            ) | (
              { __typename?: 'Customer' }
              & Pick<Customer, 'id' | 'gid'>
            ) | (
              { __typename?: 'DataPoint' }
              & Pick<DataPoint, 'id' | 'gid'>
            ) | (
              { __typename?: 'FinancingLead' }
              & Pick<FinancingLead, 'id' | 'gid'>
            ) | (
              { __typename?: 'InsightsQuery' }
              & Pick<InsightsQuery, 'id' | 'gid'>
            ) | (
              { __typename?: 'InsuranceLead' }
              & Pick<InsuranceLead, 'id' | 'gid'>
            ) | (
              { __typename?: 'InvoiceAddress' }
              & Pick<InvoiceAddress, 'id' | 'gid'>
            ) | (
              { __typename?: 'Lead' }
              & Pick<Lead, 'id' | 'gid'>
            ) | (
              { __typename?: 'LeadManager' }
              & Pick<LeadManager, 'id' | 'gid'>
            ) | (
              { __typename?: 'NotificationToken' }
              & Pick<NotificationToken, 'id' | 'gid'>
            ) | (
              { __typename?: 'Office' }
              & Pick<Office, 'id' | 'gid'>
            ) | (
              { __typename?: 'Organization' }
              & Pick<Organization, 'id' | 'gid'>
            ) | (
              { __typename?: 'Payment' }
              & Pick<Payment, 'id' | 'gid'>
            ) | (
              { __typename?: 'PaymentMandate' }
              & Pick<PaymentMandate, 'id' | 'gid'>
            ) | (
              { __typename?: 'Pdf' }
              & Pick<Pdf, 'id' | 'gid'>
            ) | (
              { __typename?: 'Policy' }
              & Pick<Policy, 'id' | 'gid'>
            ) | (
              { __typename?: 'PreferencesSet' }
              & Pick<PreferencesSet, 'id' | 'gid'>
            ) | (
              { __typename?: 'Property' }
              & Pick<Property, 'id' | 'gid'>
            ) | (
              { __typename?: 'Review' }
              & Pick<Review, 'id' | 'gid'>
            ) | (
              { __typename?: 'Role' }
              & Pick<Role, 'id' | 'gid'>
            ) | (
              { __typename?: 'SearchProfile' }
              & Pick<SearchProfile, 'id' | 'gid'>
            ) | (
              { __typename?: 'Task' }
              & Pick<Task, 'id' | 'gid'>
            ) | (
              { __typename?: 'Timeframe' }
              & Pick<Timeframe, 'id' | 'gid'>
            ) | (
              { __typename?: 'User' }
              & Pick<User, 'id' | 'gid'>
            )> }
          )> }
        )>, office?: Maybe<(
          { __typename: 'Office' }
          & Pick<Office, 'id' | 'disabledPaymentMethods'>
        )>, leadManager?: Maybe<(
          { __typename: 'LeadManager' }
          & Pick<LeadManager, 'id' | 'acceptableLimit'>
        )>, supervisor?: Maybe<(
          { __typename: 'Broker' }
          & Pick<Broker, 'id'>
          & { preferences: (
            { __typename: 'PreferencesSet' }
            & Pick<PreferencesSet, 'id' | 'firstName' | 'lastName'>
          ) }
        )> }
      )>, error?: Maybe<(
        { __typename: 'MutationError' }
        & Pick<MutationError, 'type' | 'actionDetails' | 'message'>
      )> }
    ) }
  ) }
);

export type AddUserMutationVariables = Exact<{
  id: Scalars['LeadGlobalIdOrUuid'];
  userId: Scalars['ID'];
}>;


export type AddUserMutation = (
  { __typename?: 'Mutations' }
  & { lead: (
    { __typename?: 'LeadNamespace' }
    & { addUser: (
      { __typename?: 'LeadAddUserPayload' }
      & { lead?: Maybe<(
        { __typename?: 'Lead' }
        & Pick<Lead, 'id'>
        & { brokers?: Maybe<Array<(
          { __typename: 'Broker' }
          & Pick<Broker, 'id'>
        )>> }
      )>, error?: Maybe<(
        { __typename: 'MutationError' }
        & Pick<MutationError, 'type' | 'actionDetails' | 'message'>
      )> }
    ) }
  ) }
);

export type RemoveUserMutationVariables = Exact<{
  id: Scalars['LeadGlobalIdOrUuid'];
  userId: Scalars['ID'];
}>;


export type RemoveUserMutation = (
  { __typename?: 'Mutations' }
  & { lead: (
    { __typename?: 'LeadNamespace' }
    & { removeUser: (
      { __typename?: 'LeadRemoveUserPayload' }
      & { lead?: Maybe<(
        { __typename?: 'Lead' }
        & Pick<Lead, 'id'>
        & { brokers?: Maybe<Array<(
          { __typename: 'Broker' }
          & Pick<Broker, 'id'>
        )>> }
      )>, error?: Maybe<(
        { __typename: 'MutationError' }
        & Pick<MutationError, 'type' | 'actionDetails' | 'message'>
      )> }
    ) }
  ) }
);

export type CreatePaymentMutationVariables = Exact<{
  ownerGid: Scalars['GlobalID'];
}>;


export type CreatePaymentMutation = (
  { __typename?: 'Mutations' }
  & { payment: (
    { __typename?: 'PaymentNamespace' }
    & { create: (
      { __typename?: 'PaymentCreatePayload' }
      & { payment?: Maybe<(
        { __typename?: 'Payment' }
        & Pick<Payment, 'id' | 'currency' | 'paymentProvider' | 'paymentProviderType' | 'state' | 'settlementStartedAt' | 'settledAt'>
        & { owner?: Maybe<(
          { __typename?: 'AccountBalancePackage' }
          & Pick<AccountBalancePackage, 'id'>
        ) | (
          { __typename?: 'AccountBalanceTransaction' }
          & Pick<AccountBalanceTransaction, 'id'>
        ) | (
          { __typename?: 'Activity' }
          & Pick<Activity, 'id'>
        ) | (
          { __typename?: 'Appointment' }
          & Pick<Appointment, 'id'>
        ) | (
          { __typename?: 'Asset' }
          & Pick<Asset, 'id'>
        ) | (
          { __typename?: 'Broker' }
          & Pick<Broker, 'id'>
        ) | (
          { __typename?: 'Customer' }
          & Pick<Customer, 'id'>
        ) | (
          { __typename?: 'DataPoint' }
          & Pick<DataPoint, 'id'>
        ) | (
          { __typename?: 'FinancingLead' }
          & Pick<FinancingLead, 'id'>
        ) | (
          { __typename?: 'InsightsQuery' }
          & Pick<InsightsQuery, 'id'>
        ) | (
          { __typename?: 'InsuranceLead' }
          & Pick<InsuranceLead, 'id'>
        ) | (
          { __typename?: 'InvoiceAddress' }
          & Pick<InvoiceAddress, 'id'>
        ) | (
          { __typename?: 'Lead' }
          & Pick<Lead, 'id'>
        ) | (
          { __typename?: 'LeadManager' }
          & Pick<LeadManager, 'id'>
        ) | (
          { __typename?: 'NotificationToken' }
          & Pick<NotificationToken, 'id'>
        ) | (
          { __typename?: 'Office' }
          & Pick<Office, 'id'>
        ) | (
          { __typename?: 'Organization' }
          & Pick<Organization, 'id'>
        ) | (
          { __typename?: 'Payment' }
          & Pick<Payment, 'id'>
        ) | (
          { __typename?: 'PaymentMandate' }
          & Pick<PaymentMandate, 'id'>
        ) | (
          { __typename?: 'Pdf' }
          & Pick<Pdf, 'id'>
        ) | (
          { __typename?: 'Policy' }
          & Pick<Policy, 'id'>
        ) | (
          { __typename?: 'PreferencesSet' }
          & Pick<PreferencesSet, 'id'>
        ) | (
          { __typename?: 'Property' }
          & Pick<Property, 'id'>
        ) | (
          { __typename?: 'Review' }
          & Pick<Review, 'id'>
        ) | (
          { __typename?: 'Role' }
          & Pick<Role, 'id'>
        ) | (
          { __typename?: 'SearchProfile' }
          & Pick<SearchProfile, 'id'>
        ) | (
          { __typename?: 'Task' }
          & Pick<Task, 'id'>
        ) | (
          { __typename?: 'Timeframe' }
          & Pick<Timeframe, 'id'>
        ) | (
          { __typename?: 'User' }
          & Pick<User, 'id'>
        )>, items?: Maybe<Array<(
          { __typename?: 'PaymentItem' }
          & Pick<PaymentItem, 'name' | 'netAmount' | 'grossAmount' | 'taxRate'>
          & { sellable?: Maybe<(
            { __typename?: 'AccountBalancePackage' }
            & Pick<AccountBalancePackage, 'id'>
          ) | (
            { __typename?: 'AccountBalanceTransaction' }
            & Pick<AccountBalanceTransaction, 'id'>
          ) | (
            { __typename?: 'Activity' }
            & Pick<Activity, 'id'>
          ) | (
            { __typename?: 'Appointment' }
            & Pick<Appointment, 'id'>
          ) | (
            { __typename?: 'Asset' }
            & Pick<Asset, 'id'>
          ) | (
            { __typename?: 'Broker' }
            & Pick<Broker, 'id'>
          ) | (
            { __typename?: 'Customer' }
            & Pick<Customer, 'id'>
          ) | (
            { __typename?: 'DataPoint' }
            & Pick<DataPoint, 'id'>
          ) | (
            { __typename?: 'FinancingLead' }
            & Pick<FinancingLead, 'id'>
          ) | (
            { __typename?: 'InsightsQuery' }
            & Pick<InsightsQuery, 'id'>
          ) | (
            { __typename?: 'InsuranceLead' }
            & Pick<InsuranceLead, 'id'>
          ) | (
            { __typename?: 'InvoiceAddress' }
            & Pick<InvoiceAddress, 'id'>
          ) | (
            { __typename?: 'Lead' }
            & Pick<Lead, 'id'>
          ) | (
            { __typename?: 'LeadManager' }
            & Pick<LeadManager, 'id'>
          ) | (
            { __typename?: 'NotificationToken' }
            & Pick<NotificationToken, 'id'>
          ) | (
            { __typename?: 'Office' }
            & Pick<Office, 'id'>
          ) | (
            { __typename?: 'Organization' }
            & Pick<Organization, 'id'>
          ) | (
            { __typename?: 'Payment' }
            & Pick<Payment, 'id'>
          ) | (
            { __typename?: 'PaymentMandate' }
            & Pick<PaymentMandate, 'id'>
          ) | (
            { __typename?: 'Pdf' }
            & Pick<Pdf, 'id'>
          ) | (
            { __typename?: 'Policy' }
            & Pick<Policy, 'id'>
          ) | (
            { __typename?: 'PreferencesSet' }
            & Pick<PreferencesSet, 'id'>
          ) | (
            { __typename?: 'Property' }
            & Pick<Property, 'id'>
          ) | (
            { __typename?: 'Review' }
            & Pick<Review, 'id'>
          ) | (
            { __typename?: 'Role' }
            & Pick<Role, 'id'>
          ) | (
            { __typename?: 'SearchProfile' }
            & Pick<SearchProfile, 'id'>
          ) | (
            { __typename?: 'Task' }
            & Pick<Task, 'id'>
          ) | (
            { __typename?: 'Timeframe' }
            & Pick<Timeframe, 'id'>
          ) | (
            { __typename?: 'User' }
            & Pick<User, 'id'>
          )> }
        )>> }
      )>, error?: Maybe<(
        { __typename: 'MutationError' }
        & Pick<MutationError, 'type' | 'actionDetails' | 'message'>
      )> }
    ) }
  ) }
);

export type DeletePaymentMutationVariables = Exact<{
  paymentId: Scalars['PaymentGlobalIdOrUuid'];
}>;


export type DeletePaymentMutation = (
  { __typename?: 'Mutations' }
  & { payment: (
    { __typename?: 'PaymentNamespace' }
    & { delete: (
      { __typename?: 'PaymentDeletePayload' }
      & { error?: Maybe<(
        { __typename: 'MutationError' }
        & Pick<MutationError, 'type' | 'actionDetails' | 'message'>
      )> }
    ) }
  ) }
);

export type AddLeadToPaymentMutationVariables = Exact<{
  paymentId: Scalars['ID'];
  leadId: Scalars['LeadGlobalIdOrUuid'];
}>;


export type AddLeadToPaymentMutation = (
  { __typename?: 'Mutations' }
  & { lead: (
    { __typename?: 'LeadNamespace' }
    & { addToPayment: (
      { __typename?: 'LeadAddToPaymentPayload' }
      & { lead?: Maybe<(
        { __typename?: 'Lead' }
        & Pick<Lead, 'id'>
      )>, error?: Maybe<(
        { __typename: 'MutationError' }
        & Pick<MutationError, 'type' | 'actionDetails' | 'message'>
      )> }
    ) }
  ) }
);

export type RemoveLeadFromPaymentMutationVariables = Exact<{
  paymentId: Scalars['ID'];
  leadId: Scalars['LeadGlobalIdOrUuid'];
}>;


export type RemoveLeadFromPaymentMutation = (
  { __typename?: 'Mutations' }
  & { lead: (
    { __typename?: 'LeadNamespace' }
    & { removeFromPayment: (
      { __typename?: 'LeadRemoveFromPaymentPayload' }
      & { lead?: Maybe<(
        { __typename?: 'Lead' }
        & Pick<Lead, 'id'>
      )>, error?: Maybe<(
        { __typename: 'MutationError' }
        & Pick<MutationError, 'type' | 'actionDetails' | 'message'>
      )> }
    ) }
  ) }
);

export type AddAccountBalancePackageToPaymentMutationVariables = Exact<{
  paymentId: Scalars['ID'];
  accountBalancePackageId: Scalars['AccountBalancePackageGlobalIdOrUuid'];
}>;


export type AddAccountBalancePackageToPaymentMutation = (
  { __typename?: 'Mutations' }
  & { accountBalancePackage: (
    { __typename?: 'AccountBalancePackageNamespace' }
    & { addToPayment: (
      { __typename?: 'AccountBalancePackageAddToPaymentPayload' }
      & { accountBalancePackage?: Maybe<(
        { __typename?: 'AccountBalancePackage' }
        & Pick<AccountBalancePackage, 'id'>
      )>, error?: Maybe<(
        { __typename: 'MutationError' }
        & Pick<MutationError, 'type' | 'actionDetails' | 'message'>
      )> }
    ) }
  ) }
);

export type RemoveAccountBalancePackageToPaymentFromPaymentMutationVariables = Exact<{
  paymentId: Scalars['ID'];
  accountBalancePackageId: Scalars['AccountBalancePackageGlobalIdOrUuid'];
}>;


export type RemoveAccountBalancePackageToPaymentFromPaymentMutation = (
  { __typename?: 'Mutations' }
  & { accountBalancePackage: (
    { __typename?: 'AccountBalancePackageNamespace' }
    & { removeFromPayment: (
      { __typename?: 'AccountBalancePackageRemoveFromPaymentPayload' }
      & { accountBalancePackage?: Maybe<(
        { __typename?: 'AccountBalancePackage' }
        & Pick<AccountBalancePackage, 'id'>
      )>, error?: Maybe<(
        { __typename: 'MutationError' }
        & Pick<MutationError, 'type' | 'actionDetails' | 'message'>
      )> }
    ) }
  ) }
);

export type ChangePaymentProviderMutationVariables = Exact<{
  paymentId: Scalars['PaymentGlobalIdOrUuid'];
  paymentProvider: PaymentPaymentProvider;
}>;


export type ChangePaymentProviderMutation = (
  { __typename?: 'Mutations' }
  & { payment: (
    { __typename?: 'PaymentNamespace' }
    & { changeProvider: (
      { __typename?: 'PaymentChangeProviderPayload' }
      & { payment?: Maybe<(
        { __typename?: 'Payment' }
        & Pick<Payment, 'id' | 'currency' | 'paymentProvider' | 'paymentProviderType' | 'state' | 'settlementStartedAt' | 'settledAt'>
        & { owner?: Maybe<(
          { __typename?: 'AccountBalancePackage' }
          & Pick<AccountBalancePackage, 'id'>
        ) | (
          { __typename?: 'AccountBalanceTransaction' }
          & Pick<AccountBalanceTransaction, 'id'>
        ) | (
          { __typename?: 'Activity' }
          & Pick<Activity, 'id'>
        ) | (
          { __typename?: 'Appointment' }
          & Pick<Appointment, 'id'>
        ) | (
          { __typename?: 'Asset' }
          & Pick<Asset, 'id'>
        ) | (
          { __typename?: 'Broker' }
          & Pick<Broker, 'id'>
        ) | (
          { __typename?: 'Customer' }
          & Pick<Customer, 'id'>
        ) | (
          { __typename?: 'DataPoint' }
          & Pick<DataPoint, 'id'>
        ) | (
          { __typename?: 'FinancingLead' }
          & Pick<FinancingLead, 'id'>
        ) | (
          { __typename?: 'InsightsQuery' }
          & Pick<InsightsQuery, 'id'>
        ) | (
          { __typename?: 'InsuranceLead' }
          & Pick<InsuranceLead, 'id'>
        ) | (
          { __typename?: 'InvoiceAddress' }
          & Pick<InvoiceAddress, 'id'>
        ) | (
          { __typename?: 'Lead' }
          & Pick<Lead, 'id'>
        ) | (
          { __typename?: 'LeadManager' }
          & Pick<LeadManager, 'id'>
        ) | (
          { __typename?: 'NotificationToken' }
          & Pick<NotificationToken, 'id'>
        ) | (
          { __typename?: 'Office' }
          & Pick<Office, 'id'>
        ) | (
          { __typename?: 'Organization' }
          & Pick<Organization, 'id'>
        ) | (
          { __typename?: 'Payment' }
          & Pick<Payment, 'id'>
        ) | (
          { __typename?: 'PaymentMandate' }
          & Pick<PaymentMandate, 'id'>
        ) | (
          { __typename?: 'Pdf' }
          & Pick<Pdf, 'id'>
        ) | (
          { __typename?: 'Policy' }
          & Pick<Policy, 'id'>
        ) | (
          { __typename?: 'PreferencesSet' }
          & Pick<PreferencesSet, 'id'>
        ) | (
          { __typename?: 'Property' }
          & Pick<Property, 'id'>
        ) | (
          { __typename?: 'Review' }
          & Pick<Review, 'id'>
        ) | (
          { __typename?: 'Role' }
          & Pick<Role, 'id'>
        ) | (
          { __typename?: 'SearchProfile' }
          & Pick<SearchProfile, 'id'>
        ) | (
          { __typename?: 'Task' }
          & Pick<Task, 'id'>
        ) | (
          { __typename?: 'Timeframe' }
          & Pick<Timeframe, 'id'>
        ) | (
          { __typename?: 'User' }
          & Pick<User, 'id'>
        )>, items?: Maybe<Array<(
          { __typename?: 'PaymentItem' }
          & Pick<PaymentItem, 'name' | 'netAmount' | 'grossAmount' | 'taxRate'>
          & { sellable?: Maybe<(
            { __typename?: 'AccountBalancePackage' }
            & Pick<AccountBalancePackage, 'id'>
          ) | (
            { __typename?: 'AccountBalanceTransaction' }
            & Pick<AccountBalanceTransaction, 'id'>
          ) | (
            { __typename?: 'Activity' }
            & Pick<Activity, 'id'>
          ) | (
            { __typename?: 'Appointment' }
            & Pick<Appointment, 'id'>
          ) | (
            { __typename?: 'Asset' }
            & Pick<Asset, 'id'>
          ) | (
            { __typename?: 'Broker' }
            & Pick<Broker, 'id'>
          ) | (
            { __typename?: 'Customer' }
            & Pick<Customer, 'id'>
          ) | (
            { __typename?: 'DataPoint' }
            & Pick<DataPoint, 'id'>
          ) | (
            { __typename?: 'FinancingLead' }
            & Pick<FinancingLead, 'id'>
          ) | (
            { __typename?: 'InsightsQuery' }
            & Pick<InsightsQuery, 'id'>
          ) | (
            { __typename?: 'InsuranceLead' }
            & Pick<InsuranceLead, 'id'>
          ) | (
            { __typename?: 'InvoiceAddress' }
            & Pick<InvoiceAddress, 'id'>
          ) | (
            { __typename?: 'Lead' }
            & Pick<Lead, 'id'>
          ) | (
            { __typename?: 'LeadManager' }
            & Pick<LeadManager, 'id'>
          ) | (
            { __typename?: 'NotificationToken' }
            & Pick<NotificationToken, 'id'>
          ) | (
            { __typename?: 'Office' }
            & Pick<Office, 'id'>
          ) | (
            { __typename?: 'Organization' }
            & Pick<Organization, 'id'>
          ) | (
            { __typename?: 'Payment' }
            & Pick<Payment, 'id'>
          ) | (
            { __typename?: 'PaymentMandate' }
            & Pick<PaymentMandate, 'id'>
          ) | (
            { __typename?: 'Pdf' }
            & Pick<Pdf, 'id'>
          ) | (
            { __typename?: 'Policy' }
            & Pick<Policy, 'id'>
          ) | (
            { __typename?: 'PreferencesSet' }
            & Pick<PreferencesSet, 'id'>
          ) | (
            { __typename?: 'Property' }
            & Pick<Property, 'id'>
          ) | (
            { __typename?: 'Review' }
            & Pick<Review, 'id'>
          ) | (
            { __typename?: 'Role' }
            & Pick<Role, 'id'>
          ) | (
            { __typename?: 'SearchProfile' }
            & Pick<SearchProfile, 'id'>
          ) | (
            { __typename?: 'Task' }
            & Pick<Task, 'id'>
          ) | (
            { __typename?: 'Timeframe' }
            & Pick<Timeframe, 'id'>
          ) | (
            { __typename?: 'User' }
            & Pick<User, 'id'>
          )> }
        )>> }
      )>, error?: Maybe<(
        { __typename: 'MutationError' }
        & Pick<MutationError, 'type' | 'actionDetails' | 'message'>
      )> }
    ) }
  ) }
);

export type AuthorizePaymentMutationVariables = Exact<{
  paymentId: Scalars['PaymentGlobalIdOrUuid'];
  braintreePayment?: InputMaybe<PaymentAuthorizeBraintreePayment>;
  gocardlessPayment?: InputMaybe<PaymentAuthorizeGocardlessPayment>;
}>;


export type AuthorizePaymentMutation = (
  { __typename?: 'Mutations' }
  & { payment: (
    { __typename?: 'PaymentNamespace' }
    & { authorize: (
      { __typename?: 'PaymentAuthorizePayload' }
      & { payment?: Maybe<(
        { __typename?: 'Payment' }
        & Pick<Payment, 'id' | 'currency' | 'paymentProvider' | 'paymentProviderType' | 'state' | 'settlementStartedAt' | 'settledAt'>
        & { owner?: Maybe<(
          { __typename?: 'AccountBalancePackage' }
          & Pick<AccountBalancePackage, 'id'>
        ) | (
          { __typename?: 'AccountBalanceTransaction' }
          & Pick<AccountBalanceTransaction, 'id'>
        ) | (
          { __typename?: 'Activity' }
          & Pick<Activity, 'id'>
        ) | (
          { __typename?: 'Appointment' }
          & Pick<Appointment, 'id'>
        ) | (
          { __typename?: 'Asset' }
          & Pick<Asset, 'id'>
        ) | (
          { __typename?: 'Broker' }
          & Pick<Broker, 'id'>
        ) | (
          { __typename?: 'Customer' }
          & Pick<Customer, 'id'>
        ) | (
          { __typename?: 'DataPoint' }
          & Pick<DataPoint, 'id'>
        ) | (
          { __typename?: 'FinancingLead' }
          & Pick<FinancingLead, 'id'>
        ) | (
          { __typename?: 'InsightsQuery' }
          & Pick<InsightsQuery, 'id'>
        ) | (
          { __typename?: 'InsuranceLead' }
          & Pick<InsuranceLead, 'id'>
        ) | (
          { __typename?: 'InvoiceAddress' }
          & Pick<InvoiceAddress, 'id'>
        ) | (
          { __typename?: 'Lead' }
          & Pick<Lead, 'id'>
        ) | (
          { __typename?: 'LeadManager' }
          & Pick<LeadManager, 'id'>
        ) | (
          { __typename?: 'NotificationToken' }
          & Pick<NotificationToken, 'id'>
        ) | (
          { __typename?: 'Office' }
          & Pick<Office, 'id'>
        ) | (
          { __typename?: 'Organization' }
          & Pick<Organization, 'id'>
        ) | (
          { __typename?: 'Payment' }
          & Pick<Payment, 'id'>
        ) | (
          { __typename?: 'PaymentMandate' }
          & Pick<PaymentMandate, 'id'>
        ) | (
          { __typename?: 'Pdf' }
          & Pick<Pdf, 'id'>
        ) | (
          { __typename?: 'Policy' }
          & Pick<Policy, 'id'>
        ) | (
          { __typename?: 'PreferencesSet' }
          & Pick<PreferencesSet, 'id'>
        ) | (
          { __typename?: 'Property' }
          & Pick<Property, 'id'>
        ) | (
          { __typename?: 'Review' }
          & Pick<Review, 'id'>
        ) | (
          { __typename?: 'Role' }
          & Pick<Role, 'id'>
        ) | (
          { __typename?: 'SearchProfile' }
          & Pick<SearchProfile, 'id'>
        ) | (
          { __typename?: 'Task' }
          & Pick<Task, 'id'>
        ) | (
          { __typename?: 'Timeframe' }
          & Pick<Timeframe, 'id'>
        ) | (
          { __typename?: 'User' }
          & Pick<User, 'id'>
        )>, items?: Maybe<Array<(
          { __typename?: 'PaymentItem' }
          & Pick<PaymentItem, 'name' | 'netAmount' | 'grossAmount' | 'taxRate'>
          & { sellable?: Maybe<(
            { __typename?: 'AccountBalancePackage' }
            & Pick<AccountBalancePackage, 'id'>
          ) | (
            { __typename?: 'AccountBalanceTransaction' }
            & Pick<AccountBalanceTransaction, 'id'>
          ) | (
            { __typename?: 'Activity' }
            & Pick<Activity, 'id'>
          ) | (
            { __typename?: 'Appointment' }
            & Pick<Appointment, 'id'>
          ) | (
            { __typename?: 'Asset' }
            & Pick<Asset, 'id'>
          ) | (
            { __typename?: 'Broker' }
            & Pick<Broker, 'id'>
          ) | (
            { __typename?: 'Customer' }
            & Pick<Customer, 'id'>
          ) | (
            { __typename?: 'DataPoint' }
            & Pick<DataPoint, 'id'>
          ) | (
            { __typename?: 'FinancingLead' }
            & Pick<FinancingLead, 'id'>
          ) | (
            { __typename?: 'InsightsQuery' }
            & Pick<InsightsQuery, 'id'>
          ) | (
            { __typename?: 'InsuranceLead' }
            & Pick<InsuranceLead, 'id'>
          ) | (
            { __typename?: 'InvoiceAddress' }
            & Pick<InvoiceAddress, 'id'>
          ) | (
            { __typename?: 'Lead' }
            & Pick<Lead, 'id'>
          ) | (
            { __typename?: 'LeadManager' }
            & Pick<LeadManager, 'id'>
          ) | (
            { __typename?: 'NotificationToken' }
            & Pick<NotificationToken, 'id'>
          ) | (
            { __typename?: 'Office' }
            & Pick<Office, 'id'>
          ) | (
            { __typename?: 'Organization' }
            & Pick<Organization, 'id'>
          ) | (
            { __typename?: 'Payment' }
            & Pick<Payment, 'id'>
          ) | (
            { __typename?: 'PaymentMandate' }
            & Pick<PaymentMandate, 'id'>
          ) | (
            { __typename?: 'Pdf' }
            & Pick<Pdf, 'id'>
          ) | (
            { __typename?: 'Policy' }
            & Pick<Policy, 'id'>
          ) | (
            { __typename?: 'PreferencesSet' }
            & Pick<PreferencesSet, 'id'>
          ) | (
            { __typename?: 'Property' }
            & Pick<Property, 'id'>
          ) | (
            { __typename?: 'Review' }
            & Pick<Review, 'id'>
          ) | (
            { __typename?: 'Role' }
            & Pick<Role, 'id'>
          ) | (
            { __typename?: 'SearchProfile' }
            & Pick<SearchProfile, 'id'>
          ) | (
            { __typename?: 'Task' }
            & Pick<Task, 'id'>
          ) | (
            { __typename?: 'Timeframe' }
            & Pick<Timeframe, 'id'>
          ) | (
            { __typename?: 'User' }
            & Pick<User, 'id'>
          )> }
        )>> }
      )>, error?: Maybe<(
        { __typename: 'MutationError' }
        & Pick<MutationError, 'type' | 'actionDetails' | 'message'>
      )> }
    ) }
  ) }
);

export type DeleteNotificationTokenMutationVariables = Exact<{
  id: Scalars['NotificationTokenGlobalIdOrUuid'];
}>;


export type DeleteNotificationTokenMutation = (
  { __typename?: 'Mutations' }
  & { notificationToken: (
    { __typename?: 'NotificationTokenNamespace' }
    & { delete: (
      { __typename?: 'NotificationTokenDeletePayload' }
      & { notificationToken?: Maybe<(
        { __typename?: 'NotificationToken' }
        & Pick<NotificationToken, 'token' | 'id'>
      )>, error?: Maybe<(
        { __typename: 'MutationError' }
        & Pick<MutationError, 'type' | 'actionDetails' | 'message'>
      )> }
    ) }
  ) }
);

export type AddOfficeMemberMutationVariables = Exact<{
  id: Scalars['OfficeGlobalIdOrUuid'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  gender?: InputMaybe<OfficeGender>;
}>;


export type AddOfficeMemberMutation = (
  { __typename?: 'Mutations' }
  & { office: (
    { __typename?: 'OfficeNamespace' }
    & { inviteMember: (
      { __typename?: 'OfficeInviteMemberPayload' }
      & { office?: Maybe<(
        { __typename?: 'Office' }
        & Pick<Office, 'id'>
      )>, error?: Maybe<(
        { __typename: 'MutationError' }
        & Pick<MutationError, 'type' | 'actionDetails' | 'message'>
      )> }
    ) }
  ) }
);

export type RemoveOfficeMemberMutationVariables = Exact<{
  id: Scalars['OfficeGlobalIdOrUuid'];
  userGid: Scalars['GlobalID'];
}>;


export type RemoveOfficeMemberMutation = (
  { __typename?: 'Mutations' }
  & { office: (
    { __typename?: 'OfficeNamespace' }
    & { removeMember: (
      { __typename?: 'OfficeRemoveMemberPayload' }
      & { office?: Maybe<(
        { __typename?: 'Office' }
        & Pick<Office, 'id'>
      )>, error?: Maybe<(
        { __typename: 'MutationError' }
        & Pick<MutationError, 'type' | 'actionDetails' | 'message'>
      )> }
    ) }
  ) }
);

export type LeadQueryVariables = Exact<{
  id: Scalars['LeadGlobalIdOrUuid'];
}>;


export type LeadQuery = (
  { __typename?: 'Queries' }
  & { lead?: Maybe<(
    { __typename: 'Lead' }
    & Pick<Lead, 'closedReason' | 'id' | 'gid' | 'identifier' | 'createdAt' | 'processedAt' | 'accessPermittedAt' | 'activatedAt' | 'closedAt' | 'lockedAt' | 'soldAt' | 'dueAt' | 'labels' | 'statePropertiesUpdatedAt' | 'statePropertiesUpdateRequestedAt' | 'statePropertiesUpdateSkippedUntil' | 'contractProperties' | 'currentMandatoryTaskName' | 'processedWith' | 'processedComment'>
    & { property?: Maybe<(
      { __typename: 'Property' }
      & Pick<Property, 'dossierLink' | 'id'>
      & { objectDetails?: Maybe<(
        { __typename: 'PropertyObjectDetails' }
        & Pick<PropertyObjectDetails, 'saleHorizon' | 'condition' | 'landSize' | 'livingSpace' | 'amountRooms' | 'amountBathrooms' | 'amountParkingSpaces' | 'saleReason' | 'ownershipStructure' | 'freeFrom' | 'amountBalcony' | 'amountTerrace' | 'listed' | 'equipmentText' | 'descriptionText' | 'locationText' | 'additionalInfoText' | 'yearOfConstruction' | 'construction' | 'exploitation' | 'yearOfModernization' | 'rented' | 'inhabited' | 'amountUnits' | 'netRent' | 'propertyType' | 'propertySubtype' | 'city' | 'postalCode' | 'street' | 'streetAddition' | 'latitude' | 'longitude'>
        & { equipment?: Maybe<(
          { __typename?: 'PropertyEquipment' }
          & Pick<PropertyEquipment, 'attic' | 'cellar' | 'lift' | 'kitchen' | 'handicappedAccessible' | 'seniorFriendly' | 'garden' | 'guestToilet' | 'sauna' | 'parkingSpaceType'>
        )> }
      )>, owner?: Maybe<(
        { __typename: 'User' }
        & Pick<User, 'id'>
        & { preferences: (
          { __typename: 'PreferencesSet' }
          & Pick<PreferencesSet, 'id' | 'firstName' | 'lastName'>
        ) }
      )>, valuation?: Maybe<(
        { __typename: 'PropertyValuation' }
        & Pick<PropertyValuation, 'value'>
      )> }
    )>, objectProperties?: Maybe<(
      { __typename?: 'LeadObjectProperties' }
      & Pick<LeadObjectProperties, 'additionalInfo' | 'evaluationReason' | 'isForSale'>
    )>, contacts?: Maybe<Array<(
      { __typename: 'LeadContact' }
      & Pick<LeadContact, 'firstName' | 'lastName' | 'emails' | 'gender' | 'phoneNumbers' | 'reachability'>
      & { customer?: Maybe<(
        { __typename: 'Customer' }
        & Pick<Customer, 'id'>
        & { avatar?: Maybe<(
          { __typename?: 'Asset' }
          & Pick<Asset, 'fileUrl'>
        )> }
      )> }
    )>>, brokers?: Maybe<Array<(
      { __typename: 'Broker' }
      & Pick<Broker, 'id'>
    )>>, assets?: Maybe<(
      { __typename?: 'AssetPage' }
      & { nodes: Array<(
        { __typename: 'Asset' }
        & Pick<Asset, 'gid' | 'id' | 'filename' | 'fileUrl' | 'contentType' | 'title' | 'metadata'>
      )> }
    )>, stateProperties?: Maybe<(
      { __typename: 'LeadStateProperties' }
      & Pick<LeadStateProperties, 'currency' | 'customerAppointmentDate' | 'performEvaluationPrice' | 'performEvaluationDate' | 'performEvaluationDateTookPlace' | 'evaluationGid' | 'shareEvaluation' | 'nextCustomerContactDate' | 'acquisitionCompleted' | 'updateAlternativeText' | 'brokerContractCommissionUnit' | 'brokerContractCommission' | 'brokerContractBuyerCommission' | 'brokerContractBuyerCommissionUnit' | 'brokerContractDate' | 'brokerContractPrice' | 'brokerContractType' | 'expectedSalePrice' | 'brokerContractTermDate' | 'expectedSaleNotaryDate' | 'objectSaleBuyerCommission' | 'objectSaleBuyerCommissionTaxation' | 'objectSaleBuyerCommissionUnit' | 'objectSaleCommission' | 'objectSaleCommissionTaxation' | 'objectSaleCommissionUnit' | 'objectSaleNotaryDate' | 'objectSalePrice' | 'marketingCompleted' | 'saleCompleted' | 'invoiceSent'>
    )>, tasks?: Maybe<(
      { __typename: 'TaskPage' }
      & { nodes: Array<(
        { __typename: 'Task' }
        & Pick<Task, 'id' | 'title' | 'description' | 'type' | 'status' | 'updatedAt' | 'createdAt' | 'editable' | 'dueDate' | 'result'>
        & { references?: Maybe<Array<(
          { __typename?: 'AccountBalancePackage' }
          & Pick<AccountBalancePackage, 'gid' | 'id'>
        ) | (
          { __typename?: 'AccountBalanceTransaction' }
          & Pick<AccountBalanceTransaction, 'gid' | 'id'>
        ) | (
          { __typename?: 'Activity' }
          & Pick<Activity, 'gid' | 'id'>
        ) | (
          { __typename?: 'Appointment' }
          & Pick<Appointment, 'gid' | 'id'>
        ) | (
          { __typename?: 'Asset' }
          & Pick<Asset, 'gid' | 'id'>
        ) | (
          { __typename?: 'Broker' }
          & Pick<Broker, 'gid' | 'id'>
        ) | (
          { __typename?: 'Customer' }
          & Pick<Customer, 'gid' | 'id'>
        ) | (
          { __typename?: 'DataPoint' }
          & Pick<DataPoint, 'gid' | 'id'>
        ) | (
          { __typename?: 'FinancingLead' }
          & Pick<FinancingLead, 'gid' | 'id'>
        ) | (
          { __typename?: 'InsightsQuery' }
          & Pick<InsightsQuery, 'gid' | 'id'>
        ) | (
          { __typename?: 'InsuranceLead' }
          & Pick<InsuranceLead, 'gid' | 'id'>
        ) | (
          { __typename?: 'InvoiceAddress' }
          & Pick<InvoiceAddress, 'gid' | 'id'>
        ) | (
          { __typename?: 'Lead' }
          & Pick<Lead, 'gid' | 'id'>
        ) | (
          { __typename?: 'LeadManager' }
          & Pick<LeadManager, 'gid' | 'id'>
        ) | (
          { __typename?: 'NotificationToken' }
          & Pick<NotificationToken, 'gid' | 'id'>
        ) | (
          { __typename?: 'Office' }
          & Pick<Office, 'gid' | 'id'>
        ) | (
          { __typename?: 'Organization' }
          & Pick<Organization, 'gid' | 'id'>
        ) | (
          { __typename?: 'Payment' }
          & Pick<Payment, 'gid' | 'id'>
        ) | (
          { __typename?: 'PaymentMandate' }
          & Pick<PaymentMandate, 'gid' | 'id'>
        ) | (
          { __typename?: 'Pdf' }
          & Pick<Pdf, 'gid' | 'id'>
        ) | (
          { __typename?: 'Policy' }
          & Pick<Policy, 'gid' | 'id'>
        ) | (
          { __typename?: 'PreferencesSet' }
          & Pick<PreferencesSet, 'gid' | 'id'>
        ) | (
          { __typename?: 'Property' }
          & Pick<Property, 'gid' | 'id'>
        ) | (
          { __typename?: 'Review' }
          & Pick<Review, 'gid' | 'id'>
        ) | (
          { __typename?: 'Role' }
          & Pick<Role, 'gid' | 'id'>
        ) | (
          { __typename?: 'SearchProfile' }
          & Pick<SearchProfile, 'gid' | 'id'>
        ) | (
          { __typename?: 'Task' }
          & Pick<Task, 'gid' | 'id'>
        ) | (
          { __typename?: 'Timeframe' }
          & Pick<Timeframe, 'gid' | 'id'>
        ) | (
          { __typename?: 'User' }
          & Pick<User, 'gid' | 'id'>
        )>>, owner?: Maybe<(
          { __typename?: 'AccountBalancePackage' }
          & Pick<AccountBalancePackage, 'id' | 'gid'>
        ) | (
          { __typename?: 'AccountBalanceTransaction' }
          & Pick<AccountBalanceTransaction, 'id' | 'gid'>
        ) | (
          { __typename?: 'Activity' }
          & Pick<Activity, 'id' | 'gid'>
        ) | (
          { __typename?: 'Appointment' }
          & Pick<Appointment, 'id' | 'gid'>
        ) | (
          { __typename?: 'Asset' }
          & Pick<Asset, 'id' | 'gid'>
        ) | (
          { __typename?: 'Broker' }
          & Pick<Broker, 'id' | 'gid'>
        ) | (
          { __typename?: 'Customer' }
          & Pick<Customer, 'id' | 'gid'>
        ) | (
          { __typename?: 'DataPoint' }
          & Pick<DataPoint, 'id' | 'gid'>
        ) | (
          { __typename?: 'FinancingLead' }
          & Pick<FinancingLead, 'id' | 'gid'>
        ) | (
          { __typename?: 'InsightsQuery' }
          & Pick<InsightsQuery, 'id' | 'gid'>
        ) | (
          { __typename?: 'InsuranceLead' }
          & Pick<InsuranceLead, 'id' | 'gid'>
        ) | (
          { __typename?: 'InvoiceAddress' }
          & Pick<InvoiceAddress, 'id' | 'gid'>
        ) | (
          { __typename?: 'Lead' }
          & Pick<Lead, 'id' | 'gid'>
        ) | (
          { __typename?: 'LeadManager' }
          & Pick<LeadManager, 'id' | 'gid'>
        ) | (
          { __typename?: 'NotificationToken' }
          & Pick<NotificationToken, 'id' | 'gid'>
        ) | (
          { __typename?: 'Office' }
          & Pick<Office, 'id' | 'gid'>
        ) | (
          { __typename?: 'Organization' }
          & Pick<Organization, 'id' | 'gid'>
        ) | (
          { __typename?: 'Payment' }
          & Pick<Payment, 'id' | 'gid'>
        ) | (
          { __typename?: 'PaymentMandate' }
          & Pick<PaymentMandate, 'id' | 'gid'>
        ) | (
          { __typename?: 'Pdf' }
          & Pick<Pdf, 'id' | 'gid'>
        ) | (
          { __typename?: 'Policy' }
          & Pick<Policy, 'id' | 'gid'>
        ) | (
          { __typename?: 'PreferencesSet' }
          & Pick<PreferencesSet, 'id' | 'gid'>
        ) | (
          { __typename?: 'Property' }
          & Pick<Property, 'id' | 'gid'>
        ) | (
          { __typename?: 'Review' }
          & Pick<Review, 'id' | 'gid'>
        ) | (
          { __typename?: 'Role' }
          & Pick<Role, 'id' | 'gid'>
        ) | (
          { __typename?: 'SearchProfile' }
          & Pick<SearchProfile, 'id' | 'gid'>
        ) | (
          { __typename?: 'Task' }
          & Pick<Task, 'id' | 'gid'>
        ) | (
          { __typename?: 'Timeframe' }
          & Pick<Timeframe, 'id' | 'gid'>
        ) | (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'gid'>
        )> }
      )> }
    )>, office?: Maybe<(
      { __typename: 'Office' }
      & Pick<Office, 'id' | 'disabledPaymentMethods'>
    )>, leadManager?: Maybe<(
      { __typename: 'LeadManager' }
      & Pick<LeadManager, 'id' | 'acceptableLimit'>
    )>, supervisor?: Maybe<(
      { __typename: 'Broker' }
      & Pick<Broker, 'id'>
      & { preferences: (
        { __typename: 'PreferencesSet' }
        & Pick<PreferencesSet, 'id' | 'firstName' | 'lastName'>
      ) }
    )> }
  )> }
);

export type OpenImmoQueryVariables = Exact<{
  id: Scalars['LeadGlobalIdOrUuid'];
}>;


export type OpenImmoQuery = (
  { __typename?: 'Queries' }
  & { lead?: Maybe<(
    { __typename?: 'Lead' }
    & Pick<Lead, 'id' | 'openImmoXml'>
  )> }
);

export type LeadListQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<LeadSearchParameters>;
  sort?: InputMaybe<Array<LeadSortParameters> | LeadSortParameters>;
}>;


export type LeadListQuery = (
  { __typename?: 'Queries' }
  & { leads?: Maybe<(
    { __typename?: 'LeadPage' }
    & Pick<LeadPage, 'count'>
    & { nodes: Array<(
      { __typename: 'Lead' }
      & Pick<Lead, 'id' | 'gid' | 'identifier' | 'createdAt' | 'processedAt' | 'accessPermittedAt' | 'activatedAt' | 'closedAt' | 'lockedAt' | 'soldAt' | 'dueAt' | 'labels' | 'statePropertiesUpdatedAt' | 'statePropertiesUpdateRequestedAt' | 'statePropertiesUpdateSkippedUntil' | 'contractProperties' | 'currentMandatoryTaskName' | 'processedWith' | 'processedComment'>
      & { leadManager?: Maybe<(
        { __typename: 'LeadManager' }
        & Pick<LeadManager, 'id' | 'acceptableLimit'>
      )>, objectProperties?: Maybe<(
        { __typename?: 'LeadObjectProperties' }
        & Pick<LeadObjectProperties, 'isForSale'>
      )>, property?: Maybe<(
        { __typename: 'Property' }
        & Pick<Property, 'id'>
        & { owner?: Maybe<(
          { __typename: 'User' }
          & Pick<User, 'id'>
          & { preferences: (
            { __typename: 'PreferencesSet' }
            & Pick<PreferencesSet, 'id' | 'firstName' | 'lastName'>
          ) }
        )>, objectDetails?: Maybe<(
          { __typename: 'PropertyObjectDetails' }
          & Pick<PropertyObjectDetails, 'propertyType' | 'propertySubtype' | 'city' | 'postalCode' | 'street' | 'streetAddition' | 'saleHorizon' | 'latitude' | 'longitude'>
        )>, valuation?: Maybe<(
          { __typename: 'PropertyValuation' }
          & Pick<PropertyValuation, 'value'>
        )> }
      )>, supervisor?: Maybe<(
        { __typename: 'Broker' }
        & Pick<Broker, 'id'>
        & { preferences: (
          { __typename: 'PreferencesSet' }
          & Pick<PreferencesSet, 'id' | 'firstName' | 'lastName'>
        ) }
      )> }
    )> }
  )> }
);

export type LeadListCountQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<LeadSearchParameters>;
}>;


export type LeadListCountQuery = (
  { __typename?: 'Queries' }
  & { leads?: Maybe<(
    { __typename?: 'LeadPage' }
    & Pick<LeadPage, 'count'>
  )> }
);

export type UserQueryVariables = Exact<{
  id?: InputMaybe<Scalars['BrokerGlobalIdOrUuid']>;
}>;


export type UserQuery = (
  { __typename?: 'Queries' }
  & { broker?: Maybe<(
    { __typename?: 'Broker' }
    & Pick<Broker, 'id' | 'gid'>
    & { avatar?: Maybe<(
      { __typename?: 'Asset' }
      & Pick<Asset, 'id' | 'fileUrl'>
    )>, preferences: (
      { __typename?: 'PreferencesSet' }
      & Pick<PreferencesSet, 'id' | 'gender' | 'firstName' | 'lastName' | 'mobile'>
    ) }
  )> }
);

export type UsersQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['BrokerGlobalIdOrUuid']> | Scalars['BrokerGlobalIdOrUuid']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type UsersQuery = (
  { __typename?: 'Queries' }
  & { brokers?: Maybe<(
    { __typename?: 'BrokerPage' }
    & Pick<BrokerPage, 'count'>
    & { nodes: Array<(
      { __typename: 'Broker' }
      & Pick<Broker, 'id'>
      & { avatar?: Maybe<(
        { __typename?: 'Asset' }
        & Pick<Asset, 'id' | 'fileUrl'>
      )>, user: (
        { __typename?: 'User' }
        & Pick<User, 'email' | 'id'>
      ), preferences: (
        { __typename?: 'PreferencesSet' }
        & Pick<PreferencesSet, 'id' | 'firstName' | 'lastName'>
      ) }
    )> }
  )> }
);

export type PaymentMandatesQueryVariables = Exact<{
  ownerGid: Scalars['GlobalID'];
}>;


export type PaymentMandatesQuery = (
  { __typename?: 'Queries' }
  & { paymentMandates?: Maybe<(
    { __typename?: 'PaymentMandatePage' }
    & { nodes: Array<(
      { __typename?: 'PaymentMandate' }
      & Pick<PaymentMandate, 'gid' | 'id' | 'metadata' | 'status'>
    )> }
  )> }
);

export type InvoiceAddressesQueryVariables = Exact<{
  search: InvoiceAddressSearchParameters;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type InvoiceAddressesQuery = (
  { __typename?: 'Queries' }
  & { invoiceAddresses?: Maybe<(
    { __typename?: 'InvoiceAddressPage' }
    & Pick<InvoiceAddressPage, 'count' | 'limit' | 'offset'>
    & { nodes: Array<(
      { __typename?: 'InvoiceAddress' }
      & Pick<InvoiceAddress, 'accountBalance' | 'accountBalanceCurrency' | 'id'>
    )> }
  )> }
);

export type AccountBalancePackagesQueryVariables = Exact<{
  search: AccountBalancePackageSearchParameters;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type AccountBalancePackagesQuery = (
  { __typename?: 'Queries' }
  & { accountBalancePackages?: Maybe<(
    { __typename?: 'AccountBalancePackagePage' }
    & Pick<AccountBalancePackagePage, 'count' | 'limit' | 'offset'>
    & { nodes: Array<(
      { __typename?: 'AccountBalancePackage' }
      & Pick<AccountBalancePackage, 'id' | 'gid' | 'bonus' | 'price' | 'totalAmount'>
    )> }
  )> }
);

export type OfficesQueryVariables = Exact<{
  search: OfficeSearchParameters;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type OfficesQuery = (
  { __typename?: 'Queries' }
  & { offices?: Maybe<(
    { __typename?: 'OfficePage' }
    & Pick<OfficePage, 'count' | 'limit'>
    & { nodes: Array<(
      { __typename: 'Office' }
      & Pick<Office, 'employeesCount' | 'id' | 'name'>
      & { organization?: Maybe<(
        { __typename: 'Organization' }
        & Pick<Organization, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type OfficeQueryVariables = Exact<{
  id: Scalars['OfficeGlobalIdOrUuid'];
}>;


export type OfficeQuery = (
  { __typename?: 'Queries' }
  & { office?: Maybe<(
    { __typename: 'Office' }
    & Pick<Office, 'id' | 'name'>
    & { leadReceiver?: Maybe<(
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
    )>, brokers?: Maybe<Array<(
      { __typename: 'Broker' }
      & Pick<Broker, 'id' | 'gid'>
      & { avatar?: Maybe<(
        { __typename: 'Asset' }
        & Pick<Asset, 'id' | 'fileUrl'>
      )>, user: (
        { __typename: 'User' }
        & Pick<User, 'id' | 'email' | 'status' | 'createdAt'>
      ), preferences: (
        { __typename: 'PreferencesSet' }
        & Pick<PreferencesSet, 'firstName' | 'lastName' | 'gender' | 'id'>
      ) }
    )>> }
  )> }
);

export const MutationErrorFragmentDoc = gql`
    fragment MutationError on MutationError {
  __typename
  type
  actionDetails
  message
}
    `;
export const LeadFragmentDoc = gql`
    fragment Lead on Lead {
  __typename
  id
  gid
  identifier
  createdAt
  processedAt
  accessPermittedAt
  activatedAt
  closedAt
  lockedAt
  closedAt
  soldAt
  dueAt
  labels
  statePropertiesUpdatedAt
  statePropertiesUpdateRequestedAt
  statePropertiesUpdateSkippedUntil
  contractProperties
  currentMandatoryTaskName
  processedWith
  processedComment
  leadManager {
    __typename
    id
    acceptableLimit
  }
  objectProperties {
    isForSale
  }
  property {
    __typename
    id
    owner {
      __typename
      id
      preferences {
        __typename
        id
        firstName
        lastName
      }
    }
    objectDetails {
      __typename
      propertyType
      propertySubtype
      city
      postalCode
      street
      streetAddition
      saleHorizon
      latitude
      longitude
    }
    valuation {
      __typename
      value
    }
  }
  supervisor {
    __typename
    id
    preferences {
      __typename
      id
      firstName
      lastName
    }
  }
}
    `;
export const AssetFragmentDoc = gql`
    fragment Asset on Asset {
  __typename
  gid
  id
  filename
  fileUrl
  contentType
  title
  metadata
}
    `;
export const TaskFragmentDoc = gql`
    fragment Task on Task {
  id
  title
  description
  type
  status
  references {
    gid
    id
  }
  updatedAt
  createdAt
  editable
  dueDate
  result
  owner {
    id
    gid
  }
  __typename
}
    `;
export const LeadWithDetailsAndPropertyAndStateFragmentDoc = gql`
    fragment LeadWithDetailsAndPropertyAndState on Lead {
  ...Lead
  closedReason
  property {
    dossierLink
    objectDetails {
      saleHorizon
      condition
      landSize
      livingSpace
      amountRooms
      amountBathrooms
      amountParkingSpaces
      saleReason
      ownershipStructure
      freeFrom
      equipment {
        attic
        cellar
        lift
        kitchen
        handicappedAccessible
        seniorFriendly
        garden
        guestToilet
        sauna
        parkingSpaceType
      }
      amountBalcony
      amountTerrace
      listed
      equipmentText
      descriptionText
      locationText
      additionalInfoText
      yearOfConstruction
      construction
      exploitation
      yearOfModernization
      rented
      inhabited
      amountUnits
      netRent
    }
  }
  objectProperties {
    additionalInfo
    evaluationReason
  }
  contacts {
    __typename
    firstName
    lastName
    emails
    gender
    phoneNumbers
    reachability
    customer {
      __typename
      id
      avatar {
        fileUrl
      }
    }
  }
  brokers {
    id
    __typename
  }
  assets {
    nodes {
      ...Asset
    }
  }
  stateProperties {
    currency
    customerAppointmentDate
    performEvaluationPrice
    performEvaluationDate
    performEvaluationDateTookPlace
    evaluationGid
    shareEvaluation
    nextCustomerContactDate
    acquisitionCompleted
    updateAlternativeText
    brokerContractCommissionUnit
    brokerContractCommission
    brokerContractBuyerCommission
    brokerContractBuyerCommissionUnit
    brokerContractDate
    brokerContractPrice
    brokerContractType
    expectedSalePrice
    brokerContractTermDate
    expectedSaleNotaryDate
    objectSaleBuyerCommission
    objectSaleBuyerCommissionTaxation
    objectSaleBuyerCommissionUnit
    objectSaleCommission
    objectSaleCommissionTaxation
    objectSaleCommissionUnit
    objectSaleNotaryDate
    objectSalePrice
    marketingCompleted
    saleCompleted
    invoiceSent
    __typename
  }
  tasks {
    nodes {
      ...Task
    }
    __typename
  }
  office {
    __typename
    id
    disabledPaymentMethods
  }
}
    `;
export const PaymentFragmentDoc = gql`
    fragment Payment on Payment {
  id
  owner {
    id
  }
  currency
  items {
    name
    sellable {
      id
    }
    netAmount
    grossAmount
    taxRate
  }
  paymentProvider
  paymentProviderType
  state
  settlementStartedAt
  settledAt
}
    `;
export const RejectLeadDocument = gql`
    mutation RejectLead($id: LeadGlobalIdOrUuid!, $userId: ID!, $comment: String) {
  lead {
    reject(id: $id, userId: $userId, comment: $comment) {
      lead {
        ...LeadWithDetailsAndPropertyAndState
      }
      error {
        actionDetails
        type
        message
      }
    }
  }
}
    ${LeadWithDetailsAndPropertyAndStateFragmentDoc}
${LeadFragmentDoc}
${AssetFragmentDoc}
${TaskFragmentDoc}`;

export function useRejectLeadMutation() {
  return Urql.useMutation<RejectLeadMutation, RejectLeadMutationVariables>(RejectLeadDocument);
};
export const CreatePaymentMandateDocument = gql`
    mutation CreatePaymentMandate($redirectUrlTemplate: String!) {
  paymentMandate {
    create(redirectUrlTemplate: $redirectUrlTemplate) {
      paymentMandate {
        id
        metadata
      }
      error {
        __typename
        type
        actionDetails
        message
      }
    }
  }
}
    `;

export function useCreatePaymentMandateMutation() {
  return Urql.useMutation<CreatePaymentMandateMutation, CreatePaymentMandateMutationVariables>(CreatePaymentMandateDocument);
};
export const ConfirmPaymentMandateDocument = gql`
    mutation ConfirmPaymentMandate($id: PaymentMandateGlobalIdOrUuid!) {
  paymentMandate {
    confirm(id: $id) {
      paymentMandate {
        id
        metadata
      }
      error {
        __typename
        type
        actionDetails
        message
      }
    }
  }
}
    `;

export function useConfirmPaymentMandateMutation() {
  return Urql.useMutation<ConfirmPaymentMandateMutation, ConfirmPaymentMandateMutationVariables>(ConfirmPaymentMandateDocument);
};
export const CreateAssetDocument = gql`
    mutation CreateAsset($attachable: GlobalID!, $title: String, $file: File!, $description: String, $category: String, $filename: String, $public: Boolean, $metadata: JsonBlob) {
  asset {
    create(
      attachable: $attachable
      title: $title
      file: $file
      description: $description
      category: $category
      filename: $filename
      public: $public
      metadata: $metadata
    ) {
      asset {
        ...Asset
      }
      error {
        ...MutationError
      }
    }
  }
}
    ${AssetFragmentDoc}
${MutationErrorFragmentDoc}`;

export function useCreateAssetMutation() {
  return Urql.useMutation<CreateAssetMutation, CreateAssetMutationVariables>(CreateAssetDocument);
};
export const DeleteAssetDocument = gql`
    mutation DeleteAsset($id: AssetGlobalIdOrUuid!) {
  asset {
    delete(id: $id) {
      asset {
        ...Asset
      }
      error {
        ...MutationError
      }
    }
  }
}
    ${AssetFragmentDoc}
${MutationErrorFragmentDoc}`;

export function useDeleteAssetMutation() {
  return Urql.useMutation<DeleteAssetMutation, DeleteAssetMutationVariables>(DeleteAssetDocument);
};
export const UpdateMandatoryTaskDocument = gql`
    mutation UpdateMandatoryTask($id: LeadGlobalIdOrUuid!, $stateProperties: LeadUpdateMandatoryTaskStateProperties, $closeTask: Boolean) {
  lead {
    updateMandatoryTask(
      id: $id
      stateProperties: $stateProperties
      closeTask: $closeTask
    ) {
      lead {
        ...LeadWithDetailsAndPropertyAndState
      }
      error {
        ...MutationError
      }
    }
  }
}
    ${LeadWithDetailsAndPropertyAndStateFragmentDoc}
${LeadFragmentDoc}
${AssetFragmentDoc}
${TaskFragmentDoc}
${MutationErrorFragmentDoc}`;

export function useUpdateMandatoryTaskMutation() {
  return Urql.useMutation<UpdateMandatoryTaskMutation, UpdateMandatoryTaskMutationVariables>(UpdateMandatoryTaskDocument);
};
export const CreateTaskDocument = gql`
    mutation CreateTask($title: String!, $description: String, $result: String, $type: String, $status: TaskStatus, $dueDate: DateTime, $referenceGids: [GlobalID!], $ownerGid: GlobalID!, $editable: Boolean) {
  task {
    create(
      title: $title
      description: $description
      status: $status
      type: $type
      dueDate: $dueDate
      result: $result
      referenceGids: $referenceGids
      ownerGid: $ownerGid
      editable: $editable
    ) {
      task {
        ...Task
      }
      error {
        ...MutationError
      }
    }
  }
}
    ${TaskFragmentDoc}
${MutationErrorFragmentDoc}`;

export function useCreateTaskMutation() {
  return Urql.useMutation<CreateTaskMutation, CreateTaskMutationVariables>(CreateTaskDocument);
};
export const UpdateTaskDocument = gql`
    mutation UpdateTask($id: TaskGlobalIdOrUuid!, $title: String, $description: String, $result: String, $status: TaskStatus, $dueDate: DateTime, $referenceGids: [GlobalID!]) {
  task {
    update(
      id: $id
      title: $title
      description: $description
      status: $status
      dueDate: $dueDate
      result: $result
      referenceGids: $referenceGids
    ) {
      task {
        ...Task
      }
      error {
        ...MutationError
      }
    }
  }
}
    ${TaskFragmentDoc}
${MutationErrorFragmentDoc}`;

export function useUpdateTaskMutation() {
  return Urql.useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(UpdateTaskDocument);
};
export const CancelLeadDocument = gql`
    mutation CancelLead($id: LeadGlobalIdOrUuid!, $comment: String!, $reason: LeadReason!) {
  lead {
    close(id: $id, comment: $comment, reason: $reason) {
      lead {
        ...LeadWithDetailsAndPropertyAndState
      }
      error {
        ...MutationError
      }
    }
  }
}
    ${LeadWithDetailsAndPropertyAndStateFragmentDoc}
${LeadFragmentDoc}
${AssetFragmentDoc}
${TaskFragmentDoc}
${MutationErrorFragmentDoc}`;

export function useCancelLeadMutation() {
  return Urql.useMutation<CancelLeadMutation, CancelLeadMutationVariables>(CancelLeadDocument);
};
export const PauseLeadDocument = gql`
    mutation PauseLead($id: LeadGlobalIdOrUuid!, $reason: String!, $date: DateTime!) {
  lead {
    delay(id: $id, reason: $reason, date: $date) {
      lead {
        ...LeadWithDetailsAndPropertyAndState
      }
      error {
        ...MutationError
      }
    }
  }
}
    ${LeadWithDetailsAndPropertyAndStateFragmentDoc}
${LeadFragmentDoc}
${AssetFragmentDoc}
${TaskFragmentDoc}
${MutationErrorFragmentDoc}`;

export function usePauseLeadMutation() {
  return Urql.useMutation<PauseLeadMutation, PauseLeadMutationVariables>(PauseLeadDocument);
};
export const ResumeLeadDocument = gql`
    mutation ResumeLead($id: LeadGlobalIdOrUuid!) {
  lead {
    removeDelay(id: $id) {
      lead {
        ...LeadWithDetailsAndPropertyAndState
      }
      error {
        ...MutationError
      }
    }
  }
}
    ${LeadWithDetailsAndPropertyAndStateFragmentDoc}
${LeadFragmentDoc}
${AssetFragmentDoc}
${TaskFragmentDoc}
${MutationErrorFragmentDoc}`;

export function useResumeLeadMutation() {
  return Urql.useMutation<ResumeLeadMutation, ResumeLeadMutationVariables>(ResumeLeadDocument);
};
export const SellLeadDocument = gql`
    mutation SellLead($id: LeadGlobalIdOrUuid!, $userId: ID!) {
  lead {
    sell(id: $id, userId: $userId) {
      lead {
        ...LeadWithDetailsAndPropertyAndState
      }
      error {
        ...MutationError
      }
    }
  }
}
    ${LeadWithDetailsAndPropertyAndStateFragmentDoc}
${LeadFragmentDoc}
${AssetFragmentDoc}
${TaskFragmentDoc}
${MutationErrorFragmentDoc}`;

export function useSellLeadMutation() {
  return Urql.useMutation<SellLeadMutation, SellLeadMutationVariables>(SellLeadDocument);
};
export const AddUserDocument = gql`
    mutation AddUser($id: LeadGlobalIdOrUuid!, $userId: ID!) {
  lead {
    addUser(id: $id, userId: $userId) {
      lead {
        id
        brokers {
          id
          __typename
        }
      }
      error {
        ...MutationError
      }
    }
  }
}
    ${MutationErrorFragmentDoc}`;

export function useAddUserMutation() {
  return Urql.useMutation<AddUserMutation, AddUserMutationVariables>(AddUserDocument);
};
export const RemoveUserDocument = gql`
    mutation RemoveUser($id: LeadGlobalIdOrUuid!, $userId: ID!) {
  lead {
    removeUser(id: $id, userId: $userId) {
      lead {
        id
        brokers {
          id
          __typename
        }
      }
      error {
        ...MutationError
      }
    }
  }
}
    ${MutationErrorFragmentDoc}`;

export function useRemoveUserMutation() {
  return Urql.useMutation<RemoveUserMutation, RemoveUserMutationVariables>(RemoveUserDocument);
};
export const CreatePaymentDocument = gql`
    mutation CreatePayment($ownerGid: GlobalID!) {
  payment {
    create(ownerGid: $ownerGid) {
      payment {
        ...Payment
      }
      error {
        ...MutationError
      }
    }
  }
}
    ${PaymentFragmentDoc}
${MutationErrorFragmentDoc}`;

export function useCreatePaymentMutation() {
  return Urql.useMutation<CreatePaymentMutation, CreatePaymentMutationVariables>(CreatePaymentDocument);
};
export const DeletePaymentDocument = gql`
    mutation DeletePayment($paymentId: PaymentGlobalIdOrUuid!) {
  payment {
    delete(id: $paymentId) {
      error {
        ...MutationError
      }
    }
  }
}
    ${MutationErrorFragmentDoc}`;

export function useDeletePaymentMutation() {
  return Urql.useMutation<DeletePaymentMutation, DeletePaymentMutationVariables>(DeletePaymentDocument);
};
export const AddLeadToPaymentDocument = gql`
    mutation AddLeadToPayment($paymentId: ID!, $leadId: LeadGlobalIdOrUuid!) {
  lead {
    addToPayment(id: $leadId, paymentId: $paymentId) {
      lead {
        id
      }
      error {
        ...MutationError
      }
    }
  }
}
    ${MutationErrorFragmentDoc}`;

export function useAddLeadToPaymentMutation() {
  return Urql.useMutation<AddLeadToPaymentMutation, AddLeadToPaymentMutationVariables>(AddLeadToPaymentDocument);
};
export const RemoveLeadFromPaymentDocument = gql`
    mutation RemoveLeadFromPayment($paymentId: ID!, $leadId: LeadGlobalIdOrUuid!) {
  lead {
    removeFromPayment(id: $leadId, paymentId: $paymentId) {
      lead {
        id
      }
      error {
        ...MutationError
      }
    }
  }
}
    ${MutationErrorFragmentDoc}`;

export function useRemoveLeadFromPaymentMutation() {
  return Urql.useMutation<RemoveLeadFromPaymentMutation, RemoveLeadFromPaymentMutationVariables>(RemoveLeadFromPaymentDocument);
};
export const AddAccountBalancePackageToPaymentDocument = gql`
    mutation AddAccountBalancePackageToPayment($paymentId: ID!, $accountBalancePackageId: AccountBalancePackageGlobalIdOrUuid!) {
  accountBalancePackage {
    addToPayment(id: $accountBalancePackageId, paymentId: $paymentId) {
      accountBalancePackage {
        id
      }
      error {
        ...MutationError
      }
    }
  }
}
    ${MutationErrorFragmentDoc}`;

export function useAddAccountBalancePackageToPaymentMutation() {
  return Urql.useMutation<AddAccountBalancePackageToPaymentMutation, AddAccountBalancePackageToPaymentMutationVariables>(AddAccountBalancePackageToPaymentDocument);
};
export const RemoveAccountBalancePackageToPaymentFromPaymentDocument = gql`
    mutation RemoveAccountBalancePackageToPaymentFromPayment($paymentId: ID!, $accountBalancePackageId: AccountBalancePackageGlobalIdOrUuid!) {
  accountBalancePackage {
    removeFromPayment(id: $accountBalancePackageId, paymentId: $paymentId) {
      accountBalancePackage {
        id
      }
      error {
        ...MutationError
      }
    }
  }
}
    ${MutationErrorFragmentDoc}`;

export function useRemoveAccountBalancePackageToPaymentFromPaymentMutation() {
  return Urql.useMutation<RemoveAccountBalancePackageToPaymentFromPaymentMutation, RemoveAccountBalancePackageToPaymentFromPaymentMutationVariables>(RemoveAccountBalancePackageToPaymentFromPaymentDocument);
};
export const ChangePaymentProviderDocument = gql`
    mutation ChangePaymentProvider($paymentId: PaymentGlobalIdOrUuid!, $paymentProvider: PaymentPaymentProvider!) {
  payment {
    changeProvider(id: $paymentId, paymentProvider: $paymentProvider) {
      payment {
        ...Payment
      }
      error {
        ...MutationError
      }
    }
  }
}
    ${PaymentFragmentDoc}
${MutationErrorFragmentDoc}`;

export function useChangePaymentProviderMutation() {
  return Urql.useMutation<ChangePaymentProviderMutation, ChangePaymentProviderMutationVariables>(ChangePaymentProviderDocument);
};
export const AuthorizePaymentDocument = gql`
    mutation AuthorizePayment($paymentId: PaymentGlobalIdOrUuid!, $braintreePayment: PaymentAuthorizeBraintreePayment, $gocardlessPayment: PaymentAuthorizeGocardlessPayment) {
  payment {
    authorize(
      id: $paymentId
      gocardlessPayment: $gocardlessPayment
      braintreePayment: $braintreePayment
    ) {
      payment {
        ...Payment
      }
      error {
        ...MutationError
      }
    }
  }
}
    ${PaymentFragmentDoc}
${MutationErrorFragmentDoc}`;

export function useAuthorizePaymentMutation() {
  return Urql.useMutation<AuthorizePaymentMutation, AuthorizePaymentMutationVariables>(AuthorizePaymentDocument);
};
export const DeleteNotificationTokenDocument = gql`
    mutation deleteNotificationToken($id: NotificationTokenGlobalIdOrUuid!) {
  notificationToken {
    delete(id: $id) {
      notificationToken {
        token
        id
      }
      error {
        ...MutationError
      }
    }
  }
}
    ${MutationErrorFragmentDoc}`;

export function useDeleteNotificationTokenMutation() {
  return Urql.useMutation<DeleteNotificationTokenMutation, DeleteNotificationTokenMutationVariables>(DeleteNotificationTokenDocument);
};
export const AddOfficeMemberDocument = gql`
    mutation AddOfficeMember($id: OfficeGlobalIdOrUuid!, $email: String!, $firstName: String!, $lastName: String!, $gender: OfficeGender) {
  office {
    inviteMember(
      id: $id
      gender: $gender
      firstName: $firstName
      lastName: $lastName
      email: $email
    ) {
      office {
        id
      }
      error {
        ...MutationError
      }
    }
  }
}
    ${MutationErrorFragmentDoc}`;

export function useAddOfficeMemberMutation() {
  return Urql.useMutation<AddOfficeMemberMutation, AddOfficeMemberMutationVariables>(AddOfficeMemberDocument);
};
export const RemoveOfficeMemberDocument = gql`
    mutation RemoveOfficeMember($id: OfficeGlobalIdOrUuid!, $userGid: GlobalID!) {
  office {
    removeMember(id: $id, userGid: $userGid) {
      office {
        id
      }
      error {
        ...MutationError
      }
    }
  }
}
    ${MutationErrorFragmentDoc}`;

export function useRemoveOfficeMemberMutation() {
  return Urql.useMutation<RemoveOfficeMemberMutation, RemoveOfficeMemberMutationVariables>(RemoveOfficeMemberDocument);
};
export const LeadDocument = gql`
    query Lead($id: LeadGlobalIdOrUuid!) {
  lead(id: $id) {
    ...LeadWithDetailsAndPropertyAndState
  }
}
    ${LeadWithDetailsAndPropertyAndStateFragmentDoc}
${LeadFragmentDoc}
${AssetFragmentDoc}
${TaskFragmentDoc}`;

export function useLeadQuery(options: Omit<Urql.UseQueryArgs<LeadQueryVariables>, 'query'>) {
  return Urql.useQuery<LeadQuery, LeadQueryVariables>({ query: LeadDocument, ...options });
};
export const OpenImmoDocument = gql`
    query OpenImmo($id: LeadGlobalIdOrUuid!) {
  lead(id: $id) {
    id
    openImmoXml
  }
}
    `;

export function useOpenImmoQuery(options: Omit<Urql.UseQueryArgs<OpenImmoQueryVariables>, 'query'>) {
  return Urql.useQuery<OpenImmoQuery, OpenImmoQueryVariables>({ query: OpenImmoDocument, ...options });
};
export const LeadListDocument = gql`
    query LeadList($limit: Int, $offset: Int, $search: LeadSearchParameters, $sort: [LeadSortParameters!]) {
  leads(limit: $limit, offset: $offset, search: $search, sort: $sort) {
    count
    nodes {
      ...Lead
    }
  }
}
    ${LeadFragmentDoc}`;

export function useLeadListQuery(options?: Omit<Urql.UseQueryArgs<LeadListQueryVariables>, 'query'>) {
  return Urql.useQuery<LeadListQuery, LeadListQueryVariables>({ query: LeadListDocument, ...options });
};
export const LeadListCountDocument = gql`
    query LeadListCount($limit: Int, $search: LeadSearchParameters) {
  leads(limit: $limit, search: $search) {
    count
  }
}
    `;

export function useLeadListCountQuery(options?: Omit<Urql.UseQueryArgs<LeadListCountQueryVariables>, 'query'>) {
  return Urql.useQuery<LeadListCountQuery, LeadListCountQueryVariables>({ query: LeadListCountDocument, ...options });
};
export const UserDocument = gql`
    query User($id: BrokerGlobalIdOrUuid) {
  broker(id: $id) {
    id
    gid
    avatar {
      id
      fileUrl
    }
    preferences {
      id
      gender
      firstName
      lastName
      mobile
    }
  }
}
    `;

export function useUserQuery(options?: Omit<Urql.UseQueryArgs<UserQueryVariables>, 'query'>) {
  return Urql.useQuery<UserQuery, UserQueryVariables>({ query: UserDocument, ...options });
};
export const UsersDocument = gql`
    query Users($ids: [BrokerGlobalIdOrUuid!], $limit: Int, $offset: Int) {
  brokers(search: {ids: $ids}, limit: $limit, offset: $offset) {
    count
    nodes {
      id
      avatar {
        id
        fileUrl
      }
      user {
        email
        id
      }
      preferences {
        id
        firstName
        lastName
      }
      __typename
    }
  }
}
    `;

export function useUsersQuery(options?: Omit<Urql.UseQueryArgs<UsersQueryVariables>, 'query'>) {
  return Urql.useQuery<UsersQuery, UsersQueryVariables>({ query: UsersDocument, ...options });
};
export const PaymentMandatesDocument = gql`
    query PaymentMandates($ownerGid: GlobalID!) {
  paymentMandates(search: {ownerGid: $ownerGid, status: "active"}) {
    nodes {
      gid
      id
      metadata
      status
    }
  }
}
    `;

export function usePaymentMandatesQuery(options: Omit<Urql.UseQueryArgs<PaymentMandatesQueryVariables>, 'query'>) {
  return Urql.useQuery<PaymentMandatesQuery, PaymentMandatesQueryVariables>({ query: PaymentMandatesDocument, ...options });
};
export const InvoiceAddressesDocument = gql`
    query InvoiceAddresses($search: InvoiceAddressSearchParameters!, $limit: Int, $offset: Int) {
  invoiceAddresses(search: $search, offset: $offset, limit: $limit) {
    count
    limit
    nodes {
      accountBalance
      accountBalanceCurrency
      id
    }
    offset
  }
}
    `;

export function useInvoiceAddressesQuery(options: Omit<Urql.UseQueryArgs<InvoiceAddressesQueryVariables>, 'query'>) {
  return Urql.useQuery<InvoiceAddressesQuery, InvoiceAddressesQueryVariables>({ query: InvoiceAddressesDocument, ...options });
};
export const AccountBalancePackagesDocument = gql`
    query AccountBalancePackages($search: AccountBalancePackageSearchParameters!, $limit: Int, $offset: Int) {
  accountBalancePackages(search: $search, offset: $offset, limit: $limit) {
    count
    limit
    nodes {
      id
      gid
      bonus
      price
      totalAmount
    }
    offset
  }
}
    `;

export function useAccountBalancePackagesQuery(options: Omit<Urql.UseQueryArgs<AccountBalancePackagesQueryVariables>, 'query'>) {
  return Urql.useQuery<AccountBalancePackagesQuery, AccountBalancePackagesQueryVariables>({ query: AccountBalancePackagesDocument, ...options });
};
export const OfficesDocument = gql`
    query Offices($search: OfficeSearchParameters!, $limit: Int, $offset: Int) {
  offices(search: $search, offset: $offset, limit: $limit) {
    count
    limit
    nodes {
      employeesCount
      id
      name
      organization {
        id
        name
        __typename
      }
      __typename
    }
  }
}
    `;

export function useOfficesQuery(options: Omit<Urql.UseQueryArgs<OfficesQueryVariables>, 'query'>) {
  return Urql.useQuery<OfficesQuery, OfficesQueryVariables>({ query: OfficesDocument, ...options });
};
export const OfficeDocument = gql`
    query Office($id: OfficeGlobalIdOrUuid!) {
  office(id: $id) {
    id
    name
    leadReceiver {
      id
    }
    __typename
    brokers {
      id
      gid
      __typename
      avatar {
        id
        fileUrl
        __typename
      }
      user {
        id
        email
        status
        createdAt
        __typename
      }
      preferences {
        firstName
        lastName
        gender
        id
        __typename
      }
    }
  }
}
    `;

export function useOfficeQuery(options: Omit<Urql.UseQueryArgs<OfficeQueryVariables>, 'query'>) {
  return Urql.useQuery<OfficeQuery, OfficeQueryVariables>({ query: OfficeDocument, ...options });
};