/* eslint-disable @typescript-eslint/naming-convention,@typescript-eslint/no-restricted-imports */

/*
 * In this file we overwrite/add to types that are automatically generated in graphql.source.ts.
 * Use it to define, for example, JSON blob properties that are not typed backend-side.
 */

import {
  Lead as OriginalLead,
  Payment as OriginalPayment,
  Maybe,
  Scalars,
  ID,
  GlobalId,
  LeadAcceptPaymentMethod as OriginalLeadAcceptPaymentMethod,
} from './graphql.source';

export interface LeadContractPropertiesPartner {
  name: Scalars['String'];
  legal_form: Scalars['String'];
  address?: Maybe<LeadContractPropertiesPartnerAddress>;
}

export interface LeadContractPropertiesPartnerAddress {
  street?: Scalars['String'];
  street_addition?: Scalars['String'];
  zipcode?: Scalars['String'];
  city?: Scalars['String'];
  country_code?: Scalars['String'];
}

export interface LeadContractProperties {
  version: Scalars['String'];
  url: Scalars['String'];
  contact_name: Scalars['String'];
  fee?: Scalars['Float'];
  strike_fee?: Maybe<Scalars['Float']>;
  price_surcharge?: Maybe<Scalars['Float']>;
  free_reason?: Maybe<Scalars['String']>;
  lead_channel?: Maybe<Scalars['String']>;
  lead_category?: Maybe<Scalars['String']>;
  partner?: Maybe<LeadContractPropertiesPartner>;
  validated_with_voice_record?: Maybe<Scalars['Boolean']>;
}

export type PaymentProviderMetadata = {
  token?: Maybe<Scalars['String']>;
};

export type PaymentProvider = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  metadata?: Maybe<PaymentProviderMetadata>;
  payment_nonce?: Maybe<Scalars['String']>;
  payment_type?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['String']>;
};

/**
 * PaymentMethod
 * The supported payment methods of the payment-api.
 */
export enum PaymentMethod {
  CreditCard = 'CreditCard',
  Invoice = 'Invoice',
  PayPal = 'PayPal',
  PayPalAccount = 'PayPalAccount',
  PaymentMandate = 'PaymentMandate',
}

/**
 * LeadAcceptPaymentMethod
 * Deprecated `LeadType` is omitted on type`, and we re-assign our local
 * PaymentMethod here.
 */
export type LeadAcceptPaymentMethod = Omit<
  OriginalLeadAcceptPaymentMethod,
  'type'
> & {
  type: PaymentMethod;
};

/**
 * An ID of a `Payment` entity, or a valid `Payment` GlobalID.
 * GlobalIDs of other entity types are rejected. If provided a
 * GlobalID, it must have the structure
 * `gid://payment-api/Payment/model-id`.
 * FIXME: This is not defined as a type but a scalar only, in schema.graphql.
 */
export type PaymentGlobalIdOrUuid = GlobalId<'payment-api', 'Payment'> | ID;

export type Payment = Omit<OriginalPayment, 'paymentProvider'> & {
  /** The payment provider specific data like nounces, etc. */
  paymentProvider?: Maybe<PaymentProvider>;
};

/** A HAUSGOLD ecosystem lead belongs to a Broker (+User+). A lead is located at the Maklerportal API and contains general data like address and contact information.  rubocop:disable Metrics/ClassLength because of the associations and helpers :reek:TooManyMethods because of the number of workflows */
export type Lead = Omit<OriginalLead, 'contractProperties'> & {
  /** The contract properties of the lead */
  contractProperties?: LeadContractProperties;
};

export * from './graphql.source';
