export type Routes = Record<string, 'authorized' | 'unauthorized' | 'public'>;

/**
 * A list of all known routes including there required authorization state.
 *
 * authorized - Needs the page to be authed.
 * unauthorized - Needs the page to be not authed.
 * public - Can be accessed no matter the auth state.
 */
const routes: Routes = {
  '/': 'authorized',
  '/dashboard': 'authorized',
  '/properties': 'authorized',
  '/search': 'authorized',
  '/lead/[...detailParams]': 'authorized',
  '/confirm-mandate/[...params]': 'authorized',
  '/settings': 'authorized',
  '/support': 'authorized',
  '/help': 'authorized',
  '/help/knowledge/pwa/info': 'authorized',
  '/help/knowledge/pwa/installation': 'authorized',
  '/help/knowledge/pwa/notification': 'authorized',
  '/login': 'unauthorized',
  '/404': 'public',
  '/500': 'public',
  '/logout': 'public',
  '/legal/notice': 'public',
  '/reset-password': 'public',
  '/reset-password/confirm': 'public',
  '/activate-account': 'public',
  '/lead-closed': 'public',
  '/ios-migration': 'public',
};

export default routes;
