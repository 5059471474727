/*
 * This file stores all localStorage keys at one point.
 * The structure should be as following:
 * - Every key variable should be suffixed by `_STORAGE_KEY`.
 * - Every key should be in snake_case for better readability in the browser.
 * - If a key does not contain a specific value but is used as a flag where the presence is checked, the value 'true' should be used.
 */

// Saves the pathname the app was initially opened with, so the page can be restored after login.
export const REDIRECT_PATH_STORAGE_KEY = 'redirect_path';

// Used to restore a paymentId.
export const PAYMENT_ID_STORAGE_KEY = 'payment_id';

// Indicates whether the app is out-of-date and should be updated by the user.
export const FORCE_UPDATE_STORAGE_KEY = 'out_of_date';

// Remembers whether the info modal has been opened up before.
export const INFO_MODAL_STORAGE_KEY = 'credit_deal_info_modal';

// Remembers whether the pwa install modal has been opened up before.
export const PWA_STORAGE_KEY = 'pwa_installation_declined';

// Saves the bundle, needed for the user session.
export const BUNDLE_STORAGE_KEY = 'bundle';
