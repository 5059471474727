import { ErrorBoundary as AppsignalErrorBoundary } from '@appsignal/react';

/**
 * ErrorBoundary
 *
 * Extends AppSignal's ErrorBoundary to include a reset function.
 */
class ErrorBoundary extends AppsignalErrorBoundary {
  resetErrorBoundary = () => {
    this.setState({ error: null });
  };

  render() {
    const { error } = this.state;
    const { children, fallback } = this.props;

    if (error) {
      return fallback ? fallback(this.resetErrorBoundary) : null;
    }

    return children;
  }
}

export default ErrorBoundary;
