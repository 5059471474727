/**
 * `falsyContext` post processor
 *
 * If you want to translate a context-based key and your context is falsy,
 * `i18next` will, by default, return the default key.
 *
 * For example: `t('key', { context: null })` will return the value of `key`.
 *
 * We usually do not want this behaviour, so this postProcessor returns `null`
 * in this case. This impacts all falsy values: `false`, `0`, `'', `null`,
 * `undefined`, `NaN`.
 *
 * To get the default behaviour, use
 * `t('key', { context: null, postProcess: false })`.
 *
 * Note: This does not check if the context actually exists; so if you give
 * a bogus string as a context, it will return the default value.
 *
 * @type {import('i18next').Module}
 */
export default {
  type: 'postProcessor',
  name: 'falsyContext',

  /*
   * ATTENTION:
   *
   * If you delete/disable this, also make sure you delete the replicating
   * behaviour in the react-i18next mock for Jest.
   */
  process(value, key, options) {
    // Disable if `postProcess` is false.
    if ('postProcess' in options && !options.postProcess) return value;

    if ('context' in options && !options.context) return null;

    return value;
  },
};
